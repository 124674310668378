import { TaskPlanItem } from '#models/index';
import { TaskPlanApiService } from '#services-api/index';
import { LoadingIndicatorService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-task-plan-item-create',
  templateUrl: './task-plan-item-create.component.html',
})
export class TaskPlanItemCreateComponent implements OnInit, OnDestroy {
  public taskPlanId: number;
  public subSectionId: number;
  public taskPlanItem: TaskPlanItem = new TaskPlanItem();
  public displayWarningDesc: boolean;
  public sectionId: number;

  //? Subscriptions
  private SUBS$ = new Subscription();

  constructor(
    private taskPlanSvc: TaskPlanApiService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.SUBS$.add(
      this.aRoute.params.subscribe((el) => {
        this.taskPlanId = el['taskPlanId'];
        this.taskPlanItem.TaskPlanSubSectionId = el['subSectionId'];
        this.sectionId = el['sectionId'];
      })
    );

    this.taskPlanItem.DisplayFollowUpWO = false;
    this.taskPlanItem.DisplayComments = false;
    this.taskPlanItem.DisplayImmediateAttentionRequired = false;
    this.taskPlanItem.DisplayNA = false;
    this.taskPlanItem.DisplayPhoto = false;
    this.displayWarningDesc = false;
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }

  public onRedirectButtonClick(): void {
    this._router.navigate(['admin/task-plan-manage', { id: this.taskPlanId, sectionId: this.sectionId }]);
  }

  public createTaskPlanItem(): void {
    this.displayWarningDesc = this.taskPlanSvc.isInvalidString(this.taskPlanItem.Description);
    if (!this.displayWarningDesc) {
      this.loadingIndicatorSvc.show();
      this.SUBS$.add(
        this.taskPlanSvc
          .createTaskPlanItem(this.taskPlanItem)
          .finally(() => this.loadingIndicatorSvc.hide())
          .subscribe((data: any) => {
            if (data.Success) {
              this.onRedirectButtonClick();
            }
          })
      );
    }
  }
}
