import { AuthService } from '#services-shared/index';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private authSvc: AuthService, private router: Router) {}
  public showDisclaimer: boolean;
  public currentUserId: number;

  ngOnInit(): void {
    const currentUser = this.authSvc.getCurrentUser();
    this.currentUserId = currentUser.Id;
    this.showDisclaimer = !currentUser.DisclaimerAccepted;
  }

  public iAccept() {
    this.authSvc.acceptDisclaimer(this.currentUserId);
    this.showDisclaimer = false;
    this.router.navigate(['/wells']);
  }
}
