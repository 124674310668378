import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
  declarations: [NotFoundComponent, GenericErrorComponent, NotAuthorizedComponent],
  imports: [CommonModule],
  providers: [],
})
export class ErrorPagesModule {}
