import { environment } from '#environments/environment';
import { Centralizer } from '#models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CentralizeApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/centralizersetup${method}`;
  }
  //#endregion 'General'

  //#region 'Get'
  public getByCasingStringId(casingStringId: string): Observable<Centralizer[]> {
    return this.http.get<Centralizer[]>(this.getURL(`/getByCasingStringId`), {
      params: { casingStringId: casingStringId },
    });
  }
  //#endregion 'Get'

  //#region 'Post'
  public create(dto: Centralizer): Observable<Centralizer> {
    return this.http.post<Centralizer>(this.getURL(`/create`), dto);
  }

  public createBulk(dto: Centralizer[]): Observable<Centralizer[]> {
    return this.http.post<Centralizer[]>(this.getURL(`/createBulk`), dto);
  }
  //#endregion 'Post'

  //#region 'Put'
  public update(dto: Centralizer): Observable<Centralizer> {
    return this.http.put<Centralizer>(this.getURL(`/update`), dto);
  }

  public updateBulk(dto: Centralizer[]): Observable<Centralizer[]> {
    return this.http.put<Centralizer[]>(this.getURL(`/updateBulk`), dto);
  }
  //#endregion 'Put'

  //#region 'Delete'
  public delete(dto: Centralizer): Observable<{}> {
    return this.http.put<Centralizer>(this.getURL(`/delete`), dto);
  }

  public deleteBulk(dto: Centralizer[]): Observable<{}> {
    return this.http.put(this.getURL(`/deleteBulk`), dto);
  }
  //#endregion 'Delete'
}
