import { ActivityCode, DailyOperation, Job, Timelog } from '#models/index';
import { DailyOperationApiService } from '#services-api/jobs/daily-operation-api.service';
import { JobsApiService } from '#services-api/jobs/jobs-api.service';
import { TimelogApiService } from '#services-api/jobs/timelog-api.service';
import { LoadingIndicatorService } from '#services-shared/loading-indicator.service';
import { TooltipService } from '#services-shared/tooltip.service';
import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Message } from 'primeng/api';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';

@Injectable({
  providedIn: 'root',
})
export class DailyOperationService implements OnDestroy {
  //#region 'Variables'
  public wellId: string;
  public wellName: string;
  public jobID: string;
  public DOP_ID: string;
  public idRecWellBore: string;
  public selectedDOP = 0;
  public TMLSysSeq = 0;

  //? Job
  public selectedJob: Job;

  //? Notifications
  public notificationsTMLDOP: Message[] = [];
  public notificationsDOP: Message[] = [];

  //? Daily Operation Variables
  public formDOP: FormGroup;
  public DOPs: DailyOperation[] = [];
  public DOPNew: DailyOperation;
  public DOPObject: DailyOperation;
  public DOPDelteObject: DailyOperation;

  //? Timelog Variables
  public formTML: FormGroup;
  public TMLNew: Timelog;
  public TMLObject: Timelog;
  public TMLDelteObject: Timelog;
  public TMLs: Timelog[] = [];
  public activityCodes: ActivityCode[] = [];
  public TMLTime: number;

  //? Split Timelog Variables
  public dataToValidateDates: DailyOperation[] = [];
  public dopDateSplit: string;
  public durationP1 = 0;
  public durationP2 = 0;
  public isNormalUpdate = true;

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private _jobs: JobsApiService,
    private _timelog: TimelogApiService,
    private _dailyOperation: DailyOperationApiService,
    private _loader: LoadingIndicatorService,
    public _tooltip: TooltipService
  ) {}

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
    this._loader.hide();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'CRUD - Job'
  public getJobByID() {
    this.SUBS$.add(
      this._jobs.getJobByID(this.jobID).subscribe(
        (data: any) => {
          this.selectedJob = data;
        },
        (e: any) => {
          console.error(e.message);
        }
      )
    );
  }
  //#endregion 'CRUD - Job'

  //#region 'CRUD - Daily Operations'
  public loadDOPs() {
    // console.log('******************** Enter loadDOPs ********************');
    this._loader.show();
    this.SUBS$.add(
      this._dailyOperation
        .getByJobId(this.jobID)
        .finally(() => this._loader.hide())
        .subscribe((dataDOP) => {
          if (dataDOP.length > 0) {
            dataDOP = this.sortDataByStartDate(dataDOP);
            this.DOPs = this._tooltip.deepClone(dataDOP);
            // console.log('DOPs: ', this._tooltip.deepClone(dataDOP));
            // console.log('CALL -> selectDOP(this.selectedDOP)', this.selectedDOP);
            this.selectDOP(this.selectedDOP, true);
          }
        })
    );
  }

  public createNewDOP() {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      if (this.formDOP) {
        this.fetchFormToDOPNew(true);
      }

      this.SUBS$.add(
        this._dailyOperation
          .create(this.DOPNew)
          .finally(() => {
            this._loader.hide();
            this.loadDOPs();
          })
          .subscribe(
            () => {
              resolve({
                key: 'DOPNotify',
                severity: 'success',
                summary: 'Correct!',
                detail: 'Daily Operation Created Successfully',
              });
            },
            (e: any) => {
              console.error(e.message);
              reject({
                key: 'DOPError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'We had a problem while creating, please refresh the page and try again.',
                life: 6000,
              });
            }
          )
      );
    });
  }

  public updateDOP() {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.fetchFormToDOPNew(false);
      this.SUBS$.add(
        this._dailyOperation
          .update(this.DOPNew)
          .finally(() => {
            this._loader.hide();
            this.loadDOPs();
          })
          .subscribe(
            () => {
              resolve({
                key: 'DOPNotify',
                severity: 'success',
                summary: 'Correct!',
                detail: 'Daily Operation Updated Successfully',
              });
            },
            (e: any) => {
              console.error(e.message);
              reject({
                key: 'DOPError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'We had a problem while updating, please refresh the page and try again.',
                life: 6000,
              });
            }
          )
      );
    });
  }

  public deleteDOP() {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        this._dailyOperation
          .delete(this.DOPDelteObject)
          .finally(() => {
            this._loader.hide();
            this.loadDOPs();
          })
          .subscribe(
            () => {
              this.selectedDOP = 0;
              resolve({
                key: 'DOPNotify',
                severity: 'success',
                summary: 'Correct!',
                detail: 'Daily Operation Deleted Successfully',
              });
            },
            (e: any) => {
              console.error(e.message);
              reject({
                key: 'DOPError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'We had a problem while deleting, please refresh the page and try again.',
                life: 6000,
              });
            }
          )
      );
    });
  }
  //#endregion 'CRUD - Daily Operations'

  //#region 'CRUD - Timelog'
  public loadTMLs() {
    // console.log('******************** Enter loadTMLs ********************');
    this._loader.show();
    this.SUBS$.add(
      this._timelog
        .getByDailyOperationId(this.DOP_ID)
        .finally(() => this._loader.hide())
        .subscribe((data) => {
          this.setTMLData(data);
        })
    );
  }

  public loadActivityCodes() {
    this._loader.show();
    this.SUBS$.add(
      this._timelog
        .getActivityCode()
        .finally(() => this._loader.hide())
        .subscribe((data) => {
          this.activityCodes = this._tooltip.deepClone(data);
        })
    );
  }

  public createTML(tml?: Timelog) {
    return new Promise(async (resolve, reject) => {
      if (tml) {
        this.TMLNew = tml;
      } else {
        this._loader.show();
        if (this.formTML) {
          this.fetchFormToTMLNew(true);
        }
      }

      const CREATE$ = this._timelog
        .create(this.TMLNew)
        .finally(() => {
          this._loader.hide();
          this.loadTMLs();
        })
        .subscribe(
          () => {
            resolve({
              key: 'TMLNotify',
              severity: 'success',
              summary: 'Correct!',
              detail: 'Timelog Created Successfully',
            });
          },
          (e: any) => {
            console.error(e.message);
            reject({
              key: 'TMLError',
              severity: 'error',
              summary: 'We got a problem!',
              detail: 'We had a problem while creating, please refresh the page and try again.',
              life: 6000,
            });
          }
        );

      this.SUBS$.add(CREATE$);
    });
  }

  public createTMLBulk(tml: Timelog[]) {
    return new Promise(async (resolve, reject) => {
      this._loader.show();

      const CREATEB$ = this._timelog
        .createBulk(tml)
        .finally(() => {
          this._loader.hide();
          this.loadTMLs();
        })
        .subscribe(
          () => {
            resolve({
              key: 'TMLNotify',
              severity: 'success',
              summary: 'Correct!',
              detail: 'Timelogs Created Successfully',
            });
          },
          (e: any) => {
            console.error(e.message);
            reject({
              key: 'TMLError',
              severity: 'error',
              summary: 'We got a problem!',
              detail: 'We had a problem while creating, please refresh the page and try again.',
              life: 6000,
            });
          }
        );

      this.SUBS$.add(CREATEB$);
    });
  }

  public updateTML() {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.fetchFormToTMLNew(false);
      this.SUBS$.add(
        this.callUpdate(this.TMLNew)
          .finally(() => {
            this._loader.hide();
            this.loadTMLs();
          })
          .subscribe(
            () => {
              resolve({
                key: 'TMLNotify',
                severity: 'success',
                summary: 'Correct!',
                detail: 'Timelog Updated Successfully',
              });
            },
            (e: any) => {
              console.error(e.message);
              reject({
                key: 'TMLError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'We had a problem while updating, please refresh the page and try again.',
                life: 6000,
              });
            }
          )
      );
    });
  }

  public updateTMLBulk(data: Timelog[]) {
    this.SUBS$.add(
      this._timelog.updateBulk(data).subscribe((resp) => {
        // console.log(resp);
      })
    );
  }

  public deleteTML() {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        this._timelog
          .delete(this.TMLDelteObject)
          .finally(() => {
            this._loader.hide();
            this.loadTMLs();
          })
          .subscribe(
            () => {
              resolve({
                key: 'DOPNotify',
                severity: 'success',
                summary: 'Correct!',
                detail: 'Timelog Deleted Successfull',
              });
            },
            (e: any) => {
              console.error(e.message);
              reject({
                key: 'DOPError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'We had a problem while deleting, please refresh the page and try again.',
                life: 6000,
              });
            }
          )
      );
    });
  }

  private callUpdate(data: Timelog): Observable<Timelog> {
    return this._timelog.update(data);
  }

  //#endregion 'CRUD - Timelog'

  //#region 'Daily General Methods'
  public fetchFormToDOPNew(isNew: boolean) {
    // console.log('******************** Enter fetchFormToDOPNew ********************');
    this.DOPNew = new DailyOperation();

    if (isNew) {
      this.DOPNew.WellId.Value = this.wellId;
      this.DOPNew.JobId.Value = this.jobID;
    } else {
      this.DOPNew.WellId = this.DOPObject.WellId;
      this.DOPNew.JobId = this.DOPObject.JobId;
      this.DOPNew.DailyOperationId = this.DOPObject.DailyOperationId;
    }

    this.DOPNew.ReportStartDate.Value = this.formDOP.controls['startDate'].value;
    this.DOPNew.ReportEndDate.Value = this.formDOP.controls['endDate'].value;
    this.DOPNew.OperationsSummary.Value = this.formDOP.controls['summary'].value;
    this.DOPNew.StatusReportingTime.Value = this.formDOP.controls['rptTimeStatus'].value;
    this.DOPNew.ProjectedOperations.Value = this.formDOP.controls['projectedOps'].value;
    this.DOPNew.RigTime.Value = this.formDOP.controls['rigTime'].value;
    this.DOPNew.RigTime.UOM = 'hr';
  }

  public selectDOP(index: number, loadTMLs: boolean) {
    // console.log('******************** Enter selectDOP ********************');
    this.selectedDOP = index;
    // console.log('this.selectedDOP = index -> ', this.selectedDOP);
    // console.log('CALL -> setDataToValidateDates(true)');
    this.setDataToValidateDates(true);
    const current: any = this.DOPs.findIndex((obj) => obj.selected === true);

    if (current !== -1) {
      this.DOPs[current].selected = false;
    }

    if (this.DOPs && this.DOPs[this.selectedDOP]) {
      // console.log('****** Validation this.DOPs && this.DOPs[this.selectedDOP] === true ******');
      this.DOPs[this.selectedDOP].selected = true;
      this.DOPObject = this.DOPs[this.selectedDOP];
      this.DOP_ID = this.DOPObject.DailyOperationId.Value;
      this.dopDateSplit = this.DOPObject.ReportEndDate.Value;
    } else {
      // console.log('****** Validation this.DOPs && this.DOPs[this.selectedDOP] === false ******');
      // console.log('CALL -> DOPDateSplitAddDAY()');
      this.DOPDateSplitAddDAY();
    }

    if (loadTMLs) {
      // console.log('CALL -> loadTMLs()');
      this.loadTMLs();
    }
    // this.resetNotifications();
  }

  public selectDOPForSplit() {
    // console.log('******************** Enter selectDOPForSplit ********************');
    // console.log('selectedDOP => ', this.selectedDOP);
    if (this.DOPs && this.DOPs[this.selectedDOP]) {
      // console.log('****** Validation this.DOPs && this.DOPs[this.selectedDOP] === true ******');
      let lastDOPEndDate = '';
      let currentDOPStartDate = '';

      // const SDOP = this.selectedDOP > 0 ? this.selectedDOP + 1 : this.selectedDOP;
      const HAVE_TIME_PREV = `${moment(this.DOPs[this.selectedDOP + 1].ReportEndDate.Value).get('hour')}${moment(
        this.DOPs[this.selectedDOP + 1].ReportEndDate.Value
      ).get('minute')}`;

      if (Number(HAVE_TIME_PREV) > 0) {
        // console.log('****** Validation Number(HAVE_TIME_PREV) > 0 === true ****** ', HAVE_TIME_PREV);
        lastDOPEndDate = moment(this.DOPs[this.selectedDOP + 1].ReportEndDate.Value).format('MM/DD/YYYY hh:mm:ss');
      } else {
        // console.log('****** Validation Number(HAVE_TIME_PREV) > 0 === false ****** ', HAVE_TIME_PREV);
        lastDOPEndDate = moment(this.DOPs[this.selectedDOP + 1].ReportEndDate.Value).format('MM/DD/YYYY 00:00:00');
      }

      const HAVE_TIME_NEXT = `${moment(this.DOPs[this.selectedDOP].ReportStartDate.Value).get('hour')}${moment(
        this.DOPs[this.selectedDOP].ReportStartDate.Value
      ).get('minute')}`;

      if (Number(HAVE_TIME_NEXT) > 0) {
        // console.log('****** Validation Number(HAVE_TIME_NEXT) > 0 === true ****** ', HAVE_TIME_NEXT);
        currentDOPStartDate = moment(this.DOPs[this.selectedDOP].ReportStartDate.Value).format('MM/DD/YYYY hh:mm:ss');
      } else {
        // console.log('****** Validation Number(HAVE_TIME_NEXT) > 0 === false ****** ', HAVE_TIME_NEXT);
        currentDOPStartDate = moment(this.DOPs[this.selectedDOP].ReportStartDate.Value).format('MM/DD/YYYY 00:00:00');
      }

      // console.log('lastDOPEndDate => ', lastDOPEndDate);
      // console.log('currentDOPStartDate => ', currentDOPStartDate);

      if (moment(lastDOPEndDate).isSame(currentDOPStartDate)) {
        // console.log('****** Validation moment(lastDOPEndDate).isSame(currentDOPStartDate) === true ******');
        this.DOP_ID = this.DOPs[this.selectedDOP].DailyOperationId.Value;
        // console.log('DOP_ID: ', this.DOP_ID);
        // console.log('GOTO -> setDataToValidateDates(true)');
        this.setDataToValidateDates(true);
      } else {
        // console.log('****** Validation moment(lastDOPEndDate).isSame(currentDOPStartDate) === false ******');
        // console.log('GOTO -> setDataToValidateDates(false)');
        this.setDataToValidateDates(false);
      }
    } else {
      // console.log('****** Validation this.DOPs && this.DOPs[this.selectedDOP] === false ******');
      // console.log('CALL -> setDataToValidateDates(false)');
      this.setDataToValidateDates(false);
    }

    // console.log('CALL -> DOPDateSplitAddDAY()');
    this.DOPDateSplitAddDAY();
  }

  public setDataToValidateDates(splice: boolean) {
    // console.log('******************** Enter setDataToValidateDates ********************');

    if (splice) {
      // console.log('****** Validation |if (splice)| === true ****** ', splice);
      this.dataToValidateDates = this._tooltip.deepClone(this.DOPs);
      const tempIndex = this.dataToValidateDates.indexOf(this.dataToValidateDates[this.selectedDOP]);
      this.dataToValidateDates.splice(tempIndex, 1);
    } else {
      // console.log('****** Validation |if (splice)| === false ****** ', splice);
      this.durationP1 = 0;
      this.DOP_ID = null;
      this.dataToValidateDates = this._tooltip.deepClone(this.DOPs);
      // console.log('durationP1 => ', this.durationP1, 'durationP2 => ', this.durationP2);
    }
  }

  public checkDatesExist(_date: any) {
    // TODO: Validate if we can remove the add 1 minute
    const DATE = moment(_date).add(1, 'minutes');
    // console.log('******************** Enter checkDatesExist ********************');
    // console.log('DATE => ', moment(DATE).format('YYYY-MM-DD HH:mm:ss'));

    for (const element of this.dataToValidateDates) {
      const dateExist = moment(DATE).isBetween(element.ReportStartDate.Value, element.ReportEndDate.Value);
      if (dateExist) {
        // console.log('ReportStartDate => ', element.ReportStartDate.Value);
        // console.log('ReportEndDate => ', element.ReportEndDate.Value);
        // console.log('isBetween => ', dateExist);
        return dateExist;
      }
    }

    // console.log('isBetween => ', false);
    return false;
  }

  public DOPDateSplitAddDAY() {
    // console.log('******************** Enter DOPDateSplitAddDAY ********************');
    // console.log('OLD dopDateSplit => ', this.dopDateSplit);
    // console.log('NEW dopDateSplit => ', moment(this.dopDateSplit).add(1, 'days').format('MM/DD/YYYY 00:00'));

    this.dopDateSplit = moment(this.dopDateSplit).add(1, 'days').format('MM/DD/YYYY 00:00');
  }

  public DOPDateSplitRemoveDAY() {
    // console.log('******************** Enter DOPDateSplitRemoveDAY ********************');
    // console.log('OLD dopDateSplit => ', this.dopDateSplit);
    // console.log('NEW dopDateSplit => ', moment(this.dopDateSplit).subtract(1, 'days').format('MM/DD/YYYY 00:00'));

    this.dopDateSplit = moment(this.dopDateSplit).subtract(1, 'days').format('MM/DD/YYYY 00:00');
  }
  //#endregion 'Daily General Methods'

  //#region 'Timelog Methods'
  public fetchFormToTMLNew(isNew: boolean) {
    // console.log('******************** Enter fetchFormToTMLNew ********************');
    this.TMLNew = new Timelog();
    const INACTIVE: any = this.getInactive(this.formTML.controls['inactive'].value);

    if (!isNew) {
      // console.log('****** Validation |if (!isNew)| === true ****** ');
      this.TMLNew.TimeLogId = this.TMLObject.TimeLogId;
      this.TMLNew.SysSeq = this.TMLObject.SysSeq;

      if (this.formTML.controls['sysSeq'].value.lenght === 0) {
        // console.log('****** Validation if (this.formTML.controls["sysSeq"].value.lenght === 0) === true ****** ');
        this.TMLNew.SysSeq.Value = this.TMLSysSeq.toString();
      }
    } else {
      // console.log('****** Validation |if (!isNew)| === false ****** ');
      this.TMLNew.SysSeq.Value = this.TMLSysSeq.toString();
    }

    this.TMLNew.WellId.Value = this.wellId;
    this.TMLNew.DailyOperationId.Value = this.DOP_ID;

    this.TMLNew.Duration.Value = this.formTML.controls['duration'].value;
    this.TMLNew.DepthStart.Value = this.formTML.controls['depthStart'].value;
    this.TMLNew.DepthEnd.Value = this.formTML.controls['depthEnd'].value;
    this.TMLNew.Inactive.Value = INACTIVE;
    this.TMLNew.Activity.Value = this.formTML.controls['activityCode'].value;
    this.TMLNew.Activity.Help = this.formTML.controls['activityDescription'].value;
    this.TMLNew.Comment.Value = this.formTML.controls['comments'].value;
  }

  public calculateTML() {
    // console.log('******************** Enter calculateTML ********************');
    this.TMLTime = 0;
    this.TMLs.map((el: Timelog) => {
      this.TMLTime = this.TMLTime + Number(el.Duration.Value);
      el.DepthEnd.Value = el.DepthEnd.Value.replace(/,/g, '');
      el.DepthStart.Value = el.DepthStart.Value.replace(/,/g, '');
      return el;
    });
  }

  public reOrderTMLRecord(old_index: number, new_index: number) {
    // console.log('******************** Enter reOrderTMLRecord ********************');
    while (old_index < 0) {
      old_index += this.TMLs.length;
    }
    while (new_index < 0) {
      new_index += this.TMLs.length;
    }
    if (new_index >= this.TMLs.length) {
      let k = new_index - this.TMLs.length + 1;
      while (k--) {
        this.TMLs.push(undefined);
      }
    }
    this.TMLs.splice(new_index, 0, this.TMLs.splice(old_index, 1)[0]);
  }

  public getInactive(data: string | boolean) {
    if (typeof data === 'string') {
      if (data === '' || data === 'N') {
        return false;
      } else {
        return true;
      }
    }

    if (typeof data === 'boolean') {
      if (data) {
        return 'Y';
      } else {
        return 'N';
      }
    }

    return null;
  }

  public setTMLData(data: Timelog[]) {
    // console.log('******************** Enter setTMLData ********************');
    data = data.filter((dto) => dto !== null);
    const NOSYS = this._tooltip.deepClone(data.filter((dto) => dto.SysSeq.Value.length === 0));
    let WITHSYS = this._tooltip.deepClone(data.filter((dto) => dto.SysSeq.Value.length > 0));
    this.TMLSysSeq = WITHSYS.length;

    WITHSYS = WITHSYS.sort((a: any, b: any) =>
      Number(a.SysSeq.Value) > Number(b.SysSeq.Value) ? 1 : Number(b.SysSeq.Value) > Number(a.SysSeq.Value) ? -1 : 0
    );

    data = this._tooltip.deepClone([...NOSYS, ...WITHSYS]);
    this.TMLs = this._tooltip.deepClone(data);
    this.TMLObject = this.TMLs[0];

    // console.log('CALL -> calculateTML()');
    this.calculateTML();
  }
  //#endregion 'Timelog Methods'

  //#region 'Notification'
  public showNotification(ntf: any, type: number) {
    this.resetNotifications();
    if (type === 1) {
      this.notificationsTMLDOP = [];
      this.notificationsTMLDOP.push({
        severity: ntf.severity,
        summary: ntf.summary,
        detail: ntf.detail,
      });
    } else if (type === 2) {
      this.notificationsDOP = [];
      this.notificationsDOP.push({
        severity: ntf.severity,
        summary: ntf.summary,
        detail: ntf.detail,
      });
    }

    setTimeout(() => {
      this.notificationsTMLDOP = [];
      this.notificationsDOP = [];
    }, 4000);
  }

  public resetNotifications() {
    this.notificationsTMLDOP = [];
    this.notificationsDOP = [];
  }
  //#endregion 'Notification'

  //#region 'General Methods'
  public isDOPFull() {
    return new Promise(async (resolve, reject) => {
      if (this.TMLTime >= 24) {
        reject({
          key: 'TMLError',
          severity: 'warn',
          summary: 'Timelog Full',
          detail: 'Timelog time is full now (24 hrs only), please verify.',
          life: 6000,
        });
      } else {
        resolve(false);
      }
    });
  }

  public sortDataByStartDate(dataDOP: DailyOperation[]): DailyOperation[] {
    // console.log('******************** Enter sortDataByStartDate ********************');
    dataDOP.sort((a, b) => {
      if (a.ReportStartDate.Value === '' && b.ReportStartDate.Value !== '') {
        return 1;
      } else if (a.ReportStartDate.Value !== '' && b.ReportStartDate.Value === '') {
        return -1;
      } else {
        if (new Date(a.ReportStartDate.Value) > new Date(b.ReportStartDate.Value)) {
          return -1;
        } else if (new Date(a.ReportStartDate.Value) < new Date(b.ReportStartDate.Value)) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    return dataDOP;
  }
  //#endregion 'General Methods'

  //#region 'Split Timelog'
  public loadDOPsSplit(): Observable<DailyOperation[]> {
    // console.log('******************** Enter loadDOPsSplit ********************');
    return this._dailyOperation.getByJobId(this.jobID);
  }

  public loadDOPDto(start: any, end: any) {
    // console.log('******************** Enter loadDOPDto ********************');
    this.DOPNew = new DailyOperation();
    this.DOPNew.WellId.Value = this.wellId;
    this.DOPNew.JobId.Value = this.jobID;

    this.DOPNew.ReportStartDate.Value = start;
    this.DOPNew.ReportEndDate.Value = end;
    this.DOPNew.OperationsSummary.Value = 'Operations Summary generated by split *- OVERFLOW -*';
    this.DOPNew.StatusReportingTime.Value = 'Status at Reporting Time generated by split *- OVERFLOW -*';
    this.DOPNew.ProjectedOperations.Value = 'Projected Operations generated by split *- OVERFLOW -*';

    const duration = moment.duration(moment(end).diff(moment(start)));
    const hours = Math.round(duration.asHours()).toString();
    this.DOPObject.RigTime.Value = hours;

    this.DOPNew.RigTime.Value = hours;
    this.DOPNew.RigTime.UOM = 'hr';
  }

  public loadTMLsSplit(): Observable<Timelog[]> {
    // console.log('******************** Enter loadTMLsSplit ********************');
    this._loader.show();
    return this._timelog.getByDailyOperationId(this.DOP_ID);
  }

  public loadTMLDto(useDurationP1: boolean) {
    // console.log('******************** Enter loadTMLDto ********************');
    this.TMLNew = new Timelog();

    this.TMLNew.WellId.Value = this.wellId;
    this.TMLNew.DailyOperationId.Value = this.DOP_ID;
    this.TMLNew.SysSeq.Value = this.TMLSysSeq.toString();

    this.TMLNew.Duration.Value = useDurationP1 ? this.durationP1.toString() : this.durationP2.toString();
    this.TMLNew.DepthStart.Value = this.formTML.controls['depthStart'].value;
    this.TMLNew.DepthEnd.Value = this.formTML.controls['depthEnd'].value;
    this.TMLNew.Inactive.Value = this.formTML.controls['inactive'].value;
    this.TMLNew.Activity.Value = this.formTML.controls['activityCode'].value;
    this.TMLNew.Comment.Value = `OVERFLOW - ${this.formTML.controls['comments'].value}`;
    this.TMLNew.Comment.UOM = `OVERFLOW`;
  }

  public createDOPSplit(): Observable<DailyOperation> {
    // console.log('******************** Enter createDOPSplit ********************');
    // console.log('DOPNew: ', this._tooltip.deepClone(this.DOPNew));
    return this._dailyOperation.create(this.DOPNew);
  }

  public createNewDOPAfterTML(startDate: string, endDate: string) {
    // console.log('******************** Enter createNewDOPAfterTML ********************');
    //? Now that we created our timelog on the current DOP
    //? we have to create the new DOP
    this.formTML.controls['duration'].setValue(this.durationP2);

    // console.log('CALL -> loadDOPDto()');
    this.loadDOPDto(startDate, endDate);

    // console.log('GOTO -> createDOPSplit()');
    this.SUBS$.add(
      this.createDOPSplit().subscribe((data: any) => {
        //? Now in this new DOP we create our new timelog
        this.DOP_ID = data.DailyOperationId.Value;
        // console.log('****** createDOPSplit Completed - In Subscriber ****** ');
        // console.log('DOP_ID => ', this.DOP_ID);

        // console.log('GOTO -> loadDOPsSplit()');
        this.SUBS$.add(
          this.loadDOPsSplit().subscribe((dataDOP) => {
            // console.log('****** loadDOPsSplit Completed - In Subscriber ****** ');

            if (dataDOP.length > 0) {
              // console.log('****** Validation if (dataDOP.length > 0) === true ****** ');
              dataDOP = this.sortDataByStartDate(dataDOP);
              this.DOPs = this._tooltip.deepClone(dataDOP);
              // console.log('DOPs :', this._tooltip.deepClone(dataDOP));

              // console.log('CALL -> validateDurationBeforeCreate()');
              this.validateDurationBeforeCreate();
            }
          })
        );
      })
    );
  }

  public createNewTML(useDurationP1: boolean) {
    // console.log('******************** Enter createNewTML ********************');
    // console.log('useDurationP1 => ', useDurationP1);
    this.formTML.controls['duration'].setValue(useDurationP1 ? this.durationP1 : this.durationP2);
    this.loadTMLDto(useDurationP1);

    // console.log('GOTO -> createTMLSplit()');
    this.SUBS$.add(
      this.createTMLSplit(useDurationP1).subscribe(() => {
        // console.log('****** createTMLSplit Completed - In Subscriber ****** ');
        this.TMLSysSeq = 0;
        this._loader.hide();

        if (useDurationP1) {
          // console.log('****** Validation if (useDurationP1) === true ****** ');
          this.durationP1 = this.durationP2;
          this.formTML.controls['duration'].setValue(this.durationP2);

          // console.log('CALL -> revalidationOfTML()');
          this.revalidationOfTML();
        } else {
          // console.log('****** Validation if (useDurationP1) === false ****** ');
          // console.log('CALL -> loadDOPs()');
          this.loadDOPs();
        }
      })
    );
  }

  public createTMLSplit(fetch: boolean): Observable<Timelog> {
    // console.log('******************** Enter createTMLSplit ********************');

    if (fetch) {
      // console.log('****** Validation if (fetch) === true ****** ');
      this.fetchFormToTMLNew(true);
      this.TMLNew.Duration.Value = this.durationP1.toString();
      this.TMLNew.Comment.Value = `OVERFLOW - ${this.TMLNew.Comment.Value}`;
      this.TMLNew.Comment.UOM = `OVERFLOW`;
    }

    // console.log('this.TMLNew.Duration => ', this.TMLNew.Duration.Value);
    // console.log('this.TMLNew.DailyOperationId => ', this.TMLNew.DailyOperationId.Value);
    return this._timelog.create(this.TMLNew);
  }

  public createTMLOnCurrentDOP() {
    // console.log('******************** Enter createTMLOnCurrentDOP ********************');

    // First we add the first split TML to the current DOP
    // console.log('GOTO -> createTMLSplit()');
    this.SUBS$.add(
      this.createTMLSplit(true).subscribe(() => {
        // Now that we created the TML in the current DOP
        // we need to validate that we can insert the next TML in the next DOP
        // console.log('****** createTMLSplit Completed - In Subscriber ****** ');

        this.TMLSysSeq = 0;
        this.formTML.controls['duration'].setValue(this.durationP2);

        // console.log('CALL -> revalidationOfTML()');
        this.revalidationOfTML();
      })
    );
  }

  public isTMLTimeFull(isEdit: boolean): boolean {
    this.isNormalUpdate = undefined;
    const CURRENT_DURATION = this.TMLObject ? Number(this.TMLObject.Duration.Value) : 0;
    const DURATION = Number(this.formTML.controls['duration'].value);
    const TOTALRIG = DURATION + this.TMLTime;
    const RIG_TIME = Number(this.DOPObject.RigTime.Value);

    // console.log('******************** Enter isTMLTimeFull ********************');
    // console.log('DURATION => ', DURATION);
    // console.log('TMLTime => ', this.TMLTime);
    // console.log('TOTALRIG => ', TOTALRIG);
    // console.log('RIG_TIME => ', RIG_TIME);
    // console.log('CURRENT_DURATION => ', CURRENT_DURATION);

    if (isEdit) {
      // console.log('****** Validation if (isEdit) === true ****** ');
      //? Validate if the Duration has been changed so we call the update, if not we can just update
      if (DURATION === CURRENT_DURATION) {
        // console.log('****** Validation if (DURATION === CURRENT_DURATION) === true ****** ');
        this.isNormalUpdate = true;
        return false;
      } else {
        // console.log('****** Validation if (DURATION === CURRENT_DURATION) === false ****** ');
        //? We need to validate first that the new time with the timelog doesn't exceed the
        //? time available, if not, we call a normal update, but if we exceed we need to update
        //? the new duration and make a split

        const NEW_DURATION = DURATION - CURRENT_DURATION + this.TMLTime;
        if (NEW_DURATION <= RIG_TIME) {
          // console.log('****** Validation if (NEW_DURATION <= RIG_TIME) === true ****** ');
          this.isNormalUpdate = true;
          return false;
        } else {
          // console.log('****** Validation if (NEW_DURATION <= RIG_TIME) === false ****** ');
          const NEW_TMLT = this.TMLTime - CURRENT_DURATION;
          this.durationP1 = RIG_TIME - NEW_TMLT;
          this.durationP2 = DURATION - this.durationP1;
          this.isNormalUpdate = false;
          // console.log('durationP1 => ', this.durationP1, 'durationP2 => ', this.durationP2);
          return false;
        }
      }
    }

    if (TOTALRIG > RIG_TIME) {
      // console.log('****** Validation if (TOTALRIG > RIG_TIME) === true ****** ');
      this.durationP1 = RIG_TIME - this.TMLTime;
      this.durationP2 = TOTALRIG - RIG_TIME;
      // console.log('durationP1 => ', this.durationP1, 'durationP2 => ', this.durationP2);
      return true;
    } else {
      // console.log('****** Validation if (TOTALRIG > RIG_TIME) === false ****** ');
      return false;
    }
  }

  public checkNextDate() {
    // console.log('******************** Enter checkNextDate ********************');
    // this.resetNotifications();
    let NEWDOPSTARTDATE = '';

    const HAVE_TIME = `${moment(this.dopDateSplit).get('hour')}${moment(this.dopDateSplit).get('minute')}`;
    if (Number(HAVE_TIME) > 0) {
      // console.log('****** Validation if (Number(HAVE_TIME) > 0) === true ****** ');
      NEWDOPSTARTDATE = moment(this.dopDateSplit).format('MM/DD/YYYY hh:mm:ss');
    } else {
      // console.log('****** Validation if (Number(HAVE_TIME) > 0) === false ****** ');
      NEWDOPSTARTDATE = moment(this.dopDateSplit).format('MM/DD/YYYY 00:00:00');
    }
    const NEWDOPENDDATE = moment(this.dopDateSplit).add(1, 'days').format('MM/DD/YYYY 00:00');

    // console.log('End Date => ', NEWDOPENDDATE);
    // console.log('Start Date to validate => ', NEWDOPSTARTDATE);

    if (this.checkDatesExist(NEWDOPSTARTDATE)) {
      // console.log('****** Validation if (this.checkDatesExist(NEWDOPSTARTDATE)) === true ****** ');
      //? Dates are not valid, so we add this new Timelog to the next DOP if exist
      //? But we need to validate that there is space for this new time log entry
      //? If not, we need to do all this again

      if (this.durationP1 === 0) {
        // console.log('****** Validation if (this.durationP1 === 0) === true ****** ');
        // this.selectedDOP = this.selectedDOP > 0 ? this.selectedDOP - 1 : this.selectedDOP;
        // console.log('this.selectedDOP -> ', this.selectedDOP);
        this.DOP_ID = this.DOPs[this.selectedDOP].DailyOperationId.Value;
        // console.log('this.DOPs[this.selectedDOP].DailyOperationId.Value => ', this.DOP_ID);
        this.durationP1 = this.durationP2;
        // console.log('durationP1 => ', this.durationP1, 'durationP2 => ', this.durationP2);

        // console.log('GOTO -> createTMLSplit()');
        this.SUBS$.add(
          this.createTMLSplit(true).subscribe(() => {
            // console.log('****** createTMLSplit Completed - In Subscriber ****** ');
            this.TMLSysSeq = 0;
            this.loadDOPs();
          })
        );
      } else {
        // console.log('****** Validation if (this.durationP1 === 0) === false ****** ');
        // console.log('CALL -> createTMLOnCurrentDOP()');
        this.createTMLOnCurrentDOP();
      }
    } else {
      // console.log('****** Validation if (this.checkDatesExist(NEWDOPSTARTDATE)) === false ****** ');
      //? Dates are valid, so we create a new DOP
      //? But first we update the current one so we add the split part

      if (this.durationP1 === 0) {
        // console.log('CALL -> createNewDOPAfterTML()');
        this.createNewDOPAfterTML(NEWDOPSTARTDATE, NEWDOPENDDATE);
      } else {
        // console.log('GOTO -> createTMLSplit()');
        this.SUBS$.add(
          this.createTMLSplit(true).subscribe(() => {
            // console.log('****** createTMLSplit Completed - In Subscriber ****** ');
            this.TMLSysSeq = 0;
            // console.log('CALL -> createNewDOPAfterTML()');
            this.createNewDOPAfterTML(NEWDOPSTARTDATE, NEWDOPENDDATE);
          })
        );
      }
    }
  }

  public validateDurationBeforeCreate() {
    const RIG_TIME = Number(this.DOPObject.RigTime.Value);
    // console.log('******************** Enter validateDurationBeforeCreate ********************');
    // console.log('durationP2 => ', this.durationP2);
    // console.log('RIG_TIME => ', RIG_TIME);

    if (this.durationP2 > RIG_TIME) {
      // console.log('****** Validation if (this.durationP2 > RIG_TIME) === true ****** ');
      this.durationP1 = RIG_TIME;
      this.durationP2 = this.durationP2 - RIG_TIME;
      // console.log('durationP1 => ', this.durationP1, 'durationP2 => ', this.durationP2);
      // console.log('CALL -> createNewTML(true)');
      this.createNewTML(true);
    } else {
      // console.log('****** Validation if (this.durationP2 > RIG_TIME) === false ****** ');
      // console.log('CALL -> createNewTML(false)');
      this.createNewTML(false);
    }
  }

  public revalidationOfTML() {
    // console.log('******************** Enter revalidationOfTML ********************');
    // we have to assign the other part of the duration to make it the current one
    // so we can calculate again if there is space in the next DOP to insert
    this.selectedDOP = this.selectedDOP > 0 ? this.selectedDOP - 1 : this.selectedDOP;
    // console.log('this.selectedDOP -> ', this.selectedDOP);

    // console.log('CALL -> selectDOPForSplit()');
    this.selectDOPForSplit();

    if (this.DOP_ID) {
      // console.log('****** Validation if (this.DOP_ID) === true ****** ');

      // console.log('GOTO -> loadTMLsSplit()');
      this.SUBS$.add(
        this.loadTMLsSplit()
          .finally(() => this._loader.hide())
          .subscribe((data) => {
            // console.log('****** loadTMLsSplit Completed - In Subscriber ****** ');
            // console.log('CALL -> setTMLData()');
            this.setTMLData(data);

            // If next DOP is full, we need to do again all the process of validation
            // to see if there is another DOP so we can insert, if not create it
            // console.log('CALL -> isTMLTimeFull()');
            const ISVALID = this.isTMLTimeFull(false);

            if (ISVALID === true) {
              // console.log('****** Validation if (ISVALID === true) === true ****** ');
              // console.log('CALL -> checkNextDate()');
              // Do again all the process
              this.checkNextDate();
            } else if (ISVALID === false) {
              // console.log('****** Validation if (ISVALID === true) === false ****** ');
              // The next DOP is not full so we can insert the TML
              // console.log('CALL -> validateDurationBeforeCreate()');
              this.validateDurationBeforeCreate();
            }
          })
      );
    } else {
      // console.log('****** Validation if (this.DOP_ID) === false ****** ');

      // console.log('CALL -> DOPDateSplitRemoveDAY()');
      this.DOPDateSplitRemoveDAY();

      // console.log('CALL -> checkNextDate()');
      this.checkNextDate();
    }
  }

  public updateTMLThenSplit() {
    // console.log('******************** Enter updateTMLThenSplit ********************');
    this._loader.show();
    this.fetchFormToTMLNew(false);

    this.TMLNew.Duration.Value = this.durationP1.toString();
    this.TMLNew.Comment.Value = `OVERFLOW - ${this.TMLNew.Comment.Value}`;
    this.TMLNew.Comment.UOM = `OVERFLOW`;

    // console.log('GOTO -> callUpdate()');
    this.SUBS$.add(
      this.callUpdate(this.TMLNew)
        .finally(() => {
          this._loader.hide();
        })
        .subscribe(() => {
          // console.log('****** callUpdate Completed - In Subscriber ****** ');

          // console.log('GOTO -> loadTMLsSplit()');
          this.SUBS$.add(
            this.loadTMLsSplit().subscribe(() => {
              // console.log('****** loadTMLsSplit Completed - In Subscriber ****** ');
              this.durationP1 = 0;
              this.DOPObject.RigTime.Value = '24';

              // console.log('CALL -> checkNextDate()');
              this.checkNextDate();
            })
          );
        })
    );
  }
  //#endregion 'Split Timelog'
}
