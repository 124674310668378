<div class="row">
  <div class="fl">
    <p-fileUpload
      #imgUploads
      name="attachments"
      mode="basic"
      customUpload="true"
      chooseLabel="Select Files"
      maxFileSize="214748364"
      multiple="multiple"
      accept=".jpg,.jpeg,.png,.pdf"
      [auto]="true"
      (onClear)="notify = []"
      (uploadHandler)="onUploadHandler(imgUploads)"
    ></p-fileUpload>
  </div>
</div>
