export class MultipleCasingAdd {
  IsDrillPipe: boolean;
  Quantity: number;
  ItemDesc: string;
  ItemType: string;
  ODNominal: string;
  ID: string;
  WeightLength: string;
  Grade: string;
  TopThread: string;
  BottomThread: string;

  constructor() {
    this.IsDrillPipe = false;
    this.Quantity = undefined;
    this.ItemDesc = '';
    this.ItemType = '';
    this.ODNominal = '';
    this.ID = '';
    this.WeightLength = '';
    this.Grade = '';
    this.TopThread = '';
    this.BottomThread = '';
  }
}
