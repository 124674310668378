<div class="box-shadow module">
  <h2>Create Task Plan</h2>
  <hr />
  <div class="form-horizontal">
    <label style="margin-left: 17%; margin-top: 1%; color: orangered; font: small-caption" *ngIf="displayWarningNote">
      * This Code has already been taken
    </label>
    <br />
    <label style="margin-left: 17%; color: dimgray; font: small-caption">
      [Note] Code name need to be unique, and it cannot be renamed after Task Plan is created.
    </label>
    <div class="form-group">
      <label class="control-label col-md-2">Code</label>
      <div class="col-md-10">
        <input class="form-control text-box single-line" [(ngModel)]="taskPlan.Code" />
        <span class="text-danger field-validation-error" *ngIf="displayWarningCode">
          <span>The Code field is required.</span>
        </span>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-2">Task Plan</label>
      <div class="col-md-10">
        <input class="form-control text-box single-line" [(ngModel)]="taskPlan.Description" />
        <span class="text-danger field-validation-error" *ngIf="displayWarningDesc">
          <span>The Task Plan field is required.</span>
        </span>
      </div>
    </div>
    <div class="form-group">
      <div class="col-md-offset-2 col-md-10">
        <a class="btn btn-default" style="width: 100px" (click)="createTaskPlan()">Create</a>
      </div>
    </div>
  </div>
  <div>
    <a (click)="onRedirectButtonClick()" style="cursor: pointer">Back to Task Plan</a>
  </div>
</div>
