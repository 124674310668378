import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
})
export class HelpComponent implements OnInit {
  //#region 'Variables'
  public link =
    'https://chevron.sharepoint.com/sites/DCComm/Wellview/Lists/New%20Features/Topic.aspx?RootFolder=%2Fsites%2FDCComm%2FWellview%2FNew%20Feature%20%20Training%2FDSR%20Workspace%2FCasing%20Tally%20Tool&FolderCTID=0x0120007109C4D3AB1791469C142F4A527C5C39&View=%7BA3CBAF15%2D8276%2D40E9%2D8F00%2D8D1FC7795FEC%7D';
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor() {}

  ngOnInit() {}
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  //#endregion 'General Methods'
}
