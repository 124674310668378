import { Field } from '#models/shared/field';

export class OfflineActivity {
  IdRec: Field = new Field();
  WellId: Field = new Field();
  JobId: Field = new Field();

  Name: Field = new Field();
  CreationDate: Field = new Field();
  selected?: Boolean = false;
}
