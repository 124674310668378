import { environment } from '#environments/environment';
import { CasingItemDescription } from '#models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CasingComponent, ConnectionThread, ToolTip } from 'models';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root',
})
export class CasingComponentApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/CasingComponent${method}`;
  }

  private getURLRep(method?: string): string {
    return `${environment.apiEndpoint}/reports${method}`;
  }
  //#endregion 'General'

  //#region 'Get'
  public getCasingStringId(casingStringId: string): Observable<CasingComponent[]> {
    return this.http.get<CasingComponent[]>(this.getURL(`/getByCasingStringId`), {
      params: { casingStringId: casingStringId },
    });
  }

  public getCasingComponentMetaData(): Observable<CasingComponent> {
    return this.http.get<CasingComponent>(this.getURL(`/getMetaData`));
  }

  public getRunningByCasingStringId(casingStringId: string): Observable<CasingComponent[]> {
    return this.http.get<CasingComponent[]>(this.getURL(`/GetRunningByCasingStringId`), {
      params: { casingStringId: casingStringId },
    });
  }

  public getCasingItemSubType(): Observable<string[]> {
    return this.http.get<string[]>(this.getURL(`/getSubType`));
  }

  public getAllToolTips(): Observable<ToolTip[]> {
    return this.http.get<ToolTip[]>(this.getURL(`/getToolTip`));
  }

  public getCasingItemDescription(): Observable<CasingItemDescription[]> {
    return this.http.get<CasingItemDescription[]>(this.getURL(`/getCasingItemDescription`));
  }

  public getCasingItemConnectionThread(): Observable<ConnectionThread[]> {
    return this.http.get<ConnectionThread[]>(this.getURL(`/getConnectionThread`));
  }

  public getIconDescription(): Observable<[]> {
    return this.http.get<[]>(this.getURL(`/getIconDescription`));
  }
  //#endregion 'Get'

  //#region 'Create'
  public create(casingComponent: CasingComponent): Observable<CasingComponent> {
    return this.http.post<CasingComponent>(this.getURL(`/create`), casingComponent);
  }

  public createBulk(casingComponentBulk: CasingComponent[]): Observable<CasingComponent[]> {
    return this.http.post<CasingComponent[]>(this.getURL(`/createBulk`), casingComponentBulk);
  }
  //#endregion 'Create'

  //#region 'Update'
  public update(casingComponent: CasingComponent): Observable<CasingComponent> {
    return this.http.put<CasingComponent>(this.getURL(`/update`), casingComponent);
  }

  public updateBulk(casingComponentBulk: CasingComponent[]): Observable<CasingComponent[]> {
    return this.http.put<CasingComponent[]>(this.getURL(`/updateBulk`), casingComponentBulk);
  }

  public approveBulk(casingComponentBulk: CasingComponent[], runTime: string): Observable<CasingComponent[]> {
    return this.http.put<CasingComponent[]>(this.getURL(`/approveBulk`), {
      LstDto: casingComponentBulk,
      RunDate: runTime,
    });
  }

  public validateCasing(casingComponent: CasingComponent): Observable<CasingComponent> {
    return this.http.put<CasingComponent>(this.getURL(`/validate`), casingComponent);
  }

  public unvalidateCasing(casingComponent: CasingComponent): Observable<CasingComponent> {
    return this.http.put<CasingComponent>(this.getURL(`/unvalidate`), casingComponent);
  }

  public validateCasingBulk(casingComponentBulk: CasingComponent[]): Observable<CasingComponent[]> {
    return this.http.put<CasingComponent[]>(this.getURL(`/validateBulk`), casingComponentBulk);
  }
  //#endregion 'Update'

  //#region 'Delete'
  public delete(casingComponentId: CasingComponent): Observable<{}> {
    return this.http.put<CasingComponent>(this.getURL(`/delete`), casingComponentId);
  }

  public deleteBulk(casingComponentBulk: CasingComponent[]): Observable<{}> {
    return this.http.put(this.getURL(`/deleteBulk`), casingComponentBulk);
  }
  //#endregion 'Delete'

  //#region 'PDF & Excel'
  public getCasingInventoryPDF(CasingStringId: string): Observable<any> {
    return this.http.get(this.getURLRep(`/getCasingInventoryPDF`), {
      params: {
        CasingStringId: CasingStringId,
      },
      responseType: 'blob',
      observe: 'response',
    });
  }

  public getCasingRunningTallyPDF_AllCasings(CasingStringId: string): Observable<any> {
    return this.http.get(this.getURLRep(`/getCasingRunningTallyPDF/AllCasings`), {
      params: {
        CasingStringId: CasingStringId,
      },
      responseType: 'blob',
      observe: 'response',
    });
  }

  public getCasingRunningTallyPDF_RunOnly(CasingStringId: string): Observable<any> {
    return this.http.get(this.getURLRep(`/getCasingRunningTallyPDF/RunOnly`), {
      params: {
        CasingStringId: CasingStringId,
      },
      responseType: 'blob',
      observe: 'response',
    });
  }

  public getCasingInventoryExcel(CasingStringId: string): Observable<any> {
    return this.http.get<Object>(this.getURLRep(`/getCasingInventoryExcel`), {
      params: {
        CasingStringId: CasingStringId,
      },
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }

  public getCasingRunningTallyExcel_AllCasings(CasingStringId: string): Observable<any> {
    return this.http.get(this.getURLRep(`/getCasingRunningTallyExcel/AllCasings`), {
      params: {
        CasingStringId: CasingStringId,
      },
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }

  public getCasingRunningTallyExcel_RunOnly(CasingStringId: string): Observable<any> {
    return this.http.get(this.getURLRep(`/getCasingRunningTallyExcel/RunOnly`), {
      params: {
        CasingStringId: CasingStringId,
      },
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }
  //#endregion 'PDF & Excel'
}
