<!-- Messages -->
<!-- <div class="row">
  <p-messages id="tml_msg" [(value)]="_sectionDailyOperation.notificationsTMLDOP" [closable]="false"></p-messages>
</div> -->
<!-- Messages -->

<!-- Daily Operations Content -->
<div>
  <!-- Timelog Info -->
  <div class="row mb8">
    <div class="row">
      <div class="col-md-2">
        <div class="divCenter40">
          <span class="tmle">Timelog Entry</span>
        </div>
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-4">
        <div class="tmlt divCenter40">
          <span class="subTitle ml20">Timelog time</span>&nbsp;&nbsp;&nbsp;<span class="rigTime"
            >{{ this._sectionDailyOperation.TMLTime | number: '1.2' }} hr</span
          >
        </div>
      </div>
    </div>
  </div>
  <!-- Timelog Info -->

  <!-- Timelog Toolbar -->
  <div class="row">
    <div class="col-md-1">
      <button
        id="btn_new_entry"
        type="button"
        class="btn btn-success normalText centerContent"
        (click)="openEntryModal(false)"
      >
        <i class="pi pi-plus"></i>
        <span class="ml6">New Entry</span>
      </button>
    </div>
    <div class="col-md-2">
      <!-- <button id="btn_merge_act" type="button" class="btn btn-primary normalText centerContent">
        <i class="pi pi-th-large"></i>
        <span class="ml6">Merge Activities</span>
      </button> -->
    </div>
    <div class="col-md-6"></div>
    <div class="col-md-3">
      <div class="divCenter40 fr">
        <button
          id="btn_import_smp"
          type="button"
          class="btn btn-primary normalText centerContent"
          (click)="openSmartProcedures()"
        >
          <i class="pi pi-upload"></i>
          <span class="ml6">Import from smart procedures</span>
        </button>
      </div>
    </div>
  </div>
  <!-- Timelog Toolbar -->

  <!-- Timelog Table -->
  <div class="row paddingLR15">
    <div class="text-left">
      <table class="table table-striped noborder noMarginBottom">
        <!-- Table Headers -->
        <thead class="text-primary">
          <tr>
            <!-- <th class="w2"></th> -->
            <th class="w6">Re-Order</th>
            <th class="w6">Duration</th>
            <th class="w6">Depth Start</th>
            <th class="w6">Depth End</th>
            <th class="w6">Inactive</th>
            <th class="w12">Activity Code</th>
            <th class="w50">Comment</th>
            <th class="w8"></th>
          </tr>
        </thead>
        <!-- Table Headers -->
      </table>
    </div>
    <div class="text-left table-responsive" style="height: 380px; max-height: 380px">
      <table class="table table-striped noborder">
        <!-- Table Body -->
        <tbody id="timelogRecord">
          <tr
            *ngFor="let tl of _sectionDailyOperation.TMLs; let idx = index"
            [ngClass]="{
              overflowComment: tl.Comment.Value.startsWith('OVERFLOW')
            }"
          >
            <!-- <td class="w2">
              <div>
                <p-checkbox id="ccb_merge_0{{ idx }}" name="multiEdit" binary="true"> </p-checkbox>
              </div>
            </td> -->
            <td class="w6">
              <div>
                <i
                  id="icn_up_0{{ idx }}"
                  [ngClass]="{ disable: idx === 0 }"
                  class="pi pi-caret-up reorder"
                  (click)="timelogUp(idx)"
                ></i>
                <i
                  id="icn_down_0{{ idx }}"
                  [ngClass]="{ disable: idx === _sectionDailyOperation.TMLs.length - 1 }"
                  class="pi pi-caret-down reorder"
                  (click)="timelogDown(idx)"
                ></i>
              </div>
            </td>
            <td class="w6">
              <div class="fl">
                <span class="divCenter2">{{ tl.Duration.Value }}</span>
              </div>
              <div class="fl" style="margin-left: 8px">
                <span class="divCenter2">hr</span>
              </div>
            </td>
            <td class="w6">
              <div class="fl">
                <span class="divCenter2">{{ tl.DepthStart.Value | number: '1.2' }}</span>
              </div>
            </td>
            <td class="w6">
              <div class="fl">
                <span class="divCenter2">{{ tl.DepthEnd.Value | number: '1.2' }}</span>
              </div>
            </td>
            <td class="w6">
              <div class="fl">
                <span class="divCenter2"> {{ tl.Inactive.Value }}</span>
              </div>
            </td>
            <td class="w12">
              <div>
                <span class="divCenter2">{{ tl.Activity.Value }}</span>
              </div>
            </td>
            <td class="w50">
              <div>
                <span>{{ tl.Comment.Value }}</span>
              </div>
            </td>
            <td class="w8">
              <div id="div_edit_0{{ idx }}" class="fl" (click)="editTimeLogRecord(tl)">
                <i class="pi pi-pencil divCenter2 edtIcon"></i>
              </div>
              <div id="div_delete_0{{ idx }}" class="fl" style="margin-left: 8px" (click)="deleteTML(tl)">
                <i class="pi pi-trash divCenter2 dltIcon"></i>
              </div>
            </td>
          </tr>
        </tbody>
        <!-- Table Body -->
      </table>
    </div>
  </div>
  <!-- Timelog Table -->
</div>
<!-- Daily Operations Content -->

<!-- Create New Entry / Merge Activities / Edit Entry -->
<p-dialog
  [(visible)]="showCreateNewEntry"
  [style]="{ width: '660px', height: 'auto' }"
  [contentStyle]="{ overflow: 'auto' }"
  [baseZIndex]="5000"
  [modal]="true"
  [showHeader]="false"
  [closeOnEscape]="false"
>
  <form [formGroup]="formTimeLog">
    <br />
    <div class="row">
      <div class="col-md-12">
        <span class="titleCreateNew">Timelog Entry</span>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-3">
        <div class="row">
          <div>
            <span class="rigTime"><span class="mark-asterisk">*</span> Duration</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 noPaddingLeft">
            <div>
              <input
                id="txt_dlg_duration"
                type="number"
                onkeyup="if(this.value < 0){this.value = this.value * -1}"
                min="1"
                max="24"
                class="form-control"
                formControlName="duration"
                onfocus="this.select();"
              />
            </div>
          </div>
          <div class="col-md-2 noPadding">
            <span class="divCenter2">hr</span>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <div><span class="rigTime">Depth Start</span></div>
        </div>
        <div class="row">
          <div class="col-md-10 noPaddingLeft">
            <div>
              <input
                id="txt_dlg_depth_start"
                type="number"
                onkeyup="if(this.value < 0){this.value = this.value * -1}"
                min="0"
                class="form-control"
                formControlName="depthStart"
                onfocus="this.select();"
              />
            </div>
          </div>
          <div class="col-md-2 noPadding">
            <span class="divCenter2">ft</span>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <div><span class="rigTime">Depth End</span></div>
        </div>
        <div class="row">
          <div class="col-md-10 noPaddingLeft">
            <div>
              <input
                id="txt_dlg_depth_end"
                type="number"
                onkeyup="if(this.value < 0){this.value = this.value * -1}"
                min="0"
                class="form-control"
                formControlName="depthEnd"
                onfocus="this.select();"
              />
            </div>
          </div>
          <div class="col-md-2 noPadding">
            <span class="divCenter2">ft</span>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <div><span class="rigTime">Inactive</span></div>
        </div>
        <div class="row">
          <div class="col-md-12 noPaddingLeft">
            <div>
              <p-checkbox id="ccb_dlg_inactive" name="multiEdit" binary="true" formControlName="inactive"> </p-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-3">
        <div class="row">
          <span class="rigTime"><span class="mark-asterisk">*</span> Activity Code</span>
        </div>
        <div class="row">
          <div id="div_dlg_open_activity" class="fl" (click)="openActivityCodes()">
            <i class="pi pi-pencil divCenter2 edtIcon"></i>
          </div>
          <div *ngIf="activityToEdit" class="fl">
            <span class="divCenter2">{{ activityToEdit.Value }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="row"><span class="rigTime">Activity Description</span></div>
        <div class="row">
          <div *ngIf="activityToEdit" class="col-md-12 noPaddingLeft">
            <span>{{ activityToEdit.Help }}</span>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <span class="rigTime"><span class="mark-asterisk">*</span> Comments</span>
        </div>
        <div class="row">
          <div class="col-md-12 noPaddingLeft">
            <textarea id="txt_dlg_comments" maxlength="1800" formControlName="comments"></textarea>
          </div>
        </div>
      </div>
    </div>
    <br *ngIf="showErrorcreateTML || showErrorDurationSteps" />
    <div *ngIf="showErrorcreateTML" class="row">
      <div class="col-md-12">
        <span class="errorSpan">-Please fill all the fields with (*).</span>
      </div>
    </div>
    <div *ngIf="showErrorDurationSteps" class="row">
      <div class="col-md-12">
        <span class="errorSpan"
          >-Current duration exceeds timelog available <b>({{ currentTimelogAvailable }} hr)</b> time, please
          check.</span
        >
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="fr mr14">
          <button id="btn_dlg_create" *ngIf="!isTimeUpdate" type="button" class="btn btn-primary" (click)="createTML()">
            Create
          </button>
          <button id="btn_dlg_update" *ngIf="isTimeUpdate" type="button" class="btn btn-primary" (click)="updateTML()">
            Update
          </button>
          <button id="btn_dlg_cancel" type="button" class="btn btn-danger ml8" (click)="closeEntryModal()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
<!-- Create New Entry / Merge Activities / Edit Entry -->

<!-- Create from Steps -->
<p-dialog
  [(visible)]="showCreateMultipleEntry"
  styleClass="tmlDoDialogSteps"
  [style]="{ width: '920px', height: 'auto' }"
  [contentStyle]="{ overflow: 'auto' }"
  [baseZIndex]="5000"
  [modal]="true"
  [showHeader]="false"
  [closeOnEscape]="false"
>
  <div class="row">
    <div class="table-responsive">
      <table class="table table-striped noborder">
        <thead>
          <tr>
            <td class="subTitle w6"><span>Delete</span></td>
            <td class="subTitle w10 tac"><span>Duration (hr)</span></td>
            <td class="subTitle w10 tac"><span>Depth Start (ft)</span></td>
            <td class="subTitle w10 tac"><span>Depth End (ft)</span></td>
            <td class="subTitle w6"><span>Inactive</span></td>
            <td class="subTitle w18"><span>Activity Code</span></td>
            <td class="subTitle w40"><span>Comments</span></td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tlfs of timelogsFromSteps; let idx = index">
            <td class="w6">
              <div id="div_stp_delete_timelog_0{{ idx }}" class="fl" (click)="deleteFromTimeLog(idx)">
                <i *ngIf="timelogsFromSteps.length > 1" class="pi pi-trash dltIcon"></i>
              </div>
            </td>
            <td class="w10">
              <div>
                <input
                  id="txt_stp_duration_0{{ idx }}"
                  type="number"
                  onkeyup="if(this.value < 0){this.value = this.value * -1}"
                  min="1"
                  class="form-control"
                  [(ngModel)]="tlfs.Duration.Value"
                />
              </div>
            </td>
            <td class="w10">
              <div>
                <input
                  id="txt_stp_depth_start_0{{ idx }}"
                  type="number"
                  onkeyup="if(this.value < 0){this.value = this.value * -1}"
                  min="0"
                  class="form-control"
                  [(ngModel)]="tlfs.DepthStart.Value"
                />
              </div>
            </td>
            <td class="w10">
              <div>
                <input
                  id="txt_stp_depth_end_0{{ idx }}"
                  type="number"
                  onkeyup="if(this.value < 0){this.value = this.value * -1}"
                  min="0"
                  class="form-control"
                  [(ngModel)]="tlfs.DepthEnd.Value"
                />
              </div>
            </td>
            <td class="w6">
              <div>
                <p-checkbox
                  id="ccb_stp_inactive_0{{ idx }}"
                  name="multiEdit"
                  binary="true"
                  [(ngModel)]="tlfs.Inactive.Value"
                >
                </p-checkbox>
              </div>
            </td>
            <td class="w18 noPadding">
              <div class="row">
                <div id="div_stp_open_activity_0{{ idx }}" class="fl" (click)="openActivityCodes(idx)">
                  <i class="pi pi-pencil divCenter2 edtIcon"></i>
                </div>
                <div class="fl">
                  <span class="divCenter2">{{ tlfs.Activity.Value }}</span>
                </div>
              </div>
            </td>
            <td class="w40">
              <div class="noPaddingLeft">
                <textarea
                  id="txt_stp_comment_0{{ idx }}"
                  maxlength="1800"
                  style="height: auto"
                  [(ngModel)]="tlfs.Comment.Value"
                ></textarea>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <span><b>NOTE:</b> If you want to create all <b>AS ONE RECORD</b>, please only fill the first row.</span>
    </div>
  </div>
  <br />
  <div *ngIf="showErrorCreateMultiTimeLog" class="row">
    <div class="col-md-12">
      <span class="errorSpan">-Please fill all the missing fields (Activity Code, Comments).</span>
    </div>
  </div>
  <div *ngIf="showErrorDurationSteps" class="row">
    <div class="col-md-12">
      <span class="errorSpan"
        >-Current duration <b>({{ currentDurationFromSteps }} hr)</b> exceeds timelog available
        <b>({{ currentTimelogAvailable }} hr)</b> time, please check.</span
      >
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-6"></div>
    <div class="col-md-6">
      <button
        id="btn_stp_cancel"
        type="button"
        class="btn btn-danger normalText fr ml6"
        (click)="closeMultipleTimelogModal()"
      >
        Cancel
      </button>
      <button id="btn_stp_separate" type="button" class="btn btn-primary normalText fr ml6" (click)="createTMLBulk()">
        Create SEPARATELY
      </button>
      <button id="btn_stp_one" type="button" class="btn btn-primary normalText fr" (click)="concatTimelogs()">
        Create as ONE
      </button>
    </div>
  </div>
</p-dialog>
<!-- Create from Steps -->

<!-- Activities SideBar -->
<p-sidebar
  [(visible)]="displayActivityConfigurations"
  [showCloseIcon]="true"
  [dismissible]="true"
  styleClass="ui-sidebar-lg"
  position="right"
  [style]="{ overflow: 'auto' }"
  [baseZIndex]="10000"
>
  <br />
  <p-table
    #dt
    id="btn_sdb_select"
    [value]="_sectionDailyOperation.activityCodes"
    selectionMode="single"
    (onRowSelect)="activityCodeSelect($event)"
    class="normalText"
  >
    <ng-template pTemplate="caption">
      <span class="normalText"> Select Activity Code </span>
    </ng-template>
    <ng-template pTemplate="header">
      <tr class="normalText">
        <th class="w30">Activity</th>
        <th class="w70">Description</th>
      </tr>
      <tr class="normalText">
        <th class="w30">
          <i class="pi pi-search"></i>
          <input
            id="txt_sdb_filter_activity"
            class="autoWidth normalText"
            pInputText
            type="text"
            placeholder="Filter"
            (input)="dt.filter($event.target.value, 'Activity', 'contains')"
          />
        </th>
        <th class="w70">
          <i class="pi pi-search"></i>
          <input
            id="txt_sdb_filter_help"
            class="autoWidth normalText"
            pInputText
            type="text"
            placeholder="Filter"
            (input)="dt.filter($event.target.value, 'Help', 'contains')"
          />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr [pSelectableRow]="item" class="normalText">
        <td class="w30">{{ item.Activity }}</td>
        <td class="w70">{{ item.Help }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-sidebar>
<!-- Activities SideBar -->

<!-- Smart Procedures SideBar -->
<p-sidebar
  [(visible)]="showSmartProcedures"
  [showCloseIcon]="true"
  [dismissible]="true"
  styleClass="ui-sidebar-lg"
  position="right"
  [style]="{ overflow: 'auto' }"
  [baseZIndex]="10000"
  (onHide)="resetSPData()"
>
  <app-smart-procedures *ngIf="loadSmartProcedure" (addStep)="onAddStep($event)"></app-smart-procedures>
</p-sidebar>
<!-- Smart Procedures SideBar -->

<!-- Toast -->
<p-toast [style]="{ marginTop: '40px !important' }" key="TMLNotify" [baseZIndex]="6000"></p-toast>
<p-toast [style]="{ marginTop: '40px !important' }" key="TMLError" [baseZIndex]="6000" position="top-center"></p-toast>
<!-- Toast -->
