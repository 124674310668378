<div class="box-shadow module">
  <h2>Business Units</h2>

  <table class="table table-striped table-bordered">
    <thead>
      <tr>
        <th>Name</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let businessUnit of businessUnits">
        <td>{{ businessUnit.Name }}</td>
      </tr>
    </tbody>
  </table>

  <form class="row ui-g" [formGroup]="newBuForm" (ngSubmit)="addBusinessUnit()">
    <div class="form-group ui-xl-2 ui-lg-4 ui-md-6 ui-sm-8">
      <input type="text" pInputText class="ui-g-12" formControlName="name" placeholder="Business Unit Name" />
    </div>
    <div class="form-group ui-xl-1 ui-lg-2 ui-md-3 ui-sm-4">
      <input type="submit" pButton type="submit" class="ui-g-12" [disabled]="!newBuForm.valid" value="Add" />
    </div>
  </form>
</div>
