import { NotificationToast } from '#models/index';
import { SectionDataService } from '#services-shared/index';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  providers: [MessageService],
})
export class FileUploadComponent implements OnInit {
  //#region 'Input, Output, ViewChild'
  @Input() set setFileImportNotify(importStatus: any) {
    if (importStatus === true) {
      this.showNotification({
        key: 'fileNotify',
        severity: 'success',
        summary: 'Correct!',
        detail: `${this.uploadedFileName} was Uploaded.`,
      });
    } else if (importStatus === false) {
      this.showNotification({
        key: 'fileError',
        severity: 'warn',
        summary: 'We got a problem!',
        detail: `${this.uploadedFileName} contains duplicate joint numbers. 
        Please remove duplicates identified below and try again.`,
        life: 6000,
      });
    }
  }

  @Output() importFileSuccess = new EventEmitter<any>();
  //#endregion 'Input, Output, ViewChild'

  //#region 'Variables'
  public uploadedFile: File;
  public uploadedFileName: string;
  public importStatus: boolean;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(private _sectionData: SectionDataService, private _message: MessageService) {}

  ngOnInit() {}
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public uploadFile(file: any, type: number) {
    const dataAlreadyImported = this._sectionData.getImportFileData();
    if (dataAlreadyImported && dataAlreadyImported.length > 0) {
      // Clear Files UploadedList
      file.clear();
      this.showNotification({
        key: 'fileError',
        severity: 'warn',
        summary: 'We got a problem!',
        detail: 'Please Create/Delete Casings before uploading more files.',
        life: 6000,
      });
    } else {
      // Defaulting to only 1 File
      this.uploadedFile = file.files[0];
      this.uploadedFileName = this.uploadedFile.name;
      const splitArray = this.uploadedFileName.split('.');
      const fileExtension = splitArray[splitArray.length - 1];
      const reader = new FileReader();
      reader.readAsArrayBuffer(this.uploadedFile);
      reader.onload = (e: any) => {
        let importedData;
        if (fileExtension === 'xls') {
          let data = '';
          const bytes = new Uint8Array(e.target['result']);
          for (let i = 0; i < bytes.byteLength; i++) {
            data += String.fromCharCode(bytes[i]);
          }
          const workbook = XLSX.read(data, { type: 'binary' });
          const saved_SheetName = workbook.SheetNames[0];
          importedData = XLSX.utils.sheet_to_json(workbook.Sheets[saved_SheetName], { header: 1 });
        } else {
          let data = e.target['result'];
          data = new Uint8Array(data);
          const workbook = XLSX.read(data, { type: 'array' });
          const saved_SheetName = workbook.SheetNames[0];
          importedData = XLSX.utils.sheet_to_json(workbook.Sheets[saved_SheetName], { header: 1 });
        }
        if (importedData.length <= 1) {
          this.showNotification({
            key: 'fileError',
            severity: 'warn',
            summary: 'We got a problem!',
            detail: `${this.uploadedFileName} does not contain any data. Please verify and try again.`,
            life: 6000,
          });
        } else {
          this._sectionData.setImportFileData(importedData);
          // Clear Files UploadedList
          file.clear();
          this.importFileSuccess.emit({ ok: true, type });
        }
      };
    }
  }

  public showPipeTracer() {
    const URL = window.location.href;
    return URL.includes('localhost') || URL.includes('-dev.') || URL.includes('-qa.') || URL.includes('-test.')
      ? true
      : false;
  }
  //#endregion 'General Methods'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._message.add({
      key: msg && msg.key && msg.key.length > 0 ? msg.key : 'fileNotify',
      sticky: msg && msg.key && msg.key != 'fileNotify' ? true : false,
      closable: msg && msg.key && msg.key != 'fileNotify' ? true : false,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life,
    });
  }
  //#endregion 'Notification'
}
