<div class="row">
  <!-- Excel -->
  <div class="fl">
    <p-fileUpload
      #excelUpload
      customUpload="true"
      mode="basic"
      [auto]="true"
      chooseLabel="Excel File"
      uploadLabel="Upload File"
      cancelLabel="Cancel Upload"
      name="excelFile"
      (uploadHandler)="uploadFile(excelUpload, 1)"
      accept=".xlsx,.xlsm,.xls,application/msexcel"
      maxFileSize="1000000"
    ></p-fileUpload>
  </div>
  <!-- Excel -->
  <!-- Pipe Tracer -->
  <div *ngIf="showPipeTracer()" class="ml12 fl">
    <p-fileUpload
      #pipeTracerUpload
      customUpload="true"
      mode="basic"
      [auto]="true"
      chooseLabel="Pipe Tracer File"
      uploadLabel="Upload File"
      cancelLabel="Cancel Upload"
      name="pipeTracerFile"
      (uploadHandler)="uploadFile(pipeTracerUpload, 2)"
      accept=".xlsx,.xlsm,.xls,application/msexcel"
      maxFileSize="1000000"
    ></p-fileUpload>
  </div>
  <!-- Pipe Tracer -->
</div>

<!-- Toast -->
<p-toast
  [style]="{ marginTop: '40px !important' }"
  key="fileNotify"
  position="top-center"
  [baseZIndex]="6000"
></p-toast>
<p-toast
  [style]="{ marginTop: '40px  !important !important' }"
  key="fileError"
  [baseZIndex]="6000"
  position="top-center"
></p-toast>
<!-- Toast -->
