import { environment } from '#environments/environment';
import { DailyOperation } from '#models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DailyOperationApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/dailyOperation${method}`;
  }
  //#endregion 'General'

  //#region 'Get'
  public getByJobId(jobId: string): Observable<DailyOperation[]> {
    return this.http.get<DailyOperation[]>(this.getURL(`/getByJobId`), {
      params: { jobId: jobId },
    });
  }
  //#endregion 'Get'

  //#region 'Post'
  public create(dailyOperation: DailyOperation): Observable<DailyOperation> {
    return this.http.post<DailyOperation>(this.getURL(`/create`), dailyOperation);
  }
  //#endregion 'Post'

  //#region 'Put'
  public update(dailyOperation: DailyOperation): Observable<DailyOperation> {
    return this.http.put<DailyOperation>(this.getURL(`/update`), dailyOperation);
  }
  //#endregion 'Put'

  //#region 'Delete'
  public delete(dailyOperation: DailyOperation): Observable<{}> {
    return this.http.put<DailyOperation>(this.getURL(`/delete`), dailyOperation);
  }
  //#endregion 'Delete'
}
