import { TaskPlanSection } from '#models/index';
import { TaskPlanApiService } from '#services-api/index';
import { LoadingIndicatorService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-task-plan-section-edit',
  templateUrl: './task-plan-section-edit.component.html',
})
export class TaskPlanSectionEditComponent implements OnInit, OnDestroy {
  public sectionId: number;
  public taskPlanSection: TaskPlanSection = new TaskPlanSection();
  public displayWarningDesc: boolean;

  //? Subscriptions
  private SUBS$ = new Subscription();

  constructor(
    private taskPlanSvc: TaskPlanApiService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.SUBS$.add(this.aRoute.params.subscribe((el) => (this.sectionId = el['id'])));
    this.getTaskPlanSectionById(this.sectionId);
    this.displayWarningDesc = false;
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }

  public getTaskPlanSectionById(id: number): void {
    this.loadingIndicatorSvc.show();
    this.SUBS$.add(
      this.taskPlanSvc
        .getTaskPlanSectionById(id)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data: any) => {
          this.taskPlanSection = data;
        })
    );
  }

  public onRedirectButtonClick(): void {
    this._router.navigate([
      'admin/task-plan-manage',
      { id: this.taskPlanSection.TaskPlanId, sectionId: this.sectionId },
    ]);
  }

  public updateTaskPlanSection(): void {
    this.displayWarningDesc =
      this.taskPlanSection.Description === undefined ||
      this.taskPlanSection.Description == null ||
      this.taskPlanSection.Description.length === 0;
    if (!this.displayWarningDesc) {
      this.loadingIndicatorSvc.show();
      this.SUBS$.add(
        this.taskPlanSvc
          .updateTaskPlanSection(this.taskPlanSection)
          .finally(() => this.loadingIndicatorSvc.hide())
          .subscribe(() => {
            this.onRedirectButtonClick();
          })
      );
    }
  }
}
