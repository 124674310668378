import { Field, NotificationToast, Timelog } from '#models/index';
import { DailyOperationService, SmartProceduresService, TooltipService } from '#services-shared/index';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-timelog-do',
  templateUrl: './timelog-do.component.html',
  styleUrls: ['../../timelog.shared.scss'],
  providers: [MessageService],
})
export class TimeLogDOComponent implements OnInit {
  //#region 'Input, Output, ViewChild'
  @Input() public startDate: string;
  @Input() public endDate: string;

  @Output() public message: EventEmitter<any> = new EventEmitter<any>();
  //#endregion 'Input, Output, ViewChild'

  //#region 'Variables'
  public isTimeUpdate: boolean;
  public isTimelogFromSteps: boolean;
  public loadSmartProcedure: boolean;
  public showCreateNewEntry: boolean;
  public showSmartProcedures: boolean;
  public showErrorcreateTML: boolean;
  public showErrorDurationSteps: boolean;
  public showCreateMultipleEntry: boolean;
  public isActivityCallFromSteps: boolean;
  public showErrorCreateMultiTimeLog: boolean;
  public displayActivityConfigurations: boolean;

  public selectedStepIndex: number;
  public currentDurationFromSteps: number;
  public currentTimelogAvailable: number;

  public activityToEdit: Field;

  // Forms
  public formTimeLog: FormGroup;

  // Objects
  public timelogsFromSteps: Timelog[] = [];

  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    public _sectionDailyOperation: DailyOperationService,
    private _smartProcedures: SmartProceduresService,
    public _tooltip: TooltipService,
    private _message: MessageService
  ) {}

  ngOnInit(): void {
    this.loadFormEntry();
    this.loadSmartProcedure = true;
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  public loadFormEntry() {
    this.formTimeLog = new FormGroup({
      duration: new FormControl(0, Validators.required),
      depthStart: new FormControl(0),
      depthEnd: new FormControl(0),
      inactive: new FormControl(false),
      activityCode: new FormControl('', Validators.required),
      activityDescription: new FormControl(''),
      comments: new FormControl('', Validators.required),
      sysSeq: new FormControl(''),
    });
  }
  //#endregion 'Load'

  //#region 'Modals'
  public openEntryModal(isEdit: boolean, isFromSP?: boolean) {
    this._sectionDailyOperation.isDOPFull().then(
      (res) => {
        return res;
      },
      (error) => {
        this.showNotification(error);
        return true;
      }
    );

    if (!isEdit) {
      if (!isFromSP) {
        this.resetFormTimeLog();
      }
      this.activityToEdit = new Field();
    }

    this.isTimeUpdate = isEdit;
    this.showErrorcreateTML = false;
    this.showCreateNewEntry = true;
  }

  public closeEntryModal() {
    this.showCreateNewEntry = false;
  }

  public openMultipleTimelogModal() {
    this.showCreateMultipleEntry = true;
  }

  public closeMultipleTimelogModal() {
    this.showErrorDurationSteps = false;
    this.showCreateMultipleEntry = false;
  }
  //#endregion 'Modals'

  //#region 'SideBar'
  public openActivityCodes(index?: number) {
    if (index >= 0) {
      this.selectedStepIndex = index;
      this.isActivityCallFromSteps = true;
    } else {
      this.selectedStepIndex = undefined;
      this.isActivityCallFromSteps = false;
    }

    this.displayActivityConfigurations = true;
  }

  public closeActivityCodes() {
    this.displayActivityConfigurations = false;
  }

  public activityCodeSelect(e: any) {
    this.editActivityCode(e.data);
    this.closeActivityCodes();
  }

  public openSmartProcedures() {
    this.resetFormTimeLog();
    this._smartProcedures.loadATRData(this.startDate, this.endDate);
    this.showSmartProcedures = true;
    this.loadSmartProcedure = true;
  }

  public closeSmartProcedures() {
    this.showSmartProcedures = false;
    this.loadSmartProcedure = false;
  }
  //#endregion 'SideBar'

  //#region 'Validation'
  private isTimeLogValid(fromStep?: boolean): boolean {
    this.currentTimelogAvailable = 0;

    if (!this.formTimeLog.valid) {
      this.showErrorcreateTML = true;
      return false;
    } else {
      this.showErrorcreateTML = false;
    }

    if (fromStep) {
      if (this.validateDurationAvailable(this.formTimeLog.controls['duration'].value)) {
        this.showErrorDurationSteps = false;
      } else {
        this.showErrorDurationSteps = false;
      }
    }

    return true;
  }

  private areTimelogsValid(tml: Timelog[]) {
    let errCount = 0;
    this.currentTimelogAvailable = 0;
    this.currentDurationFromSteps = 0;
    this.showErrorCreateMultiTimeLog = false;

    tml.forEach((element: Timelog) => {
      this.currentDurationFromSteps = this.currentDurationFromSteps + Number(element.Duration.Value);
      if (!element.Activity.Value || (element.Activity.Value && element.Activity.Value.length === 0)) {
        errCount++;
      }
      if (!element.Comment.Value || (element.Comment.Value && element.Comment.Value.length === 0)) {
        errCount++;
      }
    });
    this.showErrorCreateMultiTimeLog = errCount === 0 ? false : true;
    this.validateDurationAvailable(Number(this.currentDurationFromSteps));

    if (!this.showErrorCreateMultiTimeLog && !this.showErrorDurationSteps) {
      return true;
    } else {
      return false;
    }
  }

  public validateTimelog(tml: Timelog): boolean {
    let errCount = 0;
    if (!tml.Activity.Value || (tml.Activity.Value && tml.Activity.Value.length === 0)) {
      errCount++;
    }
    if (!tml.Comment.Value || (tml.Comment.Value && tml.Comment.Value.length === 0)) {
      errCount++;
    }

    this.showErrorCreateMultiTimeLog = errCount === 0 ? false : true;

    if (!this.showErrorCreateMultiTimeLog) {
      return true;
    } else {
      return false;
    }
  }

  private validateDurationAvailable(duration: number): boolean {
    // Validate if duration don't exceeds the time available
    const RIG_TIME = Number(this._sectionDailyOperation.DOPObject.RigTime.Value);
    this.currentTimelogAvailable = RIG_TIME - this._sectionDailyOperation.TMLTime;
    const AVAILABLE = this.currentTimelogAvailable - duration;
    if (AVAILABLE < 0) {
      this.showErrorDurationSteps = true;
      return false;
    } else {
      this.showErrorDurationSteps = false;
      return true;
    }
  }
  //#endregion 'Validation'

  //#region 'CRUD'
  public createTML() {
    this._sectionDailyOperation.formTML = this.formTimeLog;
    if (this.isTimelogFromSteps) {
      if (this.isTimeLogValid(true)) {
        this._sectionDailyOperation.createTML().then(
          (done: any) => {
            this.showNotification(done);
          },
          (error) => {
            this.showNotification(error);
          }
        );
        this.closeEntryModal();
      }
    } else {
      if (this.isTimeLogValid()) {
        if (this._sectionDailyOperation.isTMLTimeFull(false)) {
          this._sectionDailyOperation.checkNextDate();
          this.closeEntryModal();
        } else {
          this._sectionDailyOperation.createTML().then(
            (done: any) => {
              this.showNotification(done);
            },
            (error) => {
              this.showNotification(error);
            }
          );
          this.closeEntryModal();
        }
      }
    }
  }

  public updateTML() {
    if (this.isTimeLogValid()) {
      this._sectionDailyOperation.formTML = this.formTimeLog;
      if (this._sectionDailyOperation.isTMLTimeFull(true)) {
        this._sectionDailyOperation.checkNextDate();
        this.closeEntryModal();
      } else {
        if (this._sectionDailyOperation.isNormalUpdate) {
          this._sectionDailyOperation.updateTML().then(
            (done: any) => {
              this.showNotification(done);
            },
            (error) => {
              this.showNotification(error);
            }
          );
        } else {
          this._sectionDailyOperation.updateTMLThenSplit();
        }
        this.closeEntryModal();
      }
    }
  }

  public createTMLBulk() {
    if (this.areTimelogsValid(this.timelogsFromSteps)) {
      this._sectionDailyOperation.createTMLBulk(this.timelogsFromSteps).then(
        (done: any) => {
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
      this.closeMultipleTimelogModal();
    }
  }

  public concatTimelogs() {
    this.currentTimelogAvailable = 0;
    this.currentDurationFromSteps = 0;
    const dto: Timelog = this.timelogsFromSteps[0];

    this.currentDurationFromSteps = 0;
    let comments = '';
    this.timelogsFromSteps.map((element) => {
      this.currentDurationFromSteps = this.currentDurationFromSteps + Number(element.Duration.Value);
      comments = `${comments} - ${element.Comment.Value}`;
    });

    if (this.validateTimelog(dto)) {
      if (this.validateDurationAvailable(Number(this.currentDurationFromSteps))) {
        dto.Duration.Value = this.currentDurationFromSteps.toString();
        dto.Comment.Value = comments;
        this._sectionDailyOperation.createTML(dto).then(
          (done: any) => {
            this.showNotification(done);
          },
          (error) => {
            this.showNotification(error);
          }
        );
        this.closeMultipleTimelogModal();
      }
    }
  }

  public deleteTML(dto: Timelog) {
    this._sectionDailyOperation.TMLDelteObject = dto;
    this.message.next({
      key: 'c',
      sticky: true,
      severity: 'warn',
      summary: 'Delete Time Log',
      detail: 'Are you sure you want to delete the selected Time Log?',
      clear: true,
      toastType: 'DeleteTimeLog',
    });
  }
  //#endregion 'CRUD'

  //#region 'General Methods'
  public editDailyOperation() {
    this.isTimeUpdate = true;
    this.formTimeLog.controls['duration'].setValue(this._sectionDailyOperation.TMLObject.Duration.Value);
    this.formTimeLog.controls['depthStart'].setValue(this._sectionDailyOperation.TMLObject.DepthStart.Value);
    this.formTimeLog.controls['depthEnd'].setValue(this._sectionDailyOperation.TMLObject.DepthEnd.Value);
    this.formTimeLog.controls['inactive'].setValue(
      this._sectionDailyOperation.getInactive(this._sectionDailyOperation.TMLObject.Inactive.Value)
    );
    this.formTimeLog.controls['activityCode'].setValue(this._sectionDailyOperation.TMLObject.Activity.Value);
    this.formTimeLog.controls['activityDescription'].setValue(this._sectionDailyOperation.TMLObject.Activity.Help);
    this.formTimeLog.controls['comments'].setValue(this._sectionDailyOperation.TMLObject.Comment.Value);
    this.formTimeLog.controls['sysSeq'].setValue(this._sectionDailyOperation.TMLObject.SysSeq.Value);

    this.activityToEdit = this._sectionDailyOperation.TMLObject.Activity;
    this.openEntryModal(true);
  }

  public resetFormTimeLog() {
    this.formTimeLog.reset();
  }

  public editTimeLogRecord(e: any) {
    this._sectionDailyOperation.TMLObject = this._tooltip.deepClone(e);
    this.editDailyOperation();
    this.showCreateNewEntry = true;
  }

  public editActivityCode(dto: any) {
    if (this.isActivityCallFromSteps) {
      this.timelogsFromSteps[this.selectedStepIndex].Activity.Value = dto.Activity;
    } else {
      this.formTimeLog.controls['activityCode'].setValue(dto.Activity);
      this.formTimeLog.controls['activityDescription'].setValue(dto.Help);

      this.activityToEdit = new Field();
      this.activityToEdit.Value = dto.Activity;
      this.activityToEdit.Help = dto.Help;
    }
  }
  //#endregion 'General Methods'

  //#region 'Smart Procedures'
  public onAddStep(event: any[]) {
    this.timelogsFromSteps = [];
    if (event) {
      this.resetFormTimeLog();
      this.isTimelogFromSteps = true;
      if (event.length === 1) {
        this.formTimeLog.controls['duration'].setValue(event[0].Duration);
        this.formTimeLog.controls['comments'].setValue(event[0].Name);
        this.formTimeLog.controls['activityCode'].setValue(event[0].ActivityCode);
        this.loadSmartProcedure = false;
        this.showSmartProcedures = false;
        this._smartProcedures.stepsTotalDuration = 0;
        this.openEntryModal(false, true);
      } else if (event.length > 1) {
        let sysSeq: number = this._sectionDailyOperation.TMLSysSeq;

        const WELLID = this._sectionDailyOperation.TMLs[0].WellId;
        const WELLBORE = this._sectionDailyOperation.TMLs[0].Wellbore;
        const WELLBORETABLE = this._sectionDailyOperation.TMLs[0].WellboreTable;
        const DAILYOPERATION = this._sectionDailyOperation.TMLs[0].DailyOperationId;

        event.forEach((element) => {
          const dto = new Timelog();
          dto.Duration.Value = element.Duration;
          dto.Comment.Value = element.Name;
          dto.Activity.Value = element.ActivityCode;
          dto.SysSeq.Value = sysSeq.toString();

          dto.WellId = WELLID;
          dto.Wellbore = WELLBORE;
          dto.WellboreTable = WELLBORETABLE;
          dto.DailyOperationId = DAILYOPERATION;

          this.timelogsFromSteps.push(dto);
          sysSeq++;
        });
        this.loadSmartProcedure = false;
        this.showSmartProcedures = false;
        this._smartProcedures.stepsTotalDuration = 0;
        this.openMultipleTimelogModal();
      }
    }
  }

  public deleteFromTimeLog(idx: number) {
    this.timelogsFromSteps.splice(idx, 1);
  }

  public resetSPData() {
    this._smartProcedures.smartProcedureDATA = [];
    this._smartProcedures.smartProcedureDATAOriginal = [];
  }
  //#endregion 'Smart Procedures'

  //#region 'Re-Order'
  public timelogUp(index: number) {
    if (index === 0) {
      return;
    }

    this._sectionDailyOperation.reOrderTMLRecord(index, index - 1);
    this.changeSysSequence(index, index - 1);
  }

  public timelogDown(index: number) {
    if (index === this._sectionDailyOperation.TMLs.length - 1) {
      return;
    }

    this._sectionDailyOperation.reOrderTMLRecord(index, index + 1);
    this.changeSysSequence(index, index + 1);
  }

  private changeSysSequence(firstIndex: number, secondIndex: number) {
    const TML1 = this._sectionDailyOperation.TMLs[firstIndex];
    const TML2 = this._sectionDailyOperation.TMLs[secondIndex];
    TML1.SysSeq.Value = firstIndex.toString();
    TML2.SysSeq.Value = secondIndex.toString();

    const DATATOSAVE: Timelog[] = [];
    DATATOSAVE.push(TML1);
    DATATOSAVE.push(TML2);

    this._sectionDailyOperation.updateTMLBulk(DATATOSAVE);
  }
  //#endregion 'Re-Order'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._message.add({
      key: msg && msg.key && msg.key.length > 0 ? msg.key : 'TMLNotify',
      sticky: msg && msg.key && msg.key != 'TMLNotify' ? true : false,
      closable: msg && msg.key && msg.key != 'TMLNotify' ? true : false,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life,
    });
  }
  //#endregion 'Notification'
}
