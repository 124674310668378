import { AuthService } from '#services-shared/index';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/switchMap';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  //#region 'Variables'
  private refreshTokenInProgress = false;
  private tokenRefreshedSource = new Subject();
  private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(public _auth: AuthService) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addAuthHeader(request);
    return next.handle(request).catch((error) => {
      if (error.status === 401) {
        return this.refreshToken()
          .switchMap(() => {
            request = this.addAuthHeader(request);
            return next.handle(request);
          })
          .catch(() => {
            // tslint:disable-next-line: deprecation
            return Observable.empty();
          });
      }
      // tslint:disable-next-line: deprecation
      return Observable.throw(error);
    });
  }

  private addAuthHeader(request: HttpRequest<any>) {
    const authHeader = this._auth.getAuthorizationHeader();
    if (authHeader) {
      return request.clone({
        setHeaders: {
          Authorization: authHeader,
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre- check=0',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });
    } else {
      return request.clone({
        setHeaders: {
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre- check=0',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });
    }
  }

  private refreshToken() {
    if (this.refreshTokenInProgress) {
      return new Observable((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      this._auth.login();
      this.refreshTokenInProgress = false;
      this.tokenRefreshedSource.next();

      return Observable.of();
    }
  }
  //#endregion 'General Methods'
}
