<!-- Content -->
<div>
  <!-- Messages -->
  <!-- <div class="row">
    <p-messages id="dly_msg" [(value)]="_sectionDailyOperation.notificationsDOP" [closable]="false"></p-messages>
  </div> -->
  <!-- Messages -->

  <!-- Daily Operations Toolbar -->
  <div class="row mb10">
    <div class="col-2">
      <button id="btn_new_daily" type="button" class="btn btn-success centerContent" (click)="openDailyModal(false)">
        <i class="pi pi-plus"></i>
        <span class="ml6">Create Daily Operation</span>
      </button>
    </div>
    <div class="col-2"></div>
    <div class="col-8"></div>
  </div>
  <!-- Daily Operations Toolbar -->

  <!-- Content -->
  <div class="row">
    <!-- Daily Operations List -->
    <div class="tableLeft">
      <table style="width: 100%">
        <tbody>
          <tr
            id="tr_select_dop"
            *ngFor="let do of _sectionDailyOperation.DOPs; let idx = index"
            [ngClass]="{ selected: do.selected }"
            (click)="selectDOP(idx)"
            class="selectedBorder"
          >
            <td class="p6">
              <table width="100%">
                <tr>
                  <td>
                    <div class="row">
                      <div class="col-md-10 noPadding">
                        <span class="tabTitle">
                          <span class="rigTime">Report # </span>
                          {{ _sectionDailyOperation.DOPs.length - idx }}
                        </span>
                        <br />
                        <span class="tabTitle">
                          <span class="rigTime">Summary:</span>
                          {{ do.OperationsSummary.Value }}</span
                        >
                      </div>
                      <div class="col-md-2 noPadding">
                        <div id="div_edit_daily_0{{ idx }}" class="fl" (click)="editDailyOperation(idx)">
                          <i class="pi pi-pencil edtIcon"></i>
                        </div>
                        <div id="div_delete_daily_0{{ idx }}" class="fl" (click)="deleteDOP(do)">
                          <i class="pi pi-trash dltIcon"></i>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <br />
              </table>

              <table width="100%">
                <tr>
                  <td class="w40" valign="bottom">
                    <span class="tabDesc">Start</span>
                  </td>
                  <td class="w40" valign="bottom">
                    <span class="tabDesc">End</span>
                  </td>
                  <td class="w20" valign="bottom">
                    <span class="tabDesc">Rig time</span>
                  </td>
                </tr>
                <tr>
                  <td class="w40" valign="bottom">
                    <span class="rigTime">{{ do.ReportStartDate.Value }}</span>
                  </td>
                  <td class="w40" valign="bottom">
                    <span class="rigTime">{{ do.ReportEndDate.Value }}</span>
                  </td>
                  <td class="w20" valign="bottom">
                    <span class="rigTime">{{ do.RigTime.Value || 0 }} {{ do.RigTime.UOM }}</span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Daily Operations List -->

    <!-- Daily Operations Content -->
    <div class="dailyContent">
      <div class="row" *ngIf="_sectionDailyOperation.DOPObject">
        <!-- Daylight Operations Desc -->
        <div id="dop" class="col-md-7">
          <div class="mt24">
            <span class="subTitle"> Operations Summary </span>
          </div>
          <p>
            <span class="text-data">
              {{ _sectionDailyOperation.DOPObject.OperationsSummary.Value }}
            </span>
          </p>
          <div>
            <span class="subTitle"> Status at Reporting Time </span>
          </div>
          <p>
            <span class="text-data">
              {{ _sectionDailyOperation.DOPObject.StatusReportingTime.Value }}
            </span>
          </p>
        </div>
        <!-- Daylight Operations Desc -->

        <!-- Daylight Content -->
        <div class="col-md-5">
          <div class="row mt24">
            <div class="col-md-4">
              <div>
                <span class="subTitle">Start</span>
              </div>
              <div>
                <span class="rigTime">{{ _sectionDailyOperation.DOPObject.ReportStartDate.Value }}</span>
              </div>
            </div>
            <div class="col-md-4">
              <div>
                <span class="subTitle">End</span>
              </div>
              <div>
                <span class="rigTime">{{ _sectionDailyOperation.DOPObject.ReportEndDate.Value }}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div>
                <span class="subTitle">Rig time</span>
              </div>
              <div>
                <span class="rigTime"
                  >{{ _sectionDailyOperation.DOPObject.RigTime.Value || 0 }}
                  {{ _sectionDailyOperation.DOPObject.RigTime.UOM }}</span
                >
              </div>
            </div>
            <div class="col-md-1">
              <div id="div_edit_daily" class="fl" (click)="editDailyOperation()">
                <i class="pi pi-pencil edtIcon"></i>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12">
              <div><span class="subTitle">Projected Operations</span></div>
              <p>
                <span class="text-data">{{ _sectionDailyOperation.DOPObject.ProjectedOperations.Value }}</span>
              </p>
            </div>
          </div>
        </div>
        <!-- Daylight Content -->
      </div>
      <hr />
      <!-- Time Log Component -->
      <app-timelog-do
        *ngIf="_sectionDailyOperation.DOP_ID"
        [startDate]="_sectionDailyOperation.DOPObject.ReportStartDate.Value"
        [endDate]="_sectionDailyOperation.DOPObject.ReportEndDate.Value"
        (message)="showMessage($event)"
      ></app-timelog-do>
      <!-- Time Log Component -->
    </div>
    <!-- Daily Operations Content -->
  </div>
  <!-- Content -->
</div>
<!-- Content -->

<!-- Create New / Edit Daily Operation -->
<p-dialog
  [(visible)]="showCreateNewDaily"
  [style]="{ width: '750px', height: 'auto' }"
  [contentStyle]="{ overflow: 'auto' }"
  [baseZIndex]="5000"
  [modal]="true"
  [showHeader]="false"
  [closeOnEscape]="false"
>
  <form [formGroup]="formDailyOperation">
    <br />
    <div class="row">
      <div class="col-md-12">
        <span *ngIf="!isDailyUpdate" class="titleCreateNew">Create Daily Operation</span>
        <span *ngIf="isDailyUpdate" class="titleCreateNew">Edit Daily Operation</span>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-4">
        <div class="row">
          <div>
            <span class="rigTime"><span class="mark-asterisk">*</span> Start Date</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 noPaddingLeft">
            <p-calendar
              id="dtp_start_date"
              [showIcon]="true"
              [showTime]="true"
              formControlName="startDate"
            ></p-calendar>
          </div>
          <div *ngIf="showStartDateError" class="col-md-12 noPaddingLeft">
            <span class="errorSpan">Date already exist in a Daily Operation</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row">
          <div>
            <span class="rigTime"><span class="mark-asterisk">*</span> End Date</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 noPaddingLeft">
            <p-calendar id="dtp_end_date" [showIcon]="true" [showTime]="true" formControlName="endDate"></p-calendar>
          </div>
          <div *ngIf="showEndDateError" class="col-md-12 noPaddingLeft">
            <span class="errorSpan">Date already exist in a Daily Operation</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row">
          <span class="rigTime"><span class="mark-asterisk">*</span> Rig time</span>
        </div>
        <div class="row">
          <div class="col-md-10 noPaddingLeft">
            <div>
              <input
                id="txt_rig_time"
                size="6"
                type="number"
                onkeyup="if(this.value < 0){this.value = this.value * -1}"
                min="1"
                max="24"
                class="form-control"
                formControlName="rigTime"
                onfocus="this.select();"
              />
            </div>
          </div>
          <div class="col-md-2 noPadding">
            <div class="divCenter2">
              <span>hr</span>
            </div>
          </div>
          <div *ngIf="showRigTimeError" class="col-md-12 noPaddingLeft">
            <span class="errorSpan">Must be less or equal to 24 Hours or in the range of start and end date time.</span>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <span class="rigTime"><span class="mark-asterisk">*</span> Status at Reporting Time</span>
        </div>
        <div class="row">
          <div class="col-md-12 noPaddingLeft">
            <input id="txt_time_status" size="6" type="text" class="form-control" formControlName="rptTimeStatus" />
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <span class="rigTime"><span class="mark-asterisk">*</span> Operations Summary</span>
        </div>
        <div class="row">
          <div class="col-md-12 noPaddingLeft">
            <textarea id="txt_summary" formControlName="summary"></textarea>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <span class="rigTime"><span class="mark-asterisk">*</span> Projected Operations</span>
        </div>
        <div class="row">
          <div class="col-md-12 noPaddingLeft">
            <textarea id="txt_projected_ops" formControlName="projectedOps"></textarea>
          </div>
        </div>
      </div>
    </div>
    <br *ngIf="showErrorCreateNewDaily || areDatesInvalids" />
    <div *ngIf="showErrorCreateNewDaily" class="row">
      <div class="col-md-12">
        <span class="errorSpan">-Please fill all the fields marked with (*).</span>
      </div>
    </div>
    <div *ngIf="areDatesInvalids" class="row">
      <div class="col-md-12">
        <span class="errorSpan">-Please validate the start and end date.</span>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="fr mr14">
          <button
            id="btn_create"
            *ngIf="!isDailyUpdate"
            type="button"
            class="btn btn-primary"
            (click)="createDailyOperation()"
          >
            Create
          </button>
          <button
            id="btn_update"
            *ngIf="isDailyUpdate"
            type="button"
            class="btn btn-primary"
            (click)="updateDailyOperation()"
          >
            Update
          </button>
          <button id="btn_cancel" type="button" class="btn btn-danger ml8" (click)="closeDailyModal()">Cancel</button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
<!-- Create New / Edit Daily Operation -->

<!-- Toast -->
<p-toast position="center" key="c" (onClose)="onReject()" [modal]="true" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="tac">
      <i class="pi pi-exclamation-triangle fontSize3m"></i>
      <h3>{{ message.summary }}</h3>
      <p>{{ message.detail }}</p>
    </div>
    <div class="ui-g ui-fluid">
      <div class="ui-g-6">
        <button
          id="btn_toast_yes"
          type="button"
          pButton
          (click)="onConfirm()"
          label="Yes"
          class="ui-button-info"
        ></button>
      </div>
      <div class="ui-g-6">
        <button
          id="btn_toast_no"
          type="button"
          pButton
          (click)="onReject()"
          label="No"
          class="ui-button-secondary"
        ></button>
      </div>
    </div>
  </ng-template>
</p-toast>

<p-toast [style]="{ marginTop: '40px !important' }" key="DOPNotify" [baseZIndex]="6000"></p-toast>
<p-toast [style]="{ marginTop: '40px !important' }" key="DOPError" [baseZIndex]="6000" position="top-center"></p-toast>
<!-- Toast -->
