export class CasingString {
  WellId: string;
  WellboreId: string;
  CasingStringId: string;
  CasingDescription: string;
  SetDepth: string;
  RunDate: string;
  CutPullDate: string;
  DepthCutPull: string;
  RKB: number;
  StickUp: number;
  Length: number;
  Yield: number;
  MixWater: number;
  PrevCasingDescription: string;
}
