import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Section, WellBore } from 'models';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root',
})
export class SectionsApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/wellBoreSection${method}`;
  }
  //#endregion 'General'

  //#region 'Get'
  public getWellBoreByID(id: string): Observable<WellBore[]> {
    return this.http.get<WellBore[]>(`${environment.apiEndpoint}/wellbore/getByWellId`, { params: { wellId: id } });
  }

  public getSectionsByWellBoreID(id: string): Observable<Section[]> {
    return this.http.get<Section[]>(this.getURL(`/getByWellboreId`), {
      params: { wellboreId: id },
    });
  }

  public getSectionBySectionID(id: string): Observable<Section> {
    return this.http.get<Section>(this.getURL(`/getByWellboreSectionId`), {
      params: { wellboreSectionId: id },
    });
  }
  //#endregion 'Get'
}
