import { Job, NotificationToast, WellBore } from '#models/index';
import { JobsApiService, SectionsApiService } from '#services-api/index';
import { DailyOperationService, LoadingIndicatorService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
  providers: [MessageService],
})
export class JobsComponent implements OnInit, OnDestroy {
  //#region 'Variables'
  public wellId: string;
  public jobsData: Job[] = [];
  public selectedWellBore: WellBore;
  public wellBoreData: WellBore[];

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _jobs: JobsApiService,
    private _loader: LoadingIndicatorService,
    private _sectionDailyOperation: DailyOperationService,
    private _section: SectionsApiService,
    private _message: MessageService
  ) {}

  ngOnInit(): void {
    this.wellId = this.activatedRoute.snapshot.paramMap.get('wellID');
    this.loadJobs();
    this.loadWellbore(this.wellId);
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
    this._loader.hide();
  }

  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  private loadJobs() {
    this._loader.show();
    this.SUBS$.add(
      this._jobs
        .getByWellId(this.wellId)
        .finally(() => this._loader.hide())
        .subscribe(
          (data: Job[]) => {
            this.jobsData = data;
          },
          () => {
            this.jobsData = [];
            this.showNotification({
              severity: 'error',
              summary: 'We got a problem!',
              detail:
                'There was an error loading Jobs information, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
    );
  }

  private async loadWellbore(wellId: string) {
    this.SUBS$.add(
      await this._section.getWellBoreByID(wellId).subscribe((data: WellBore[]) => {
        this.wellBoreData = data;
        if (data[0]) {
          this.selectedWellBore = data[0];
        }
      })
    );
  }
  //#endregion 'Load'

  //#region 'General Methods'
  public goToDaily(job: Job) {
    this.setJob(job);
    this.router.navigate([
      'daily-operations',
      {
        wellID: this.wellId,
        jobID: job.JobId.Value,
      },
    ]);
  }

  public goToPlanActivities(job: Job) {
    this.setJob(job);
    this.router.navigate([
      'plan-activities',
      {
        wellID: this.wellId,
        jobID: job.JobId.Value,
        wellboreID: this.selectedWellBore.WellboreId,
      },
    ]);
  }

  public setJob(job: Job) {
    this._sectionDailyOperation.selectedJob = job;
    this._sectionDailyOperation.wellId = job.WellId.Value;
    this._sectionDailyOperation.jobID = job.JobId.Value;
    this._sectionDailyOperation.idRecWellBore = this.selectedWellBore.WellboreId;
  }
  //#endregion 'General Methods'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._message.add({
      key: msg && msg.key && msg.key.length > 0 ? msg.key : 'JobNotify',
      sticky: msg && msg.key && msg.key != 'JobNotify' ? true : false,
      closable: msg && msg.key && msg.key != 'JobNotify' ? true : false,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life,
    });
  }
  //#endregion 'Notification'
}
