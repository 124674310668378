import { DailyOperationService } from '#services-shared/index';
import { OfflineActivityService } from '#services-shared/jobs/offline-activity.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-daily-operation-main',
  templateUrl: './daily-operation-main.component.html',
  styleUrls: ['./daily-operation-main.component.scss'],
  providers: [],
})
export class DailyOperationMainComponent implements OnInit, OnDestroy {
  //#region 'Variables'
  public tabName: string = 'offlineActivities';
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private activatedRoute: ActivatedRoute,
    public _sectionDailyOperation: DailyOperationService,
    public _offlineActivity: OfflineActivityService
  ) {
    this._sectionDailyOperation.wellId = this.activatedRoute.snapshot.paramMap.get('wellID');
    this._sectionDailyOperation.jobID = this.activatedRoute.snapshot.paramMap.get('jobID');
    this._offlineActivity.wellId = this.activatedRoute.snapshot.paramMap.get('wellID');
    this._offlineActivity.jobID = this.activatedRoute.snapshot.paramMap.get('jobID');
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
  //#endregion 'Angular Life Cycle'

  //#region 'Tabs'
  public dailyOperations() {
    this.tabName = 'dailyOperations';
  }

  public offlineActivity() {
    this.tabName = 'offlineActivities';
  }
  //#endregion 'Tabs'
}
