<!-- Header -->
<div class="box-shadow module">
  <div class="row">
    <div class="table-responsive">
      <app-well-header *ngIf="wellId != ''" [wellId]="wellId"></app-well-header>
    </div>
  </div>
</div>
<!-- Header -->

<!-- Content -->
<div class="box-shadow module">
  <!-- Notification Msg -->
  <!-- <div class="row">
    <p-messages [(value)]="notifications" [closable]="false"></p-messages>
  </div> -->
  <!-- Notification Msg -->
  <!-- Title -->
  <div class="row">
    <h3>Wellbore Sections</h3>
  </div>
  <!-- Title -->
  <div class="row">
    <div class="col-sm-8"></div>
    <!-- Sub-Title -->
    <div class="col-sm-2">
      <h4 class="pull-right"><span class="boldTextFont">Wellbore :</span></h4>
    </div>
    <!-- Sub-Title -->
    <!-- Wellbore -->
    <div class="col-sm-2">
      <select class="form-control" (change)="wellBoreIDChange()" [(ngModel)]="selectedWellBore">
        <option *ngFor="let item of wellBoreData" [ngValue]="item">{{ item.Name }}</option>
      </select>
    </div>
    <!-- Wellbore -->
  </div>
  <!-- Table -->
  <div class="row" style="height: 444px; max-height: 444px">
    <table
      *ngIf="sectionData && sectionData.length > 0"
      class="table table-striped table-hover table-responsive text-center table-condensed"
    >
      <thead class="text-primary text-bold">
        <tr>
          <td class="w15">Name</td>
          <td class="w15">Hole Size</td>
          <td class="w15">Top Depth</td>
          <td class="w15">Bottom Depth</td>
          <td class="w15">Start Date</td>
          <td class="w15">End Date</td>
          <td class="w10">Actions</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let section of sectionData">
          <td class="w15">{{ section.SectionDescription }}</td>
          <td class="w15">{{ section.HoleSize }}</td>
          <td class="w15">{{ section.ActualTopDepth }}</td>
          <td class="w15">{{ section.ActualBottomDepth }}</td>
          <td class="w15">{{ section.StartDate }}</td>
          <td class="w15">{{ section.EndDate }}</td>
          <td class="w10">
            <button id="btn_casing" class="btn btn-primary" (click)="casingTally(section)">Casing Tally</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Table -->
</div>
<!-- Content -->

<!-- Toast -->
<p-toast [style]="{ marginTop: '40px !important' }" key="SecNotify" [baseZIndex]="6000"></p-toast>
<p-toast [style]="{ marginTop: '40px !important' }" key="SecError" [baseZIndex]="6000" position="top-center"></p-toast>
<!-- Toast -->
