import { TaskPlanSubSection } from '#models/index';

export class TaskPlanSection {
  public General: boolean;
  public Description: string;
  public ID: number;
  public TaskPlanId: number;
  public SortID: number;
  public TaskPlanSubSections: TaskPlanSubSection[];
}
