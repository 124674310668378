import { Injectable } from '@angular/core';

@Injectable()
export class LoadingIndicatorService {
  //#region 'Variables'
  private counter = 0;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor() {}
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public show(): void {
    this.counter++;
  }

  public hide(): void {
    if (this.counter > 0) {
      this.counter--;
    }
  }

  public isVisible(): boolean {
    return this.counter > 0;
  }
  //#endregion 'General Methods'
}
