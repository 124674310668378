import { OrganizationComponent } from '#pages/admin';
import { AdminPagesModule } from '#pages/admin/admin.pages.module';
import { BusinessUnitsComponent } from '#pages/admin/business-units/business-units.component';
import { TaskPlanCreateComponent } from '#pages/admin/task-plan-create/task-plan-create.component';
import { TaskPlanDeleteComponent } from '#pages/admin/task-plan-delete/task-plan-delete.component';
import { TaskPlanEditComponent } from '#pages/admin/task-plan-edit/task-plan-edit.component';
import { TaskPlanItemCreateComponent } from '#pages/admin/task-plan-item-create/task-plan-item-create.component';
import { TaskPlanItemEditComponent } from '#pages/admin/task-plan-item-edit/task-plan-item-edit.component';
import { TaskPlanManageComponent } from '#pages/admin/task-plan-manage/task-plan-manage.component';
import { TaskPlanSectionDeleteComponent } from '#pages/admin/task-plan-section-delete/task-plan-section-delete.component';
import { TaskPlanSectionEditComponent } from '#pages/admin/task-plan-section-edit/task-plan-section-edit.component';
import { TaskPlanSubSectionDeleteComponent } from '#pages/admin/task-plan-sub-section-delete/task-plan-sub-section-delete.component';
import { TaskPlanSubSectionEditComponent } from '#pages/admin/task-plan-sub-section-edit/task-plan-sub-section-edit.component';
import { TaskPlanComponent } from '#pages/admin/task-plan/task-plan.component';
import { UserComponent } from '#pages/admin/user/user.component';
import { DailyOperationMainComponent } from '#pages/jobs/daily-operations/daily-operation-main.component';
import { JobsComponent } from '#pages/jobs/jobs.component';
import { PlanActivitiesComponent } from '#pages/jobs/plan-activities/plan-activities.component';
import { PagesModule } from '#pages/pages.module';
import { CasingTallyComponent } from '#pages/sections/casing-tally/casing-tally.component';
import { SectionsComponent } from '#pages/sections/sections.component';
import { EnvironmentInfoComponent } from '#pages/settings/environment/environment.component';
import { SettingsComponent } from '#pages/settings/settings.component';
import { ErrorPagesModule } from '#pages/shared/error/error.pages.module';
import { GenericErrorComponent } from '#pages/shared/error/generic-error/generic-error.component';
import { NotAuthorizedComponent } from '#pages/shared/error/not-authorized/not-authorized.component';
import { NotFoundComponent } from '#pages/shared/error/not-found/not-found.component';
import { LoginComponent } from '#pages/shared/login/login.component';
import { ViewAttachmentsComponent } from '#pages/shared/view-attachments/view-attachments.component';
import { WellsComponent } from '#pages/wells/wells.component';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { IsApplicationUser, IsSystemAdmin } from './app.routing.guards';
import { HelpComponent } from './help/help.component';

const routes: Route[] = [
  // Home page and slash-appender
  {
    path: '',
    redirectTo: '/wells',
    pathMatch: 'full',
  },
  // Wells page
  {
    path: 'wells',
    canActivate: [IsApplicationUser],
    component: WellsComponent,
  },
  // Settings
  {
    path: 'settings',
    canActivate: [IsApplicationUser],
    component: SettingsComponent,
  },
  {
    path: 'settings/env',
    canActivate: [IsSystemAdmin, IsApplicationUser],
    component: EnvironmentInfoComponent,
  },
  // Sections Page
  {
    path: 'sections',
    canActivate: [IsApplicationUser],
    component: SectionsComponent,
  },
  // Jobs Page
  {
    path: 'jobs',
    canActivate: [IsApplicationUser],
    component: JobsComponent,
  },
  // Daily Ooperations
  {
    path: 'daily-operations',
    canActivate: [IsApplicationUser],
    component: DailyOperationMainComponent,
  },
  // Section Casings Page
  {
    path: 'section-casings',
    canActivate: [IsApplicationUser],
    component: CasingTallyComponent,
  },
  // AMRA
  {
    path: 'plan-activities',
    canActivate: [IsApplicationUser],
    component: PlanActivitiesComponent,
  },
  // Admin pages
  {
    path: 'admin',
    canActivate: [IsSystemAdmin, IsApplicationUser],
    children: [
      { path: 'business-units', component: BusinessUnitsComponent },
      { path: 'task-plan', component: TaskPlanComponent },
      { path: 'task-plan-create', component: TaskPlanCreateComponent },
      { path: 'task-plan-edit', component: TaskPlanEditComponent },
      { path: 'task-plan-manage', component: TaskPlanManageComponent },
      { path: 'task-plan-delete', component: TaskPlanDeleteComponent },
      { path: 'task-plan-section-edit', component: TaskPlanSectionEditComponent },
      { path: 'task-plan-section-delete', component: TaskPlanSectionDeleteComponent },
      { path: 'task-plan-sub-section-edit', component: TaskPlanSubSectionEditComponent },
      { path: 'task-plan-sub-section-delete', component: TaskPlanSubSectionDeleteComponent },
      { path: 'task-plan-item-create', component: TaskPlanItemCreateComponent },
      { path: 'task-plan-item-edit', component: TaskPlanItemEditComponent },
      { path: 'organizations', component: OrganizationComponent },
      { path: 'users', component: UserComponent },
    ],
  },
  // "Help" page
  {
    path: 'help',
    canActivate: [IsApplicationUser],
    component: HelpComponent,
  },
  // "Awaiting login" page
  {
    path: 'login',
    component: LoginComponent,
  },
  // "Attachments" page
  {
    path: 'view-attachment',
    component: ViewAttachmentsComponent,
  },
  // Error pages
  {
    path: 'error',
    children: [
      { path: 'not-found', component: NotFoundComponent },
      { path: 'internal-error', component: GenericErrorComponent },
      { path: 'not-authorized', component: NotAuthorizedComponent },
    ],
  },

  // Displays 404 "Not Found" error page for all unrecognized router.
  // Instead of redirecting, renders context to preserve original "incorrect" url
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), PagesModule, ErrorPagesModule, AdminPagesModule],
  exports: [RouterModule],
  providers: [IsSystemAdmin, IsApplicationUser],
})
export class AppRoutingModule {}
