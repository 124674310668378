import { Feedback } from '#models/index';
import { FeedbackApiService } from '#services-api/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import 'rxjs/add/operator/finally';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit, OnDestroy {
  //#region 'Variables'
  public formVisible = false;
  public sending = false;

  public categories = ['Feedback', 'Improvement', 'Issue'];

  public feedbackForm = new FormGroup({
    comments: new FormControl('', Validators.required),
    category: new FormControl(this.categories[0], Validators.required),
    stars: new FormControl(0),
  });

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(private feedbackSvc: FeedbackApiService) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public showForm(): void {
    this.formVisible = true;
  }

  public hideForm(): void {
    this.formVisible = false;
  }

  public submitFeedback(): void {
    this.formVisible = false;

    const feedback: Feedback = {
      Application: 'DSR Workspace',
      Category: this.feedbackForm.get('category').value,
      Stars: this.feedbackForm.get('stars').value,
      Comments: this.feedbackForm.get('comments').value,
    };

    this.sending = true;
    this.SUBS$.add(
      this.feedbackSvc
        .postFeedback(feedback)
        .finally(() => (this.sending = false))
        .subscribe((res: any) => {
          this.feedbackForm.reset();
        })
    );
  }
  //#endregion 'General Methods'
}
