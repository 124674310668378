import { CasingComponent, CasingDescription, CasingString, NotificationToast } from '#models/index';
import { CementVolumeComponent } from '#pages/sections/casing-tally/cement-volume/cement-volume.component';
import { InventoryComponent } from '#pages/sections/casing-tally/inventory/inventory.component';
import { RunningComponent } from '#pages/sections/casing-tally/running/running.component';
import { CasingComponentApiService, CasingStringApiService } from '#services-api/index';
import { LoadingIndicatorService, SectionCasingService, TooltipService } from '#services-shared/index';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-casing-tally',
  templateUrl: './casing-tally.component.html',
  styleUrls: ['./casing-tally.component.scss'],
  providers: [MessageService],
})
export class CasingTallyComponent implements OnInit, OnDestroy {
  //#region 'Input, Output, ViewChild & ViewChild'
  @ViewChild('casingInventory') private casingInventory: InventoryComponent;
  @ViewChild('casingRunning') private casingRunning: RunningComponent;
  @ViewChild('cementVolume') private cementVolume: CementVolumeComponent;
  //#endregion 'Input, Output, ViewChild & ViewChild'

  //#region 'Variables'
  public tabName: string;
  public setToastType = '';
  public tmpSelectedIndex: number;
  public disableCasingComponents = true;
  public Verify_CasingComponents_NotValidated = false;

  public tmpSelectedCasingComponent: CasingComponent;
  public toggleArraySet: number[] = [];

  public runDateTime: string;
  public selectedDesc: string = '';
  public selectedODCmntVol: string;
  public selectedIDCmntVol: string;
  public prevCasingDescription: CasingDescription;
  public runDateTimeNotSelected: boolean = false;

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private _casingString: CasingStringApiService,
    private _casingComponent: CasingComponentApiService,
    private _loader: LoadingIndicatorService,
    private _message: MessageService,
    private activatedRoute: ActivatedRoute,
    public _sectionCasing: SectionCasingService,
    public _tooltip: TooltipService
  ) {}

  ngOnInit() {
    this._sectionCasing.wellID = this.activatedRoute.snapshot.paramMap.get('wellId');
    this._sectionCasing.wellBoreID = this.activatedRoute.snapshot.paramMap.get('wellboreId');
    this._sectionCasing.wellBoreSectionID = this.activatedRoute.snapshot.paramMap.get('wellboreSectionId');

    this._sectionCasing.loadCasingDescription().then(
      () => {
        const CURR_DESC_LS = this._tooltip.getItemLocalStorage('description');
        if (CURR_DESC_LS && CURR_DESC_LS.length > 0) {
          const CURR_DESC = CURR_DESC_LS.split('|');
          this.selectedDesc = CURR_DESC && CURR_DESC.length > 0 ? CURR_DESC[0] : undefined;

          if (
            this.selectedDesc &&
            this._sectionCasing.wellID === CURR_DESC[1] &&
            this._sectionCasing.wellBoreSectionID === CURR_DESC[2]
          ) {
            this.casingDescriptionChange(this.selectedDesc);
          } else {
            this.selectedDesc = '---Select---';
            this._tooltip.addItemLocalStorage(
              'description',
              `---Select---|${this._sectionCasing.wellID}|${this._sectionCasing.wellBoreSectionID}`
            );
            this.casingDescriptionChange('---Select---');
          }
        } else {
          this.selectedDesc = '---Select---';
          this._tooltip.addItemLocalStorage(
            'description',
            `---Select---|${this._sectionCasing.wellID}|${this._sectionCasing.wellBoreSectionID}`
          );
          this.casingDescriptionChange('---Select---');
        }
      },
      (error) => {
        this.showNotification(error);
      }
    );

    this._sectionCasing.loadCasingItemDescription().then(
      () => {},
      (error) => {
        this.showNotification(error);
      }
    );
    this._sectionCasing.loadCasingComponentSubType().then(
      () => {},
      (error) => {
        this.showNotification(error);
      }
    );
    this._sectionCasing.loadCasingComponentConnectionThread().then(
      () => {},
      (error) => {
        this.showNotification(error);
      }
    );
    this._sectionCasing.loadCasingComponentMetaData().then(
      () => {},
      (error) => {
        this.showNotification(error);
      }
    );
    this._sectionCasing.getIconDescription();
    this._sectionCasing.loadSection();
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
    this._loader.hide();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  private async loadCasingString(wellBoreId: string, casingDescription: string) {
    this._loader.show();
    this.SUBS$.add(
      await this._casingString.getWellboreIdCasingDescription(wellBoreId, casingDescription).subscribe(
        (data: CasingString[]) => {
          if (data && data.length > 0) {
            this._sectionCasing.casingString = data[0];
            this._sectionCasing.casingStringID = data[0].CasingStringId;
            this.loadCasingComponents(this._sectionCasing.casingString.CasingStringId);

            // Load Previous Casing String for Cement Volume
            if (this._sectionCasing.casingString.PrevCasingDescription) {
              this._sectionCasing.loadPreviousCasingData(false, this._sectionCasing.casingString.PrevCasingDescription);
            }
            this._loader.hide();
          } else {
            this._loader.hide();
            this.showMessage({
              key: 'c',
              sticky: true,
              severity: 'info',
              summary: 'Create Casing',
              detail: 'Casing string not found, would you like to create it?.',
              clear: true,
              toastType: 'Create',
            });
          }
        },
        (error) => {
          console.error(error.message);
          this._loader.hide();
          this.showNotification({
            key: 'casingError',
            severity: 'error',
            summary: 'We got a problem!',
            detail: 'There was an error loading casing strings, please refresh the page or notify the administrator.',
            life: 6000,
          });
        }
      )
    );
  }

  private async loadCasingComponents(casingStringId: string) {
    this._sectionCasing.resetLstCasingComponent();
    this._loader.show();
    this.SUBS$.add(
      //TODO: Add IsEdit in a map on the call.
      await this._casingComponent.getCasingStringId(casingStringId).subscribe(
        (data) => {
          if (data.length > 0) {
            this._sectionCasing.casingStringIdApproved =
              data.filter((obj) => obj.IsApproved === true).length > 0 ? true : false;
            this._sectionCasing.lstCasingComponent = this._sectionCasing.filterByJointNumber(data);
            this._sectionCasing.loadOriginalCasingComponent();
            this._sectionCasing.addEdit();
            this._loader.hide();
          } else {
            this._loader.hide();
            this._sectionCasing.casingStringIdApproved = false;
            this.showNotification({
              severity: 'info',
              summary: 'No Casing Components',
              detail: 'Selected casing does not have any associated casing components.',
            });
          }
          this._sectionCasing.getValidatedAndRunComponents();
        },
        (error) => {
          console.error(error.message);
          this._sectionCasing.getValidatedAndRunComponents();
          this._loader.hide();
          this.showNotification({
            key: 'casingError',
            severity: 'error',
            summary: 'We got a problem!',
            detail: 'There was an error loading casing component, please refresh the page or notify the administrator.',
            life: 6000,
          });
        }
      )
    );
  }

  private async loadCementVolumeCalculation() {
    // Unique OD
    const getODValues = this._sectionCasing.loadCementVolUniqueOD(this._sectionCasing.lstCasingComponent);
    this.cementVolume.casingstringODValues = getODValues;
    this.cementVolume.selectedODCmntVol = getODValues[0];
    if (this.cementVolume.selectedODCmntVol.indexOf('/') !== -1) {
      this.cementVolume.calculatedODCmntVol = this._sectionCasing.calculateMixedNumbers(
        this.cementVolume.selectedODCmntVol
      );
    } else {
      this.cementVolume.calculatedODCmntVol = Number(this.cementVolume.selectedODCmntVol);
    }

    // Unique ID
    const getIDValues = this._sectionCasing.loadCementVolUniqueID(this._sectionCasing.lstCasingComponent);
    this.cementVolume.casingstringIDValues = getIDValues;
    this.cementVolume.selectedIDCmntVol = getIDValues[0];

    // Hole Size
    if (this._sectionCasing.HoleSize.indexOf('/') !== -1) {
      this.cementVolume.selectedHoleSize = this._sectionCasing
        .calculateMixedNumbers(this._sectionCasing.HoleSize)
        .toString();
    } else {
      this.cementVolume.selectedHoleSize = this._sectionCasing.HoleSize;
    }
  }
  //#endregion 'Load'

  //#region 'Tabs'
  public inspectTabClick() {
    this.tabName = 'inspect';
  }

  public casingRunningTabClick() {
    this.tabName = 'casingrunning';
  }

  public cementVolumeTabClick() {
    this.tabName = 'cementvolume';
    if (this._sectionCasing.lstCasingComponent.length > 0) {
      this.cementVolume.cementVolumeCalculation = true;
      this.loadCementVolumeCalculation();
      if (!this._sectionCasing.casingString.PrevCasingDescription) {
        this.cementVolume.prevCasingDescription = this.cementVolume.defaultDescription;
        // Need to Set to undefined for UI/Calculation purposes
        this._sectionCasing.prevCasingID = undefined;
        this._sectionCasing.prevShoeDepth = undefined;
      } else {
        this.cementVolume.prevCasingDescription = this._sectionCasing.lstCasingDescription.filter(
          (obj) => obj.Description === this._sectionCasing.casingString.PrevCasingDescription
        )[0];
      }
      this.cementVolume.reCalculate();
    } else {
      this.cementVolume.cementVolumeCalculation = false;
    }
  }
  //#endregion 'Tabs'

  //#region 'Action Message'
  public showMessage(msg: any) {
    if (msg.clear) {
      this.hideToast();
    }

    if (msg.toastType) {
      this.setToastType = msg.toastType;
    }

    this._message.add({
      key: msg.key,
      sticky: msg.sticky,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
    });
  }

  public onReject() {
    this.hideToast();

    if (this.setToastType === 'Create') {
      this.disableCC();
    }
  }

  public async onConfirm() {
    if (this.setToastType !== 'Finalize') {
      this.hideToast();
    }
    if (this.setToastType === 'Run') {
      this._loader.show();
      this.SUBS$.add(
        await this._casingComponent.approveBulk(this._sectionCasing.lstCasingComponent, this.runDateTime).subscribe(
          (data: CasingComponent[]) => {
            this.runDateTime = undefined;
            this._sectionCasing.reloadCasingsAfterApproval(data).then((notify: any) => {
              this._loader.hide();
              this.showNotification(notify);
            });
          },
          (error) => {
            console.error(error.message);
            this.runDateTime = undefined;
            this._loader.hide();
            this.showNotification({
              key: 'casingError',
              severity: 'error',
              summary: 'We got a problem!',
              detail: 'There was an error approving run casing, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
      );
    } else if (this.setToastType === 'Delete') {
      this._loader.show();
      this.SUBS$.add(
        await this._casingComponent
          .deleteBulk(this.casingInventory.deletedCasingComponentArray)
          .finally(() => this.casingInventory.resetMultipleEdit())
          .subscribe(
            () => {
              this.casingInventory.deletedCasingComponentArray = [];
              this.loadCasingComponents(this._sectionCasing.casingString.CasingStringId);
              this.Verify_CasingComponents_NotValidated = false;
              this._loader.hide();
              this.showNotification({
                severity: 'success',
                summary: 'Correct!',
                detail: 'Selected casings were deleted successfully!',
              });
            },
            (error) => {
              console.error(error.message);
              this._loader.hide();
              this.showNotification({
                key: 'casingError',
                severity: 'error',
                summary: 'We got a problem!',
                detail:
                  'There was an error deleting casing component, please refresh the page or notify the administrator.',
                life: 6000,
              });
            }
          )
      );
    } else if (this.setToastType === 'DeleteRunning') {
      this._loader.show();
      this.SUBS$.add(
        await this._casingComponent
          .delete(this.casingRunning.casingComponentId)
          .finally(() => this.casingInventory.resetMultipleEdit())
          .subscribe(
            () => {
              this.casingInventory.deletedCasingComponentArray = [];
              this.loadCasingComponents(this._sectionCasing.casingString.CasingStringId);
              this.Verify_CasingComponents_NotValidated = false;
              this._loader.hide();
              this.showNotification({
                severity: 'success',
                summary: 'Correct!',
                detail: 'Selected running casings were deleted successfully!.',
              });
            },
            (error) => {
              console.error(error.message);
              this._loader.hide();
              this.showNotification({
                key: 'casingError',
                severity: 'error',
                summary: 'We got a problem!',
                detail:
                  'There was an error deleting casing components, please refresh the page or notify the administrator.',
                life: 6000,
              });
            }
          )
      );
    } else if (this.setToastType === 'ValidateAll') {
      const filterNotValidatedComponents = this._sectionCasing.lstCasingComponent.filter(
        (obj) => !obj.IsValidated && obj.isEdit
      );

      this._sectionCasing.prevSysSeq =
        this._sectionCasing.lstCasingComponents_RunChecked.length > 0
          ? this._sectionCasing.lstCasingComponents_RunChecked.length
          : 0;

      filterNotValidatedComponents.map((element) => {
        element.IsValidated = true;
        element.SysSeq.Value = this._sectionCasing.getNextSysSeq();
        element.Run = true;

        if (
          element.Description.Value.toUpperCase() === 'DRILL PIPE' ||
          element.Description.Value.toUpperCase() === 'HEAVYWEIGHT DRILL PIPE'
        ) {
          element.IsExclude = true;
        }
        return element;
      });

      if (filterNotValidatedComponents && filterNotValidatedComponents.length === 1) {
        const index = this._sectionCasing.lstCasingComponent.indexOf(filterNotValidatedComponents[0]);
        this.updateCasingComponent(filterNotValidatedComponents[0], index, 'Validate');
      } else {
        this._sectionCasing.updateCasingComponentBulk(filterNotValidatedComponents, 'ValidateAll', 2).then(
          (done: any) => {
            this.casingInventory.resetMultipleEdit();
            this._sectionCasing.loadRunList();
            this.showNotification(done);
          },
          (err) => {
            this.casingInventory.resetMultipleEdit();
            this.showNotification(err);
          }
        );
      }
    } else if (this.setToastType === 'Unvalidate') {
      this._loader.show();
      this._sectionCasing.setUnvalidate();
      this._sectionCasing.sysSequence().then(
        (result: CasingComponent[]) => {
          this.SUBS$.add(
            this._casingComponent
              .updateBulk(result)
              .finally(() => this._loader.hide())
              .subscribe(
                () => {
                  this.loadCasingComponents(this._sectionCasing.casingString.CasingStringId);
                  this.inspectTabClick();
                  this._sectionCasing.casingToUnvalidate = undefined;
                },
                (error) => {
                  this._sectionCasing.casingToUnvalidate = undefined;
                  console.error(error.message);
                  this.showNotification({
                    key: 'casingError',
                    severity: 'error',
                    summary: 'We got a problem!',
                    detail:
                      'There was an error unvalidating the casing component, please refresh the page or notify the administrator.',
                    life: 6000,
                  });
                }
              )
          );
        },
        (err) => {
          console.log('err => ', err);
        }
      );
    } else if (this.setToastType === 'Create') {
      this._sectionCasing.resetLists();
      this._sectionCasing.getValidatedAndRunComponents().then((msg: NotificationToast) => {
        this.showNotification(msg);
      });
      this._sectionCasing.createCasingString().then(
        (complete: NotificationToast) => {
          this.showNotification(complete);
        },
        (error) => {
          this.showNotification(error);
        }
      );
    } else if (this.setToastType === 'ClearAll') {
      this._loader.show();
      this.SUBS$.add(
        await this._casingComponent.deleteBulk(this._sectionCasing.lstCasingComponent).subscribe(
          () => {
            this.resetALLData();
            this.casingRunning.resetALLData();
            this.casingInventory.resetALLData();
            this._sectionCasing.resetALLData();

            this.inspectTabClick();
            this._loader.hide();
            this.showNotification({
              severity: 'success',
              summary: 'Correct!',
              detail: 'Selected casings were deleted successfully!.',
            });
          },
          (error) => {
            console.error(error.message);
            this._loader.hide();
            this.showNotification({
              key: 'casingError',
              severity: 'error',
              summary: 'We got a problem!',
              detail:
                'There was an error deleting casings component, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
      );
    } else if (this.setToastType === 'Finalize') {
      if (this.runDateTime && this.runDateTime.length > 0) {
        this.runDateTimeNotSelected = false;
        this.hideToast();
        this.showMessage({
          key: 'c',
          sticky: true,
          severity: 'warn',
          summary: 'Finalize & Write to WellView',
          detail: `This action will not allow for any future modification of the casing tally withing the tool.
          You will need to perform any future edits in WellView.`,
          clear: true,
          toastType: 'Run',
        });
      } else {
        this.runDateTimeNotSelected = true;
      }
    }
  }

  public isRunToast() {
    return this.setToastType === 'Run' || this.setToastType === 'ClearAll';
  }
  //#endregion 'Action Message'

  //#region 'General Methods'
  public threadOffValues(toff: any) {
    this.computeThreadOffValues(toff.casingComponent, toff.index, toff.info, toff.updateTopBot);
  }

  private hideToast() {
    this._message.clear('c');
  }

  public updtToggleArrayInfo(dt: any) {
    this.toggleArraySet = dt;
  }

  private disableCC() {
    this.disableCasingComponents = true;
  }

  private refreshVirtualScrollCasing() {
    const TEMPCSNG = this._tooltip.deepClone(this._sectionCasing.lstCasingComponent);
    this._sectionCasing.lstCasingComponent = [];
    this._sectionCasing.lstCasingComponent = this._tooltip.deepClone(TEMPCSNG);
    this._sectionCasing.loadOriginalCasingComponent();
  }

  public casingDescriptionChange(event: any) {
    this._tooltip.addItemLocalStorage(
      'description',
      `${event.trim()}|${this._sectionCasing.wellID}|${this._sectionCasing.wellBoreSectionID}`
    );
    this._sectionCasing.resetNotifications();
    this._sectionCasing.casingStringIdApproved = true;
    this._sectionCasing.lstCasingComponent = [];
    this._sectionCasing.lstCasingComponentOriginal = [];
    this._sectionCasing.lstCasingComponents_Validated = [];
    this._sectionCasing.lstCasingComponents_RunChecked = [];
    this._sectionCasing.lstCasingComponents_NotValidated = [];

    if (this.casingRunning && this.casingRunning.isToggleEnabled) {
      this.casingRunning.cancelCutPaste();
    }

    if (event.trim() === '---Select---') {
      this.disableCC();
    } else {
      // This value is for Validate All Casing in Casing Inventory
      this.Verify_CasingComponents_NotValidated = false;
      this.disableCasingComponents = false;
      this._sectionCasing.selectedCasingDescription = this._sectionCasing.lstCasingDescription.find(
        (x) => x.Description === event.trim()
      );
      this._sectionCasing.casingDescription = this._sectionCasing.selectedCasingDescription.Description;
      this._sectionCasing.resetDepthHole();
      this.loadCasingString(this._sectionCasing.wellBoreID, this._sectionCasing.selectedCasingDescription.Description);
      this.inspectTabClick();
      if (this.casingInventory) {
        this.casingInventory.resetMultipleEdit();
      }
    }
  }

  public computeThreadOffValues(casingComponent: CasingComponent, index: number, info: string, updateTopBot?: boolean) {
    if (casingComponent) {
      if (casingComponent.IsThreadLoss) {
        casingComponent.ThreadOff = (
          Number(casingComponent.Length.Value.toString().replace(',', '')) -
          Number(casingComponent.ThreadLoss) / 12
        ).toString();
      } else {
        casingComponent.ThreadOff = casingComponent.Length.Value.toString().replace(',', '');
      }
      // This method is for casing running section
      this.updateCasingComponent(casingComponent, index, info, updateTopBot);
    } else {
      if (this._sectionCasing.lstCasingComponents_NotValidated.length > 0) {
        this._sectionCasing.lstCasingComponents_NotValidated.forEach((element) => {
          if (element.IsThreadLoss) {
            element.ThreadOff = (
              Number(element.Length.Value.toString().replace(',', '')) -
              Number(element.ThreadLoss) / 12
            ).toString();
          } else {
            element.ThreadOff = element.Length.Value;
          }
          element.ThreadLoss = '0';
        });
        // This methos is for  casing inventory
        this._sectionCasing
          .updateCasingComponentBulk(this._sectionCasing.lstCasingComponents_NotValidated, 'UpdateThreadOff', 2)
          .then(
            () => {
              this.showNotification({
                severity: 'success',
                summary: 'Correct!',
                detail: 'Component thread off values successfully updated.',
              });
            },
            (error) => this.showNotification(error)
          );
      }
    }
  }

  private resetALLData() {
    this.toggleArraySet = [];

    this.setToastType = undefined;
    this.tmpSelectedIndex = undefined;
    this.selectedODCmntVol = undefined;
    this.selectedIDCmntVol = undefined;
    this.prevCasingDescription = undefined;
    this.tmpSelectedCasingComponent = undefined;

    this.Verify_CasingComponents_NotValidated = true;
  }
  //#endregion 'General Methods'

  //#region 'Validations'
  public validateCasing(vld: any) {
    this.tmpSelectedCasingComponent = vld.casingComponent;
    this.tmpSelectedIndex = vld.index;
    this.setToastType = vld.toastType;

    this.showMessage(vld);
  }
  //#endregion 'Validations'

  //#region 'CRUD'
  public updateMultipleEdit(data: any) {
    this._sectionCasing.updateCasingComponentBulk(data.arrayEdited, 'MultipleEdit', 2).then(
      () => {
        this.casingInventory.resetMultipleEdit();
        this.showNotification({
          severity: 'success',
          summary: 'Correct!',
          detail: 'Selected casing successfully updated.',
        });
      },
      (err) => {
        this.casingInventory.resetMultipleEdit();
        this.showNotification(err);
      }
    );
  }

  public updtCasingComponent(dt: any) {
    this._loader.show();
    this.updateCasingComponent(dt.casingComponent, dt.index, dt.infoField);
  }

  private async updateCasingComponent(
    casingComponent: CasingComponent,
    index: number,
    infoField: string,
    updateTopBot?: boolean
  ) {
    this.SUBS$.add(
      await this._casingComponent
        .update(casingComponent)
        .finally(() => this.casingInventory.resetMultipleEdit())
        .subscribe(
          (data: CasingComponent) => {
            if (infoField === 'Validate') {
              // After the edit we do a Refresh for the virtual scroll
              this.refreshVirtualScrollCasing();
              this._sectionCasing.getValidatedAndRunComponents();
              this._loader.hide();
              this.showNotification({
                key: '',
                severity: 'success',
                summary: 'Correct!',
                detail: 'Casing Validated Successfully',
              });
            } else {
              if (data.IsThreadLoss) {
                data.ThreadOff = data.ThreadOff = (
                  Number(data.Length.Value.toString().replace(',', '')) -
                  Number(data.ThreadLoss) / 12
                ).toString();
              }
              this._sectionCasing.lstCasingComponent[index] = data;
              this._sectionCasing.lstCasingComponent[index]['isEdit'] = casingComponent['isEdit'];

              // After the edit we do a Refresh for the virtual scroll
              this.refreshVirtualScrollCasing();

              if (updateTopBot) {
                this._sectionCasing.assignJointTopBottom(
                  this._sectionCasing.lstCasingComponents_RunChecked,
                  'Original',
                  this._sectionCasing.casingString.RKB
                );
              }

              this._sectionCasing.calculateAmounts();
              this._loader.hide();
              this.showNotification({
                severity: 'success',
                summary: 'Correct!',
                detail: `Component ${data.Description.Value} was Successfully Updated.`,
              });

              setTimeout(() => {
                if (infoField === 'threadOn') {
                  this.casingInventory.selectNextThreadOn(index + 1);
                }
              }, 200);
            }
          },
          (error) => {
            console.error(error.message);
            this.casingInventory.resetMultipleEdit();
            this._loader.hide();
            this.showNotification({
              key: 'casingError',
              severity: 'error',
              summary: 'We got a problem!',
              detail:
                'There was an error updating well information, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
    );
  }
  //#endregion 'CRUD'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._message.add({
      key: msg && msg.key && msg.key.length > 0 ? msg.key : 'casingNotify',
      sticky: msg && msg.key && msg.key != 'casingNotify' ? true : false,
      closable: msg && msg.key && msg.key != 'casingNotify' ? true : false,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life,
    });
  }
  //#endregion 'Notification'
}
