import { DownloadedFiles, OfflineActivityAttachment, ViewAttachments } from '#models/index';
import { AttachmentApiService } from '#services-api/index';
import { LoadingIndicatorService, TooltipService } from '#services-shared/index';
import { OfflineActivityService } from '#services-shared/jobs/offline-activity.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-attachments',
  templateUrl: './view-attachments.component.html',
  styleUrls: ['./view-attachments.component.scss'],
})
export class ViewAttachmentsComponent implements OnInit, OnDestroy {
  //#region 'Variables'
  public files: ViewAttachments[] = [];
  public downloadedFiles: DownloadedFiles[] = [];

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private activatedRoute: ActivatedRoute,
    public _offlineActivity: OfflineActivityService,
    private _loader: LoadingIndicatorService,
    public _AttachmentAPI: AttachmentApiService,
    public _tooltip: TooltipService
  ) {}

  ngOnInit() {
    const OADId = this.activatedRoute.snapshot.paramMap.get('OADId');
    this.downloadAllImgs(OADId);
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  private downloadAllImgs(OADId: string) {
    this.SUBS$.add(
      this._AttachmentAPI.getByOfflineActivityDateId(OADId).subscribe((dto: OfflineActivityAttachment[]) => {
        dto.sort((a, b) => (a.Name.Value > b.Name.Value ? 1 : b.Name.Value > a.Name.Value ? -1 : 0));
        dto.forEach((el) => {
          const COMMENT = el.Comment.Value.split('|');
          this.files.push({
            Name: el.Name.Value,
            FullName: el.Description.Value,
            FileType: el.EmailCAI.Value,
            Size: COMMENT.length > 0 ? COMMENT[1] : '',
            icon: el.EmailCAI.Value.includes('pdf')
              ? '../../../assets/images/pdf_icon.png'
              : '../../../assets/images/png_icon.png',
          });
        });
      })
    );
  }
  //#endregion 'General Methods'

  //#region 'Files'
  public downloadFile(index: number) {
    this._loader.show();
    const FILE = this.files[index];

    if (!this.isFileAlreadyDownloaded(FILE.FullName)) {
      this.SUBS$.add(
        this._AttachmentAPI.DownloadAttachment(FILE.FullName, FILE.FileType, FILE.Size).subscribe((dto: any) => {
          const blobData = new Blob([dto.body], { type: FILE.FileType });
          this.downloadedFiles.push({
            fullName: FILE.FullName,
            name: FILE.Name,
            type: FILE.FileType,
            data: undefined,
            blobData: blobData,
            mb: 0,
            size: FILE.Size,
          });

          this._tooltip.downloadBlob(blobData, FILE.Name);
          this._loader.hide();
        })
      );
    } else {
      const IMG = this.downloadedFiles.filter((obj) => obj.fullName === FILE.FullName);
      this._tooltip.downloadBlob(IMG[0].blobData, IMG[0].name);
      this._loader.hide();
    }
  }

  private isFileAlreadyDownloaded(name: string): boolean {
    const EXIST = this.downloadedFiles.filter((obj) => obj.fullName === name);
    return EXIST && EXIST.length > 0 ? true : false;
  }
  //#endregion 'Files'
}
