import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/do';
import { Observable } from 'rxjs/Observable';

/**
 * @description Workaround for weird angular / JSON.parse() behavior unable to automatically parse json datetime into javascript Date object
 */
@Injectable()
export class DatetimeParseInterceptor implements HttpInterceptor {
  //#region 'Variables'
  private dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)[zZ]?$/;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor() {}
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.convertDates(event.body);
      }
    });
  }

  private convertDates(object: Object) {
    if (!object || !(object instanceof Object)) {
      return;
    }

    if (object instanceof Array) {
      for (const item of object) {
        this.convertDates(item);
      }
    }

    for (const key of Object.keys(object)) {
      const value = object[key];

      if (value instanceof Array) {
        for (const item of value) {
          this.convertDates(item);
        }
      }

      if (value instanceof Object) {
        this.convertDates(value);
      }

      if (typeof value === 'string' && this.dateRegex.test(value)) {
        object[key] = new Date(value);
      } else if (value instanceof String && this.dateRegex.test(value.toString())) {
        object[key] = new Date(value.toString());
      }
    }
  }
  //#endregion 'General Methods'
}
