<div class="feedback-banner startDiv" *ngIf="!formVisible" [ngClass]="{ pulsing: sending }">
  <a href="https://digitalpm.azure.chevron.com/review/1711" target="_blank">
    <div class="feedback-banner__icon" style="padding: 1px !important"><i class="pi pi-star startIcon"></i></div>
    <div class="feedback-banner__text normalText">Rate This App</div>
  </a>
</div>
<div class="feedback-banner" *ngIf="!formVisible" (click)="showForm()" [ngClass]="{ pulsing: sending }">
  <div class="feedback-banner__icon glyphicon glyphicon-comment"></div>
  <div class="feedback-banner__text normalText">Send Feedback</div>
</div>

<div class="feedback-form" *ngIf="formVisible">
  <span class="glyphicon glyphicon-remove remove-button" (click)="hideForm()"></span>
  <form [formGroup]="feedbackForm" (ngSubmit)="submitFeedback()">
    <h3>Send us feedback</h3>
    <div class="form-group">
      <select class="form-control selectpicker normalText" formControlName="category">
        <option *ngFor="let category of categories">
          {{ category }}
        </option>
      </select>
    </div>
    <div class="mb10">
      <p-rating class="fl" formControlName="stars" [cancel]="false"></p-rating>
    </div>
    <div class="form-group">
      <textarea
        class="form-control normalText br-4"
        placeholder="Here you can write feedback about application, ideas and improvement."
        formControlName="comments"
      ></textarea>
    </div>
    <div class="form-group">
      <input
        type="submit"
        class="btn btn-primary normalText br-4"
        value="Send Message"
        [disabled]="!feedbackForm.valid"
      />
    </div>
  </form>
</div>
