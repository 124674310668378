import { AuthService, TooltipService } from '#services-shared/index';
import { Location } from '@angular/common';
import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, AfterViewChecked {
  //#region 'Variables'
  public showAdminMenu: boolean;
  public showWellsPage: boolean;
  public showGoBack: boolean;
  public environmentName: string = environment.environmentName;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private authSvc: AuthService,
    private router: Router,
    private location: Location,
    public _tooltip: TooltipService
  ) {}

  ngOnInit(): void {
    this.showWellsPage = this.authSvc.isDisclaimerAccepted();
    this.showAdminMenu = this.authSvc.isSystemAdmin();
  }

  ngAfterViewChecked(): void {
    const URL = this.router.url.replace('/', '').toUpperCase();
    this.showGoBack =
      URL !== 'WELLS' &&
      URL !== 'SETTINGS' &&
      URL !== 'ADMIN/USERS' &&
      URL !== 'HELP' &&
      URL !== 'LOGIN' &&
      URL !== 'VIEW-ATTACHMENT'
        ? true
        : false;
  }
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public redirect(url: string) {
    if (this._tooltip.enableNavbar) this.router.navigate([url]);
  }

  public goBack() {
    if (this._tooltip.enableNavbar) this.location.back();
  }
  //#endregion 'General Methods'
}
