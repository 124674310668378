import {
  CasingComponent,
  CasingDescription,
  CasingItemDescription,
  CasingString,
  Centralizer,
  ConnectionThread,
  NotificationToast,
  Section,
} from '#models/index';
import { CasingComponentApiService } from '#services-api/sections/casing-component-api.service';
import { CasingStringApiService } from '#services-api/sections/casing-string-api.service';
import { CentralizeApiService } from '#services-api/sections/centralize-api.service';
import { SectionsApiService } from '#services-api/sections/sections-api.service';
import { LoadingIndicatorService } from '#services-shared/loading-indicator.service';
import { TooltipService } from '#services-shared/tooltip.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Message } from 'primeng/api';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';

@Injectable({
  providedIn: 'root',
})
export class SectionCasingService implements OnDestroy {
  //#region 'Variables'
  public onRun = 0;
  public onTotal = 0;
  public onShelf = 0;
  public onBadJoint = 0;
  public onCentralized = 0;
  public ratHole = 0;
  public shoeDepth = 0;
  public prevSysSeq = 0;
  public unvalidateFromRun = false;
  public casingStringIdApproved = false;
  public CasingComponent_NewJointNumber: number;
  public CasingComponent_NewJointLetter: string;

  public casingString: CasingString;
  public selectedCasingODID: CasingItemDescription;
  public casingToUnvalidate: CasingComponent;
  public casingToUnvalidateIndex: number;
  public ddl_selectedTopThread: ConnectionThread;
  public ddl_selectedBottomThread: ConnectionThread;
  public lstCasingComponentColumns: CasingComponent;
  public selectedCasingDescription: CasingDescription;

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region Set & Get Variables
  private wellId: string;
  get wellID() {
    return this.wellId;
  }
  set wellID(data) {
    this.wellId = data;
  }

  private wellBoreId: string;
  get wellBoreID() {
    return this.wellBoreId;
  }
  set wellBoreID(data) {
    this.wellBoreId = data;
  }

  private holeSize: string;
  get HoleSize() {
    return this.holeSize;
  }
  set HoleSize(data) {
    this.holeSize = data;
  }

  private actualTopDepth: string;
  get ActualTopDepth() {
    return this.actualTopDepth;
  }
  set ActualTopDepth(data) {
    this.actualTopDepth = data;
  }

  private actualBottomDepth: string;
  get ActualBottomDepth() {
    return this.actualBottomDepth;
  }
  set ActualBottomDepth(data) {
    this.actualBottomDepth = data;
  }

  private startDate: string;
  get StartDate() {
    return this.startDate;
  }
  set StartDate(data) {
    this.startDate = data;
  }

  private endDate: string;
  get EndDate() {
    return this.endDate;
  }
  set EndDate(data) {
    this.endDate = data;
  }

  private sectionDescription: string;
  get SectionDescription() {
    return this.sectionDescription;
  }
  set SectionDescription(data) {
    this.sectionDescription = data;
  }

  private casingStringId: string;
  get casingStringID() {
    return this.casingStringId;
  }
  set casingStringID(data) {
    this.casingStringId = data;
  }

  private wellBoreSectionId: string;
  get wellBoreSectionID() {
    return this.wellBoreSectionId;
  }
  set wellBoreSectionID(data) {
    this.wellBoreSectionId = data;
  }

  private casingdescription: string;
  get casingDescription() {
    return this.casingdescription;
  }
  set casingDescription(data) {
    this.casingdescription = data;
  }

  private isDrilling: boolean;
  get isdrilling() {
    return this.isDrilling;
  }
  set isdrilling(data) {
    this.isDrilling = data;
  }
  //#endregion 'Variables'

  //#region 'Objects'
  // Lists
  public iconDescription: any[] = [];
  public lstCasingODID: CasingItemDescription[] = [];
  public filteredTopThread: ConnectionThread[] = [];
  public lstCasingComponent: CasingComponent[] = [];
  public lstCasingComponentOriginal: CasingComponent[] = [];
  public lstCasingDescription: CasingDescription[] = [];
  public filteredBottomThread: ConnectionThread[] = [];
  public lstCasingComponentSubType: string[] = [];
  public lstCasingComponents_Validated: CasingComponent[] = [];
  public lstCasingComponents_RunChecked: CasingComponent[] = [];
  public lstCasingComponents_NotValidated: CasingComponent[] = [];

  // Filters
  public filteredOD: CasingItemDescription[];
  public filteredID: CasingItemDescription[];
  public filteredGrade: CasingItemDescription[];
  public filteredWeight: CasingItemDescription[];

  // Notifications
  public notificationsOnRun: Message[];
  public notificationsInventory: Message[];
  public notificationsRunValidated: Message[];
  public notificationsCementVolume: Message[];

  // Cement Volume
  public prevCasingString: CasingString;
  public prevCasingStringID: string;
  public prevShoeDepth: number;
  public prevCasingID: string;
  //#endregion 'Objects'

  //#region 'Angular Life Cycle'
  constructor(
    private _section: SectionsApiService,
    private _centralize: CentralizeApiService,
    private _casingString: CasingStringApiService,
    private _casingComponent: CasingComponentApiService,
    private _loader: LoadingIndicatorService,
    public _tooltip: TooltipService
  ) {}

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
    this._loader.hide();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  public async loadSection() {
    this.SUBS$.add(
      await this._section.getSectionBySectionID(this.wellBoreSectionId).subscribe((data: Section) => {
        this.holeSize = data.HoleSize;
        this.actualTopDepth = data.ActualTopDepth;
        this.actualBottomDepth = data.ActualBottomDepth;
        this.startDate = data.StartDate;
        this.endDate = data.EndDate;
        this.sectionDescription = data.SectionDescription;
      })
    );
  }

  public loadCasingDescription() {
    return new Promise(async (resolve, reject) => {
      try {
        this.lstCasingDescription = [];
        this._loader.show();
        this.SUBS$.add(
          await this._casingString
            .getCasingDescription()
            .pipe(
              map((dt) => {
                dt.unshift({ Description: '---Select---', Help: 'Nothing to select' });
                return dt;
              })
            )
            .finally(() => this._loader.hide())
            .subscribe(
              (data: CasingDescription[]) => {
                this.lstCasingDescription = [];
                this.lstCasingDescription = data;
                resolve(true);
              },
              (error) => {
                console.error(error.message);
                reject({
                  key: 'casingError',
                  severity: 'error',
                  summary: 'We got a problem!',
                  detail:
                    'There was an error loading casing description, please refresh the page or notify the administrator.',
                  life: 6000,
                });
              }
            )
        );
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public async loadCasingComponentMetaData() {
    return new Promise(async (resolve, reject) => {
      try {
        this._loader.show();
        this.SUBS$.add(
          await this._casingComponent
            .getCasingComponentMetaData()
            .finally(() => this._loader.hide())
            .subscribe(
              (data: CasingComponent) => {
                this.lstCasingComponentColumns = data;
              },
              (error) => {
                console.error(error.message);
                reject({
                  key: 'casingError',
                  severity: 'error',
                  summary: 'We got a problem!',
                  detail:
                    'There was an error loading casing description, please refresh the page or notify the administrator.',
                  life: 6000,
                });
              }
            )
        );
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public async loadCasingItemDescription() {
    return new Promise(async (resolve, reject) => {
      try {
        this._loader.show();
        this.SUBS$.add(
          await this._casingComponent.getCasingItemDescription().subscribe(
            (data: CasingItemDescription[]) => {
              this.lstCasingODID = [];
              this.lstCasingODID = data;
              this.filterData_Casing(this.lstCasingODID);
              if (data[0]) {
                this.selectedCasingODID = data[0];
              }
              this._loader.hide();
            },
            (error) => {
              console.error(error.message);
              this._loader.hide();
              reject({
                key: 'casingError',
                severity: 'error',
                summary: 'We got a problem!',
                detail:
                  'There was an error loading casing item descriptions, please refresh the page or notify the administrator.',
                life: 6000,
              });
            }
          )
        );
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public async loadCasingComponentSubType() {
    return new Promise(async (resolve, reject) => {
      try {
        this._loader.show();
        this.SUBS$.add(
          await this._casingComponent.getCasingItemSubType().subscribe(
            (data: string[]) => {
              this.lstCasingComponentSubType = [];
              this.lstCasingComponentSubType = data;
              this._loader.hide();
            },
            (error) => {
              console.error(error.message);
              this._loader.hide();
              reject({
                key: 'casingError',
                severity: 'error',
                summary: 'We got a problem!',
                detail:
                  'There was an error loading casing description, please refresh the page or notify the administrator.',
                life: 6000,
              });
            }
          )
        );
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public async loadCasingComponentConnectionThread() {
    return new Promise(async (resolve, reject) => {
      try {
        this._loader.show();
        this.SUBS$.add(
          await this._casingComponent.getCasingItemConnectionThread().subscribe(
            (data: ConnectionThread[]) => {
              this.filteredTopThread = [];
              this.filteredBottomThread = [];

              this.filteredTopThread = data;
              this.filteredBottomThread = data;
              this.ddl_selectedTopThread = this.filteredTopThread[0];
              this.ddl_selectedBottomThread = this.filteredBottomThread[0];
              this._loader.hide();
            },
            (error) => {
              console.error(error.message);
              this._loader.hide();
              reject({
                key: 'casingError',
                severity: 'error',
                summary: 'We got a problem!',
                detail:
                  'There was an error loading casing description, please refresh the page or notify the administrator.',
                life: 6000,
              });
            }
          )
        );
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public loadValidatedRecords(data: any, comeFrom: number) {
    return new Promise(async (resolve) => {
      this.resetLstCasingComponent();
      this.lstCasingComponent = this.filterByJointNumber(data);
      this.loadOriginalCasingComponent();
      this.addEdit();
      resolve({
        key: comeFrom == 1 ? 'runningNotify' : 'casingNotify',
        severity: 'success',
        summary: 'Correct!',
        detail: 'All Casings Validated Successfully',
      });
    });
  }

  public loadOriginalCasingComponent() {
    this.lstCasingComponentOriginal = [];
    this.lstCasingComponentOriginal = this._tooltip.deepClone(this.lstCasingComponent);
  }
  //#endregion 'Load'

  //#region 'General Methods'
  public showNotification(ntf: any, type: number) {
    if (type === 1) {
      // Inventory
      this.notificationsInventory = [];
      this.notificationsInventory.push({
        severity: ntf.severity,
        summary: ntf.summary,
        detail: ntf.detail,
      });
    } else if (type === 2) {
      // Run Validated
      this.notificationsRunValidated = [];
      this.notificationsRunValidated.push({
        severity: ntf.severity,
        summary: ntf.summary,
        detail: ntf.detail,
      });
    } else if (type === 3) {
      // On Run
      this.notificationsOnRun = [];
      this.notificationsOnRun.push({
        severity: ntf.severity,
        summary: ntf.summary,
        detail: ntf.detail,
      });
    } else if (type === 4) {
      // Cement Volume
      this.notificationsCementVolume = [];
      this.notificationsCementVolume.push({
        severity: ntf.severity,
        summary: ntf.summary,
        detail: ntf.detail,
      });
    }

    setTimeout(() => {
      this.notificationsInventory = [];
      this.notificationsRunValidated = [];
      this.notificationsOnRun = [];
      this.notificationsCementVolume = [];
    }, 4000);
  }

  public resetNotifications() {
    this.notificationsOnRun = [];
    this.notificationsInventory = [];
    this.notificationsRunValidated = [];
    this.notificationsCementVolume = [];
  }

  public addEdit() {
    this.lstCasingComponent.map((casing) => {
      casing['isEdit'] = false;
      return casing;
    });
  }

  public getValidatedAndRunComponents() {
    return new Promise(async (resolve, reject) => {
      this.lstCasingComponents_Validated = [];
      this.lstCasingComponents_RunChecked = [];

      if (this.lstCasingComponent.length > 0) {
        this.lstCasingComponents_Validated = this.lstCasingComponent.filter((obj) => obj.IsValidated && !obj.Run);
        if (this.lstCasingComponents_Validated.length > 0) {
          this.lstCasingComponents_Validated = this.filterByJointNumber(this.lstCasingComponents_Validated);
        } else {
          this.lstCasingComponents_Validated = [];
          this.resetDepthHole();
          resolve({
            severity: 'info',
            summary: 'No Validated Components',
            detail: 'Selected casing does not have any validated casing components.',
          });
        }

        const RUN = this.lstCasingComponent.filter((obj) => obj.Run).length;
        if (RUN > 0) {
          this.loadRunList();
        } else {
          this.calculateAmounts();
        }
      } else {
        this.lstCasingComponents_Validated = [];
        this.lstCasingComponents_RunChecked = [];
        this.resetAmounts();
        resolve({
          severity: 'info',
          summary: 'No Casing Components',
          detail: 'Selected casing does not have any validated or run casing components.',
        });
      }
    });
  }

  public resetDepthHole() {
    this.shoeDepth = 0;
    this.ratHole = 0;
  }

  public assignJointTopBottom(array: CasingComponent[], arrayType: string, casingStringRKB: number): number {
    let ShoeDepthCalc = 0;
    if (array && array.length > 0) {
      if (arrayType === 'Original') {
        this.shoeDepth = array[0].JointBottom ? array[0].JointBottom : 0;
      } else {
        ShoeDepthCalc = array[0].JointBottom ? array[0].JointBottom : 0;
      }

      this.ratHole = Number(this.casingString.SetDepth) - this.shoeDepth;
    } else {
      this.shoeDepth = 0;
      ShoeDepthCalc = 0;
      this.ratHole = Number(this.casingString.SetDepth) - this.shoeDepth;
    }
    return ShoeDepthCalc;
  }

  public computeBottom(
    array: CasingComponent[],
    rowIndex: number,
    refIndex: number,
    arrayType: string,
    casingStringRKB: number
  ): number {
    if (array[rowIndex].IsReferenceDepth) {
      return casingStringRKB;
    }

    if (refIndex < 0) {
      return 0;
    }

    if (rowIndex < refIndex) {
      return (
        Number(array[rowIndex].ThreadOff) +
        this.computeBottom(array, rowIndex + 1, refIndex, arrayType, casingStringRKB)
      );
    } else {
      return Number(array[rowIndex - 1].JointTop);
    }
  }

  public computeTop(array: CasingComponent[], rowIndex: number, refIndex: number): number {
    if (refIndex < 0) {
      return 0;
    }

    return Number(array[rowIndex].JointBottom) - Number(array[rowIndex].ThreadOff);
  }

  public resetLstCasingComponent() {
    this.lstCasingComponent = [];
    this.lstCasingComponentOriginal = [];
  }

  public newCasingString(): CasingString {
    const ncs = new CasingString();
    ncs.WellId = this.wellID;
    ncs.WellboreId = this.wellBoreID;
    ncs.CasingStringId = '';
    ncs.CasingDescription = this.selectedCasingDescription.Description;
    ncs.SetDepth = '0';
    ncs.RunDate = '';
    ncs.RKB = 0;
    ncs.Length = 0;
    ncs.Yield = 0;
    ncs.MixWater = 0;
    ncs.PrevCasingDescription = '';
    ncs.StickUp = 0;

    return ncs;
  }

  public array_move(arr: any[], old_index: number, new_index: number) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  public getLastestJointNumber(data?: CasingComponent[]) {
    data = data && data.length > 0 ? data : this.lstCasingComponent;
    this.resetJoint();
    let DATA = [];
    if (this.isdrilling) {
      DATA = data.filter((obj) => obj.JointNumber && obj.JointNumber.length > 0 && obj.JointNumber.startsWith('DP'));
    } else {
      DATA = data.filter((obj) => obj.JointNumber && obj.JointNumber.length > 0 && !obj.JointNumber.startsWith('DP'));
    }
    DATA.sort((a, b) =>
      Number(a.JointNumber.replace('DP', '')) > Number(b.JointNumber.replace('DP', ''))
        ? 1
        : Number(b.JointNumber.replace('DP', '')) > Number(a.JointNumber.replace('DP', ''))
        ? -1
        : 0
    );
    if (DATA.length > 0) {
      const Last_JointNumber = DATA[DATA.length - 1].JointNumber;
      if (Last_JointNumber) {
        const RegExSplit = Last_JointNumber.match(/[a-z]+|[^a-z]+/gi);
        if (RegExSplit && RegExSplit.length === 1 && !RegExSplit[0].match(/[a-z]/i)) {
          this.CasingComponent_NewJointNumber = Number(RegExSplit[0]);
        } else {
          if (!this.isdrilling) {
            this.CasingComponent_NewJointLetter = RegExSplit[0];
          }
          this.CasingComponent_NewJointNumber = RegExSplit[1] ? Number(RegExSplit[1]) : 0;
        }
      }
    }
  }

  private resetJoint() {
    this.CasingComponent_NewJointLetter = this.isdrilling ? 'DP' : undefined;
    this.CasingComponent_NewJointNumber = 0;
  }

  public createNewCasingComponent(): CasingComponent {
    const ncc = new CasingComponent();
    ncc.CasingComponentId.Value = '';

    ncc.JointNumber = this.getLastJoint(true, ncc);
    ncc.WellId.Value = this.wellID;
    ncc.CasingStringId.Value = this.casingString.CasingStringId;

    // Set these to the Last Used Configuration or Default
    ncc.OD.Value =
      this.lstCasingComponent.length > 0
        ? this.lstCasingComponent[this.lstCasingComponent.length - 1].OD.Value
        : this.filteredOD[0].OD;
    ncc.ID.Value =
      this.lstCasingComponent.length > 0
        ? this.lstCasingComponent[this.lstCasingComponent.length - 1].ID.Value
        : this.filteredOD[0].ID;
    ncc.Grade.Value =
      this.lstCasingComponent.length > 0
        ? this.lstCasingComponent[this.lstCasingComponent.length - 1].Grade.Value
        : this.filteredOD[0].Grade;
    ncc.Weight.Value =
      this.lstCasingComponent.length > 0
        ? this.lstCasingComponent[this.lstCasingComponent.length - 1].Weight.Value
        : this.filteredOD[0].Weight;
    ncc.TopThread.Value = this.ddl_selectedTopThread.Name;
    ncc.BottomThread.Value = this.ddl_selectedBottomThread.Name;
    ncc.Description.Value = this.isdrilling ? 'Drill Pipe' : 'Casing Joint';
    ncc.Length.Value = '0';
    ncc.SysSeq.Value = '';
    ncc.EquipmentType.Value = this.isdrilling ? 'Other' : 'Casing';

    ncc.IsExclude = this.isdrilling ? true : false;

    // These are not on the UI - They may have Errors
    ncc.CollapsePressure.Value = this.selectedCasingODID.CollapsePressure;
    ncc.BurstPressure.Value = this.selectedCasingODID.BurstPressure;
    // These are not on the UI - They may have Errors

    ncc.IsThreadLoss = false;
    ncc.ThreadLoss = '0';
    ncc.ThreadOff = ncc.Length.Value;
    return ncc;
  }

  public getLastJoint(isNew: boolean, ncc?: CasingComponent) {
    this.CasingComponent_NewJointNumber++;
    let JTN = this.CasingComponent_NewJointLetter
      ? `${this.CasingComponent_NewJointLetter}${this.CasingComponent_NewJointNumber}`
      : `${this.CasingComponent_NewJointNumber}`;

    if (ncc) {
      ncc.JointNumber = JTN;
    }

    if (isNew) {
      let duplicated = this.getDuplicate(ncc);
      while (duplicated.length > 0) {
        this.CasingComponent_NewJointNumber++;
        JTN = this.CasingComponent_NewJointLetter
          ? `${this.CasingComponent_NewJointLetter}${this.CasingComponent_NewJointNumber}`
          : `${this.CasingComponent_NewJointNumber}`;
        ncc.JointNumber = JTN;
        duplicated = this.getDuplicate(ncc);
      }

      return JTN;
    } else {
      return JTN;
    }
  }

  public checkforDuplicateJointNumbers(casingComponent: CasingComponent, index?: number) {
    return new Promise(async (resolve, reject) => {
      if (casingComponent && casingComponent.JointNumber && casingComponent.JointNumber === '') {
        resolve(true);
      } else {
        const chkDuplicateJointNumber = this.getDuplicate(casingComponent);
        if (chkDuplicateJointNumber.length > 0) {
          if (index) {
            this.lstCasingComponent[index].JointNumber = '';
          }
          reject({
            key: 'inventoryError',
            severity: 'warn',
            summary: 'Something went wrong!',
            detail: 'Joint number entered already exists, please try again.',
            life: 6000,
          });
        } else {
          resolve(true);
        }
      }
    });
  }

  private getDuplicate(casingComponent: CasingComponent) {
    return this.lstCasingComponent.filter(
      (obj) =>
        obj.JointNumber &&
        obj.JointNumber.length > 0 &&
        casingComponent.JointNumber &&
        casingComponent.JointNumber.length > 0 &&
        obj.JointNumber.trim() === casingComponent.JointNumber.trim() &&
        obj.CasingComponentId.Value !== casingComponent.CasingComponentId.Value
    );
  }

  public filterByJointNumber(casingComponent: CasingComponent[]) {
    const ccWithJoint = casingComponent.filter((obj) => obj.JointNumber && obj.JointNumber.length > 0);
    const ccWithoutJoint = casingComponent.filter((obj) => !obj.JointNumber || obj.JointNumber.length === 0);
    if (ccWithJoint.length > 0) {
      ccWithJoint.sort((a, b) =>
        a.JointNumber.localeCompare(b.JointNumber, 'en', {
          numeric: true,
          sensitivity: 'accent',
        })
      );
      return [...ccWithoutJoint, ...ccWithJoint];
    } else {
      return casingComponent;
    }
  }

  public filterBySequence(casingComponent: CasingComponent[]) {
    casingComponent.sort((a, b) =>
      Number(a.SysSeq.Value) > Number(b.SysSeq.Value) ? 1 : Number(b.SysSeq.Value) > Number(a.SysSeq.Value) ? -1 : 0
    );
    return casingComponent;
  }

  public reOrderRunCasings() {
    let seqValue = 1;
    this.lstCasingComponents_RunChecked.map((element) => {
      element.SysSeq.Value = seqValue.toString();
      seqValue++;
      return element;
    });
  }

  public reDoNumerationInArray(arr: CasingComponent[], startOn?: number) {
    let seqValue = startOn ? startOn : 1;
    arr.map((element) => {
      if (element.Run) {
        element.SysSeq.Value = seqValue.toString();
        seqValue++;
      }
      return element;
    });

    return arr;
  }

  public resetLists() {
    this.lstCasingComponent = [];
    this.lstCasingComponentOriginal = [];
    this.lstCasingComponents_Validated = [];
    this.lstCasingComponents_RunChecked = [];
    this.lstCasingComponents_NotValidated = [];
  }

  public sysSequence() {
    return new Promise((resolve, reject) => {
      try {
        if (this.unvalidateFromRun) {
          resolve(this.reDoNumerationInArray(this.lstCasingComponents_RunChecked, 1));
        } else {
          resolve(this.reDoNumerationInArray(this.lstCasingComponents_Validated, 1));
        }
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public resetALLData() {
    this.resetAmounts();
    this.ratHole = 0;
    this.shoeDepth = 0;
    this.prevSysSeq = 0;
    this.casingStringIdApproved = false;
    this.CasingComponent_NewJointNumber = undefined;
    this.CasingComponent_NewJointLetter = undefined;

    this.casingToUnvalidate = undefined;
    this.casingToUnvalidateIndex = undefined;

    // Lists
    this.lstCasingComponent = [];
    this.lstCasingComponentOriginal = [];
    this.lstCasingComponents_Validated = [];
    this.lstCasingComponents_RunChecked = [];
    this.lstCasingComponents_NotValidated = [];

    // Notifications
    this.notificationsOnRun = [];
    this.notificationsInventory = [];
    this.notificationsRunValidated = [];
    this.notificationsCementVolume = [];

    // Cement Volume
    this.prevCasingString = undefined;
    this.prevCasingStringID = undefined;
    this.prevShoeDepth = undefined;
    this.prevCasingID = undefined;
  }

  public calculateAmounts() {
    this.resetAmounts();
    this.onShelf = this.lstCasingComponents_Validated.length;
    if (this.lstCasingComponents_RunChecked.length > 0) {
      this.onRun = this.lstCasingComponents_RunChecked.length;
      this.onBadJoint = this.lstCasingComponents_RunChecked.filter((obj) => obj.IsBadJoint).length;
      this.onCentralized = this.lstCasingComponents_RunChecked.filter((obj) => obj.IsCentralized === true).length;
    }
    this.onTotal = this.onRun + this.onShelf;
  }

  private resetAmounts() {
    this.onRun = 0;
    this.onShelf = 0;
    this.onTotal = 0;
    this.onBadJoint = 0;
    this.onCentralized = 0;
  }
  //#endregion 'General Methods'

  //#region 'Excel & PDF'
  public async getCasingInventoryPDF() {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        await this._casingComponent.getCasingInventoryPDF(this.casingStringID).subscribe(
          (data) => {
            const contentDisposition = data.headers.get('content-disposition');
            const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            const blobData = new Blob([data.body], { type: 'application/pdf' });

            //Download File
            this._loader.hide();
            this._tooltip.downloadBlob(blobData, filename);
          },
          (error) => {
            console.error(error.message);
            this._loader.hide();
            reject({
              key: 'inventoryError',
              severity: 'error',
              summary: 'We got a problem!',
              detail:
                'There was an error retrieving inventory PDF, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
      );
    });
  }

  public async getCasingInventoryExcel() {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        await this._casingComponent.getCasingInventoryExcel(this.casingStringID).subscribe(
          (data) => {
            const contentDisposition = data.headers.get('content-disposition');
            const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            const blobData = new Blob([data.body], { type: 'application/vnd.ms-excel' });

            //Download File
            this._loader.hide();
            this._tooltip.downloadBlob(blobData, filename);
          },
          (error) => {
            console.error(error.message);
            this._loader.hide();
            reject({
              key: 'inventoryError',
              severity: 'error',
              summary: 'We got a problem!',
              detail:
                'There was an error retrieving inventory Excel, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
      );
    });
  }

  public async getCasingRunningTallyPDF_AllCasings() {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        await this._casingComponent.getCasingRunningTallyPDF_AllCasings(this.casingStringID).subscribe(
          (data) => {
            const contentDisposition = data.headers.get('content-disposition');
            const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            const blobData = new Blob([data.body], { type: 'application/pdf' });

            //Download File
            this._loader.hide();
            this._tooltip.downloadBlob(blobData, filename);
          },
          (error) => {
            console.error(error.message);
            this._loader.hide();
            reject({
              key: 'runningError',
              severity: 'error',
              summary: 'We got a problem!',
              detail: 'There was an error retrieving PDF, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
      );
    });
  }

  public async getCasingRunningTallyPDF_RunOnly() {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        await this._casingComponent.getCasingRunningTallyPDF_RunOnly(this.casingStringID).subscribe(
          (data) => {
            const contentDisposition = data.headers.get('content-disposition');
            const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            const blobData = new Blob([data.body], { type: 'application/pdf' });

            //Download File
            this._loader.hide();
            this._tooltip.downloadBlob(blobData, filename);
          },
          (error) => {
            console.error(error.message);
            this._loader.hide();
            reject({
              key: 'runningError',
              severity: 'error',
              summary: 'We got a problem!',
              detail:
                'There was an error retrieving Run Only PDF, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
      );
    });
  }

  public async getCasingRunningTallyExcel_AllCasings() {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        await this._casingComponent.getCasingRunningTallyExcel_AllCasings(this.casingStringID).subscribe(
          (data) => {
            const contentDisposition = data.headers.get('content-disposition');
            const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            const blobData = new Blob([data.body], { type: 'application/vnd.ms-excel' });

            //Download File
            this._loader.hide();
            this._tooltip.downloadBlob(blobData, filename);
          },
          (error) => {
            console.error(error.message);
            this._loader.hide();
            reject({
              key: 'runningError',
              severity: 'error',
              summary: 'We got a problem!',
              detail:
                'There was an error retrieving All Casings Excel, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
      );
    });
  }

  public async getCasingRunningTallyExcel_RunOnly() {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        await this._casingComponent.getCasingRunningTallyExcel_RunOnly(this.casingStringID).subscribe(
          (data) => {
            const contentDisposition = data.headers.get('content-disposition');
            const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            const blobData = new Blob([data.body], { type: 'application/vnd.ms-excel' });

            //Download File
            this._loader.hide();
            this._tooltip.downloadBlob(blobData, filename);
          },
          (error) => {
            console.error(error.message);
            this._loader.hide();
            reject({
              key: 'runningError',
              severity: 'error',
              summary: 'We got a problem!',
              detail:
                'There was an error retrieving Run Only Casings Excel, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
      );
    });
  }
  //#endregion 'Excel & PDF'

  //#region 'Filters'
  private filterData_Casing(data: any) {
    // Client may eventually ask to 'remember' the most used Configuration
    const key = Object.create(null);

    this.filteredID = [];
    this.filteredID = data.filter((obj: any) => (key[obj.ID] ? false : (key[obj.ID] = true)));
    this.filteredID = this.filteredID.filter((val) => val.ID !== undefined && val.ID !== '' && val.ID !== null);
    this.filteredID.sort((a, b) => (Number(a.ID) > Number(b.ID) ? 1 : Number(b.ID) > Number(a.ID) ? -1 : 0));

    this.filteredOD = [];
    this.filteredOD = data.filter((obj: any) => (key[obj.OD] ? false : (key[obj.OD] = true)));
    this.filteredOD = this.filteredOD.filter((val) => val.OD !== undefined && val.OD !== '' && val.OD !== null);
    this.filteredOD.sort((a, b) => (Number(a.OD) > Number(b.OD) ? 1 : Number(b.OD) > Number(a.OD) ? -1 : 0));

    this.filteredGrade = [];
    this.filteredGrade = data.filter((obj: any) => (key[obj.Grade] ? false : (key[obj.Grade] = true)));
    this.filteredGrade = this.filteredGrade.filter(
      (val) => val.Grade !== undefined && val.Grade !== '' && val.Grade !== null
    );
    this.filteredGrade.sort((a, b) => (a.Grade > b.Grade ? 1 : b.Grade > a.Grade ? -1 : 0));

    this.filteredWeight = [];
    this.filteredWeight = data.filter((obj: any) => (key[obj.Weight] ? false : (key[obj.Weight] = true)));
    this.filteredWeight = this.filteredWeight.filter(
      (val) => val.Weight !== undefined && val.Weight !== '' && val.Weight !== null
    );
    this.filteredWeight.sort((a, b) =>
      Number(a.Weight) > Number(b.Weight) ? 1 : Number(b.Weight) > Number(a.Weight) ? -1 : 0
    );
  }
  //#endregion 'Filters'

  //#region 'CRUD'
  public async createCasingString() {
    return new Promise(async (resolve, reject) => {
      this.casingString = null;
      const newCasingString = this.newCasingString();
      this.SUBS$.add(
        await this._casingString
          .create(newCasingString)
          .finally(() => this._loader.hide())
          .subscribe(
            (data: CasingString) => {
              this.casingString = data;
              this.casingStringID = data.CasingStringId;
              this.resetLstCasingComponent();
              this.casingStringIdApproved = false;
              resolve({
                key: 'casingNotify',
                severity: 'success',
                summary: 'Correct!',
                detail: 'The casing was created successfully!.',
              });
            },
            (error) => {
              console.error(error.message);
              reject({
                key: 'casingError',
                severity: 'error',
                summary: 'We got a problem!',
                detail:
                  'There was an error creating a casing string, please refresh the page or notify the administrator.',
                life: 6000,
              });
            }
          )
      );
    });
  }

  // TODO: Special case cause we get the type
  public async updateCasingString(notificationType: number, updateType: string) {
    return new Promise(async (resolve, reject) => {
      if (!this.casingString) {
        return;
      }
      this._loader.show();
      this.SUBS$.add(
        await this._casingString
          .update(this.casingString)
          .finally(() => this._loader.hide())
          .subscribe(
            (data: CasingString) => {
              this.casingString = data;
              if (updateType !== 'Cement_Volume') {
                const RUN = this.lstCasingComponent.filter((obj) => obj.Run).length;
                if (RUN > 0) {
                  this.loadRunList();
                }
              }
              return true;
            },
            (error) => {
              console.error(error.message);
              reject({
                key: 'runningError',
                severity: 'error',
                summary: 'We got a problem!',
                detail:
                  'There was an error saving casing string information, please refresh the page or notify the administrator.',
                life: 6000,
              });
            }
          )
      );
    });
  }

  public async casingComponentUpdate(casingComponent: CasingComponent, loadRun?: boolean) {
    return new Promise(async (resolve, reject) => {
      if (!casingComponent) {
        return;
      }

      this._loader.show();
      this.SUBS$.add(
        await this._casingComponent
          .update(casingComponent)
          .finally(() => {
            if (!loadRun) {
              this._loader.hide();
            }
          })
          .subscribe(
            () => {
              if (loadRun) {
                this.loadRunList();
                this._loader.hide();
              }
            },
            (error) => {
              console.error(error.message);
              this._loader.hide();
              reject({
                key: 'runningError',
                severity: 'error',
                summary: 'We got a problem!',
                detail:
                  'There was an error updating casing component information, please refresh the page or notify the administrator.',
                life: 6000,
              });
            }
          )
      );
    });
  }

  public loadRunList() {
    this._loader.show();
    this.SUBS$.add(
      this._casingComponent
        .getRunningByCasingStringId(this.casingStringId)
        .pipe(
          map((cc) => {
            cc.map((dto) => {
              dto.isReorder = false;
              return dto;
            });
            return cc;
          })
        )
        .subscribe((data: CasingComponent[]) => {
          this.lstCasingComponents_RunChecked = this._tooltip.deepClone(data);

          if (this.lstCasingComponents_RunChecked.length > 0) {
            this.lstCasingComponents_RunChecked = this.filterBySequence(this.lstCasingComponents_RunChecked);
            this.assignJointTopBottom(this.lstCasingComponents_RunChecked, 'Original', this.casingString.RKB);
          } else {
            this.lstCasingComponents_RunChecked = [];
            this.resetDepthHole();
          }

          this.calculateAmounts();
          this._loader.hide();
        })
    );
  }

  public updateCasingComponentBulk(arrayCasingComponent: CasingComponent[], infoField: string, comeFrom: number) {
    this._loader.show();
    return new Promise(async (resolve, reject) => {
      this.SUBS$.add(
        await this._casingComponent.updateBulk(arrayCasingComponent).subscribe(
          (data: CasingComponent[]) => {
            if (infoField === 'ValidateAll') {
              let notify: any;
              this.loadValidatedRecords(data, comeFrom).then((correct: NotificationToast) => {
                this._loader.hide();
                this.getValidatedAndRunComponents();
                notify = correct;
                resolve(notify);
              });
            } else {
              this.resetLstCasingComponent();
              this.lstCasingComponent = this.filterByJointNumber(data);
              this.loadOriginalCasingComponent();
              this.addEdit();
              // Not showing an error Message for UpdateRunSequenceNumbers
              if (infoField === 'UpdateThreadOff') {
                this._loader.hide();
                // Casing Tally
                resolve({
                  key: 'casingNotify',
                  severity: 'success',
                  summary: 'Correct!',
                  detail: `Information updated correctly!`,
                });
              } else if (infoField === 'ToggleSequence') {
                this.getValidatedAndRunComponents();
                // Running
                this._loader.hide();
                resolve({
                  key: 'runningNotify',
                  severity: 'success',
                  summary: 'Correct!',
                  detail: `Information updated correctly!`,
                });
              } else if (infoField === 'MultipleEdit') {
                this._loader.hide();
                // Casing Tally
                resolve({
                  key: 'casingNotify',
                  severity: 'success',
                  summary: 'Correct!',
                  detail: `Information updated correctly!`,
                });
              } else if (infoField === 'Centralized') {
                this._loader.hide();
                // Running
                resolve({
                  key: 'runningNotify',
                  severity: 'success',
                  summary: 'Correct!',
                  detail: `Information updated correctly!`,
                });
              } else if (infoField === 'LoadRun') {
                this.loadRunList();
                this._loader.hide();
                // Running
                resolve({
                  key: 'runningNotify',
                  severity: 'success',
                  summary: 'Correct!',
                  detail: `Information updated correctly!`,
                });
              } else {
                this._loader.hide();
                // General
                resolve({
                  key: 'casingNotify',
                  severity: 'success',
                  summary: 'Correct!',
                  detail: `Information updated correctly!`,
                });
              }
            }
          },
          (error) => {
            console.error(error.message);
            this._loader.hide();
            reject({
              key: comeFrom == 1 ? 'runningError' : 'casingError',
              severity: 'error',
              summary: 'We got a problem!',
              detail:
                'There was an error updating casing components, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
      );
    });
  }

  public runCasing(casingComponent: CasingComponent, addToRunChecked: boolean, setRun?: boolean) {
    if (setRun) {
      casingComponent.Run = true;
    }

    if (this.lstCasingComponents_RunChecked.length > 0) {
      this.prevSysSeq =
        this.lstCasingComponents_RunChecked.length > 0 ? this.lstCasingComponents_RunChecked.length + 1 : 0;
      casingComponent.SysSeq.Value = this.getNextSysSeq();
    } else {
      casingComponent.SysSeq.Value = '1';
    }

    if (addToRunChecked) {
      this.lstCasingComponents_RunChecked.push(casingComponent);
    }
    return casingComponent;
  }

  public getNextSysSeq(startFrom?: number) {
    if (startFrom) {
      this.prevSysSeq = startFrom;
    } else {
      this.prevSysSeq = this.prevSysSeq + 1;
    }
    return this.prevSysSeq.toString();
  }

  public unvalidateCasing(): Observable<CasingComponent> {
    if (!this.casingToUnvalidate) {
      return undefined;
    }

    return this._casingComponent.unvalidateCasing(this.casingToUnvalidate);
  }

  public createCentralizeSetup(dto: Centralizer[]): Observable<Centralizer | Centralizer[]> {
    if (dto.length === 1) {
      return this._centralize.create(dto[0]);
    } else {
      return this._centralize.createBulk(dto);
    }
  }

  public updateCentralizeSetup(dto: Centralizer[]): Observable<Centralizer | Centralizer[]> {
    if (dto.length === 1) {
      return this._centralize.update(dto[0]);
    } else {
      return this._centralize.updateBulk(dto);
    }
  }

  public deleteCentralizeSetup(dto: Centralizer[]): Observable<any> {
    if (dto.length === 1) {
      return this._centralize.delete(dto[0]);
    } else {
      return this._centralize.deleteBulk(dto);
    }
  }

  public setUnvalidate() {
    this.casingToUnvalidate.Run = false;
    this.casingToUnvalidate.SysSeq.Value = '';
    this.casingToUnvalidate.IsValidated = false;
    this.casingToUnvalidate.IsReferenceDepth = false;

    if (this.unvalidateFromRun) {
      this.lstCasingComponents_RunChecked[this.casingToUnvalidateIndex] = this.casingToUnvalidate;
    } else {
      this.lstCasingComponents_Validated[this.casingToUnvalidateIndex] = this.casingToUnvalidate;
    }
  }

  public getIconDescription() {
    this.SUBS$.add(
      this._casingComponent.getIconDescription().subscribe((data) => {
        this.iconDescription = [...data];
      })
    );
  }
  //#endregion 'CRUD'

  //#region 'Cement Volume'
  public loadCementVolUniqueOD(array: CasingComponent[]): string[] {
    let UniqueODValues = [];
    if (array.length > 0) {
      const tempVals = array.filter(
        (value, index, self) => self.map((x) => x.OD.Value).indexOf(value.OD.Value) === index
      );
      tempVals.forEach((obj) => UniqueODValues.push(obj.OD.Value));
      UniqueODValues = UniqueODValues.filter((obj) => obj !== '');
    }
    return UniqueODValues;
  }

  public loadCementVolUniqueID(array: CasingComponent[]): string[] {
    let UniqueIDValues = [];
    if (array.length > 0) {
      const tempVals = array.filter(
        (value, index, self) => self.map((x) => x.ID.Value).indexOf(value.ID.Value) === index
      );
      tempVals.forEach((obj) => UniqueIDValues.push(obj.ID.Value));
      UniqueIDValues = UniqueIDValues.filter((obj) => obj !== '');
    }
    return UniqueIDValues;
  }

  public calculateMixedNumbers(mixedNumber: string): number {
    const calcMix = mixedNumber.split(' ');
    const mixedNum = calcMix[1].split('/');
    return Number(calcMix[0]) + Number(mixedNum[0]) / Number(mixedNum[1]);
  }

  public loadPreviousCasingData(IsUpdateCasingString: boolean, casingDesc: string) {
    this.resetNotifications();
    this._loader.show();
    return new Promise(async (resolve, reject) => {
      this.SUBS$.add(
        this._casingString.getWellboreIdCasingDescription(this.wellBoreID, casingDesc).subscribe(
          (data: CasingString[]) => {
            if (data && data.length > 0) {
              this.prevCasingString = data[0];
              this.prevCasingStringID = data[0].CasingStringId;
              if (IsUpdateCasingString) {
                this.casingString.PrevCasingDescription = casingDesc;
                this.updateCasingString(4, 'Cement_Volume');
              }
              this.loadPreviousCasingComponentData().then(
                () => {
                  resolve();
                },
                (err) => {
                  reject(err);
                }
              );
            } else {
              reject('EmptyCasingString');
            }
            this._loader.hide();
          },
          (error) => {
            console.error(error.message);
            this._loader.hide();
            reject('ErrorPreviousCasing');
          }
        )
      );
    });
  }

  public loadPreviousCasingComponentData() {
    this.resetNotifications();
    this._loader.show();
    return new Promise(async (resolve, reject) => {
      let lstPrevCasingComponents: CasingComponent[] = [];
      this.SUBS$.add(
        this._casingComponent.getCasingStringId(this.prevCasingStringID).subscribe(
          (data: CasingComponent[]) => {
            if (data && data.length > 0) {
              lstPrevCasingComponents = data;
              this.prevCasingID = this.loadCementVolUniqueID(lstPrevCasingComponents)[0];
              let lstPrevCasingComponents_RunChecked = lstPrevCasingComponents.filter(
                (obj) => obj.IsValidated && obj.Run
              );
              if (lstPrevCasingComponents_RunChecked.length > 0) {
                lstPrevCasingComponents_RunChecked = this.filterBySequence(lstPrevCasingComponents_RunChecked);
                this.prevShoeDepth = this.assignJointTopBottom(
                  lstPrevCasingComponents_RunChecked,
                  'CementVolume',
                  this.prevCasingString.RKB
                );
                resolve();
              } else {
                this.prevCasingID = undefined;
                this.prevShoeDepth = undefined;
                reject('EmptyShoeDepth');
              }
            } else {
              this.prevCasingID = undefined;
              this.prevShoeDepth = undefined;
              reject('EmptyCasingID');
            }
            this._loader.hide();
          },
          (error) => {
            console.error(error.message);
            this.prevCasingID = undefined;
            this.prevShoeDepth = undefined;
            this._loader.hide();
            reject('ErrorPreviousCasingInfo');
          }
        )
      );
    });
  }

  public reloadCasingsAfterApproval(data: CasingComponent[]) {
    return new Promise(async (resolve) => {
      this.resetLstCasingComponent();
      this.lstCasingComponent = [...this.filterByJointNumber(data)];
      this.loadOriginalCasingComponent();
      this.addEdit();
      this.casingStringIdApproved = true;
      this.getValidatedAndRunComponents();
      this._loader.hide();
      resolve({
        key: 'casingNotify',
        severity: 'success',
        summary: 'Correct!',
        detail: 'Selected casing has been successfully approved.',
      });
    });
  }

  public getValidatedAmount(): number {
    return this.lstCasingComponent.filter((dto) => dto.IsValidated === false).length;
  }
  //#endregion 'Cement Volume'
}
