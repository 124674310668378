import { TaskPlanItemDependency } from '#models/index';

export class TaskPlanItem {
  public ID: number;
  public Code: string;
  public Description: string;
  public ConditionComments: string;
  public TaskPlanSubSectionId: number;
  public ConditionalExpression: string;
  public DisplayFollowUpWO: boolean;
  public DisplayComments: boolean;
  public DisplayImmediateAttentionRequired: boolean;
  public DisplayNA: boolean;
  public DisplayPhoto: boolean;
  public Units: string;
  public SortID: number;
  public TaskPlanItemDependencies: TaskPlanItemDependency[];
}
