import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggedUser, User } from 'models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'Get'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/user${method}`;
  }

  public getCurrentUser(): Observable<LoggedUser> {
    return this.http.get<LoggedUser>(this.getURL(`/current`));
  }

  public getParticipants(query: string): Observable<User[]> {
    return this.http.get<User[]>(this.getURL(`/getParticipants/${query}`));
  }

  public getUsersByOrg(orgId: number): Observable<User[]> {
    return this.http.get<User[]>(this.getURL(`/getByOrganization/${orgId}`));
  }

  public acceptDisclaimer(userId: number): Observable<User> {
    return this.http.put<User>(this.getURL(`/acceptDisclaimer/${userId}`), null);
  }

  public getAll(): Observable<User[]> {
    return this.http.get<User[]>(this.getURL(`/getAll`));
  }

  public getProfiles(): Observable<Array<String>> {
    return this.http.get<Array<String>>(this.getURL(`/getProfiles`));
  }

  public getUnits(): Observable<Array<String>> {
    return this.http.get<Array<String>>(this.getURL(`/getUnits`));
  }
  //#endregion 'Get'

  //#region 'Update'
  public update(user: User): Observable<User> {
    return this.http.put<User>(this.getURL(`/update`), user);
  }
  //#endregion 'Update'

  //#region 'Delete'
  public removeUser(id: number): Observable<User> {
    return this.http.put<User>(this.getURL(`/removeUser/${id}`), null);
  }
  //#endregion 'Delete'
}
