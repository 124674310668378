import { BusinessUnitsApiService } from '#services-api/shared/business-unit-api.service';
import { OrganizationApiService } from '#services-api/shared/organization-api.service';
import { UserApiService } from '#services-api/shared/user-api.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Adal6Service } from 'adal-angular6';
import { BusinessUnit, LoggedUser, Organization } from 'models';
import 'rxjs/add/operator/finally';
import { Subscription } from 'rxjs/Subscription';
import { LoadingIndicatorService } from './loading-indicator.service';

@Injectable()
export class AuthService implements OnDestroy {
  //#region 'Variables'
  public currentUser: LoggedUser;
  private usrs: any[] = [];

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private _user: UserApiService,
    private _adal6: Adal6Service,
    private _organization: OrganizationApiService,
    private _businessUnit: BusinessUnitsApiService,
    private _loader: LoadingIndicatorService
  ) {}

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
    this._loader.hide();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  public loadUserInfo(): Promise<LoggedUser> {
    this._loader.show();
    return this._user
      .getCurrentUser()
      .finally(() => this._loader.hide())
      .toPromise()
      .then(async (userInfo: LoggedUser) => {
        await this.validateAccess(userInfo);
        this.currentUser = userInfo;
        return userInfo;
      });
  }
  //#endregion 'Load'

  //#region 'General Methods'
  public getToken(): string {
    return this._adal6.userInfo ? this._adal6.userInfo.token : null;
  }

  public getAuthorizationHeader(): string {
    const token = this.getToken();

    return !!token ? `Bearer ${token}` : '';
  }

  public getFullName(): string {
    return this._adal6.userInfo ? this._adal6.userInfo.username : null;
  }

  public isAuthenticated(): boolean {
    return this._adal6.userInfo ? this._adal6.userInfo.authenticated : null;
  }

  public isAuthorized(): boolean {
    return !!this.currentUser && this.currentUser.Id > 0;
  }

  public isDisclaimerAccepted(): boolean {
    return !!this.currentUser && this.currentUser.Id > 0 && this.currentUser.DisclaimerAccepted;
  }

  public isInUserTable(): boolean {
    return this.usrs && this.usrs.length > 0 ? true : false;
  }

  public getCurrentUser(): LoggedUser {
    return this.currentUser;
  }

  public isSystemAdmin(): boolean {
    return !!this.currentUser && this.currentUser.IsSystemAdmin;
  }

  public login(force: boolean = false): void {
    if (!this._adal6.loginInProgress() || force) {
      this._adal6.login();
    }
  }

  public logout(): void {
    this._adal6.logOut();
    this.currentUser = null;
    this.usrs = [];
  }

  public acceptDisclaimer(userId: number) {
    this._loader.show();
    const DISC$ = this._user
      .acceptDisclaimer(userId)
      .finally(() => {
        this._loader.hide();
        window.location.reload();
      })
      .subscribe((userInfo: LoggedUser) => {
        this.currentUser = userInfo;
        return userInfo;
      });

    this.SUBS$.add(DISC$);
  }
  //#endregion 'General Methods'

  //#region 'Validations'
  private validateAccess(userInfo: LoggedUser): Promise<boolean> {
    return this._businessUnit
      .getUserBUs()
      .toPromise()
      .then((dataBU: BusinessUnit[]) => {
        if (dataBU.length > 0) {
          return this._organization
            .getUserOrgs(dataBU[0].Id)
            .toPromise()
            .then((dataOR: Organization[]) => {
              this.usrs = dataOR[0].OrganizationUsers.filter((usr) => usr.CAI === userInfo.CAI);
              return true;
            });
        }
      });
  }
  //#endregion 'Validations'
}
