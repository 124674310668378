import { environment } from '#environments/environment';
import { ActivityCode, OfflineActivityTimelog } from '#models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OfflineActivityTimelogApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/offlineActivityTimelog${method}`;
  }
  //#endregion 'General'

  //#region 'Get'
  public getActivityCode(): Observable<ActivityCode[]> {
    return this.http.get<ActivityCode[]>(this.getURL(`/getActivityCode`));
  }

  public getByOfflineActivityDateId(oaDateId: string): Observable<OfflineActivityTimelog[]> {
    return this.http.get<OfflineActivityTimelog[]>(this.getURL(`/getByOfflineActivityDateId`), {
      params: { oaDateId: oaDateId },
    });
  }
  //#endregion 'Get'

  //#region 'Post'
  public create(offlineActivityTimelog: OfflineActivityTimelog): Observable<OfflineActivityTimelog> {
    return this.http.post<OfflineActivityTimelog>(this.getURL(`/create`), offlineActivityTimelog);
  }

  public createBulk(offlineActivityTimelog: OfflineActivityTimelog[]): Observable<OfflineActivityTimelog[]> {
    return this.http.post<OfflineActivityTimelog[]>(this.getURL(`/createBulk`), offlineActivityTimelog);
  }
  //#endregion 'Post'

  //#region 'Put'
  public update(offlineActivityTimelog: OfflineActivityTimelog): Observable<OfflineActivityTimelog> {
    return this.http.put<OfflineActivityTimelog>(this.getURL(`/update`), offlineActivityTimelog);
  }

  public updateBulk(offlineActivityTimelog: OfflineActivityTimelog[]): Observable<OfflineActivityTimelog[]> {
    return this.http.put<OfflineActivityTimelog[]>(this.getURL(`/updateBulk`), offlineActivityTimelog);
  }
  //#endregion 'Put'

  //#region 'Delete'
  public delete(offlineActivityTimelog: OfflineActivityTimelog): Observable<{}> {
    return this.http.put<OfflineActivityTimelog>(this.getURL(`/delete`), offlineActivityTimelog);
  }
  //#endregion 'Delete'
}
