import { NotificationService } from '#services-shared/index';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NotificationComponent } from './notification/notification.component';

@NgModule({
  declarations: [NotificationComponent],
  imports: [CommonModule, FormsModule],
  providers: [NotificationService],
  exports: [NotificationComponent],
})
export class ComponentsModule {}
