import { environment } from '#environments/environment';
import { EmailNotification } from '#models/shared/email-notification';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/email${method}`;
  }
  //#endregion 'General'

  //#region 'Create'
  public sendNotificationEmail(email: EmailNotification): Observable<any> {
    return this.http.post<any>(this.getURL(`/sendNotificationEmail`), email);
  }
  //#endregion 'Create'
}
