<div class="box-shadow module">
  <div class="row">
    <h2>Organization</h2>
    <hr />
  </div>

  <div class="row">
    <div class="col-xs-6 padding-0">
      <label>Business Unit :</label>
      <select class="form-control" [(ngModel)]="selectedBU" (change)="loadOrganizations()">
        <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu">
          {{ bu.Name }}
        </option>
      </select>
    </div>
    <div class="col-xs-6 padding-0">&nbsp;</div>
  </div>

  <div class="row">&nbsp;</div>

  <!-- We might not need this if BU is init with first element -->
  <div class="row" *ngIf="!selectedBU">
    <div class="alert alert-warning">Please select a business unit.</div>
  </div>

  <div class="row" *ngIf="lstOrganization.length >= 0 && selectedBU">
    <div class="row">
      <label>Organizations : </label>
    </div>

    <div class="row">
      <table class="table table-hover table-stripped table-condensed">
        <thead>
          <tr>
            <th class="text-center">Business Unit</th>
            <th class="text-center">ID</th>
            <th>Organization</th>
            <th>&nbsp;</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colspan="4" *ngIf="lstOrganization.length == 0">
              <div class="text-center">
                <div class="alert alert-warning">There are no organizations yet.</div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let org of lstOrganization">
            <td class="col-xs-2 text-center">{{ selectedBU.Name }}</td>
            <td class="col-xs-2 text-center">{{ org.Id }}</td>
            <td class="col-xs-6">{{ org.Name }}</td>
            <td class="col-xs-4 text-center">
              <button type="button" class="btn btn-sm btn-primary">Edit</button> &nbsp;
              <button type="button" class="btn btn-sm btn-danger">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row text-right">
      <div class="form-inline">
        <div class="form-group">
          <label>Add Organization : </label> &nbsp;
          <input
            type="text"
            class="form-control"
            placeholder="new org name"
            [(ngModel)]="newOrganzationName"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
        &nbsp;<button type="submit" class="btn btn-primary" (click)="btnCreateOrganization()">Create</button>
      </div>
    </div>
  </div>

  <div class="row">&nbsp;</div>
</div>
