<div class="box-shadow module form-horizontal" style="margin: auto auto 20px; width: 80%">
  <h3>Edit Task Plan Item</h3>

  <div class="form-group">
    <div style="text-align: center">
      {{ taskPlanItem.Code }}
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-2">Description</label>
    <div class="col-md-10">
      <input class="form-control text-box single-line" [(ngModel)]="taskPlanItem.Description" />
      <span class="text-danger field-validation-error" *ngIf="displayWarningDesc">
        <span>The Description field is required.</span>
      </span>
    </div>
  </div>

  <div class="form-group">
    <div class="col-md-12" style="padding-left: 19%">
      <label style="padding-right: 10px">
        <input type="checkbox" [(ngModel)]="taskPlanItem.DisplayComments" />
        Display Comment
      </label>
      <label style="padding-right: 10px">
        <input type="checkbox" [(ngModel)]="taskPlanItem.DisplayFollowUpWO" />
        Display Work Order Recommended
      </label>
      <label style="padding-right: 10px">
        <input type="checkbox" [(ngModel)]="taskPlanItem.DisplayImmediateAttentionRequired" />
        Display Immediate Attention Required
      </label>
      <label style="padding-right: 10px">
        <input type="checkbox" [(ngModel)]="taskPlanItem.DisplayNA" />
        Display N/A
      </label>
      <label style="padding-right: 10px">
        <input type="checkbox" [(ngModel)]="taskPlanItem.DisplayPhoto" />
        Display Photo
      </label>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-md-2">Comment Instructions</label>
    <div class="col-md-10">
      <textarea class="form-control" rows="5" cols="100" [(ngModel)]="taskPlanItem.ConditionComments"></textarea>
    </div>
  </div>

  <div class="form-group">
    <div class="col-md-offset-2 col-md-10">
      <a class="btn btn-default" style="width: 100px" (click)="updateTaskPlanItem()">Save</a>
    </div>
  </div>
</div>

<div>
  <a (click)="onRedirectButtonClick()" style="cursor: pointer">Back to Task Plan</a>
</div>
