<app-header></app-header>
<div id="body">
  <main tabindex="-1" id="main-content" role="main">
    <div *ngIf="showDisclaimer" class="module box-shadow text-center">
      <h3 style="margin-bottom: 0px">NOTICE TO USERS:</h3>
      <h4>
        This Chevron software product is for use by authorized users only. To the extent permitted by law, by using this
        software product you acknowledge and consent to the monitoring, access (including the decryption and inspection
        of selected encrypted internet traffic), use or disclosure by Chevron of any information generated, received or
        stored on this system/device and waive any right or expectation of privacy in connection with your use.
        Unauthorized and/or improper use of this software product in violation of Chevron corporate policies will be not
        tolerated and may result in disciplinary action, including termination.
      </h4>
      <button type="button" class="btn btn-light" (click)="iAccept()">I Agree</button>
    </div>
    <div *ngIf="!showDisclaimer" style="min-height: 500px">
      <div class="content">
        <app-loading-indicator></app-loading-indicator
        ><!-- loading image -->
        <router-outlet></router-outlet
        ><!-- app-home -->
      </div>
    </div>
  </main>
</div>

<app-footer></app-footer>

<!--
<app-feedback></app-feedback>
<app-notification></app-notification> 
-->
