import { environment } from '#environments/environment';
import { ActivityCode, Timelog } from '#models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimelogApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/timeLog${method}`;
  }
  //#endregion 'General'

  //#region 'Get'
  public getActivityCode(): Observable<ActivityCode[]> {
    return this.http.get<ActivityCode[]>(this.getURL(`/getActivityCode`));
  }

  public getByDailyOperationId(dailyOperationId: string): Observable<Timelog[]> {
    return this.http.get<Timelog[]>(this.getURL(`/getByDailyOperationId`), {
      params: { dailyOperationId: dailyOperationId },
    });
  }
  //#endregion 'Get'

  //#region 'Post'
  public create(timeLog: Timelog): Observable<Timelog> {
    return this.http.post<Timelog>(this.getURL(`/create`), timeLog);
  }

  public createBulk(timeLog: Timelog[]): Observable<Timelog[]> {
    return this.http.post<Timelog[]>(this.getURL(`/createBulk`), timeLog);
  }
  //#endregion 'Post'

  //#region 'Put'
  public update(timeLog: Timelog): Observable<Timelog> {
    return this.http.put<Timelog>(this.getURL(`/update`), timeLog);
  }

  public updateBulk(timeLog: Timelog[]): Observable<Timelog[]> {
    return this.http.put<Timelog[]>(this.getURL(`/updateBulk`), timeLog);
  }
  //#endregion 'Put'

  //#region 'Delete'
  public delete(timeLog: Timelog): Observable<{}> {
    return this.http.put<Timelog>(this.getURL(`/delete`), timeLog);
  }
  //#endregion 'Delete'
}
