import { TaskPlanItem } from '#models/index';

export class TaskPlanSubSection {
  public Description: string;
  public ID: number;
  public TaskPlanSectionId: number;
  public SortID: number;
  public TaskPlanItems: TaskPlanItem[];

  public firstItemId: number;
  public lastItemId: number;
}
