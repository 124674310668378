import { environment } from '#environments/environment';
import { CVXPlanData, Plan, PlanActions, PlanActivity } from '#models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root',
})
export class PlanApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/Plan${method}`;
  }
  //#endregion 'General'

  //#region 'Get'
  public getCVXPlannedDuration(): Observable<CVXPlanData[]> {
    return this.http.get<CVXPlanData[]>(`${environment.apiEndpoint}/CVXPlanActivities/getCVXPlannedDuration`);
  }

  public getAllPlansByJobId(jobId: string): Observable<Plan[]> {
    return this.http.get<Plan[]>(this.getURL(`/getAllPlansByJobId`), {
      params: { idRecParent: jobId },
    });
  }
  //#endregion 'Get'

  //#region 'Post'
  public createPlan(planData: PlanActions): Observable<Plan> {
    return this.http.post<Plan>(this.getURL(`/create`), planData);
  }

  public createBulkPlan(planData: PlanActions[]): Observable<Plan[]> {
    return this.http.post<Plan[]>(this.getURL(`/createBulk`), planData);
  }

  public createPlanActivity(planData: PlanActions): Observable<PlanActivity> {
    return this.http.post<PlanActivity>(`${environment.apiEndpoint}/PlanActivity/create`, planData);
  }

  public createBulkPlanActivity(planData: PlanActions[]): Observable<PlanActivity[]> {
    return this.http.post<PlanActivity[]>(`${environment.apiEndpoint}/PlanActivity/createBulk`, planData);
  }
  //#endregion 'Post'

  //#region 'Update'
  public updatePlan(planData: Plan): Observable<Plan> {
    return this.http.put<Plan>(this.getURL(`/update`), planData);
  }

  public updateBulkPlan(planData: Plan[]): Observable<Plan[]> {
    return this.http.put<Plan[]>(this.getURL(`/updateBulk`), planData);
  }
  //#endregion 'Update'
}
