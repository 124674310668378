import { CasingDescription } from '#models/index';
import { SectionCasingService } from '#services-shared/index';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cement-volume',
  templateUrl: './cement-volume.component.html',
  styleUrls: ['./cement-volume.component.scss'],
})
export class CementVolumeComponent implements OnInit {
  //#region 'Input, Output, ViewChild'
  @Output() public message: EventEmitter<any> = new EventEmitter<any>();
  //#endregion 'Input, Output, ViewChild'

  //#region 'Variables'
  // UI Input Variables
  public defaultDescription: CasingDescription = { Description: '---Select---', Help: '' };
  public prevCasingDescription: CasingDescription;
  public casingstringIDValues: string[];
  public casingstringODValues: string[];
  public cementVolumeCalculation: boolean;

  // Calculated Variables
  public selectedIDCmntVol: string;
  public selectedODCmntVol: string;
  public calculatedODCmntVol: number;
  public selectedHoleSize: string;

  public pipeCapacity: number;
  public pipeDisplacement: number;
  public internalCasingVolume: number;
  public totalAnnulusVolume: number;
  public totalFluidVolume: number;
  public targetVolume: number;
  public targetVolumeOH: number;
  public sacksReqd: number;
  public mixWaterCalc: number;
  public shoeTrackVolume: number;
  public halfShoeTrackVolume: number;
  public freshWaterDisplacement: number;
  public maxDisplacement: number;
  public basketDepth: number;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(public _sectionCasing: SectionCasingService) {}

  ngOnInit() {}
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  public reCalculate() {
    // Follow this Order - Calculations Depend on Each other
    this.computePipeCapacity();
    this.computePipeDisplacement();
    this.computeInternalCasingVolume();
    this.computeTotalAnnulusVolume();
    this.computeTotalFluidVolume();
    this.computeShoeTrackVolume();
    this.computeHalfShoeTrackVolume();
    this.computeTargetVolume();
    this.computeTargetVolume30();
    this.computeSacks();
    this.computeMixWater();
    this.computeFreshWaterDisp();
    this.computeMaxDisp();
  }
  //#endregion 'Load'

  //#region 'Compute Methods'
  private computePipeCapacity() {
    this.pipeCapacity = Math.pow(this.calculatedODCmntVol, 2) / 1029.4;
  }

  private computePipeDisplacement() {
    this.pipeDisplacement =
      ((Math.pow(this.calculatedODCmntVol, 2) - Math.pow(Number(this.selectedIDCmntVol), 2)) / 1029.4) *
      this._sectionCasing.shoeDepth;
  }

  private computeInternalCasingVolume() {
    this.internalCasingVolume = (Math.pow(Number(this.selectedIDCmntVol), 2) / 1029.4) * this._sectionCasing.shoeDepth;
  }

  private computeTotalAnnulusVolume() {
    if (this.prevCasingDescription === this.defaultDescription) {
      this.totalAnnulusVolume =
        ((Math.pow(Number(this.selectedHoleSize), 2) - Math.pow(this.calculatedODCmntVol, 2)) / 1029.4) *
        this._sectionCasing.shoeDepth;
    } else {
      this.totalAnnulusVolume =
        ((Math.pow(Number(this._sectionCasing.prevCasingID), 2) - Math.pow(this.calculatedODCmntVol, 2)) / 1029.4) *
          (this._sectionCasing.prevShoeDepth - 19.3) +
        ((Math.pow(Number(this.selectedHoleSize), 2) - Math.pow(this.calculatedODCmntVol, 2)) / 1029.4) *
          (this._sectionCasing.shoeDepth - this._sectionCasing.prevShoeDepth);
    }
  }

  private computeTotalFluidVolume() {
    this.totalFluidVolume = this.totalAnnulusVolume + this.internalCasingVolume;
  }

  private computeTargetVolume() {
    this.targetVolume = this.totalAnnulusVolume + this.shoeTrackVolume;
  }

  private computeTargetVolume30() {
    this.targetVolumeOH =
      ((Math.pow(Number(this._sectionCasing.prevCasingID), 2) - Math.pow(this.calculatedODCmntVol, 2)) / 1029.4) *
        (this._sectionCasing.prevShoeDepth - 32.4) +
      ((Math.pow(Number(this.selectedHoleSize), 2) - Math.pow(this.calculatedODCmntVol, 2)) / 1029.4) *
        (this._sectionCasing.shoeDepth - this._sectionCasing.prevShoeDepth) *
        1.3 +
      this.shoeTrackVolume;
  }

  private computeSacks() {
    this.sacksReqd = this.targetVolumeOH / 0.1781 / this._sectionCasing.casingString.Yield;
  }

  private computeMixWater() {
    this.mixWaterCalc = (this.sacksReqd * this._sectionCasing.casingString.MixWater) / 42.2;
  }

  private computeShoeTrackVolume() {
    this.shoeTrackVolume = this._sectionCasing.casingString.Length * this.pipeCapacity;
  }

  private computeHalfShoeTrackVolume() {
    this.halfShoeTrackVolume = this.shoeTrackVolume * 0.5;
  }

  private computeFreshWaterDisp() {
    this.freshWaterDisplacement =
      (this._sectionCasing.shoeDepth -
        this._sectionCasing.casingString.Length +
        this._sectionCasing.casingString.StickUp) *
      this.pipeCapacity;
  }

  private computeMaxDisp() {
    this.maxDisplacement = this.freshWaterDisplacement + this.halfShoeTrackVolume;
  }

  public async prevCasingDescriptionChange(event: any) {
    this._sectionCasing.resetNotifications();
    if (this.prevCasingDescription.Description === this._sectionCasing.selectedCasingDescription.Description) {
      this._sectionCasing.prevCasingID = '';
      this._sectionCasing.prevShoeDepth = 0;
      this._sectionCasing.showNotification(
        {
          severity: 'warn',
          summary: 'Something went wrong!',
          detail: 'Please Select Different Previous Casing Than Current.',
        },
        4
      );
    } else {
      if (this.prevCasingDescription === this.defaultDescription) {
        this._sectionCasing.casingString.PrevCasingDescription = null;
        this._sectionCasing.prevCasingID = undefined;
        this._sectionCasing.prevShoeDepth = undefined;
        this._sectionCasing.updateCasingString(4, 'Cement_Volume');
        this.reCalculate();
      } else {
        this._sectionCasing.loadPreviousCasingData(true, this.prevCasingDescription.Description).then(
          () => {
            this.message.next({
              key: 'casingNotify',
              sticky: false,
              closable: false,
              severity: 'success',
              summary: 'Casing String Updated',
              detail: 'Previous Casing Description Successfully Updated.',
            });
            this.reCalculate();
          },
          (err) => {
            switch (err) {
              case 'EmptyCasingString': {
                this._sectionCasing.showNotification(
                  {
                    severity: 'info',
                    summary: 'No Casing String',
                    detail: 'No Associated Casings Available.',
                  },
                  4
                );
                break;
              }
              case 'ErrorPreviousCasing': {
                this._sectionCasing.showNotification(
                  {
                    severity: 'error',
                    summary: 'We got a problem!',
                    detail: 'Error Retrieving Previous Casing for Cement Volume Calculation.',
                  },
                  4
                );
                break;
              }
              case 'EmptyShoeDepth': {
                this._sectionCasing.showNotification(
                  {
                    severity: 'info',
                    summary: 'No Casing Shoe Depth Info',
                    detail: 'No Associated Casing Shoe Depth Info.',
                  },
                  4
                );
                break;
              }
              case 'EmptyCasingID': {
                this._sectionCasing.showNotification(
                  {
                    severity: 'info',
                    summary: 'No Casing ID Info',
                    detail: 'No Associated Casings ID Info.',
                  },
                  4
                );
                break;
              }
              case 'ErrorPreviousCasingInfo': {
                this._sectionCasing.showNotification(
                  {
                    severity: 'error',
                    summary: 'We got a problem!',
                    detail: 'Error Retrieving Previous Casing Info.',
                  },
                  4
                );
                break;
              }
              default: {
                this._sectionCasing.showNotification(
                  {
                    severity: 'error',
                    summary: 'We got a problem!',
                    detail: 'Error Loading Previous Casing Information, please refresh the page.',
                  },
                  4
                );
                break;
              }
            }
          }
        );
      }
    }
  }
  //#endregion 'Compute Methods'

  //#region 'CRUD'
  public updateODCmntVal() {
    if (this.selectedODCmntVol.includes('/')) {
      this.calculatedODCmntVol = this._sectionCasing.calculateMixedNumbers(this.selectedODCmntVol);
    } else {
      this.calculatedODCmntVol = Number(this.selectedODCmntVol);
    }
  }

  public updateCasingStringInfo(updatedField: string, notificationType: number) {
    const updateSuccess = this._sectionCasing.updateCasingString(notificationType, 'Cement_Volume');
    if (updateSuccess) {
      this.message.next({
        key: 'casingNotify',
        sticky: false,
        closable: false,
        severity: 'success',
        summary: 'Casing String Updated',
        detail: `${updatedField} was Successfully Updated.`,
      });
    }
  }
  //#endregion 'CRUD'
}
