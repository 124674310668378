import { environment } from '#environments/environment';
import { APIConfiguration } from '#models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Well } from 'models';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root',
})
export class WellsApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/Well${method}`;
  }
  //#endregion 'General'

  //#region 'Get'
  public getWellData(): Observable<Well[]> {
    return this.http.get<Well[]>(this.getURL(`/getAll`));
  }

  public getWellDataByID(wellID: string): Observable<Well> {
    return this.http.get<Well>(this.getURL(`/getByWellId`), { params: { wellId: wellID } });
  }

  public getApiConfigurationInfo(): Observable<APIConfiguration> {
    return this.http.get<APIConfiguration>(this.getURL(`/getApiConfigurationInfo`));
  }
  //#endregion 'Get'
}
