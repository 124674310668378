<div class="box-shadow module" style="height: 690px">
  <div>
    <h1>DSR Workspace Help</h1>
  </div>
  <br />
  <div>
    <h2>Quick Guide</h2>
    <div style="text-align: center; font-size: 18px">
      <span class="boldTextFont fs18"
        ><b>
          <a [href]="link" target="_blank" rel="noopener noreferrer">Click to see the Quick Guide.</a>
        </b></span
      >
    </div>
  </div>
  <br />
  <div>
    <h2>Troubleshooting Contact</h2>
    <div style="text-align: center; font-size: 18px; color: #007ad9">
      <span class="boldTextFont fs18"><b> - Local BU DSR Workspace Power Users</b></span>
      <br />
      <span class="boldTextFont fs18"
        ><b> - UC D&C DCAP Team - <span class="boldTextFont emailHelp">Email : DCAP@chevron.com</span></b></span
      >
    </div>
  </div>
</div>
