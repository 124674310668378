import { SectionsApiService } from '#services-api/index';
import { LoadingIndicatorService, SectionDataService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationToast, Section, Well, WellBore } from 'models';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss'],
  providers: [MessageService],
})
export class SectionsComponent implements OnInit, OnDestroy {
  //#region 'Variables'
  public wellId: string;
  public selectedWell: Well;
  public selectedWellBore: WellBore;
  public wellBoreData: WellBore[];
  public sectionData: Section[];
  public validatedCasings: string[];

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle''
  constructor(
    private _section: SectionsApiService,
    private _sectionData: SectionDataService,
    private _loader: LoadingIndicatorService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _message: MessageService
  ) {}

  ngOnInit() {
    this.wellId = this.activatedRoute.snapshot.paramMap.get('wellID');
    this.validatedCasings = this._sectionData.getvalidatedCasings();
    this.loadWellbore(this.wellId);
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
    this._loader.hide();
  }
  //#endregion 'Angular Life Cycle''

  //#region 'Load'
  private async loadWellbore(wellId: string) {
    this._loader.show();
    this.SUBS$.add(
      await this._section
        .getWellBoreByID(wellId)
        .finally(() => this._loader.hide())
        .subscribe(
          (data: WellBore[]) => {
            this.wellBoreData = data;
            if (data[0]) {
              this.selectedWellBore = data[0];
              this.loadSections(this.selectedWellBore);
            }
          },
          () => {
            this.sectionData = [];
            this.showNotification({
              key: 'SecError',
              severity: 'error',
              summary: 'We got a problem!',
              detail:
                'There was an error loading well information, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
    );
  }

  private async loadSections(wellBore: WellBore) {
    this._loader.show();
    this.SUBS$.add(
      await this._section
        .getSectionsByWellBoreID(wellBore.WellboreId)
        .finally(() => this._loader.hide())
        .subscribe(
          (data: Section[]) => {
            this.sectionData = data;
            this.sectionData.sort((a, b) => {
              if (a.StartDate === '' && b.StartDate !== '') {
                return 1;
              } else if (a.StartDate !== '' && b.StartDate === '') {
                return -1;
              } else {
                if (new Date(a.StartDate) > new Date(b.StartDate)) {
                  return -1;
                } else if (new Date(a.StartDate) < new Date(b.StartDate)) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          },
          () => {
            this.sectionData = [];
            this.showNotification({
              key: 'SecError',
              severity: 'error',
              summary: 'We got a problem!',
              detail:
                'There was an error loading well information, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
    );
  }
  //#endregion 'Load'

  //#region 'General Methods'
  public wellBoreIDChange() {
    this.loadSections(this.selectedWellBore);
  }

  public casingTally(section: Section) {
    this.router.navigate([
      'section-casings',
      {
        wellId: this.wellId,
        wellboreId: this.selectedWellBore.WellboreId,
        wellboreSectionId: section.WellboreSectionId,
      },
    ]);
  }
  //#endregion 'General Methods'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._message.add({
      key: msg && msg.key && msg.key.length > 0 ? msg.key : 'SecNotify',
      sticky: msg && msg.key && msg.key != 'SecNotify' ? true : false,
      closable: msg && msg.key && msg.key != 'SecNotify' ? true : false,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life,
    });
  }
  //#endregion 'Notification'
}
