<!-- Content -->
<div class="panel" style="height: 100%">
  <!-- Title -->
  <h1>Attachments</h1>
  <!-- Title -->
  <br />
  <div>
    <div class="row">
      <div *ngFor="let image of this.files; let idx = index" class="col-md-3">
        <div class="img-div mt12">
          <img [src]="image.icon" />
        </div>
        <div class="mt12">
          <div>
            <span class="wrap">
              <b>{{ image.Name }}</b>
            </span>
          </div>
          <div class="mt12">
            <button
              id="btn_download"
              type="button"
              class="btn btn-primary bbottom centerContent fl"
              (click)="downloadFile(idx)"
            >
              <i class="pi pi-cloud-download"></i>
              <span class="ml6">Download </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Content -->
