import { environment } from '#environments/environment';
import { OfflineActivityDates } from '#models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OfflineActivityDatesApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/offlineActivityDates${method}`;
  }
  //#endregion 'General'

  //#region 'Get'
  public getByActivityId(oaId: string): Observable<OfflineActivityDates[]> {
    return this.http.get<OfflineActivityDates[]>(this.getURL(`/getByActivityId`), {
      params: { oaId: oaId },
    });
  }
  //#endregion 'Get'

  //#region 'Post'
  public create(offlineActivityDates: OfflineActivityDates): Observable<OfflineActivityDates> {
    return this.http.post<OfflineActivityDates>(this.getURL(`/create`), offlineActivityDates);
  }
  //#endregion 'Post'

  //#region 'Put'
  public update(offlineActivityDates: OfflineActivityDates): Observable<OfflineActivityDates> {
    return this.http.put<OfflineActivityDates>(this.getURL(`/update`), offlineActivityDates);
  }
  //#endregion 'Put'

  //#region 'Delete'
  public delete(offlineActivityDates: OfflineActivityDates): Observable<{}> {
    return this.http.put<OfflineActivityDates>(this.getURL(`/delete`), offlineActivityDates);
  }
  //#endregion 'Delete'
}
