<small *ngIf="_sectionDailyOperation.selectedJob">
  <table class="table table-striped table-hover text-center table-condensed">
    <caption>
      Job :
      {{
        _sectionDailyOperation.selectedJob.JobCategory.Value
      }}
    </caption>
    <thead class="text-primary">
      <tr>
        <td>Primary Job</td>
        <td>Actual Start Date</td>
        <td>Actual End Date</td>
        <td>Job Spud Date</td>
      </tr>
    </thead>
    <tbody>
      <td>{{ _sectionDailyOperation.selectedJob.PrimaryJob.Value }}</td>
      <td>{{ _sectionDailyOperation.selectedJob.ActualStartDate.Value }}</td>
      <td>{{ _sectionDailyOperation.selectedJob.ActualEndDate.Value }}</td>
      <td>{{ _sectionDailyOperation.selectedJob.JobSpudDAte.Value }}</td>
    </tbody>
  </table>
</small>
