import {
  CVXPlanData,
  Field,
  NotificationToast,
  Plan,
  PlanActions,
  PlanActivity,
  PlanActivityEdit,
  PlanFilters,
  PlanHeaderBuild,
} from '#models/index';
import { PlanApiService } from '#services-api/index';
import { DailyOperationService, LoadingIndicatorService, TooltipService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-plan-activities',
  templateUrl: 'plan-activities.component.html',
  styleUrls: ['plan-activities.component.scss'],
  providers: [MessageService],
})
export class PlanActivitiesComponent implements OnInit, OnDestroy {
  //#region 'Variables'
  public editIdxC: number = 0;
  public editIdxP: number = 0;
  public allSelected: boolean = false;
  public alreadyDrilled: boolean = false;
  public isThreeStringDesign: boolean = false;
  public activityToEdit: PlanActivityEdit = new PlanActivityEdit();

  public dataCVX: CVXPlanData[] = [];
  public selectFilterItems: string[] = [];

  private currentSelect: string;
  private actionCodes = new Set<any>();
  private onlyAvailablePhases: any[] = [];
  private currentPlans: Plan[] = [];
  private plansToUpdate: Plan[] = [];
  private selectedCVX: CVXPlanData[] = [];
  private selectedActions: PlanActions[] = [];
  private dataCVXOriginal: CVXPlanData[] = [];
  private existingPlans: CVXPlanData[] = [];
  private notExistingPlans: CVXPlanData[] = [];

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private _AMRA: PlanApiService,
    private _message: MessageService,
    private _toolTip: TooltipService,
    private activatedRoute: ActivatedRoute,
    private _loader: LoadingIndicatorService,
    public _sectionDailyOperation: DailyOperationService,
    public _tooltip: TooltipService
  ) {
    this._sectionDailyOperation.wellId = this.activatedRoute.snapshot.paramMap.get('wellID');
    this._sectionDailyOperation.jobID = this.activatedRoute.snapshot.paramMap.get('jobID');
    this._sectionDailyOperation.idRecWellBore = this.activatedRoute.snapshot.paramMap.get('wellboreID');
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
    this._loader.hide();
  }

  ngOnInit(): void {
    this.loadAMRAInfo();
    this.loadACurrentPlans();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  private loadAMRAInfo() {
    this._loader.show();
    const CVX$ = this._AMRA
      .getCVXPlannedDuration()
      .pipe(
        map<CVXPlanData[], CVXPlanData[]>((dt) => {
          dt.map((plan) => {
            plan.HaveActionsSelected = false;
            plan.Actions.map((act) => {
              act.Selected = act.StepDependency === 'Required' ? true : false;
              act.StepTitle = act.StepTitle.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
              act.SequenceNo = this._toolTip.zeroPad(Number(act.SequenceNo), 4);

              if (!plan.HaveActionsSelected && act.Selected) {
                plan.HaveActionsSelected = true;
              }
              return act;
            });
            plan.HeaderName.SequenceNo = this._toolTip.zeroPad(Number(plan.HeaderName.SequenceNo), 4);
          });
          return dt;
        })
      )
      .subscribe(
        (dto) => {
          dto.sort((a, b) =>
            a.HeaderName.SequenceNo > b.HeaderName.SequenceNo
              ? 1
              : b.HeaderName.SequenceNo > a.HeaderName.SequenceNo
              ? -1
              : 0
          );

          this.loadPhasesWithSequence(this._tooltip.deepClone(dto));
          this.dataCVX = this._tooltip.deepClone(dto);
          this.dataCVXOriginal = this._tooltip.deepClone(dto);
          this._loader.hide();
        },
        () => {
          this._loader.hide();
          this.showNotification({
            key: 'planActivitiesError',
            severity: 'error',
            summary: 'We got a problem!',
            detail:
              'There was a problem while loading Plans & Plan Activities, please refresh the page or notify the administrator.',
            life: 6000,
          });
        }
      );

    this.selectFilterItems.push('All', 'Required', 'None');
    this.SUBS$.add(CVX$);
  }

  private loadPhasesWithSequence(dto: CVXPlanData[]) {
    let counter = 1;
    let phases = new Set<any>();
    let actionsToOrder: PlanActions[] = [];
    this.onlyAvailablePhases = [];

    dto.forEach((header) => {
      actionsToOrder.push(...header.Actions);
    });

    actionsToOrder.sort((a, b) => (a.SequenceNo > b.SequenceNo ? 1 : b.SequenceNo > a.SequenceNo ? -1 : 0));
    actionsToOrder.forEach((act) => {
      phases.add(JSON.stringify({ WellPhase: act.WellPhase, Phase1: act.Phase1, Phase2: act.Phase2 }));
    });

    phases.forEach((el) => {
      let phase = JSON.parse(el);
      phase.SysSeq = counter;
      this.onlyAvailablePhases.push(phase);
      counter++;
    });
  }

  private loadACurrentPlans() {
    this._loader.show();
    const PH$ = this._AMRA.getAllPlansByJobId(this._sectionDailyOperation.jobID).subscribe(
      (dto) => {
        this.currentPlans = [...dto];
        this._loader.hide();
      },
      () => {
        this._loader.hide();
        this.showNotification({
          key: 'planActivitiesError',
          severity: 'error',
          summary: 'We got a problem!',
          detail:
            'There was a problem while inserting Plans & Plan Activities, please refresh the page or notify the administrator.',
          life: 6000,
        });
      }
    );

    this.SUBS$.add(PH$);
  }
  //#endregion 'Load'

  //#region 'Filters'
  public filterSurface() {
    this.filterBy(PlanFilters.ALREADY_DRILLED, this.alreadyDrilled);
  }

  public filterDesign() {
    this.filterBy(PlanFilters.THREE_STRING_DESIGN, this.isThreeStringDesign);
  }

  public getActionsByPhase(plan: CVXPlanData): PlanActions[] {
    let actions = this.dataCVXOriginal.filter((dt) => dt.HeaderName.StepTitle === plan.HeaderName.StepTitle)[0].Actions;
    actions = this.validateFilters(actions);
    return actions;
  }

  public filterBy(plan: PlanFilters, condition: boolean) {
    this.dataCVX.forEach((dt) => {
      const el = dt.HeaderName.StepTitle.toUpperCase();
      if (plan === PlanFilters.ALREADY_DRILLED) {
        if (el.includes('SURFACE HOLE') || el.includes('SURFACE')) {
          dt.Actions = condition ? [] : this.getActionsByPhase(dt);
        }
      } else if (plan === PlanFilters.THREE_STRING_DESIGN) {
        if (el.includes('INTERMEDIATE #2 HOLE') || el.includes('INTERMEDIATE #2')) {
          dt.Actions = condition ? [] : this.getActionsByPhase(dt);
        }
      }
    });
  }

  public selectItems(event: Event) {
    this.currentSelect = event.target['value'];
    this.dataCVX.forEach((dt) => {
      this.validateFilters(dt.Actions);
    });
  }

  public validateFilters(actions: PlanActions[]): PlanActions[] {
    let _actions: PlanActions[] = [];

    if (this.currentSelect === 'All') {
      _actions = this.selectAll(true, actions);
    } else if (this.currentSelect === 'Required') {
      _actions = this.selectRequiredOnly(actions);
    } else {
      _actions = this.selectAll(false, actions);
    }

    return _actions;
  }

  public selectAll(val: boolean, actions: PlanActions[]): PlanActions[] {
    actions.map((el) => {
      el.Selected = val;
      return;
    });
    return actions;
  }

  public selectRequiredOnly(actions: PlanActions[]): PlanActions[] {
    actions.map((el) => {
      if (el.StepDependency === 'Required') {
        el.Selected = true;
      } else {
        el.Selected = false;
      }
      return;
    });
    return actions;
  }

  public selectItem(idxP: number, idxC: number, isSelected: boolean) {
    this.dataCVX[idxP].Actions[idxC].Selected = isSelected;
  }
  //#endregion 'Filters'

  //#region 'General Methods'
  public getDataReadyToInsert() {
    this.selectedCVX = [];

    this.dataCVX.forEach((cvx) => {
      if (cvx.HaveActionsSelected) {
        const actionsSelected = cvx.Actions.filter((obj) => obj.Selected);
        this.selectedActions.push(...actionsSelected);
      }
    });

    this.createCVXObject();
  }

  public createCVXObject() {
    this.actionCodes.clear();
    this.setActionCodes();

    this.actionCodes.forEach((ac: any) => {
      this.createCVXPlanDataToInsert(ac);
    });
  }

  public setActionCodes() {
    this.selectedActions.forEach((act) => {
      if (
        act.WellPhase &&
        act.WellPhase.length > 0 &&
        act.Phase1 &&
        act.Phase1.length > 0 &&
        act.Phase2 &&
        act.Phase2.length > 0 &&
        act.Selected
      ) {
        this.actionCodes.add(
          JSON.stringify({
            WellPhase: act.WellPhase,
            Phase1: act.Phase1,
            Phase2: act.Phase2,
          })
        );
      }
    });
  }

  public createCVXPlanDataToInsert(actionCode: any) {
    const dataAC: PlanHeaderBuild = JSON.parse(actionCode);
    const headerExist: Plan[] = this.validatePlanExist(dataAC);
    let header: PlanActions = new PlanActions();
    let IdRec: Field = new Field();

    if (headerExist.length > 0) {
      IdRec = headerExist[0].IdRec;
      header.Exist = true;
      header.IdRec = IdRec.Value;
    }

    header.WellPhase = dataAC.WellPhase;
    header.Phase1 = dataAC.Phase1;
    header.Phase2 = dataAC.Phase2;

    header.WellId = this._sectionDailyOperation.wellId;
    header.JobId = this._sectionDailyOperation.jobID;
    header.Wellbore = this._sectionDailyOperation.idRecWellBore;

    let cvx: CVXPlanData = new CVXPlanData();
    cvx.HeaderName = header;

    const actions = this.selectedActions.filter(
      (obj) => obj.WellPhase === dataAC.WellPhase && obj.Phase1 === dataAC.Phase1 && obj.Phase2 === dataAC.Phase2
    );

    actions.map((act) => {
      act.WellId = this._sectionDailyOperation.wellId;
      act.Wellbore = this._sectionDailyOperation.idRecWellBore;

      if (header.Exist) {
        act.JobId = IdRec.Value;
      }
      return;
    });

    cvx.Actions = [...actions];
    this.selectedCVX.push(cvx);
  }
  //#endregion 'General Methods'

  //#region 'Toast'
  public hideToast() {
    this._message.clear('c');
  }

  public onConfirm() {
    if (this.validateIfSelected()) {
      this.hideToast();
      this.saveData();
    } else {
      this.hideToast();
      this.showNotification({
        key: 'planActivitiesError',
        severity: 'warn',
        summary: 'Missiing Plan',
        detail: 'Plase select at least one Plan Activity',
        life: 6000,
      });
    }
  }

  public onReject() {
    this.hideToast();
  }

  public showMessage() {
    this._message.add({
      key: 'c',
      sticky: true,
      severity: 'info',
      summary: 'Save into WellView',
      detail: 'You are about to save the info to WellView, would you like to continue?.',
    });
  }
  //#endregion 'Toast'

  //#region 'Validations'
  private validateIfSelected(): boolean {
    let count = 0;
    this.dataCVX.forEach((cvx) => {
      let contAct = 0;
      cvx.Actions.forEach((ac) => {
        count = ac.Selected ? count + 1 : count;
        contAct = ac.Selected ? contAct + 1 : contAct;
      });

      if (contAct > 0) {
        cvx.HaveActionsSelected = true;
      } else {
        cvx.HaveActionsSelected = false;
      }
    });
    return count > 0 ? true : false;
  }

  private validatePlanExist(header: PlanHeaderBuild): Plan[] {
    return this.currentPlans.filter(
      (obj) =>
        obj.WellPhase.Value === header.WellPhase &&
        obj.Phase1.Value === header.Phase1 &&
        obj.Phase2.Value === header.Phase2
    );
  }

  private validateCorrectSeq() {
    this.plansToUpdate = [];
    this.currentPlans.forEach((cp) => {
      const OAP = this.onlyAvailablePhases.filter(
        (obj) =>
          obj.WellPhase === cp.WellPhase.Value && obj.Phase1 === cp.Phase1.Value && obj.Phase2 === cp.Phase2.Value
      );

      if (OAP && OAP.length > 0) {
        let updt = this._tooltip.deepClone(cp);
        updt.SysSeq.Value = OAP[0].SysSeq.toString();
        this.plansToUpdate.push(updt);
      }
    });

    this.plansToUpdate.sort((a, b) =>
      Number(a.SysSeq.Value) > Number(b.SysSeq.Value) ? 1 : Number(b.SysSeq.Value) > Number(a.SysSeq.Value) ? -1 : 0
    );

    let counter = 1;
    this.plansToUpdate.map((plan) => {
      plan.SysSeq.Value = counter.toString();
      counter++;
      return plan;
    });

    if (this.plansToUpdate && this.plansToUpdate.length > 0) {
      this.updatePlansSequence();
    }
  }
  //#endregion 'Validations'

  //#region 'CRUD'
  private async saveData() {
    this.existingPlans = [];
    this.selectedActions = [];
    this.notExistingPlans = [];
    this.getDataReadyToInsert();

    this.notExistingPlans = this.selectedCVX.filter((cvx) => !cvx.HeaderName.Exist);
    this.existingPlans = this.selectedCVX.filter((cvx) => cvx.HeaderName.Exist);

    //? Insert the PlanActivities that Exist
    if (this.existingPlans.length > 0) {
      await this.insertPlanActivities(true).then(
        () => {
          this.showNotification({
            severity: 'success',
            summary: 'Correct!',
            detail: 'Plan Activities inserted correctly',
          });
        },
        (reject) => {
          console.error(reject);
          this.showNotification({
            key: 'planActivitiesError',
            severity: 'error',
            summary: 'We got a problem!',
            detail:
              'There was a problem while inserting Plan Activities, please refresh the page or notify the administrator.',
            life: 6000,
          });
        }
      );
    }

    //? Insert the Plans that doesnt exist
    if (this.notExistingPlans.length > 0) {
      await this.insertPlans().then(
        async (resolve: Plan[]) => {
          resolve.forEach((cvx) => {
            let HEAD: CVXPlanData[] = this.notExistingPlans.filter(
              (obj) =>
                obj.HeaderName.WellPhase === cvx.WellPhase.Value &&
                obj.HeaderName.Phase1 === cvx.Phase1.Value &&
                obj.HeaderName.Phase2 === cvx.Phase2.Value
            );

            HEAD[0].HeaderName.IdRec = cvx.IdRec.Value;
            HEAD[0].Actions.map((cvxAct) => {
              cvxAct.JobId = cvx.IdRec.Value;
              return;
            });
          });

          await this.insertPlanActivities(false).then(
            () => {
              this.validateCorrectSeq();
              this.showNotification({
                severity: 'success',
                summary: 'Correct!',
                detail: 'Plans & Plan Activities Inserted Correctly',
              });
            },
            (error) => {
              console.error(error);
              this.showNotification({
                key: 'planActivitiesError',
                severity: 'error',
                summary: 'We got a problem!',
                detail:
                  'There was a problem while inserting Plans & Plan Activities, please refresh the page or notify the administrator.',
                life: 6000,
              });
            }
          );
        },
        (reject) => {
          console.error(reject);
          this.showNotification({
            key: 'planActivitiesError',
            severity: 'error',
            summary: 'We got a problem!',
            detail:
              'There was a problem while inserting Plans & Plan Activities, please refresh the page or notify the administrator.',
            life: 6000,
          });
        }
      );
    }
  }

  private insertPlans(): Promise<Plan[]> {
    let plansToInsert: PlanActions[] = [];
    return new Promise((resolve, reject) => {
      try {
        this._loader.show();
        this.notExistingPlans.forEach((head) => {
          plansToInsert.push(head.HeaderName);
        });

        const CP$ = this._AMRA.createBulkPlan(plansToInsert).subscribe(
          (resp) => {
            this.currentPlans = this.currentPlans.concat(resp);
            this._loader.hide();
            resolve(resp);
          },
          (error) => {
            this._loader.hide();
            reject(error);
          }
        );
        this.SUBS$.add(CP$);
      } catch (err) {
        this._loader.hide();
        this.showNotification({
          key: 'planActivitiesError',
          severity: 'error',
          summary: 'We got a problem!',
          detail: 'There was a problem while inserting Plans, please refresh the page or notify the administrator.',
          life: 6000,
        });
        reject(Error(err));
      }
    });
  }

  private insertPlanActivities(fromExist: boolean): Promise<PlanActivity[]> {
    return new Promise((resolve, reject) => {
      try {
        let actionsToInsert: PlanActions[] = [];

        if (fromExist) {
          this.existingPlans.forEach((act) => {
            actionsToInsert.push(...act.Actions);
          });
        } else {
          this.notExistingPlans.forEach((act) => {
            actionsToInsert.push(...act.Actions);
          });
        }
        this._loader.show();

        const CP$ = this._AMRA.createBulkPlanActivity(actionsToInsert).subscribe(
          (resp) => {
            this._loader.hide();
            resolve(resp);
          },
          (error) => {
            this._loader.hide();
            reject(error);
          }
        );
        this.SUBS$.add(CP$);
      } catch (err) {
        this._loader.hide();
        this.showNotification({
          key: 'planActivitiesError',
          severity: 'error',
          summary: 'We got a problem!',
          detail:
            'There was a problem while inserting Plan Activities, please refresh the page or notify the administrator.',
          life: 6000,
        });
        reject(Error(err));
      }
    });
  }

  private updatePlansSequence() {
    return new Promise((resolve, reject) => {
      try {
        this._loader.show();
        const CP$ = this._AMRA.updateBulkPlan(this.plansToUpdate).subscribe(
          (resp) => {
            this._loader.hide();
            resolve(resp);
          },
          (error) => {
            this._loader.hide();
            reject(error);
          }
        );
        this.SUBS$.add(CP$);
        resolve(true);
      } catch (err) {
        reject(Error(err));
      }
    });
  }
  //#endregion 'CRUD'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._message.add({
      key: msg && msg.key && msg.key.length > 0 ? msg.key : 'planActivitiesNotify',
      sticky: msg && msg.key && msg.key != 'planActivitiesNotify' ? true : false,
      closable: msg && msg.key && msg.key != 'planActivitiesNotify' ? true : false,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life,
    });
  }
  //#endregion 'Notification'
}
