import { ATRProcedure } from '#models/index';
import { ATRProcedureApiService } from '#services-api/index';
import { TooltipService } from '#services-shared/tooltip.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

@Injectable({
  providedIn: 'root',
})
export class SmartProceduresService implements OnDestroy {
  //#region 'Variables'
  public stepsTotalDuration: number = 0;
  public smartProcedureDATA: ATRProcedure[] = [];
  public smartProcedureDATAOriginal = [];

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(private _ATRProcedure: ATRProcedureApiService, public _tooltip: TooltipService) {}

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  public loadATRData(startDate: string, endDate: string) {
    const ATR$ = this._ATRProcedure.getATRByStartEndDate(startDate, endDate).subscribe((data: any) => {
      data = this.setStatusNumber(data);

      data.map((el) => {
        el.Sessions.map((session) => {
          session.Steps.sort((a, b) => {
            if (new Date(a.EventDate) > new Date(b.EventDate)) {
              return -1;
            } else if (new Date(a.EventDate) < new Date(b.EventDate)) {
              return 1;
            } else {
              return 0;
            }
          });
          return session;
        });
        return el;
      });

      this.smartProcedureDATA = data;
      this.smartProcedureDATAOriginal = this._tooltip.deepClone(this.smartProcedureDATA);
    });

    this.SUBS$.add(ATR$);
  }
  //#endregion 'Load'

  //#region 'Methods'

  public filterByName(filterText: string) {
    this.smartProcedureDATA = [];
    if (filterText && filterText.length > 0) {
      const DETAIL = this.smartProcedureDATAOriginal.filter((obj) =>
        obj.Name.toUpperCase().includes(filterText.toUpperCase())
      );
      this.smartProcedureDATA = this._tooltip.deepClone(DETAIL);
    } else {
      this.smartProcedureDATA = this._tooltip.deepClone(this.smartProcedureDATAOriginal);
    }
  }

  public openSession(procedureIndex: number, sessionIndex: number) {
    // First reset the data
    const current: any = this.smartProcedureDATA[procedureIndex].Sessions.findIndex((obj) => obj.showDetail === true);
    if (current !== -1 && current !== sessionIndex) {
      this.smartProcedureDATA[procedureIndex].Sessions[current].showDetail = false;
    }

    this.smartProcedureDATA[procedureIndex].Sessions[sessionIndex].showDetail = !this.smartProcedureDATA[procedureIndex]
      .Sessions[sessionIndex].showDetail;
  }

  public showSPDetail(index: number) {
    // First reset the data
    const current: any = this.smartProcedureDATA.findIndex((obj) => obj.showDetail === true);

    if (current !== -1 && current !== index) {
      this.smartProcedureDATA[current].showDetail = false;
    }

    // Set the new one
    this.smartProcedureDATA[index].showDetail = !this.smartProcedureDATA[index].showDetail;
  }

  public setStatusNumber(data: ATRProcedure[]): ATRProcedure[] {
    data.map((proc) => {
      proc.Sessions.map((element) => {
        if (element.Status === 'Completed') {
          element.StatusByNumber = 1;
        } else if (element.Status === 'Abandoned') {
          element.StatusByNumber = 2;
        } else if (element.Status === 'Paused') {
          element.StatusByNumber = 3;
        } else if (element.Status === 'In Progress') {
          element.StatusByNumber = 4;
        } else {
          element.StatusByNumber = 5;
        }

        return element;
      });
      return proc;
    });

    return data;
  }
  //#endregion 'Methods'
}
