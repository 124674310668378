import { Field } from '#models/shared/field';

export class OfflineActivityTimelog {
  IdRec: Field = new Field();
  WellId: Field = new Field();
  OADateId: Field = new Field();

  Duration: Field = new Field();
  Activity: Field = new Field();
  InactiveTime: Field = new Field();
  StartDepth: Field = new Field();
  EndDepth: Field = new Field();
  Comment: Field = new Field();
  SysSeq: Field = new Field();

  Wellbore?: Field = new Field();
  WellboreTable?: Field = new Field();
}
