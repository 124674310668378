import { Field } from '../shared/field';

export class CasingComponent {
  WellId: Field = new Field();
  CasingStringId: Field = new Field();
  CasingComponentId: Field = new Field();

  Description: Field = new Field();
  Icon: Field = new Field();
  OD: Field = new Field();
  ID: Field = new Field();
  Weight: Field = new Field();
  Grade: Field = new Field();
  Length: Field = new Field();
  CumLength: Field = new Field();
  TopDepth: Field = new Field();
  BottomDepth: Field = new Field();
  Joints: Field = new Field();
  EquipmentType: Field = new Field();
  SysSeq: Field = new Field();

  Run: Boolean;
  IsValidated: Boolean;
  IsApproved: Boolean;
  JointNumber: string;
  IsCentralized: Boolean;
  CentralizedNotes: string;
  IsBadJoint: Boolean;

  TopThread: Field = new Field();
  BottomThread: Field = new Field();

  CollapsePressure: Field = new Field();
  BurstPressure: Field = new Field();

  IsThreadLoss: Boolean;
  ThreadLoss: string;
  ThreadOff: string;

  IsReferenceDepth: Boolean;
  IsExclude: Boolean;

  JointTop: number;
  JointBottom: number;

  CEDisplacement: number;
  OEDisplacement: number;
  CumWeight: number;
  MudWeight: number;

  isEdit?: boolean;
  isReorder?: boolean;
  RefID: Field = new Field();
}
