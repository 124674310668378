import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
})
export class AttachmentsComponent implements OnInit {
  //#region 'Input, Output, ViewChild'
  @Output() importFileSuccess = new EventEmitter<any>();
  //#endregion 'Input, Output, ViewChild'

  //#region 'Variables'
  public notify: Message[] = [];
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor() {}

  ngOnInit() {}
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public onUploadHandler(event: any) {
    this.notify = [];
    this.importFileSuccess.emit(event);
    event.clear();
  }
  //#endregion 'General Methods'
}
