import { BusinessUnit } from '#models/index';
import { BusinessUnitsApiService } from '#services-api/index';
import { LoadingIndicatorService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-business-units',
  templateUrl: './business-units.component.html',
})
export class BusinessUnitsComponent implements OnInit, OnDestroy {
  public businessUnits: BusinessUnit[];

  //? Subscriptions
  private SUBS$ = new Subscription();

  public newBuForm = new FormGroup({
    name: new FormControl('', Validators.required),
  });

  constructor(private businessUnitsSvc: BusinessUnitsApiService, public loadingIndicatorSvc: LoadingIndicatorService) {}

  ngOnInit() {
    this.loadBusinessUnits();
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }

  public addBusinessUnit(): void {
    const businessUnit: BusinessUnit = {
      Name: this.newBuForm.get('name').value,
      Id: 0,
    };

    this.SUBS$.add(
      this.businessUnitsSvc.addBusinessUnit(businessUnit).subscribe((data: BusinessUnit) => {
        this.businessUnits.push(data);
        this.newBuForm.get('name').setValue('');
      })
    );
  }

  private loadBusinessUnits(): void {
    this.loadingIndicatorSvc.show();

    this.SUBS$.add(
      this.businessUnitsSvc
        .getUserBUs()
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data: BusinessUnit[]) => (this.businessUnits = data))
    );
  }
}
