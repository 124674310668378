export class OrganizationUser {
  Id: number;
  OrganizationId: number;

  UserId: number;
  FirstName: string;
  LastName: string;
  Email: string;
  CAI: string;

  IsAdmin: boolean;
  IsUser: boolean;
  IsExternal: boolean;
}
