import { TaskPlan, TaskPlanItem, TaskPlanSection, TaskPlanSubSection } from '#models/index';
import { TaskPlanApiService } from '#services-api/index';
import { LoadingIndicatorService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-task-plan-manage',
  templateUrl: './task-plan-manage.component.html',
  styleUrls: ['./task-plan-manage.component.scss'],
})
export class TaskPlanManageComponent implements OnInit, OnDestroy {
  public taskPlan: TaskPlan = new TaskPlan();
  public taskPlanId: number;
  public hasSections: boolean;
  // -----------------------
  public displayWarningSec: boolean;
  public taskPlanSection: TaskPlanSection = new TaskPlanSection();
  public selectedSectionId: number;
  public firstSectionId: number;
  public lastSectionId: number;
  // -----------------------
  public displayWarningSubSec: boolean;
  public subSectionList: TaskPlanSubSection[];
  public subSection: TaskPlanSubSection = new TaskPlanSubSection();
  public firstSubSectionId: number;
  public lastSubSectionId: number;
  // -----------------------
  public itemToDelete: TaskPlanItem = new TaskPlanItem();
  // -----------------------

  //? Subscriptions
  private SUBS$ = new Subscription();

  constructor(
    private taskPlanSvc: TaskPlanApiService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute,
    private confirmationService: ConfirmationService
  ) {}

  // ---------------INIT FUNCTION---------------//
  ngOnInit() {
    this.SUBS$.add(
      this.aRoute.params.subscribe((el) => {
        this.taskPlanId = el['id'];
        this.selectedSectionId = this.taskPlanSvc.isInvalidString(el['sectionId']) ? null : el['sectionId'];
      })
    );
    this.getTaskPlanById(this.taskPlanId);
    this.displayWarningSec = false;
    this.displayWarningSubSec = false;
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }

  public onRedirectButtonClick(): void {
    this._router.navigate(['admin/task-plan']);
  }

  // ---------------TASK PLAN SECTION---------------//
  public getTaskPlanById(id: number, selectLastSection: boolean = false): void {
    this.loadingIndicatorSvc.show();
    this.SUBS$.add(
      this.taskPlanSvc
        .getTaskPlanById(id)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data: any) => {
          this.taskPlan = data;
          this.completeTaskPlanSectionsList(selectLastSection);
        })
    );
  }

  public createTaskPlanSection(): void {
    this.displayWarningSec = this.taskPlanSvc.isInvalidString(this.taskPlanSection.Description);
    if (!this.displayWarningSec) {
      this.taskPlanSection.TaskPlanId = this.taskPlanId;
      this.loadingIndicatorSvc.show();
      this.SUBS$.add(
        this.taskPlanSvc
          .createTaskPlanSection(this.taskPlanSection)
          .finally(() => this.loadingIndicatorSvc.hide())
          .subscribe(() => {
            this.getTaskPlanById(this.taskPlanId, true);
          })
      );
    }
  }

  public moveUpTaskPlanSection(tpsId: number): void {
    this.loadingIndicatorSvc.show();
    this.SUBS$.add(
      this.taskPlanSvc
        .moveUpTaskPlanSection(tpsId)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapSection(tpsId, -1); // MOVE UP
            this.completeTaskPlanSectionsList();
          } else {
            // console.log(data.Message);
          }
        })
    );
  }

  public moveDownTaskPlanSection(tpsId: number): void {
    this.loadingIndicatorSvc.show();
    this.SUBS$.add(
      this.taskPlanSvc
        .moveDownTaskPlanSection(tpsId)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapSection(tpsId, 1); // MOVE DOWN
            this.completeTaskPlanSectionsList();
          } else {
            // console.log(data.Message);
          }
        })
    );
  }

  // MoveUp (possition = -1) && MoveDown (set possition = 1)
  public swapSection(sectionId: number, possition: number): void {
    const indexItem = this.taskPlan.TaskPlanSections.findIndex((x) => x.ID === sectionId);
    const itemUpSortId = this.taskPlan.TaskPlanSections[indexItem].SortID;
    const itemDownSortId = this.taskPlan.TaskPlanSections[indexItem + possition].SortID;
    this.taskPlan.TaskPlanSections[indexItem].SortID = itemDownSortId;
    this.taskPlan.TaskPlanSections[indexItem + possition].SortID = itemUpSortId;
  }

  public completeTaskPlanSectionsList(selectLastSection: boolean = false): void {
    const list = this.taskPlan.TaskPlanSections;
    this.hasSections = false;
    if (list.length > 0) {
      this.hasSections = true;
      this.taskPlan.TaskPlanSections = list.sort(function (obj1: TaskPlanSection, obj2: TaskPlanSection) {
        return obj1.SortID - obj2.SortID;
      });
      this.firstSectionId = this.taskPlan.TaskPlanSections[0].ID;
      this.lastSectionId = this.taskPlan.TaskPlanSections[this.taskPlan.TaskPlanSections.length - 1].ID;
      if (this.selectedSectionId === 0 || this.selectedSectionId == null || this.selectedSectionId === undefined) {
        this.selectedSectionId = this.taskPlan.TaskPlanSections[0].ID;
      }
      this.selectTaskPlanSections(selectLastSection ? this.lastSectionId : this.selectedSectionId);
    }
  }

  public selectTaskPlanSections(sectionId: number): void {
    this.loadingIndicatorSvc.show();
    this.selectedSectionId = sectionId;
    this.SUBS$.add(
      this.taskPlanSvc
        .getTaskPlanSubSections(sectionId)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data: TaskPlanSubSection[]) => {
          this.subSectionList = data;
          this.completeTaskPlanSubSectionsList();
        })
    );
  }

  public updateTaskPlanSection(sectionId: number, desc: string): void {
    this._router.navigate(['admin/task-plan-section-edit', { taskPlanId: this.taskPlanId, id: sectionId, desc: desc }]);
  }

  public deleteTaskPlanSection(sectionId: number, desc: string): void {
    this._router.navigate([
      'admin/task-plan-section-delete',
      {
        taskPlanId: this.taskPlanId,
        taskPlanDesc: this.taskPlan.Description,
        id: sectionId,
        desc: desc,
      },
    ]);
  }

  public resetTaskPlans(): void {
    this.loadingIndicatorSvc.show();
    this.SUBS$.add(
      this.taskPlanSvc
        .resetTaskPlan(this.taskPlanId)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe(() => {
          // TO-DO -- add modal
        })
    );
  }

  // ---------------TASK PLAN SUB SECTION---------------//
  public createSubSection(): void {
    this.displayWarningSubSec = this.taskPlanSvc.isInvalidString(this.subSection.Description);
    if (!this.displayWarningSubSec) {
      this.subSection.TaskPlanSectionId = this.selectedSectionId;
      this.loadingIndicatorSvc.show();
      this.SUBS$.add(
        this.taskPlanSvc
          .createTaskPlanSubSection(this.subSection)
          .finally(() => this.loadingIndicatorSvc.hide())
          .subscribe(() => {
            this.getTaskPlanById(this.taskPlanId);
            this.subSection.Description = '';
          })
      );
    }
  }

  public moveUpTaskPlanSubSection(tpsId: number): void {
    this.loadingIndicatorSvc.show();
    this.SUBS$.add(
      this.taskPlanSvc
        .moveUpTaskPlanSubSection(tpsId)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapSubSection(tpsId, -1); // MOVE UP
            this.completeTaskPlanSubSectionsList();
          } else {
            // console.log(data.Message);
          }
        })
    );
  }

  public moveDownTaskPlanSubSection(tpsId: number): void {
    this.loadingIndicatorSvc.show();
    this.SUBS$.add(
      this.taskPlanSvc
        .moveDownTaskPlanSubSection(tpsId)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapSubSection(tpsId, 1); // MOVE DOWN
            this.completeTaskPlanSubSectionsList();
          } else {
            // console.log(data.Message);
          }
        })
    );
  }

  // MoveUp (possition = -1) && MoveDown (set possition = 1)
  public swapSubSection(subSectionId: number, possition: number): void {
    const indexItem = this.subSectionList.findIndex((x) => x.ID === subSectionId);
    const itemUpSortId = this.subSectionList[indexItem].SortID;
    const itemDownSortId = this.subSectionList[indexItem + possition].SortID;
    this.subSectionList[indexItem].SortID = itemDownSortId;
    this.subSectionList[indexItem + possition].SortID = itemUpSortId;
  }

  public completeTaskPlanSubSectionsList(): void {
    const list = this.subSectionList;
    if (list.length > 0) {
      this.subSectionList = list.sort(function (obj1: TaskPlanSubSection, obj2: TaskPlanSubSection) {
        return obj1.SortID - obj2.SortID;
      });
      this.firstSubSectionId = this.subSectionList[0].ID;
      this.lastSubSectionId = this.subSectionList[this.subSectionList.length - 1].ID;
      for (const ss of this.subSectionList) {
        this.completeTaskPlanItemList(ss.ID);
      }
    }
  }

  public updateTaskPlanSubSection(id: number): void {
    this._router.navigate([
      'admin/task-plan-sub-section-edit',
      { taskPlanId: this.taskPlanId, id: id, sectionId: this.selectedSectionId },
    ]);
  }

  public deleteTaskPlanSubSection(id: number): void {
    const section = this.taskPlan.TaskPlanSections.find((x) => x.ID === this.selectedSectionId);
    this._router.navigate([
      'admin/task-plan-sub-section-delete',
      {
        taskPlanId: this.taskPlanId,
        id: id,
        sectionId: this.selectedSectionId,
        section: section.Description,
      },
    ]);
  }

  // ---------------TASK PLAN ITEM---------------//
  public moveUpTaskPlanItem(id: number, subId: number): void {
    this.loadingIndicatorSvc.show();
    this.SUBS$.add(
      this.taskPlanSvc
        .moveUpTaskPlanItem(id)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapItem(id, subId, -1); // MOVE UP
            this.completeTaskPlanItemList(subId);
          } else {
            // console.log(data.Message);
          }
        })
    );
  }

  public moveDownTaskPlanItem(id: number, subId: number): void {
    this.loadingIndicatorSvc.show();
    this.SUBS$.add(
      this.taskPlanSvc
        .moveDownTaskPlanItem(id)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapItem(id, subId, 1); // MOVE DOWN
            this.completeTaskPlanItemList(subId);
          } else {
            // console.log(data.Message);
          }
        })
    );
  }

  // MoveUp (possition = -1) && MoveDown (set possition = 1)
  public swapItem(itemId: number, subId: number, possition: number): void {
    const indexSub = this.subSectionList.findIndex((x) => x.ID === subId);
    const indexItem = this.subSectionList[indexSub].TaskPlanItems.findIndex((x) => x.ID === itemId);
    const itemUpSortId = this.subSectionList[indexSub].TaskPlanItems[indexItem].SortID;
    const itemDownSortId = this.subSectionList[indexSub].TaskPlanItems[indexItem + possition].SortID;
    this.subSectionList[indexSub].TaskPlanItems[indexItem].SortID = itemDownSortId;
    this.subSectionList[indexSub].TaskPlanItems[indexItem + possition].SortID = itemUpSortId;
  }

  public completeTaskPlanItemList(subId: number): void {
    let list = this.subSectionList.find((x) => x.ID === subId).TaskPlanItems;
    if (list.length > 0) {
      list = list.sort(function (obj1: TaskPlanItem, obj2: TaskPlanItem) {
        return obj1.SortID - obj2.SortID;
      });
      this.subSectionList.find((x) => x.ID === subId).TaskPlanItems = list;
      this.subSectionList.find((x) => x.ID === subId).firstItemId = list[0].ID;
      this.subSectionList.find((x) => x.ID === subId).lastItemId = list[list.length - 1].ID;
    }
  }

  public createItem(subSectionId: number): void {
    this._router.navigate([
      'admin/task-plan-item-create',
      {
        taskPlanId: this.taskPlanId,
        subSectionId: subSectionId,
        sectionId: this.selectedSectionId,
      },
    ]);
  }

  public updateItem(id: number): void {
    this._router.navigate([
      'admin/task-plan-item-edit',
      {
        taskPlanId: this.taskPlanId,
        itemId: id,
        sectionId: this.selectedSectionId,
      },
    ]);
  }

  public deleteItem(id: number, subId: number) {
    this.itemToDelete = this.subSectionList.find((x) => x.ID === subId).TaskPlanItems.find((x) => x.ID === id);
    const message = `Record will be permanently removed, continue?<br/>
                    - Item Code:  <b>${this.itemToDelete.Code}</b><br/>
                    - Item Description: <b>${this.itemToDelete.Description}</b><br/>`;
    this.confirmationService.confirm({
      message: message,
      header: 'Confirm delete',
      accept: () => {
        this.loadingIndicatorSvc.show();
        this.SUBS$.add(
          this.taskPlanSvc
            .deleteTaskPlanItem(id)
            .finally(() => this.loadingIndicatorSvc.hide())
            .subscribe(() => {
              const index = this.subSectionList.find((x) => x.ID === subId).TaskPlanItems.findIndex((x) => x.ID === id);
              this.subSectionList.find((x) => x.ID === subId).TaskPlanItems.splice(index, 1);
              this.completeTaskPlanItemList(subId);
            })
        );
      },
      reject: () => {},
    });
  }
}
