import { SectionCasingService } from '#services-shared/index';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
})
export class SectionHeaderComponent implements OnInit {
  //#region 'Angular Life Cycle'
  constructor(public _sectionCasing: SectionCasingService) {}

  ngOnInit() {}
  //#endregion 'Angular Life Cycle'
}
