import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Feedback } from 'models';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root',
})
export class FeedbackApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'Create'
  public postFeedback(feedback: Feedback): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/feedback`, feedback);
  }
  //#endregion 'Create'
}
