import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class LoggerService {
  //#region 'Angular Life Cycle'
  constructor() {}
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public error(message: string) {
    console.error(`Error: :${message}`);
  }

  public httpError(error: HttpErrorResponse, additionalMessage: string = '') {
    let message = `Http call to ${error.url} failed with following code: ${error.status}:\n${error.message}`;

    if (additionalMessage) {
      message = `${message}. Additional message: ${additionalMessage}`;
    }

    console.error(message);
  }

  public message(message: string) {
    // console.log(message);
  }
  //#endregion 'General Methods'
}
