import { Field } from '../../shared/field';

export class Timelog {
  Activity: Field = new Field();
  Comment: Field = new Field();
  DailyOperationId: Field = new Field();
  DepthEnd: Field = new Field();
  DepthStart: Field = new Field();
  Duration: Field = new Field();
  Inactive: Field = new Field();
  SysSeq: Field = new Field();
  TimeLogId: Field = new Field();
  WellId: Field = new Field();
  Wellbore?: Field = new Field();
  WellboreTable?: Field = new Field();
}
