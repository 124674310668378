import { CasingComponent, Centralizer, NotificationToast } from '#models/index';
import { CentralizeApiService } from '#services-api/index';
import { SectionCasingService } from '#services-shared/index';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-running',
  templateUrl: './running.component.html',
  styleUrls: ['./running.component.scss'],
  providers: [MessageService],
})
export class RunningComponent implements OnInit, OnDestroy {
  //#region 'Input, Output, ViewChild'
  @Input() public tmpSelectedIndex: number;
  @Input() public targetShoeDepth: number;
  @Input() public tmpSelectedCasingComponent: CasingComponent;

  @Output() public message: EventEmitter<any> = new EventEmitter<any>();
  @Output() public shoeDepth: EventEmitter<any> = new EventEmitter<any>();
  @Output() public threadOffValues: EventEmitter<any> = new EventEmitter<any>();
  @Output() public updtToggleArrayInfo: EventEmitter<any> = new EventEmitter<any>();
  @Output() public updtCasingComponent: EventEmitter<any> = new EventEmitter<any>();
  //#endregion 'Input, Output, ViewChild'

  //#region 'Variables'
  public casingComponentId: CasingComponent;
  public centralizedNotes = '';
  public isEditNote: boolean;
  public displayCentralize: boolean;
  public disableCentralizedNotes: boolean;
  public displayCentralizedNotes: boolean;
  public displayMultipleRunning: boolean;
  public dragIndex: number;
  public dragEnterHover: number;
  public OLD_threadLoss: string;
  public centralizeData: Centralizer[] = [];

  // Toggle
  public isToggleEnabled: boolean;
  public isCutEnabled: boolean;
  public showCut: boolean;
  public showPaste: boolean;
  public readyToPaste: boolean = false;
  public pasteIndex: number;
  public pasteArray: CasingComponent[] = [];
  public toggleArrayCasingComponentSet: CasingComponent[] = [];
  public toggleMessages = '';

  // Report Format
  public selectedReportFormat: string[] = [];

  // Centralize
  public showCtlNumberExist: boolean;
  public showCtlIncorrectNumber: boolean;

  // Multiple Running
  public startSeq: number;
  public finalSeq: number;
  public runningType: string = 'Un-Run';
  public disableRunUnRunButton: boolean = true;
  public showMultipleRunningError: boolean;
  public casingsToRun: CasingComponent[] = [];
  public casingsToUnRun: CasingComponent[] = [];

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    public _sectionCasing: SectionCasingService,
    private _centralize: CentralizeApiService,
    private _message: MessageService
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public runCasing() {
    this.message.next({
      key: 'c',
      sticky: true,
      severity: 'warn',
      summary: 'Please select the Date and Time of Runing',
      detail: ``,
      clear: true,
      toastType: 'Finalize',
    });
  }

  public runClearAll() {
    this.message.next({
      key: 'c',
      sticky: true,
      severity: 'warn',
      summary: 'Clear All',
      detail: `This action will DELETE all the items in this casing, are you sure you want to continue?.`,
      clear: true,
      toastType: 'ClearAll',
    });
  }

  public openCentralizedNotes(casingComponent: CasingComponent, index: number) {
    this.centralizedNotes = casingComponent.CentralizedNotes;
    this.tmpSelectedCasingComponent = casingComponent;
    this.tmpSelectedIndex = index;
    this.disableCentralizedNotes = this.tmpSelectedCasingComponent.IsApproved ? true : false;
    this.displayCentralizedNotes = true;
    this.isEditNote = true;
  }

  public saveNotes() {
    if (this.isEditNote) {
      this.displayCentralizedNotes = false;
      if (
        this.centralizedNotes.trim() !== '' &&
        this.tmpSelectedCasingComponent.CentralizedNotes !== this.centralizedNotes.trim()
      ) {
        this.tmpSelectedCasingComponent.CentralizedNotes = this.centralizedNotes.trim();
        this.updateCasingInspect(this.tmpSelectedCasingComponent, this.tmpSelectedIndex, 'casingSpacingUpdate');
      }
    }
  }

  public generateReport(OPGenerateReports: OverlayPanel) {
    if (this.selectedReportFormat.includes('PDF_AllCasings')) {
      this._sectionCasing.getCasingRunningTallyPDF_AllCasings().then(
        () => {},
        (error) => {
          this.showNotification(error);
        }
      );
    }
    if (this.selectedReportFormat.includes('PDF_RunOnly')) {
      this._sectionCasing.getCasingRunningTallyPDF_RunOnly().then(
        () => {},
        (error) => {
          this.showNotification(error);
        }
      );
    }
    if (this.selectedReportFormat.includes('Excel_AllCasings')) {
      this._sectionCasing.getCasingRunningTallyExcel_AllCasings().then(
        () => {},
        (error) => {
          this.showNotification(error);
        }
      );
    }
    if (this.selectedReportFormat.includes('Excel_RunOnly')) {
      this._sectionCasing.getCasingRunningTallyExcel_RunOnly().then(
        () => {},
        (error) => {
          this.showNotification(error);
        }
      );
    }
    this.selectedReportFormat = [];
    OPGenerateReports.hide();
  }

  public onClickBad(casingComponent: CasingComponent) {
    casingComponent.IsCentralized = false;
    casingComponent.IsReferenceDepth = false;
    casingComponent.CentralizedNotes = '';

    if (
      casingComponent.IsBadJoint ||
      casingComponent.Description.Value === 'Drill Pipe' ||
      casingComponent.Description.Value === 'Heavyweight Drill Pipe'
    ) {
      casingComponent.IsExclude = true;
    } else {
      casingComponent.IsExclude = false;
    }

    this._sectionCasing.casingComponentUpdate(casingComponent, true).then(
      () => {},
      (error) => {
        this.showNotification(error);
      }
    );
  }

  public async updtCasingInspectDragDrop(ev: any) {
    let dropIndex = ev.dropIndex;
    const originalRC = [...this._sectionCasing.lstCasingComponents_RunChecked];
    const arrData = ev.indexes;
    const diMinus = dropIndex > arrData[arrData.length - 1] ? true : false;
    for (let i = arrData.length; i > 0; i--) {
      const element = arrData[i - 1];
      const x = originalRC[element];
      const dragIndex = this._sectionCasing.lstCasingComponents_RunChecked.indexOf(x);

      if (dropIndex !== dragIndex) {
        this._sectionCasing.lstCasingComponents_RunChecked = this._sectionCasing.array_move(
          this._sectionCasing.lstCasingComponents_RunChecked,
          dragIndex,
          dropIndex
        );
      }
      if (dropIndex > 0 && diMinus) {
        dropIndex--;
      }
    }
    this._sectionCasing.reOrderRunCasings();
    this._sectionCasing
      .updateCasingComponentBulk(this._sectionCasing.lstCasingComponents_RunChecked, 'ToggleSequence', 1)
      .then(
        (done: any) => {
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
  }

  public computeThreadOffValues(casingComponent: CasingComponent, index: number, info: string) {
    this.threadOffValues.next({
      casingComponent: casingComponent,
      index: index,
      info: info,
      updateTopBot: true,
    });
  }

  public resetALLData() {
    this.casingComponentId = undefined;
    this.centralizedNotes = '';
    this.isEditNote = undefined;
    this.displayCentralize = undefined;
    this.disableCentralizedNotes = undefined;
    this.displayCentralizedNotes = undefined;
    this.displayMultipleRunning = undefined;
    this.dragIndex = undefined;
    this.dragEnterHover = undefined;
    this.OLD_threadLoss = undefined;
    this.centralizeData = [];

    // Toggle
    this.isToggleEnabled = false;
    this.isCutEnabled = false;
    this.showCut = false;
    this.showPaste = false;
    this.pasteIndex = undefined;
    this.pasteArray = [];
    this.toggleArrayCasingComponentSet = [];
    this.toggleMessages = '';

    // Report Format
    this.selectedReportFormat = [];

    // Centralize
    this.showCtlNumberExist = false;
    this.showCtlIncorrectNumber = false;

    // Multiple Running
    this.startSeq = 0;
    this.finalSeq = 0;
    this.runningType = 'Un-Run';
    this.showMultipleRunningError = false;
  }
  //#endregion 'General Methods'

  //#region 'Cut & Paste'
  public selectToggleIndex(casingComponent: CasingComponent) {
    const exist = this.toggleArrayCasingComponentSet.findIndex((cc) => cc === casingComponent);

    if (exist === -1) {
      casingComponent.isReorder = true;
      this.toggleArrayCasingComponentSet.push(casingComponent);
    } else {
      casingComponent.isReorder = false;
      this.toggleArrayCasingComponentSet.splice(exist, 1);
    }

    if (this.toggleArrayCasingComponentSet.length > 0) {
      this.toggleMessages =
        this.toggleArrayCasingComponentSet.length +
        (this.toggleArrayCasingComponentSet.length === 1 ? ' component' : ' components') +
        ' selected for Cut.';
      this.isToggleEnabled = true;
      this.showCut = true;
      this.showPaste = false;
    } else {
      this.cancelCutPaste();
    }
  }

  public cutRunComponents() {
    this.toggleMessages =
      this.toggleArrayCasingComponentSet.length +
      (this.toggleArrayCasingComponentSet.length === 1 ? ' component' : ' components') +
      (this.toggleArrayCasingComponentSet.length === 1 ? ' has' : ' have') +
      ' been Cut from Casings Run. Please select a designated paste area to Paste.';
    this.pasteIndex = -2;
    this.isCutEnabled = true;
    this.showCut = false;
    this.showPaste = true;

    this.toggleArrayCasingComponentSet.sort((a, b) =>
      Number(a.SysSeq.Value) > Number(b.SysSeq.Value) ? 1 : Number(b.SysSeq.Value) > Number(a.SysSeq.Value) ? -1 : 0
    );

    this.toggleArrayCasingComponentSet.forEach((element) => {
      // Push into Paste Array
      this.pasteArray.push(element);
      // And filter from Run Array i.e Cut Elements Out
      this._sectionCasing.lstCasingComponents_RunChecked = this._sectionCasing.lstCasingComponents_RunChecked.filter(
        (obj) => obj.CasingComponentId !== element.CasingComponentId
      );
    });
    this.readyToPaste = true;
  }

  public setPasteIndex(index: number) {
    this.pasteIndex = index;
  }

  public pasteRunComponents() {
    this._sectionCasing.lstCasingComponents_RunChecked.splice(this.pasteIndex + 1, 0, ...this.pasteArray);
    this._sectionCasing.reOrderRunCasings();
    this._sectionCasing
      .updateCasingComponentBulk(this._sectionCasing.lstCasingComponents_RunChecked, 'ToggleSequence', 1)
      .then(
        (done: any) => {
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
    this.isCutEnabled = false;
    this.readyToPaste = false;
    this.isToggleEnabled = false;
    this.pasteIndex = -2;
    this.pasteArray = [];
    this.toggleArrayCasingComponentSet = [];
  }

  public cancelCutPaste() {
    // Restore the current cut
    if (this.isCutEnabled) {
      this._sectionCasing.lstCasingComponents_RunChecked = [
        ...this._sectionCasing.lstCasingComponents_RunChecked,
        ...this.toggleArrayCasingComponentSet,
      ];
    }

    this._sectionCasing.lstCasingComponents_RunChecked = this._sectionCasing.filterBySequence(
      this._sectionCasing.lstCasingComponents_RunChecked
    );

    this._sectionCasing.lstCasingComponents_RunChecked.map((cc) => {
      cc.isReorder = false;
    });

    this.pasteArray = [];
    this.toggleArrayCasingComponentSet = [];

    this.showCut = false;
    this.showPaste = false;
    this.readyToPaste = false;
    this.isCutEnabled = false;
    this.isToggleEnabled = false;
  }

  public isSelectedOrNot(rowData: CasingComponent) {
    const dto = this.toggleArrayCasingComponentSet.indexOf(rowData);
    if (dto === -1) {
      return true;
    } else {
      return false;
    }
  }
  //#endregion 'Cut & Paste'

  //#region 'Validations'
  public isCentralizeHtmlValid(idx: number) {
    const DTO = this._sectionCasing.lstCasingComponents_RunChecked[idx];
    if (DTO.IsApproved || DTO.EquipmentType.Value.toUpperCase() !== 'CASING' || DTO.IsBadJoint) {
      return true;
    } else {
      return false;
    }
  }
  //#endregion 'Validation'

  //#region 'Centralize'
  public centralizeCasings() {
    if (this.showCtlIncorrectNumber || this.showCtlNumberExist) {
      return;
    }

    this.hideCentralize();
    this.centralizeData.sort((a, b) =>
      Number(a.StartDepth) < Number(b.StartDepth) ? 1 : Number(b.StartDepth) < Number(a.StartDepth) ? -1 : 0
    );

    this.centralizeData.map((ctlElement) => {
      // Get all the joints in the range of start/bottom depth
      const RANGE = this._sectionCasing.lstCasingComponents_RunChecked.filter(
        (obj: CasingComponent) =>
          (Number(obj.JointBottom) >= Number(ctlElement.StartDepth) ||
            Number(obj.JointTop) >= Number(ctlElement.StartDepth)) &&
          (Number(obj.JointBottom) <= Number(ctlElement.EndDepth) ||
            Number(obj.JointTop) <= Number(ctlElement.EndDepth)) &&
          obj.EquipmentType.Value.toUpperCase() === 'CASING' &&
          !obj.IsBadJoint
      );

      RANGE.sort((a: any, b: any) =>
        Number(a.CumLength.Value) < Number(b.CumLength.Value)
          ? 1
          : Number(b.CumLength.Value) < Number(a.CumLength.Value)
          ? -1
          : 0
      );

      // Reset all the Joints we filtered
      RANGE.map((element) => {
        element.CentralizedNotes = '';
        element.IsCentralized = false;
        return element;
      });

      for (let idx = 0; idx < RANGE.length; ) {
        const rngElement: CasingComponent = RANGE[idx];

        const indexRun = this._sectionCasing.lstCasingComponents_RunChecked.findIndex(
          (dto) => dto.SysSeq.Value === rngElement.SysSeq.Value
        );

        this._sectionCasing.lstCasingComponents_RunChecked[indexRun].IsCentralized = true;
        this._sectionCasing.lstCasingComponents_RunChecked[indexRun].CentralizedNotes = ctlElement.CentralizerType;

        if (ctlElement.CentralizerPlacement === 'All Joint') {
          idx++;
        } else if (ctlElement.CentralizerPlacement === 'Every Other Joint') {
          idx = idx + 2;
        } else if (ctlElement.CentralizerPlacement === 'Every 3rd Joint') {
          idx = idx + 3;
        } else if (ctlElement.CentralizerPlacement === 'Every 4th Joint') {
          idx = idx + 4;
        } else if (ctlElement.CentralizerPlacement === 'No Centralizer') {
          this._sectionCasing.lstCasingComponents_RunChecked[indexRun].IsCentralized = false;
          this._sectionCasing.lstCasingComponents_RunChecked[indexRun].CentralizedNotes = '';
          idx++;
        }
      }
    });

    this.executeCentralize();
  }

  public addNewCentrilize() {
    const DATA = new Centralizer();
    DATA.CasingStringId = this._sectionCasing.casingStringID;
    if (this.centralizeData.length > 0) {
      DATA.EndDepth = this.centralizeData[this.centralizeData.length - 1].StartDepth;
    }
    this.centralizeData.push(DATA);
  }

  public deleteCentrilize(index: number) {
    const ARR: Centralizer[] = [];
    ARR.push(this.centralizeData[index]);

    if (ARR[0].Id > 0) {
      this.SUBS$.add(
        this._sectionCasing.deleteCentralizeSetup(ARR).subscribe(() => {
          this.removeCTL(index);
        })
      );
    } else {
      this.removeCTL(index);
    }
  }

  private removeCTL(index: number) {
    this.centralizeData.splice(index, 1);
  }

  public showCentralize() {
    this.centralizeData = [];
    this.SUBS$.add(
      this._centralize.getByCasingStringId(this._sectionCasing.casingStringID).subscribe((data) => {
        if (data.length > 0) {
          this.centralizeData = data;
        } else {
          this.addNewCentrilize();
        }
        this.displayCentralize = true;
      })
    );
  }

  public hideCentralize() {
    this.displayCentralize = false;
  }

  public validateNumber(ctl: Centralizer, position: number) {
    // Start
    this.showCtlNumberExist = false;
    this.showCtlIncorrectNumber = false;
    const DATA = this.centralizeData.filter((obj) => obj !== ctl);

    if (position === 1 && Number(ctl.EndDepth) > 0) {
      this.showCtlIncorrectNumber = Number(ctl.StartDepth) >= Number(ctl.EndDepth) ? true : false;
    }

    if (position === 2 && Number(ctl.StartDepth) > 0) {
      this.showCtlIncorrectNumber = Number(ctl.EndDepth) <= Number(ctl.StartDepth) ? true : false;
    }

    if (this.showCtlIncorrectNumber) {
      return;
    }

    if (DATA.length > 0) {
      if (position === 1) {
        const START = Number(ctl.StartDepth);

        DATA.map((element) => {
          if (START >= Number(element.StartDepth) && START <= Number(element.EndDepth)) {
            // Exists
            this.showCtlNumberExist = true;
          }
        });
      }

      // Bottom
      if (position === 2) {
        const BOTTOM = Number(ctl.EndDepth);

        DATA.map((element) => {
          if (BOTTOM >= Number(element.StartDepth) && BOTTOM <= Number(element.EndDepth)) {
            // Exists
            this.showCtlNumberExist = true;
          }
        });
      }
    }
  }

  private executeCentralize() {
    this._sectionCasing.calculateAmounts();
    const DELETE = this.centralizeData.filter((obj) => obj.Id > 0);
    if (DELETE.length > 0) {
      this.SUBS$.add(
        this._sectionCasing.deleteCentralizeSetup(DELETE).subscribe(() => {
          this.centralizeData.map((element) => {
            element.Id = 0;
            return element;
          });

          this.centralizeCU();
        })
      );
    } else {
      this.centralizeCU();
    }
  }

  private centralizeCU() {
    this.SUBS$.add(
      this._sectionCasing.createCentralizeSetup(this.centralizeData).subscribe(() => {
        this._sectionCasing
          .updateCasingComponentBulk(this._sectionCasing.lstCasingComponents_RunChecked, 'Centralized', 1)
          .then(
            () => {
              this.showNotification({
                severity: 'success',
                summary: 'Correct!',
                detail: 'Casing running successfully centralized.',
              });
            },
            (error) => this.showNotification(error)
          );
      })
    );
  }
  //#endregion 'Centralize'

  //#region 'Multiple Running'
  public onClickRun(casingComponent: CasingComponent, type: string) {
    if (type === 'RUN') {
      // Run
      const INDEX = this.casingsToRun.findIndex((dto) => dto === casingComponent);
      if (INDEX != -1) {
        this.casingsToRun.splice(INDEX, 1);
      } else {
        this.casingsToRun.push(casingComponent);
      }
    } else {
      // UnRun
      const INDEX = this.casingsToUnRun.findIndex((dto) => dto === casingComponent);
      if (INDEX != -1) {
        this.casingsToUnRun.splice(INDEX, 1);
      } else {
        this.casingsToUnRun.push(casingComponent);
      }
    }
    this.disableRunUnRun();
  }

  public applyRunUnRun() {
    let bulkCasings: CasingComponent[] = [];
    this._sectionCasing.resetNotifications();

    if (this.casingsToRun && this.casingsToRun.length > 0) {
      this.casingsToRun.sort((a, b) =>
        Number(a.JointNumber) > Number(b.JointNumber) ? 1 : Number(b.JointNumber) > Number(a.JointNumber) ? -1 : 0
      );
      this.casingsToRun.forEach((casing) => {
        this._sectionCasing.runCasing(casing, true, false);
      });
    }

    if (this.casingsToUnRun && this.casingsToUnRun.length > 0) {
      this.casingsToUnRun.forEach((casing) => {
        casing.SysSeq.Value = '';
        casing.IsReferenceDepth = false;
        casing.IsCentralized = false;
        casing.CentralizedNotes = '';

        const INDEX = this._sectionCasing.lstCasingComponents_RunChecked.findIndex((dto) => dto === casing);
        this._sectionCasing.lstCasingComponents_RunChecked.splice(INDEX, 1);
      });
    }

    bulkCasings = JSON.parse(
      JSON.stringify(this._sectionCasing.reDoNumerationInArray(this._sectionCasing.lstCasingComponents_RunChecked, 1))
    );
    bulkCasings.push(...this.casingsToUnRun);
    this._sectionCasing.updateCasingComponentBulk(bulkCasings, 'ToggleSequence', 1).then(
      (done: any) => {
        this.casingsToRun = [];
        this.casingsToUnRun = [];
        this.disableRunUnRun();
        this.showNotification(done);
      },
      (error) => this.showNotification(error)
    );

    this.hideMultipleRunUnRun();
  }

  public showMultipleRunUnRun() {
    this.displayMultipleRunning = true;
  }

  public hideMultipleRunUnRun() {
    this.displayMultipleRunning = false;
  }

  public showRunUnRunButton(): boolean {
    if (
      this._sectionCasing.lstCasingComponents_RunChecked.length > 0 ||
      this._sectionCasing.lstCasingComponents_Validated.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  public disableRunUnRun() {
    if (this.casingsToRun.length > 0 || this.casingsToUnRun.length > 0) {
      this.disableRunUnRunButton = false;
    } else {
      this.disableRunUnRunButton = true;
    }
  }
  //#endregion 'Multiple Running'

  //#region 'CRUD'
  public updateCasingInspect(casingComponent: CasingComponent, index: number, infoField: string) {
    this._sectionCasing.resetNotifications();
    this.updtCasingComponent.next({
      casingComponent,
      index,
      infoField,
    });
  }

  public updateExclude(casingComponent: CasingComponent) {
    if (casingComponent) {
      this._sectionCasing.casingComponentUpdate(casingComponent).then(
        () => {},
        (error) => {
          this.showNotification(error);
        }
      );
    }
  }

  public updateDepthRef(casingComponent: CasingComponent) {
    if (!casingComponent) {
      return;
    }

    const currentRD = this._sectionCasing.lstCasingComponents_RunChecked.filter(
      (obj) => obj.IsReferenceDepth && obj.CasingComponentId !== casingComponent.CasingComponentId
    );
    if (currentRD && currentRD.length > 0 && currentRD[0].IsReferenceDepth) {
      currentRD[0].IsReferenceDepth = false;
      const casings: CasingComponent[] = [];

      casings.push(currentRD[0]);
      casings.push(casingComponent);
      this._sectionCasing.updateCasingComponentBulk(casings, 'LoadRun', 1).then(
        (done: any) => {
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
    } else {
      this._sectionCasing.casingComponentUpdate(casingComponent, true).then(
        () => {},
        (error) => {
          this.showNotification(error);
        }
      );
    }
  }

  public updateCasingString(type: number, from: string) {
    this._sectionCasing.updateCasingString(type, from).then(
      () => {},
      (error) => {
        this.showNotification(error);
      }
    );
  }

  public removeCasingInspect(validatedCasingComponent: CasingComponent) {
    this.casingComponentId = validatedCasingComponent;
    this.message.next({
      key: 'c',
      sticky: true,
      severity: 'warn',
      summary: 'Delete Casing',
      detail: 'Are you sure you want to delete selected casing?',
      clear: true,
      toastType: 'DeleteRunning',
    });
  }

  public unvalidateCasing(dto: CasingComponent, idx: number, cameFromRun: boolean) {
    this._sectionCasing.unvalidateFromRun = cameFromRun;
    this._sectionCasing.casingToUnvalidateIndex = idx;
    this._sectionCasing.casingToUnvalidate = dto;
    this.message.next({
      key: 'c',
      sticky: true,
      severity: 'warn',
      summary: 'Edit Casing',
      detail: 'Are you sure you want to Edit selected casing?',
      clear: true,
      toastType: 'Unvalidate',
    });
  }
  //#endregion 'CRUD'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._message.add({
      key: msg && msg.key && msg.key.length > 0 ? msg.key : 'runningNotify',
      sticky: msg && msg.key && msg.key != 'runningNotify' ? true : false,
      closable: msg && msg.key && msg.key != 'runningNotify' ? true : false,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life,
    });
  }
  //#endregion 'Notification'
}
