<div class="row" *ngIf="cementVolumeCalculation">
  <div class="col-sm-4">
    <div class="row text-center">
      <h3 class="text-primary">Casing Information</h3>
    </div>
    <div class="row">
      <div class="panel panel-info">
        <div class="panel-heading">
          <h3 class="panel-title">Previous Casing</h3>
        </div>
        <div class="panel-body">
          <p-messages [(value)]="_sectionCasing.notificationsCementVolume" [closable]="false"></p-messages>
          <div class="form-group">
            <label for="previousCasing">Select Previous Casing </label>
            <select
              class="form-control"
              [(ngModel)]="prevCasingDescription"
              (change)="prevCasingDescriptionChange($event)"
            >
              <option [ngValue]="defaultDescription">{{ defaultDescription.Description }}</option>
              <option *ngFor="let item of _sectionCasing.lstCasingDescription" [ngValue]="item">
                {{ item.Description }}
              </option>
            </select>
          </div>
          <div *ngIf="prevCasingDescription === defaultDescription" class="alert alert-warning" role="alert">
            Note: Calculations provided for Base Casing with no Previous Selected.
          </div>
          <table *ngIf="prevCasingDescription !== defaultDescription" class="table noborder mb0">
            <tbody>
              <tr>
                <td>
                  <div class="form-group">
                    <label for="prevCasingID">Previous Casing ID</label>
                    <label readonly class="form-control">{{ _sectionCasing.prevCasingID }}</label>
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <label for="prevShoeDepth">Previous Shoe Depth</label>
                    <label readonly class="form-control">{{ _sectionCasing.prevShoeDepth | number: '1.0-2' }}</label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="panel panel-info">
        <div class="panel-heading">
          <h3 class="panel-title">Current Casing</h3>
        </div>
        <div class="panel-body">
          <table class="table noborder">
            <tbody>
              <tr>
                <td>
                  <div class="form-group">
                    <label for="holeSize">Hole Section</label>
                    <input
                      readonly
                      type="text"
                      class="form-control"
                      (blur)="reCalculate()"
                      id="holeSize"
                      [(ngModel)]="selectedHoleSize"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <label for="mixWater">Mix Water</label>
                    <input
                      type="number"
                      onkeyup="if(this.value < 0){this.value = this.value * -1}"
                      step="0.01"
                      min="0"
                      class="form-control"
                      id="mixWater"
                      [(ngModel)]="_sectionCasing.casingString.MixWater"
                      (blur)="updateCasingStringInfo('Mix Water', 4); reCalculate()"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group">
                    <label
                      for="casingOD"
                      data-toggle="tooltip"
                      onmouseenter="$(this).tooltip('show')"
                      data-container="body"
                      data-placement="top"
                      [title]="_sectionCasing.lstCasingComponentColumns.OD.Help"
                    >
                      {{ _sectionCasing.lstCasingComponentColumns.OD.Label }}
                      <span *ngIf="_sectionCasing.lstCasingComponentColumns.OD.UOM !== ''"
                        >({{ _sectionCasing.lstCasingComponentColumns.OD.UOM }})</span
                      >
                    </label>
                    <select
                      id="casingOD"
                      class="form-control"
                      (change)="updateODCmntVal(); reCalculate()"
                      [(ngModel)]="selectedODCmntVol"
                    >
                      <option *ngFor="let item of casingstringODValues" [ngValue]="item">
                        {{ item }}
                      </option>
                    </select>
                    <label class="text-primary"
                      >Calculated {{ _sectionCasing.lstCasingComponentColumns.OD.Label }}
                      <span *ngIf="_sectionCasing.lstCasingComponentColumns.OD.UOM !== ''"
                        >({{ _sectionCasing.lstCasingComponentColumns.OD.UOM }})</span
                      >
                      : {{ calculatedODCmntVol }}
                    </label>
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <label for="yield">Yield (gal/sck)</label>
                    <input
                      type="number"
                      onkeyup="if(this.value < 0){this.value = this.value * -1}"
                      class="form-control"
                      id="yield"
                      min="0"
                      step="0.01"
                      [(ngModel)]="_sectionCasing.casingString.Yield"
                      (blur)="updateCasingStringInfo('Yield', 4); reCalculate()"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group">
                    <label
                      for="casingID"
                      data-toggle="tooltip"
                      onmouseenter="$(this).tooltip('show')"
                      data-container="body"
                      data-placement="top"
                      [title]="_sectionCasing.lstCasingComponentColumns.ID.Help"
                    >
                      {{ _sectionCasing.lstCasingComponentColumns.ID.Label }}
                      <span *ngIf="_sectionCasing.lstCasingComponentColumns.ID.UOM !== ''"
                        >({{ _sectionCasing.lstCasingComponentColumns.ID.UOM }})</span
                      >
                    </label>
                    <select id="casingID" class="form-control" (change)="reCalculate()" [(ngModel)]="selectedIDCmntVol">
                      <option *ngFor="let item of casingstringIDValues" [ngValue]="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <label for="length">Length</label>
                    <input
                      type="number"
                      onkeyup="if(this.value < 0){this.value = this.value * -1}"
                      class="form-control"
                      id="length"
                      min="0"
                      step="0.01"
                      [(ngModel)]="_sectionCasing.casingString.Length"
                      (blur)="updateCasingStringInfo('Length', 4); reCalculate()"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group">
                    <label for="shoeDepth">Shoe Depth</label>
                    <label for="shoeDepth" class="form-control" readonly>{{
                      _sectionCasing.shoeDepth | number: '1.0-2'
                    }}</label>
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <label for="stickUp">Stick Up</label>
                    <input
                      type="number"
                      onkeyup="if(this.value < 0){this.value = this.value * -1}"
                      class="form-control"
                      min="0"
                      step="0.01"
                      id="stickUp"
                      [(ngModel)]="_sectionCasing.casingString.StickUp"
                      (blur)="updateCasingStringInfo('Stick Up', 4); reCalculate()"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-8">
    <div class="row text-center">
      <h3 class="text-primary">Calculations</h3>
    </div>
    <div class="row">
      <div class="panel panel-info">
        <div class="panel-heading">
          <h3 class="panel-title">Fluid Capacities</h3>
        </div>
        <div class="panel-body">
          <div class="col-sm-2">
            <div class="form-group">
              <label for="pipeCapacity">Pipe Capacity (bbl/ft)</label>
              <label class="form-control">{{ pipeCapacity | number: '1.0-2' }}</label>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="pipeDisp">Pipe Displacement</label>
              <label class="form-control">{{ pipeDisplacement | number: '1.0-2' }}</label>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="internalCasingVol">Internal Casing Volume</label>
              <label class="form-control">{{ internalCasingVolume | number: '1.0-2' }}</label>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="annulusVol">Total Annulus Volume</label>
              <label class="form-control">{{ totalAnnulusVolume | number: '1.0-2' }}</label>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="fluidVol">Total Fluid Volume</label>
              <label class="form-control">{{ totalFluidVolume | number: '1.0-2' }}</label>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="basketDep">Basket Depth</label>
              <label readonly class="form-control">645.10</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="panel panel-info">
        <div class="panel-heading">
          <h3 class="panel-title">Cement Volumes</h3>
        </div>
        <div class="panel-body">
          <div class="col-sm-2"></div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="targetVolume">Target Volume</label>
              <label class="form-control">{{ targetVolume | number: '1.0-2' }}</label>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="targetVolOH">Target Volume (+30% OH)</label>
              <label class="form-control">{{ targetVolumeOH | number: '1.0-2' }}</label>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="sacksReqd">Sacks Required</label>
              <label class="form-control">{{ sacksReqd | number: '1.0-2' }}</label>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="mixWaterCalc">Mix Water Calculated</label>
              <label class="form-control">{{ mixWaterCalc | number: '1.0-2' }}</label>
            </div>
          </div>
          <div class="col-sm-2"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="panel panel-info">
        <div class="panel-heading">
          <h3 class="panel-title">Fresh Water Displacement</h3>
        </div>
        <div class="panel-body">
          <div class="col-sm-2"></div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="shoeTrack">Shoe Track Volume</label>
              <label class="form-control">{{ shoeTrackVolume | number: '1.0-2' }}</label>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="shoeTrackHalf">1/2 Shoe Track Volume</label>
              <label class="form-control">{{ halfShoeTrackVolume | number: '1.0-2' }}</label>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="freshWaterDisp">Fresh Water Displacement</label>
              <label class="form-control">{{ freshWaterDisplacement | number: '1.0-2' }}</label>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="maxDisp">Max Displacement</label>
              <label class="form-control">{{ maxDisplacement | number: '1.0-2' }}</label>
            </div>
          </div>
          <div class="col-sm-2"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="!cementVolumeCalculation" class="centerNoData">
  <div class="panel panel-info">
    <div class="panel-heading">
      <h3 class="panel-title">Empty Casing Components</h3>
    </div>
    <div class="panel-body">
      <span>Sorry, the selected casing has no data to display for Cement Volume Calculations at this time.</span>
    </div>
  </div>
</div>
