import { TaskPlanItemCreateComponent } from '#pages/admin/task-plan-item-create/task-plan-item-create.component';
import { TaskPlanItemEditComponent } from '#pages/admin/task-plan-item-edit/task-plan-item-edit.component';
import { TaskPlanSectionDeleteComponent } from '#pages/admin/task-plan-section-delete/task-plan-section-delete.component';
import { TaskPlanSectionEditComponent } from '#pages/admin/task-plan-section-edit/task-plan-section-edit.component';
import { TaskPlanSubSectionDeleteComponent } from '#pages/admin/task-plan-sub-section-delete/task-plan-sub-section-delete.component';
import { TaskPlanSubSectionEditComponent } from '#pages/admin/task-plan-sub-section-edit/task-plan-sub-section-edit.component';
import { UserComponent } from '#pages/admin/user/user.component';
import { ApiModule } from '#services-api/api.module';
import { OrganizationApiService } from '#services-api/index';
import { LoadingIndicatorService } from '#services-shared/index';
import { SharedModule } from '#services-shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
// tslint:disable-next-line: max-line-length
import {
  BusinessUnitsComponent,
  TaskPlanComponent,
  TaskPlanCreateComponent,
  TaskPlanDeleteComponent,
  TaskPlanEditComponent,
  TaskPlanManageComponent,
} from './';
import { OrganizationComponent } from './organization/organization.component';

@NgModule({
  declarations: [
    BusinessUnitsComponent,
    TaskPlanComponent,
    TaskPlanCreateComponent,
    TaskPlanEditComponent,
    TaskPlanManageComponent,
    TaskPlanDeleteComponent,
    TaskPlanSectionEditComponent,
    TaskPlanSectionDeleteComponent,
    TaskPlanSubSectionEditComponent,
    TaskPlanSubSectionDeleteComponent,
    TaskPlanItemCreateComponent,
    TaskPlanItemEditComponent,
    OrganizationComponent,
    UserComponent,
  ],
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,

    // PrimeNG
    InputTextModule,
    ButtonModule,
    BrowserAnimationsModule,
    ConfirmDialogModule,
    DialogModule,
    AutoCompleteModule,
    ToastModule,

    // App
    ApiModule,
    SharedModule,
    FormsModule,
  ],
  providers: [LoadingIndicatorService, ConfirmationService, OrganizationApiService],
})
export class AdminPagesModule {}
