<small>
  <table class="table table-striped table-hover text-center table-condensed">
    <caption>
      Well :
      {{
        wellName
      }}
    </caption>
    <thead class="text-primary">
      <tr>
        <td>Business Unit</td>
        <td>Area</td>
        <td>Field Name</td>
        <td>Original Spud Date</td>
        <td>Platform Jacket Pad</td>
        <td>Current RKB Elevation</td>
        <td>Ground Elevation</td>
      </tr>
    </thead>
    <tbody>
      <td>{{ BusinessUnit }}</td>
      <td>{{ Area }}</td>
      <td>{{ FieldName }}</td>
      <td>{{ OriginalSpudDate }}</td>
      <td>{{ PlatformJacketPad }}</td>
      <td>{{ CurrentRKBElevation }}</td>
      <td>{{ GroundElevation }}</td>
    </tbody>
  </table>
</small>
