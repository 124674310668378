import { Field } from '#models/shared/field';

export class OfflineActivityAttachment {
  IdRec: Field = new Field();
  OADId: Field = new Field();
  WellId: Field = new Field();

  Date: Field = new Field();
  Type: Field = new Field();
  SubType: Field = new Field();
  Description: Field = new Field();
  ReferenceId: Field = new Field();
  Status: Field = new Field();
  Name: Field = new Field();
  EmailCAI: Field = new Field();
  Comment: Field = new Field();
}
