import { WellsApiService } from '#services-api/index';
import { DailyOperationService } from '#services-shared/index';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Well } from 'models';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-well-header',
  templateUrl: './well-header.component.html',
  styleUrls: ['./well-header.component.scss'],
})
export class WellHeaderComponent implements OnInit, OnDestroy {
  //#region 'Input, Output, ViewChild'
  @Input() wellId: string;
  //#endregion 'Input, Output, ViewChild'

  //#region 'Variables'
  public wellName: string;
  public BusinessUnit: string;
  public Area: string;
  public FieldName: string;
  public OriginalSpudDate: string;
  public PlatformJacketPad: string;
  public CurrentRKBElevation: string;
  public GroundElevation: string;

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(private _well: WellsApiService, public _sectionDailyOperation: DailyOperationService) {}

  ngOnInit() {
    this.loadWell();
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  private async loadWell() {
    this.SUBS$.add(
      await this._well.getWellDataByID(this.wellId).subscribe((data: Well) => {
        this._sectionDailyOperation.wellName = data.Name;
        this.wellName = data.Name;
        this.BusinessUnit = data.BusinessUnit;
        this.Area = data.Area;
        this.FieldName = data.FieldName;
        this.OriginalSpudDate = data.OriginalSpudDate;
        this.PlatformJacketPad = data.PlatformJacketPad;
        this.CurrentRKBElevation = data.CurrentRKBElevation;
        this.GroundElevation = data.GroundElevation;
      })
    );
  }
  //#endregion 'Load'
}
