<!-- Header -->
<div class="box-shadow module">
  <div class="row">
    <div class="col-xs-7">
      <app-well-header
        *ngIf="_sectionDailyOperation.wellId != ''"
        [wellId]="_sectionDailyOperation.wellId"
      ></app-well-header>
    </div>
    <div class="col-xs-5 headerSection">
      <app-job-header> </app-job-header>
    </div>
  </div>
</div>
<!-- Header -->

<!-- Content -->
<div class="box-shadow module">
  <!-- Toolbar -->
  <div class="row">
    <div class="col-md-2">
      <div>
        <select class="form-control" (change)="selectItems($event)">
          <option [ngValue]="">---Select?---</option>
          <option *ngFor="let item of selectFilterItems" [ngValue]="item">{{ item }}</option>
        </select>
      </div>
    </div>
    <div class="col-md-2">
      <div>
        <span class="boldTextFont">Are surface holes already drilled? </span>
        <p-toggleButton
          id="tgl_btn_drilled"
          class="ml6"
          [(ngModel)]="alreadyDrilled"
          [style]="{ width: '60px' }"
          (onChange)="filterSurface()"
        ></p-toggleButton>
      </div>
    </div>
    <div class="col-md-3">
      <div>
        <span class="boldTextFont">Is this well a three string design (i.e.. No liner)? </span>
        <p-toggleButton
          id="tgl_btn_string_design"
          class="ml6"
          [(ngModel)]="isThreeStringDesign"
          [style]="{ width: '60px' }"
          (onChange)="filterDesign()"
        ></p-toggleButton>
      </div>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-1">
      <div>
        <button id="btn_test" type="button" class="btn btn-primary fr" (click)="showMessage()">
          <span class="divCenter"><i class="pi pi-check mr6"></i>Save</span>
        </button>
      </div>
    </div>
  </div>
  <!-- Toolbar -->
  <br />
  <!-- Timelog Standard -->
  <div class="row">
    <!-- Table as Header -->
    <div class="col-md-12 text-left table-responsive">
      <table class="table table-striped noborder noMarginBot">
        <!-- Run List Headers -->
        <thead>
          <tr class="text-primary helpCursor">
            <th colspan="8" class="title-hd"><span class="boldTextFont">ALL WELLS</span></th>
          </tr>
        </thead>
        <!-- Run List Headers -->

        <!-- Run List Content -->
        <tbody>
          <tr>
            <td class="w4"></td>
            <td class="w8"><span class="boldTextFont">Activity Code</span></td>
            <td class="w64"><span class="boldTextFont">Time Log Entries</span></td>
            <td class="w6"><span class="boldTextFont">Duration (hr)</span></td>
            <td class="w6"><span class="boldTextFont">Well Phase</span></td>
            <td class="w6"><span class="boldTextFont">Phase 1</span></td>
            <td class="w6"><span class="boldTextFont">Phase 2</span></td>
          </tr>
        </tbody>
        <!-- Run List Content -->
      </table>
    </div>
    <!-- Table as Header -->
    <!-- Content Table -->
    <div class="col-md-12 text-left table-responsive tableHeight">
      <table id="cvxTable" *ngFor="let tbl of dataCVX; let idxT = index" class="table noborder noMarginBot">
        <!-- Run List Headers -->
        <thead>
          <tr class="text-primary helpCursor">
            <th colspan="8" class="content-hd">
              <span class="boldTextFont">{{ tbl.HeaderName.StepTitle }}</span>
            </th>
          </tr>
        </thead>
        <!-- Run List Headers -->

        <!-- Run List Content -->
        <tbody class="notReq">
          <tr
            *ngFor="let dt of tbl.Actions; let idx = index"
            [ngClass]="{ required: dt.StepDependency === 'Required', selected: dt.Selected === true }"
          >
            <td class="w4">
              <p-checkbox
                id="chk_0{{ idxT }}_0{{ idx }}"
                [(ngModel)]="dt.Selected"
                binary="true"
                (onChange)="selectItem(idxT, idx, dt.Selected)"
              ></p-checkbox>
            </td>
            <td class="w8">
              <span>{{ dt.ActivityCode }}</span>
            </td>
            <td class="w65">
              <textarea
                id="txa_0{{ idxT }}_0{{ idx }}_tml_entry"
                name="txa_tml_entry"
                class="form-control boldTextFont"
                [(ngModel)]="dt.StepTitle"
                autosize
              ></textarea>
            </td>
            <td class="w6">
              <div>
                <input
                  id="txt_0{{ idxT }}_0{{ idx }}_edt_duration"
                  type="number"
                  onkeyup="if(this.value < 0){this.value = this.value * -1}"
                  min="1"
                  class="form-control"
                  onfocus="this.select();"
                  [(ngModel)]="dt.PlannedDuration"
                />
              </div>
            </td>
            <td class="w6">
              <span>{{ dt.WellPhase }}</span>
            </td>
            <td class="w6">
              <span>{{ dt.Phase1 }}</span>
            </td>
            <td class="w6">
              <span>{{ dt.Phase2 }}</span>
            </td>
          </tr>
        </tbody>
        <!-- Run List Content -->
      </table>
    </div>
    <!-- Content Table -->
  </div>
  <!-- Timelog Standard -->
</div>
<!-- Content -->

<!-- Toast -->
<p-toast position="center" key="c" (onClose)="hideToast()" [modal]="true" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="tac">
      <i class="pi pi-exclamation-triangle fontSize3m"></i>
      <h3>{{ message.summary }}</h3>
      <p>{{ message.detail }}</p>
    </div>
    <div class="ui-g ui-fluid">
      <div class="ui-g-6">
        <button
          id="btn_toast_msg_confirm"
          type="button"
          pButton
          (click)="onConfirm()"
          label="Yes"
          class="ui-button-info"
        ></button>
      </div>
      <div class="ui-g-6">
        <button
          id="btn_toast_msg_reject"
          type="button"
          pButton
          (click)="onReject()"
          label="No"
          class="ui-button-secondary"
        ></button>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast [style]="{ marginTop: '40px !important' }" key="planActivitiesNotify" [baseZIndex]="6000"></p-toast>
<p-toast
  [style]="{ marginTop: '40px !important' }"
  key="planActivitiesError"
  [baseZIndex]="6000"
  position="top-center"
></p-toast>
<!-- Toast -->
