export class PlanActions {
  DocNumber: string = '';
  DocName: string = '';
  FAUS: string = '';
  DocInternalVersionId: string = '';
  DocDisplayVersion: string = '';
  DocStatus: string = '';
  IDRefNo: string = '';
  ProcNumber: string = '';
  ProcTypeId: string = '';
  ProcType: string = '';
  IsTemplate: string = '';
  ParentIdrefno: string = '';
  ParentSID: string = '';
  SequenceNo: string = '';
  StepClassId: string = '';
  Classification: string = '';
  SID: string = '';
  StepTitle: string = '';
  ActivityCode: string = '';
  WellPhase: string = '';
  Phase1: string = '';
  Phase2: string = '';
  StepDependency: string = '';
  PlannedDuration: string = '';

  WellId: string = '';
  JobId: string = '';
  Wellbore: string = '';
  IdRec: string = '';
  SysSeq: string = '';

  Selected?: boolean = false;
  Exist?: boolean = false;
}
