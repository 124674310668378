<!-- Notification Msg -->
<!-- <p-messages [(value)]="notifications"></p-messages> -->
<!-- Notification Msg -->

<!-- Content -->
<div class="box-shadow module" style="height: 690px">
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <div>
        <!-- Title -->
        <h2 class="text-center">Settings</h2>
        <!-- Title -->
        <!-- Form -->
        <form class="form-horizontal">
          <div class="form-group">
            <label class="col-md-2 control-label">User</label>
            <div class="col-md-10">
              <input id="txt_user" class="form-control" type="text" placeholder="{{ userName }}" readonly />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label">Profile </label>
            <div class="col-sm-10">
              <select id="ddl_profile" [(ngModel)]="userProfile" class="form-control" name="one">
                <option *ngFor="let uProfile of profiles" [selected]="uProfile === userProfile">
                  {{ uProfile }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label"> Units </label>
            <div class="col-sm-10">
              <select id="ddl_units" [(ngModel)]="userUnit" class="form-control" name="two">
                <option *ngFor="let uUnit of units" [selected]="uUnit === userUnit">{{ uUnit }}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <button
              id="btn_environment"
              (click)="enableEnvironment()"
              type="button"
              class="btn btn-primary ml104 float-left"
            >
              Environment
            </button>
            <button id="btn_save" (click)="updateSettings()" type="button" class="btn btn-primary float-right">
              Save
            </button>
          </div>
        </form>
        <!-- Form -->
      </div>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>
<!-- Content -->

<!-- Toast -->
<p-toast [style]="{ marginTop: '40px !important' }" key="StNotify" [baseZIndex]="6000"></p-toast>
<p-toast [style]="{ marginTop: '40px !important' }" key="StError" [baseZIndex]="6000" position="top-center"></p-toast>
<!-- Toast -->
