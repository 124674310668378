import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[OnlyLettersNumbers]',
})
export class OnlyLettersNumbersDirective {
  private regex: RegExp = new RegExp('^[0-9a-zA-Z]+$');

  constructor(private elmRef: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    const current: string = this.elmRef.nativeElement.value;

    // We need this because the current value on the DOM element
    // is not yet updated with the value from this event
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
