import { environment } from '#environments/environment';
import { OfflineActivityAttachment, SharePointAttach } from '#models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AttachmentApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/offlineActivityAttachment${method}`;
  }
  //#endregion 'General'

  //#region 'Get'
  public getByOfflineActivityDateId(OADId: string): Observable<OfflineActivityAttachment[]> {
    return this.http.get<OfflineActivityAttachment[]>(this.getURL(`/getByOfflineActivityDateId`), {
      params: { oaDateId: OADId },
    });
  }
  //#endregion 'Get'

  //#region 'Post'
  public create(offlineActivityTimelog: OfflineActivityAttachment): Observable<OfflineActivityAttachment> {
    return this.http.post<OfflineActivityAttachment>(this.getURL(`/create`), offlineActivityTimelog);
  }

  public createBulk(offlineActivityTimelog: OfflineActivityAttachment[]): Observable<OfflineActivityAttachment[]> {
    return this.http.post<OfflineActivityAttachment[]>(this.getURL(`/createBulk`), offlineActivityTimelog);
  }
  //#endregion 'Post'

  //#region 'Put'
  public update(offlineActivityTimelog: OfflineActivityAttachment): Observable<OfflineActivityAttachment> {
    return this.http.put<OfflineActivityAttachment>(this.getURL(`/update`), offlineActivityTimelog);
  }

  public updateBulk(offlineActivityTimelog: OfflineActivityAttachment[]): Observable<OfflineActivityAttachment[]> {
    return this.http.put<OfflineActivityAttachment[]>(this.getURL(`/updateBulk`), offlineActivityTimelog);
  }
  //#endregion 'Put'

  //#region 'Delete'
  public delete(offlineActivityTimelog: OfflineActivityAttachment): Observable<{}> {
    return this.http.put<OfflineActivityAttachment>(this.getURL(`/delete`), offlineActivityTimelog);
  }
  //#endregion 'Delete'

  //#region 'SharePoint'
  public UploadFiles(files: FormData, OADId: string): Observable<any> {
    return this.http.post(`${this.getURL(`/uploadFiles`)}?OADId=${OADId}`, files);
  }

  public DeleteAzureFile(attach: SharePointAttach): Observable<SharePointAttach> {
    return this.http.put<SharePointAttach>(this.getURL(`/deleteAzureFile`), attach);
  }

  public DownloadAttachment(name: string, type: string, size: string): Observable<any> {
    return this.http.get(this.getURL(`/downloadAzureAttachment`), {
      params: { FileName: name, FileType: type, Size: size },
      responseType: 'blob',
      observe: 'response',
    });
  }

  public DownloadAllAttachments(OADId: string): Observable<OfflineActivityAttachment[]> {
    return this.http.get<OfflineActivityAttachment[]>(this.getURL(`/downloadAllAttachment`), {
      params: { OADId: OADId },
    });
  }
  //#endregion 'SharePoint'
}
