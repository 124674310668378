import { DailyOperationService } from '#services-shared/index';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-job-header',
  templateUrl: './job-header.component.html',
  styleUrls: ['./job-header.component.scss'],
})
export class JobHeaderComponent implements OnInit {
  //#region 'Angular Life Cycle'
  constructor(public _sectionDailyOperation: DailyOperationService) {}

  ngOnInit() {
    if (!this._sectionDailyOperation.selectedJob) {
      this._sectionDailyOperation.getJobByID();
    }
  }
  //#endregion 'Angular Life Cycle'
}
