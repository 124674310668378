import { ComponentsModule } from '#components/components.module';
import { environment } from '#environments/environment';
import { AuthInterceptor, DatetimeParseInterceptor } from '#services-inter/index';
import { AuthService, LoadingIndicatorService } from '#services-shared/index';
import { SharedModule } from '#services-shared/shared.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { Adal6HTTPService, Adal6Service } from 'adal-angular6';
import { RatingModule } from 'primeng/rating';
import { Observable } from 'rxjs';
import {
  AppComponent,
  FeedbackComponent,
  FooterComponent,
  HeaderComponent,
  HelpComponent,
  LoadingIndicatorComponent,
  VersionComponent,
  WelcomeComponent,
} from './';
import { AppRoutingModule } from './app.routing.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    WelcomeComponent,
    LoadingIndicatorComponent,
    FeedbackComponent,
    VersionComponent,
    HelpComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentsModule,
    RatingModule,
  ],
  providers: [
    Adal6Service,
    {
      provide: Adal6HTTPService,
      useFactory: Adal6HTTPService.factory,
      deps: [HttpClient, Adal6Service],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DatetimeParseInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      deps: [AuthService, Adal6Service],
      useFactory: (authSvc, adalSvc) => {
        return () => {
          adalSvc.init(environment.adalConfig);
          adalSvc.handleWindowCallback();

          if (adalSvc.userInfo.authenticated) {
            return authSvc.loadUserInfo();
          } else {
            adalSvc.login(true);
            return Observable.of().toPromise();
          }
        };
      },
      multi: true,
    },
    LoadingIndicatorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
