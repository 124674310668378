<!-- Header -->
<div class="box-shadow module">
  <div class="row">
    <div class="col-xs-7">
      <app-well-header *ngIf="_sectionCasing.wellID != ''" [wellId]="_sectionCasing.wellID"> </app-well-header>
    </div>
    <div class="col-xs-5 headerSection">
      <app-section-header *ngIf="_sectionCasing.wellBoreSectionID != ''"> </app-section-header>
    </div>
  </div>
</div>
<!-- Header -->

<!-- Content -->
<div class="row box-shadow module">
  <!-- Toolbar -->
  <div class="row table-responsive">
    <table
      class="table table-condensed noborder"
      *ngIf="!!_sectionCasing.lstCasingDescription && !!_sectionCasing.lstCasingComponentColumns"
    >
      <thead>
        <tr>
          <td class="col-sm-2">
            <label>Casing Description</label><br />
            <select
              id="ddl_casing_description"
              class="form-control"
              [(ngModel)]="selectedDesc"
              (change)="casingDescriptionChange($event.target.value)"
            >
              <option *ngFor="let item of _sectionCasing.lstCasingDescription">
                {{ item.Description }}
              </option>
            </select>
          </td>
          <td class="col-sm-10"></td>
        </tr>
      </thead>
    </table>
  </div>
  <!-- Toolbar -->

  <!-- Casing Sections -->
  <div class="row" [ngStyle]="{ visibility: disableCasingComponents ? 'hidden' : 'visible' }">
    <!-- Segments -->
    <ul class="nav nav-tabs nav-justified nav-fill">
      <li [ngClass]="tabName === 'inspect' ? 'nav-item active' : 'nav-item'">
        <a id="tab_inventory" data-toggle="tab" href="#inspect" class="active segment" (click)="inspectTabClick()"
          >Casing Inventory</a
        >
      </li>
      <li [ngClass]="tabName === 'casingrunning' ? 'nav-item active' : 'nav-item'">
        <a id="tab_running" data-toggle="tab" href="#casingrunning" class="segment" (click)="casingRunningTabClick()"
          >Casing Running Tally</a
        >
      </li>
      <li *ngIf="false" [ngClass]="tabName === 'cementvolume' ? 'nav-item active' : 'nav-item'">
        <a id="tab_cement" data-toggle="tab" href="#cementvolume" class="segment" (click)="cementVolumeTabClick()"
          >Cement Volume</a
        >
      </li>
    </ul>
    <!-- Segments -->

    <!-- Content -->
    <div class="tab-content">
      <!-- Casing Inventory -->
      <div
        id="inspect"
        [ngClass]="
          tabName === 'inspect' ? 'tab-pane fade in active box-shadow module' : 'tab-pane fade in box-shadow module'
        "
      >
        <app-inventory
          #casingInventory
          *ngIf="_sectionCasing.casingString"
          [wellId]="_sectionCasing.wellID"
          [Verify_CasingComponents_NotValidated]="Verify_CasingComponents_NotValidated"
          [tmpSelectedCasingComponent]="tmpSelectedCasingComponent"
          [tmpSelectedIndex]="tmpSelectedIndex"
          (message)="showMessage($event)"
          (validateCasing)="validateCasing($event)"
          (threadOffValues)="threadOffValues($event)"
          (updtCasingComponent)="updtCasingComponent($event)"
          (updateMultipleEdit)="updateMultipleEdit($event)"
        >
        </app-inventory>
      </div>
      <!-- Casing Inventory -->

      <!-- Casing Running Tally -->
      <div
        id="casingrunning"
        [ngClass]="
          tabName === 'casingrunning'
            ? 'tab-pane fade in active box-shadow module'
            : 'tab-pane fade in box-shadow module'
        "
      >
        <app-running
          #casingRunning
          *ngIf="_sectionCasing.casingString"
          [tmpSelectedIndex]="tmpSelectedIndex"
          [tmpSelectedCasingComponent]="tmpSelectedCasingComponent"
          (threadOffValues)="threadOffValues($event)"
          (message)="showMessage($event)"
          (updtCasingComponent)="updtCasingComponent($event)"
          (updtToggleArrayInfo)="updtToggleArrayInfo($event)"
        >
        </app-running>
      </div>
      <!-- Casing Running Tally -->

      <!-- Cement Volume -->
      <div
        *ngIf="false"
        id="cementvolume"
        [ngClass]="
          tabName === 'cementvolume'
            ? 'tab-pane fade in active box-shadow module'
            : 'tab-pane fade in box-shadow module'
        "
      >
        <app-cement-volume #cementVolume *ngIf="_sectionCasing.casingString" (message)="showMessage($event)">
        </app-cement-volume>
      </div>
      <!-- Cement Volume -->
    </div>
    <!-- Content -->
  </div>
  <!-- Casing Sections -->
</div>

<!-- Toast -->
<p-toast position="center" key="c" (onClose)="onReject()" [modal]="true" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="tac">
      <i [ngClass]="{ warningToast: isRunToast() }" class="pi pi-exclamation-triangle fontSize3m"></i>
      <h3 [ngClass]="{ warningToast: isRunToast() }">{{ message.summary }}</h3>
      <p [ngClass]="{ segment: isRunToast() }">{{ message.detail }}</p>
      <span *ngIf="setToastType === 'Finalize'">Run Date/Time:</span>
      <input
        id="dte_toast_msg_date"
        class="ml6"
        *ngIf="setToastType === 'Finalize'"
        type="datetime-local"
        id="meeting-time"
        name="meeting-time"
        [(ngModel)]="runDateTime"
      />
      <br *ngIf="runDateTimeNotSelected" />
      <span *ngIf="runDateTimeNotSelected" class="warningToast">Please select a Run Date</span>
    </div>
    <div class="ui-g ui-fluid">
      <div class="ui-g-6">
        <button
          id="btn_toast_msg_confirm"
          type="button"
          pButton
          (click)="onConfirm()"
          label="Yes"
          class="ui-button-info"
        ></button>
      </div>
      <div class="ui-g-6">
        <button
          id="btn_toast_msg_reject"
          type="button"
          pButton
          (click)="onReject()"
          label="No"
          class="ui-button-secondary"
        ></button>
      </div>
    </div>
  </ng-template>
</p-toast>

<p-toast [style]="{ marginTop: '40px !important' }" key="casingNotify" [baseZIndex]="6000"></p-toast>
<p-toast
  [style]="{ marginTop: '40px !important' }"
  key="casingError"
  [baseZIndex]="6000"
  position="top-center"
></p-toast>
<!-- Toast -->
