import { Field } from '#models/shared/field';

export class OfflineActivityDates {
  IdRec: Field = new Field();
  WellId: Field = new Field();
  OAId: Field = new Field();

  StartDate: Field = new Field();
  EndDate: Field = new Field();
  Comment: Field = new Field();
  selected?: Boolean = false;
}
