import { AuthService } from '#services-shared/index';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  //#region 'Variables'
  public userName: string;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(private authSvc: AuthService) {}

  ngOnInit() {
    this.userName = this.authSvc.getFullName();
  }
  //#endregion 'Angular Life Cycle'
}
