<!-- Header -->
<div class="box-shadow module">
  <div class="row">
    <div class="col-xs-7">
      <app-well-header
        *ngIf="_sectionDailyOperation.wellId != ''"
        [wellId]="_sectionDailyOperation.wellId"
      ></app-well-header>
    </div>
    <div class="col-xs-5 headerSection">
      <app-job-header> </app-job-header>
    </div>
  </div>
</div>
<!-- Header -->

<!-- Content -->
<div class="box-shadow module">
  <!-- Daily Sections -->
  <div class="row">
    <!-- Segments -->
    <ul class="nav nav-tabs nav-justified nav-fill">
      <li [ngClass]="tabName === 'dailyOperations' ? 'nav-item active' : 'nav-item'">
        <a
          id="tab_inventory"
          data-toggle="tab"
          href="#dailyOperations"
          class="active segment"
          (click)="dailyOperations()"
          >Daily Operations</a
        >
      </li>
      <li [ngClass]="tabName === 'offlineActivities' ? 'nav-item active' : 'nav-item'">
        <a id="tab_running" data-toggle="tab" href="#offlineActivities" class="segment" (click)="offlineActivity()"
          >Offline Activity</a
        >
      </li>
    </ul>
    <!-- Segments -->

    <!-- Content -->
    <div class="tab-content">
      <!-- Daily Operations -->
      <div
        id="dailyOperations"
        [ngClass]="
          tabName === 'dailyOperations'
            ? 'tab-pane fade in active box-shadow module'
            : 'tab-pane fade in box-shadow module'
        "
      >
        <app-daily-operation></app-daily-operation>
      </div>
      <!-- Daily Operations -->

      <!-- Offline Activity -->
      <div
        id="offlineActivities"
        [ngClass]="
          tabName === 'offlineActivities'
            ? 'tab-pane fade in active box-shadow module'
            : 'tab-pane fade in box-shadow module'
        "
      >
        <app-offline-activity></app-offline-activity>
      </div>
      <!-- Offline Activity -->
    </div>
    <!-- Content -->
  </div>
  <!-- Daily Sections -->
</div>
<!-- Content -->
