<!-- Header -->
<div class="box-shadow module">
  <div class="row">
    <div class="table-responsive">
      <app-well-header *ngIf="wellId != ''" [wellId]="wellId"></app-well-header>
    </div>
  </div>
</div>
<!-- Header -->

<!-- Content -->
<div class="box-shadow module">
  <!-- Notification Msg -->
  <!-- <div class="row">
    <p-messages id="jobs_msg" [(value)]="notifications" [closable]="false"></p-messages>
  </div> -->
  <!-- Notification Msg -->
  <!-- Title -->
  <div class="row">
    <h2>Jobs</h2>
  </div>
  <!-- Title -->
  <div class="row">
    <div class="col-sm-8"></div>
    <!-- Sub-Title -->
    <div class="col-sm-2">
      <h4 class="pull-right"><span class="boldTextFont">Wellbore :</span></h4>
    </div>
    <!-- Sub-Title -->
    <!-- Wellbore -->
    <div class="col-sm-2">
      <select class="form-control" [(ngModel)]="selectedWellBore">
        <option *ngFor="let item of wellBoreData" [ngValue]="item">{{ item.Name }}</option>
      </select>
    </div>
    <!-- Wellbore -->
  </div>
  <!-- Table -->
  <div class="row" style="height: 434px; max-height: 434px">
    <table
      *ngIf="jobsData && jobsData.length > 0"
      class="table table-striped table-hover table-responsive text-center table-condensed"
    >
      <thead class="text-primary text-bold">
        <tr>
          <td class="w17">Job Category</td>
          <td class="w17">Primary Job</td>
          <td class="w17">Actual Start Date</td>
          <td class="w17">Actual End Date</td>
          <td class="w17">Job Spud Date</td>
          <td class="w15">Actions</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let job of jobsData">
          <td class="w17">{{ job.JobCategory.Value }}</td>
          <td class="w17">{{ job.PrimaryJob.Value }}</td>
          <td class="w17">{{ job.ActualStartDate.Value }}</td>
          <td class="w17">{{ job.ActualEndDate.Value }}</td>
          <td class="w17">{{ job.JobSpudDAte.Value }}</td>
          <td class="w15">
            <button id="btn_go_daily" class="btn btn-primary" (click)="goToDaily(job)">Daily Operations</button>
            <button id="btn_go_plan" class="btn btn-primary ml6" (click)="goToPlanActivities(job)">
              Plan Activities
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Table -->
</div>
<!-- Content -->

<!-- Toast -->
<p-toast [style]="{ marginTop: '40px !important' }" key="JobNotify" [baseZIndex]="6000"></p-toast>
<p-toast [style]="{ marginTop: '40px !important' }" key="JobError" [baseZIndex]="6000" position="top-center"></p-toast>
<!-- Toast -->
