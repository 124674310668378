import { BusinessUnit, Organization } from '#models/index';
import { BusinessUnitsApiService, OrganizationApiService } from '#services-api/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
})
export class OrganizationComponent implements OnInit, OnDestroy {
  public newOrganzationName = '';
  public selectedBU: BusinessUnit;
  public lstBusinessUnit: BusinessUnit[] = [];
  public lstOrganization: Organization[] = [];

  //? Subscriptions
  private SUBS$ = new Subscription();

  constructor(private businessUnitsSvc: BusinessUnitsApiService, private organizationSvc: OrganizationApiService) {}

  ngOnInit() {
    this.loadBusinessUnits();
    this.loadOrganizations();
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }

  // Events

  async btnCreateOrganization() {
    let error = false;
    if (!this.newOrganzationName || this.newOrganzationName.length <= 0) {
      alert('Please enter an organization name.');
      error = true;
    }

    if (!error) {
      await this.organizationSvc.addOrganization(this.selectedBU.Id, this.newOrganzationName);
      this.loadOrganizations();
    }
  }

  // Methods

  private loadBusinessUnits(): void {
    this.SUBS$.add(
      this.businessUnitsSvc.getUserAdminBUs().subscribe((data: BusinessUnit[]) => (this.lstBusinessUnit = data))
    );
    if (this.lstBusinessUnit.length > 0) {
      this.selectedBU = this.lstBusinessUnit[0];
    }
  }

  public loadOrganizations(): void {
    if (this.selectedBU) {
      this.SUBS$.add(
        this.organizationSvc.getUserAdminOrgs(this.selectedBU.Id).subscribe((data: Organization[]) => {
          this.lstOrganization = data;
        })
      );
    }
  }
}
