import { environment } from '#environments/environment';
import { ATRProcedure } from '#models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root',
})
export class ATRProcedureApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'Get'
  public getATRByStartEndDate(startDate: string, endDate: string): Observable<ATRProcedure[]> {
    return this.http.get<ATRProcedure[]>(`${environment.apiEndpoint}/atrProcedure/getByStartEndDate`, {
      params: { startDate: startDate, endDate: endDate },
    });
  }
  //#endregion 'Get'
}
