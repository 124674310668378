import { TaskPlan } from '#models/index';
import { TaskPlanApiService } from '#services-api/index';
import { LoadingIndicatorService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-task-plan',
  templateUrl: './task-plan.component.html',
  styleUrls: ['./task-plan.component.scss'],
})
export class TaskPlanComponent implements OnInit, OnDestroy {
  public taskPlans: TaskPlan[];

  //? Subscriptions
  private SUBS$ = new Subscription();

  constructor(
    private taskPlanSvc: TaskPlanApiService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.loadTaskPlans();
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }

  public onRedirectButtonClick(sufix: string, id: number): void {
    if (id !== undefined && id != null) {
      this._router.navigate(['admin/task-plan-' + sufix, { id: id }]);
    } else {
      this._router.navigate(['admin/task-plan-' + sufix]);
    }
  }

  private loadTaskPlans(): void {
    this.loadingIndicatorSvc.show();

    this.SUBS$.add(
      this.taskPlanSvc
        .getTaskPlans()
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data: TaskPlan[]) => (this.taskPlans = data))
    );
  }
}
