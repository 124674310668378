import { Field, NotificationToast, OfflineActivityTimelog, SharePointAttach, SharepointFiles } from '#models/index';
import { AttachmentFiles } from '#models/jobs/offline-activity/attachment-files';
import { AttachmentApiService, NotificationsApiService } from '#services-api/index';
import { DailyOperationService, LoadingIndicatorService, TooltipService } from '#services-shared/index';
import { OfflineActivityService } from '#services-shared/jobs/offline-activity.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-timelog-oa',
  templateUrl: './timelog-oa.component.html',
  styleUrls: ['../../timelog.shared.scss'],
  providers: [MessageService],
})
export class TimeLogOAComponent implements OnInit, OnDestroy {
  //#region 'Input, Output, ViewChild'
  @Input() public startDate: string;
  @Input() public endDate: string;

  @Output() public message: EventEmitter<any> = new EventEmitter<any>();
  //#endregion 'Input, Output, ViewChild'

  //#region 'Variables'
  public isTimeUpdate: boolean;
  public showCreateNewEntry: boolean;
  public showErrorcreateTML: boolean;
  public showErrorDuration: boolean;
  public showErrorDuplicatedFiles: boolean;
  public displayActivityConfigurations: boolean;
  public activityToEdit: Field;

  //? Forms
  public formOAT: FormGroup;

  //? Attachments
  public setFileImportNotify: boolean;
  public showAttachments = false;
  public showErrorAttachments = false;
  public attachments: SharePointAttach[] = [];
  public files: AttachmentFiles[] = [];
  public filesToUpload: File[] = [];

  //? Email Notification
  public emailToAdd: string = '';
  public showEmailNotification: boolean = false;

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    public _sectionDailyOperation: DailyOperationService,
    public _offlineActivity: OfflineActivityService,
    public _AttachmentAPI: AttachmentApiService,
    public _NotificationsAPI: NotificationsApiService,
    private _loader: LoadingIndicatorService,
    public _tooltip: TooltipService,
    private _message: MessageService
  ) {}

  ngOnInit(): void {
    this.loadFormEntry();
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  public loadFormEntry() {
    this.formOAT = new FormGroup({
      duration: new FormControl(1, Validators.required),
      activityCode: new FormControl(''),
      activityDescription: new FormControl(''),
      inactive: new FormControl(false),
      depthStart: new FormControl(0),
      depthEnd: new FormControl(0),
      comments: new FormControl('', Validators.required),
      sysSeq: new FormControl(''),
    });
  }
  //#endregion 'Load'

  //#region 'Modals'
  public openEntryModal(isEdit: boolean) {
    this._offlineActivity.isDOPFull().then(
      (res) => {
        return res;
      },
      (error) => {
        this.showNotification(error);
        return true;
      }
    );

    if (!isEdit) {
      this.activityToEdit = new Field();
    }

    this.isTimeUpdate = isEdit;
    this.showErrorcreateTML = false;
    this.showCreateNewEntry = true;
  }

  public closeEntryModal(resetTime?: boolean) {
    this.formOAT.reset();
    this.showErrorDuration = false;
    this.showCreateNewEntry = false;
    this.showErrorcreateTML = false;
    if (resetTime) this._offlineActivity.getAvailableTime(false);
  }
  //#endregion 'Modals'

  //#region 'SideBar'
  public openActivityCodes() {
    this.displayActivityConfigurations = true;
  }

  public closeActivityCodes() {
    this.displayActivityConfigurations = false;
  }

  public activityCodeSelect(e: any) {
    this.editActivityCode(e.data);
    this.closeActivityCodes();
  }
  //#endregion 'SideBar'

  //#region 'Validation'
  private isTimeLogValid(isEdit: boolean): boolean {
    if (!this.formOAT.valid) {
      this.showErrorcreateTML = true;
      return false;
    } else {
      this.showErrorcreateTML = false;
    }

    const DURATION = this.formOAT.controls['duration'].value;
    if (Number(DURATION) === 0) {
      this.showErrorcreateTML = true;
      return false;
    } else {
      this.showErrorcreateTML = false;
    }

    if (!this.validateDurationAvailable(this.formOAT.controls['duration'].value, isEdit)) {
      return true;
    } else {
      return false;
    }
  }

  private validateDurationAvailable(duration: number, isEdit: boolean): boolean {
    this.showErrorDuration = this._offlineActivity.validateAvailableDuration(duration, isEdit);
    return this.showErrorDuration;
  }
  //#endregion 'Validation'

  //#region 'CRUD'
  private setOAT(isEdit: boolean): OfflineActivityTimelog {
    let dto = new OfflineActivityTimelog();
    if (!isEdit) {
      dto.WellId.Value = this._offlineActivity.wellId;
      dto.OADateId.Value = this._offlineActivity.OADList[this._offlineActivity.selectedOADId].IdRec.Value;
    } else {
      dto = this._offlineActivity.OATList[this._offlineActivity.selectedOATId];
    }

    dto.Duration.Value = this.formOAT.controls['duration'].value;
    dto.Activity.Value = this.formOAT.controls['activityCode'].value;
    dto.InactiveTime.Value = this.formOAT.controls['inactive'].value;
    dto.StartDepth.Value = this.formOAT.controls['depthStart'].value;
    dto.EndDepth.Value = this.formOAT.controls['depthEnd'].value;
    dto.Comment.Value = this.formOAT.controls['comments'].value;
    dto.SysSeq.Value = this.formOAT.controls['sysSeq'].value;
    return dto;
  }

  public createTML() {
    if (this.isTimeLogValid(false)) {
      this._offlineActivity.createOAT(this.setOAT(false)).then(
        (done: any) => {
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
      this.closeEntryModal();
    }
  }

  public updateTML() {
    if (this.isTimeLogValid(true)) {
      this._offlineActivity.updateOAT(this.setOAT(true)).then(
        (done: any) => {
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
      this.closeEntryModal();
    }
  }

  public deleteTML(dto: OfflineActivityTimelog) {
    this._offlineActivity.OATDeleteObject = this._tooltip.deepClone(dto);
    this.message.next({
      key: 'c',
      sticky: true,
      severity: 'warn',
      summary: 'Delete Offline Activity',
      detail: 'Are you sure you want to delete the selected Offline Activity?',
      clear: true,
      toastType: 'DeleteTimeLogOA',
    });
  }
  //#endregion 'CRUD'

  //#region 'General Methods'
  public editTMLOA() {
    this.isTimeUpdate = true;
    this.formOAT.controls['duration'].setValue(this._offlineActivity.OATObject.Duration.Value);
    this.formOAT.controls['depthStart'].setValue(this._offlineActivity.OATObject.StartDepth.Value);
    this.formOAT.controls['depthEnd'].setValue(this._offlineActivity.OATObject.EndDepth.Value);
    this.formOAT.controls['inactive'].setValue(
      this._offlineActivity.getInactive(this._offlineActivity.OATObject.InactiveTime.Value)
    );
    this.formOAT.controls['activityCode'].setValue(this._offlineActivity.OATObject.Activity.Value);
    this.formOAT.controls['activityDescription'].setValue(this._offlineActivity.OATObject.Activity.Help);
    this.formOAT.controls['comments'].setValue(this._offlineActivity.OATObject.Comment.Value);
    this.formOAT.controls['sysSeq'].setValue(this._offlineActivity.OATObject.SysSeq.Value);

    this.activityToEdit = this._offlineActivity.OATObject.Activity;
    this.openEntryModal(true);
  }

  public editTimeLogRecord(dto: OfflineActivityTimelog, index: number) {
    this._offlineActivity.selectedOATId = index;
    this._offlineActivity.OATObject = this._tooltip.deepClone(dto);
    this.editTMLOA();
    this.showCreateNewEntry = true;
  }

  public editActivityCode(dto: any) {
    this.formOAT.controls['activityCode'].setValue(dto.Activity);
    this.formOAT.controls['activityDescription'].setValue(dto.Help);

    this.activityToEdit = new Field();
    this.activityToEdit.Value = dto.Activity;
    this.activityToEdit.Help = dto.Help;
  }
  //#endregion 'General Methods'

  //#region 'Re-Order'
  public timelogUp(index: number) {
    if (index === 0) {
      return;
    }

    this.changeSysSequence(index, index - 1, true);
  }

  public timelogDown(index: number) {
    if (index === this._offlineActivity.OATList.length - 1) {
      return;
    }

    this.changeSysSequence(index, index + 1, false);
  }

  private changeSysSequence(firstIndex: number, secondIndex: number, isUp: boolean) {
    const TML1 = this._offlineActivity.OATList[firstIndex];
    const TML2 = this._offlineActivity.OATList[secondIndex];
    TML1.SysSeq.Value = isUp ? firstIndex.toString() : (secondIndex + 1).toString();
    TML2.SysSeq.Value = isUp ? (firstIndex + 1).toString() : secondIndex.toString();

    const DATATOSAVE: OfflineActivityTimelog[] = [TML1, TML2];
    this._offlineActivity.updateOATBulk(DATATOSAVE).then(
      (done: any) => {
        this.showNotification(done);
      },
      (error) => {
        this.showNotification(error);
      }
    );
  }
  //#endregion 'Re-Order'

  //#region 'Attachments'
  public openAttachments() {
    this.files = [];
    this.showAttachments = true;
  }

  public closeAttachments() {
    this.showAttachments = false;
    this.showErrorDuplicatedFiles = false;
  }

  public removeFile(index: number) {
    const NAME = this.files[index].nameToShow;
    const INDEX = this.filesToUpload.findIndex((x) => x.name === NAME);

    this.filesToUpload.splice(INDEX, 1);
    this.files.splice(index, 1);
  }

  public isUploadDisabled(): boolean {
    if (!this.files || this.files.length === 0) return true;
  }

  public importFileSuccess(ev: any) {
    this.filesToUpload = [...this.filesToUpload, ...ev.files];
    ev.files.forEach((fl: any) => {
      this.showErrorDuplicatedFiles = false;
      const reader = new FileReader();
      reader.readAsArrayBuffer(fl);
      reader.onload = (e: any) => {
        const SIZE = this._tooltip.getFileSize(e.loaded);
        const NAME = fl.name.replace(/\s/g, '_');
        const ISINFILES = this.files.filter((obj) => obj.nameToShow === NAME);
        const ISONLINE = this._offlineActivity.spFilesList.filter((obj) => obj.nameToShow === NAME);
        if (ISINFILES && ISINFILES.length === 0 && ISONLINE && ISONLINE.length === 0) {
          const arrayBuffer = e.target['result'];
          const bytes = new Uint8Array(arrayBuffer);
          this.files.push({
            data: bytes,
            src:
              !fl.type.includes('pdf') && SIZE <= 2
                ? `data:${fl.type};base64,${this._tooltip.convertToBase64(arrayBuffer)}`
                : '',
            name: this.getName(NAME),
            nameToShow: NAME,
            icon: '',
            type: fl.type,
            mb: SIZE,
            size: e.loaded,
          });
        } else {
          this.showErrorDuplicatedFiles = true;
        }
      };
    });
    this.setFileImportNotify = true;
  }

  public async uploadFiles() {
    this.attachments = [];
    this._offlineActivity.spFilesList = [];

    await this.getAttachmentObj(this.files).then(() => {
      this.closeAttachments();
      this.uploadFilesToAzure();
    });
  }

  private getAttachmentObj(file: AttachmentFiles[]) {
    return new Promise((resolve, reject) => {
      try {
        file.forEach((fl) => {
          let dto: SharePointAttach = {
            FileName: fl.name,
            FileNameDisplay: fl.nameToShow,
          };

          this.attachments.push(dto);
          this._offlineActivity.spFilesList.push({
            _url: '',
            name: fl.name,
            nameToShow: fl.nameToShow,
            imgType: fl.type,
            size: fl.size.toString(),
          });
        });
        resolve(true);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  private uploadFilesToAzure() {
    this._loader.show();
    const FDATA = this._tooltip.returnFiles(this.filesToUpload);
    this._offlineActivity.uploadFilesToAzure(FDATA).then(
      () => {
        this._offlineActivity.uploadUrlsToWellView().then(
          (doneW: any) => {
            this._loader.hide();
            this.showNotification(doneW);
          },
          (error) => {
            this._loader.hide();
            this.showNotification(error);
          }
        );
      },
      (error) => {
        this._loader.hide();
        this.showNotification(error);
      }
    );
  }

  public showFile(index: number) {
    this._offlineActivity.downloadFile(index);
  }

  private getName(fileName: string): string {
    return `${this._offlineActivity.OADObject.IdRec.Value}_${fileName}`;
  }

  public typeOfFile(file: AttachmentFiles, index: number) {
    const NAME = file.name.toUpperCase();
    if ((NAME.includes('.PNG') || NAME.includes('.JPG') || NAME.includes('.JPEG')) && file.mb <= 2) {
      return true;
    } else {
      if (NAME.includes('.PDF')) {
        this.files[index].icon = './../../../../../assets/images/pdf_icon.png';
      } else {
        this.files[index].icon = './../../../../../assets/images/png_icon.png';
      }
      return false;
    }
  }
  //#endregion 'Attachments'

  //#region 'Notifications'
  public sendNotification() {
    this._loader.show();
    this.closeEmailNotification();

    this.SUBS$.add(
      this._NotificationsAPI
        .sendNotificationEmail(this._offlineActivity.generateEmailData())
        .finally(() => {
          this._loader.hide();
        })
        .subscribe(() => {
          this.showNotification({
            severity: 'success',
            summary: 'Email Sent.',
            detail: 'An email notification has been sent.',
          });
        })
    );
  }

  public openEmailNotification() {
    this.showEmailNotification = true;
  }

  public closeEmailNotification() {
    this.showEmailNotification = false;
  }

  public deleteEmail(index: number) {
    this._offlineActivity.deleteEmail(index);
  }

  public addEmail() {
    this._offlineActivity.addEmail(this.emailToAdd);
    this.emailToAdd = '';
  }
  //#endregion 'Notifications'

  //#region 'View Files'
  public deleteFile(dto: SharepointFiles, index: number) {
    const idx = this._offlineActivity.OAAList.findIndex((x) => x.IdRec.Value === dto.idRec);
    this._offlineActivity.spFilesList.splice(index, 1);
    this._offlineActivity.deleteOAA(idx).then(
      (dto: any) => {
        this._offlineActivity.deleteFromAzure(dto).then(
          (doneW: any) => {
            this.showNotification(doneW);
          },
          (error) => {
            this.showNotification(error);
          }
        );
      },
      (error) => {
        this.showNotification(error);
      }
    );
  }
  //#endregion 'View Files'

  //#region 'Notifications'
  private showNotification(msg: NotificationToast) {
    this._message.add({
      key: msg && msg.key && msg.key.length > 0 ? msg.key : 'TMLOANotify',
      sticky: msg && msg.key && msg.key != 'TMLOANotify' ? true : false,
      closable: msg && msg.key && msg.key != 'TMLOANotify' ? true : false,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life,
    });
  }
  //#endregion 'Notifications'
}
