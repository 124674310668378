import { TaskPlanSubSection } from '#models/index';
import { TaskPlanApiService } from '#services-api/index';
import { LoadingIndicatorService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-task-plan-sub-section-edit',
  templateUrl: './task-plan-sub-section-edit.component.html',
})
export class TaskPlanSubSectionEditComponent implements OnInit, OnDestroy {
  public taskPlanId: number;
  public taskPlanSubSection: TaskPlanSubSection = new TaskPlanSubSection();
  public displayWarningDesc: boolean;
  public sectionId: number;

  //? Subscriptions
  private SUBS$ = new Subscription();
  constructor(
    private taskPlanSvc: TaskPlanApiService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.SUBS$.add(
      this.aRoute.params.subscribe((v) => {
        this.taskPlanId = v['taskPlanId'];
        this.taskPlanSubSection.ID = v['id'];
        this.sectionId = v['sectionId'];
      })
    );
    this.displayWarningDesc = false;
    this.getTaskPlanSubSectionById(this.taskPlanSubSection.ID);
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }

  public getTaskPlanSubSectionById(id: number): void {
    this.loadingIndicatorSvc.show();
    this.SUBS$.add(
      this.taskPlanSvc
        .getTaskPlanSubSectionById(id)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data: any) => {
          this.taskPlanSubSection = data;
        })
    );
  }

  public onRedirectButtonClick(): void {
    this._router.navigate(['admin/task-plan-manage', { id: this.taskPlanId, sectionId: this.sectionId }]);
  }

  public updateTaskPlanSubSection(): void {
    this.displayWarningDesc = this.taskPlanSvc.isInvalidString(this.taskPlanSubSection.Description);
    if (!this.displayWarningDesc) {
      this.loadingIndicatorSvc.show();
      this.SUBS$.add(
        this.taskPlanSvc
          .updateTaskPlanSubSection(this.taskPlanSubSection)
          .finally(() => this.loadingIndicatorSvc.hide())
          .subscribe((data: any) => {
            this.onRedirectButtonClick();
          })
      );
    }
  }
}
