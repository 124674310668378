import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BusinessUnit } from 'models';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root',
})
export class BusinessUnitsApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/businessUnit${method}`;
  }
  //#endregion 'General'

  //#region 'Get'
  public getUserAdminBUs(): Observable<BusinessUnit[]> {
    return this.http.get<BusinessUnit[]>(this.getURL(`/currentUserAdminBUs`));
  }

  public getUserBUs() {
    return this.http.get<BusinessUnit[]>(this.getURL(`/currentUserBUs`));
  }
  //#endregion 'Get'

  //#region 'Create'
  public addBusinessUnit(businessUnit: BusinessUnit): Observable<BusinessUnit> {
    return this.http.post<BusinessUnit>(this.getURL(), businessUnit);
  }
  //#endregion 'Create'
}
