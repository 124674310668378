import { ComponentsModule } from '#components/components.module';
import { LoadingIndicatorService } from '#services-shared/index';
import { SharedModule } from '#services-shared/shared.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnlyLettersNumbersDirective } from 'directives/only-letters-numbers.directive';
import { OnlyLettersDirective } from 'directives/only-letters.directive';
import { OnlyNumbersDirective } from 'directives/only-numbers.directive';
import { AutosizeModule } from 'ngx-autosize';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { JobHeaderComponent } from './headers/job-header/job-header.component';
import { SectionHeaderComponent } from './headers/section-header/section-header.component';
import { WellHeaderComponent } from './headers/well-header/well-header.component';
import { DailyOperationMainComponent } from './jobs/daily-operations/daily-operation-main.component';
import { DailyOperationComponent } from './jobs/daily-operations/daily-operation/daily-operation.component';
import { SessionDetailComponent } from './jobs/daily-operations/daily-operation/timelog-do/smart-procedures/session-detail/session-detail.component';
import { SmartProceduresComponent } from './jobs/daily-operations/daily-operation/timelog-do/smart-procedures/smart-procedures.component';
import { TimeLogDOComponent } from './jobs/daily-operations/daily-operation/timelog-do/timelog-do.component';
import { AttachmentsComponent } from './jobs/daily-operations/offline-activity/attachments/attachments.component';
import { OfflineActivityComponent } from './jobs/daily-operations/offline-activity/offline-activity.component';
import { TimeLogOAComponent } from './jobs/daily-operations/offline-activity/timelog-oa/timelog-oa.component';
import { JobsComponent } from './jobs/jobs.component';
import { PlanActivitiesComponent } from './jobs/plan-activities/plan-activities.component';
import { CasingTallyComponent } from './sections/casing-tally/casing-tally.component';
import { CementVolumeComponent } from './sections/casing-tally/cement-volume/cement-volume.component';
import { InventoryComponent } from './sections/casing-tally/inventory/inventory.component';
import { RunningComponent } from './sections/casing-tally/running/running.component';
import { SectionsComponent } from './sections/sections.component';
import { EnvironmentInfoComponent } from './settings/environment/environment.component';
import { SettingsComponent } from './settings/settings.component';
import { DocumentationComponent } from './shared/documentation/documentation.component';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';
import { HomeComponent } from './shared/home/home.component';
import { LoginComponent } from './shared/login/login.component';
import { ViewAttachmentsComponent } from './shared/view-attachments/view-attachments.component';
import { WellsComponent } from './wells/wells.component';

@NgModule({
  declarations: [
    HomeComponent,
    DocumentationComponent,
    LoginComponent,
    WellsComponent,
    SectionsComponent,
    WellHeaderComponent,
    SectionHeaderComponent,
    JobHeaderComponent,
    SettingsComponent,
    FileUploadComponent,
    CasingTallyComponent,
    InventoryComponent,
    RunningComponent,
    CementVolumeComponent,
    DailyOperationComponent,
    DailyOperationMainComponent,
    TimeLogDOComponent,
    JobsComponent,
    SessionDetailComponent,
    SmartProceduresComponent,
    OnlyLettersDirective,
    OnlyLettersNumbersDirective,
    OnlyNumbersDirective,
    EnvironmentInfoComponent,
    PlanActivitiesComponent,
    OfflineActivityComponent,
    TimeLogOAComponent,
    AttachmentsComponent,
    ViewAttachmentsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    ScrollingModule,

    // PrimeNG
    MessagesModule,
    MessageModule,
    CheckboxModule,
    ToastModule,
    SidebarModule,
    TableModule,
    AutoCompleteModule,
    DialogModule,
    FileUploadModule,
    OverlayPanelModule,
    CalendarModule,
    InputTextareaModule,
    MultiSelectModule,
    ToggleButtonModule,
    LightboxModule,

    // Autosize
    AutosizeModule,
  ],
  providers: [LoadingIndicatorService],
})
export class PagesModule {}
