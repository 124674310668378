import { environment } from '#environments/environment';
import { Organization, OrganizationUser } from '#models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root',
})
export class OrganizationApiService {
  //#region 'Variables'
  private returnData: any;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/organization${method}`;
  }
  //#endregion 'General'

  //#region 'Get'
  public getUserAdminOrgs(businessUnitId: number): Observable<Organization[]> {
    return this.http.get<Organization[]>(this.getURL(`/currentUserAdminOrgs/${businessUnitId}`));
  }

  public getUserOrgs(businessUnitId: number): Observable<Organization[]> {
    return this.http.get<Organization[]>(this.getURL(`/currentUserOrgs/${businessUnitId}`));
  }

  public getOrganization(orgId: number): Observable<Organization[]> {
    return this.http.get<Organization[]>(this.getURL(`/${orgId}`));
  }
  //#endregion 'Get'

  //#region 'Create'
  public async addOrganization(businessUnitId: number, organizationName: string): Promise<Organization> {
    return new Promise<Organization>(async (resolve, reject) => {
      const bodyData = {
        Name: organizationName,
        BusinessUnitId: businessUnitId,
      };

      this.returnData = await this.http.post(this.getURL(), bodyData).toPromise();
      if (this.returnData) {
        resolve(this.returnData);
      } else {
        resolve();
      }
    });
  }

  public addOrganizationUser(orgUsr: OrganizationUser): Observable<OrganizationUser> {
    return this.http.post<OrganizationUser>(this.getURL(`/addOrganizationUser`), orgUsr);
  }
  //#endregion 'Create'

  //#region 'Update'
  public updateOrganizationUser(orgUsr: OrganizationUser): Observable<OrganizationUser> {
    return this.http.put<OrganizationUser>(this.getURL(`/updateOrganizationUser`), orgUsr);
  }
  //#endregion 'Update'

  //#region 'Delete'
  public removeOrganizationUser(id: number): Observable<OrganizationUser> {
    return this.http.put<OrganizationUser>(this.getURL(`/removeOrganizationUser/${id}`), null);
  }
  //#endregion 'Delete'
}
