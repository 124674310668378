import { Component, OnInit } from '@angular/core';
declare var require: any;

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent implements OnInit {
  //#region 'Variables'
  public version: string = require('../../../package.json').version;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor() {}

  ngOnInit() {}
  //#endregion 'Angular Life Cycle'
}
