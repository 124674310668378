import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
})
export class GenericErrorComponent implements OnInit {
  //#region 'Angular Life Cycle'
  constructor() {}

  ngOnInit() {}
  //#endregion 'Angular Life Cycle'
}
