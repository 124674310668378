import { Field } from '../../shared/field';

export class DailyOperation {
  WellId: Field = new Field();
  JobId: Field = new Field();
  DailyOperationId: Field = new Field();
  ReportStartDate: Field = new Field();
  ReportEndDate: Field = new Field();
  OperationsSummary: Field = new Field();
  StatusReportingTime: Field = new Field();
  ProjectedOperations: Field = new Field();
  RigTime: Field = new Field();
  selected?: Boolean = false;
}
