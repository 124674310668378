<div class="box-shadow module">
  <!-- Title -->
  <div class="row">
    <h2 class="text-center">Wells</h2>
  </div>
  <!-- Title -->

  <!-- Table -->
  <div class="row" *ngIf="currentWellData && currentWellData.length > 0">
    <table class="table table-striped table-hover table-responsive text-center table-condensed">
      <thead class="text-primary text-bold">
        <tr>
          <td class="w15">Name</td>
          <td class="w15">Business Unit</td>
          <td class="w8">Area</td>
          <td class="w8">Field</td>
          <td class="w10">Spud Date</td>
          <td class="w10">Pad</td>
          <td class="w10">RKB Elevation</td>
          <td class="w10">Ground Elevation</td>
          <td class="w14"></td>
        </tr>
      </thead>
    </table>
    <cdk-virtual-scroll-viewport itemSize="40" style="height: 562px; max-height: 562px">
      <table class="table table-striped table-hover table-responsive text-center table-condensed">
        <tbody>
          <tr *cdkVirtualFor="let well of currentWellData">
            <td class="w15">{{ well.Name }}</td>
            <td class="w15">{{ well.BusinessUnit }}</td>
            <td class="w8">{{ well.Area }}</td>
            <td class="w8">{{ well.FieldName }}</td>
            <td class="w10">{{ well.OriginalSpudDate }}</td>
            <td class="w10">{{ well.PlatformJacketPad }}</td>
            <td class="w10">{{ well.CurrentRKBElevation }}</td>
            <td class="w10">{{ well.GroundElevation }} ft</td>
            <td class="w14">
              <button id="btn_sections" class="btn btn-primary" (click)="wellDetailsClickSections(well)">
                Sections
              </button>
              <button
                id="btn_jobs"
                style="margin-left: 8px"
                class="btn btn-primary"
                (click)="wellDetailsClickJobs(well)"
              >
                Jobs
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
  <!-- Table -->

  <!-- Toast -->
  <p-toast
    [style]="{ marginTop: '40px !important' }"
    key="wellsError"
    [baseZIndex]="6000"
    position="top-center"
  ></p-toast>
  <!-- Toast -->
</div>
