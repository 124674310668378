<div>
  <!-- Title -->
  <div class="row">
    <div class="col-md-12">
      <span class="title">Smart Procedures</span>
      <hr />
    </div>
  </div>
  <!-- Title -->
  <!-- Search -->
  <div class="row">
    <div class="col-md-4">
      <input
        id="txt_search_proc"
        type="text"
        class="form-control"
        placeholder="Procedure name to find"
        [(ngModel)]="filterText"
        (change)="filterByName()"
      />
    </div>
    <div class="col-md-1">
      <button id="btn_reset" type="button" class="btn btn-primary normalText" (click)="resetToOriginal()">
        <span>Reset</span>
      </button>
    </div>
    <div class="col-md-7"></div>
  </div>
  <!-- Search -->
  <br />
  <!-- Title -->
  <div class="row mb8">
    <div class="col-md-12">
      <span><b>Select a procedure to find sessions</b></span>
    </div>
  </div>
  <!-- Title -->
  <!-- Table -->
  <div class="row">
    <!-- Master Table Header -->
    <div class="col-md-12 text-left table-responsive">
      <table class="table table-striped noborder noMarginBottom">
        <thead>
          <tr>
            <td class="w40 no-padding-lr">
              <span class="sub-title"> Name</span>
            </td>
            <td class="w40 no-padding-lr">
              <span class="sub-title"> Number </span>
            </td>
            <td class="w20 no-padding-lr">
              <span class="sub-title">Sessions</span>
            </td>
          </tr>
        </thead>
      </table>
    </div>
    <!-- Master Table Header -->
    <!-- Master Table Body -->
    <div class="col-md-12 text-left table-responsive" style="max-height: 685px">
      <table id="master" class="table table-striped noborder">
        <tbody>
          <ng-container *ngFor="let smp of _smartProcedures.smartProcedureDATA; let masterIndex = index">
            <!-- Master Table INFO -->
            <tr
              id="tr_smp_0{{ masterIndex }}"
              class="cursor-point"
              (click)="showDetail(masterIndex)"
              [ngClass]="{ 'master-selected': smp.showDetail }"
            >
              <td class="w40">
                <span>{{ smp.Name }}</span>
              </td>
              <td class="w40">
                <span>{{ smp.Number }}</span>
              </td>
              <td class="w20">
                <div>
                  <span>{{ smp.Sessions.length }}</span>
                  <i *ngIf="!smp.showDetail" class="pi pi-caret-right mr12 fr"></i>
                  <i *ngIf="smp.showDetail" class="pi pi-caret-down mr12 fr"></i>
                </div>
              </td>
            </tr>
            <!-- Master Table INFO -->
            <!-- Detail -->
            <tr *ngIf="smp.showDetail" [ngClass]="{ 'master-selected': smp.showDetail }">
              <td colspan="3">
                <!-- Detail Table -->
                <table id="detail" class="table">
                  <!-- Detail Table Header -->
                  <thead>
                    <tr>
                      <td class="w50">
                        <span class="sub-title">Session Name</span>
                      </td>
                      <td class="w30">
                        <span class="sub-title">Start Date</span>
                      </td>
                      <td class="w20">
                        <span class="sub-title">Status</span>
                      </td>
                    </tr>
                  </thead>
                  <!-- Detail Table Header -->
                  <!-- Detail Table Body -->
                  <tbody>
                    <ng-container *ngFor="let det of smp.Sessions; let sessionIndex = index">
                      <tr
                        id="tr_det_0{{ sessionIndex }}"
                        class="cursor-point"
                        (click)="sessionDetail(masterIndex, sessionIndex)"
                        [ngClass]="{ 'session-selected': det.showDetail }"
                      >
                        <td class="w50">
                          <span>{{ det.Name }}</span>
                        </td>
                        <td class="w30">
                          <span>{{ det.StartDate | date: 'medium' }}</span>
                        </td>
                        <td class="w20">
                          <span class="divCenter2 fl"
                            ><i *ngIf="det.StatusByNumber === 1" class="pi pi-check-circle statusComplete"></i>
                            <i *ngIf="det.StatusByNumber === 2" class="pi pi-question-circle statusAbandoned"></i>
                            <i *ngIf="det.StatusByNumber === 3" class="pi pi-minus-circle statusPaused"></i>
                            <i *ngIf="det.StatusByNumber === 4" class="pi pi-chevron-circle-right statusInProgress"></i>
                            <i *ngIf="det.StatusByNumber > 4" class="pi pi-info-circle statusDefault"></i>
                            {{ det.Status }}</span
                          >
                          <span class="divCenter2 fr">
                            <i *ngIf="!det.showDetail" class="pi pi-caret-right"></i>
                            <i *ngIf="det.showDetail" class="pi pi-caret-down"></i>
                          </span>
                        </td>
                      </tr>
                      <!-- Selected Session Detail -->
                      <tr *ngIf="det.showDetail" [ngClass]="{ 'session-selected': det.showDetail }">
                        <td colspan="3">
                          <table class="table" [ngClass]="{ 'session-selected': det.showDetail }">
                            <thead>
                              <tr>
                                <td class="sub-title w80">Session date</td>
                                <td class="sub-title w20">Duration</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="w80">
                                  <span>{{ det.StartDate | date: 'medium' }}</span>
                                </td>
                                <td class="w20">
                                  <div class="fl">
                                    <span class="divCenter2">{{ _smartProcedures.stepsTotalDuration }}</span>
                                  </div>
                                  <div class="fl" style="margin-left: 8px">
                                    <span class="divCenter2">hr</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <hr />
                          <app-session-detail
                            [sessionsData]="det.Steps"
                            (addStep)="onAddStep($event)"
                          ></app-session-detail>
                        </td>
                      </tr>
                      <!-- Selected Session Detail -->
                    </ng-container>
                  </tbody>
                  <!-- Detail Table Body -->
                </table>
                <!-- Detail Table -->
              </td>
            </tr>
            <!-- Detail -->
          </ng-container>
        </tbody>
      </table>
    </div>
    <!-- Master Table Body -->
  </div>
  <!-- Table -->
</div>
