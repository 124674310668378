<!-- Header Info -->
<div class="row">
  <div class="col-md-10">
    <span><b>Select checkbox to add steps you did during timelog entry</b></span>
  </div>
  <div class="col-md-2">
    <button
      id="btn_add_steps"
      type="button"
      class="btn btn-primary normalText"
      (click)="onAddSteps()"
      [disabled]="showTimeWarning"
    >
      <span class="ml6">Add Steps</span>
    </button>
  </div>
</div>
<div class="row" *ngIf="showTimeWarning">
  <br />
  <div class="col-md-12">
    <span class="warningLabel"
      ><b>Please un-select one or more steps, the current Duration exceeds the current Timelog time available</b></span
    >
  </div>
</div>
<br />
<!-- Header Info -->
<div class="row">
  <div class="col-md-12">
    <table class="table bg-trans">
      <thead>
        <tr>
          <td class="w5"></td>
          <td class="w5"><span class="sub-title">Add?</span></td>
          <td class="w76"><span class="sub-title">Steps</span></td>
          <td class="w14"><span class="sub-title">Duration</span></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ss of sessionsData; let idx = index">
          <td class="w5">
            <span>{{ idx + 1 }}</span>
          </td>
          <td class="w5">
            <p-checkbox
              id="ccb_select_0{{ idx }}"
              name="multiEdit"
              binary="true"
              [(ngModel)]="ss.Add"
              (onChange)="onSelectedStep(idx)"
            >
            </p-checkbox>
          </td>
          <td class="w76">
            <div class="row">
              <span>{{ ss.Name }}</span>
            </div>
            <div class="row">
              <i class="pi pi-circle-on green-icon"></i
              ><span class="ml8"
                ><b>{{ ss.EventType }}</b></span
              ><br />
              <span>{{ ss.SignOffBy }} - {{ ss.EventDate | date: 'medium' }}</span>
            </div>
          </td>
          <td class="w14">
            <div class="fl">
              <span class="divCenter2">{{ ss.Duration }}</span>
            </div>
            <div class="fl" style="margin-left: 8px">
              <span class="divCenter2">hr</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
