import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SectionDataService {
  //#region 'Variables'
  private validatedInspect: boolean;
  private validatedCasings: string[] = [];
  private importedFileData: any[];
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor() {}
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public setvalidatedInspect(validate: any) {
    this.validatedInspect = validate;
    this.setvalidatedCasings('inspect');
  }

  public getvalidatedInspect() {
    return this.validatedInspect;
  }

  public setvalidatedCasings(value: any) {
    this.validatedCasings.push(value);
  }

  public getvalidatedCasings() {
    if (this.validatedCasings.indexOf('inspect') !== -1) {
      this.setvalidatedInspect(true);
    }
    return this.validatedCasings;
  }

  public setImportFileData(value: any) {
    this.importedFileData = value;
  }

  public getImportFileData() {
    return this.importedFileData;
  }
  //#endregion 'General Methods'
}
