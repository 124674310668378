import { ATRStep } from '#models/index';
import { DailyOperationService, SmartProceduresService } from '#services-shared/index';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-session-detail',
  templateUrl: './session-detail.component.html',
  styleUrls: ['./../smart-procedures.component.scss'],
})
export class SessionDetailComponent implements OnInit {
  //#region 'Input, Output, ViewChild'
  @Input() public sessionsData: ATRStep[];
  @Output() public addStep: EventEmitter<any[]> = new EventEmitter<any[]>();
  //#endregion 'Input, Output, ViewChild'

  //#region 'Variables'
  public isAddActivated: boolean = true;
  public showTimeWarning: boolean = false;
  public totalTimeSelected: number = 0;
  public totalTimeReaming: number = 0;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private _smartProcedures: SmartProceduresService,
    private _sectionDailyOperation: DailyOperationService
  ) {}

  ngOnInit(): void {
    const RIG_TIME = Number(this._sectionDailyOperation.DOPObject.RigTime.Value);
    this.totalTimeReaming = RIG_TIME - this._sectionDailyOperation.TMLTime;
  }
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public onAddSteps() {
    const ADD = this.sessionsData.filter((obj) => obj.Add);
    const STEP: any[] = [];

    ADD.forEach((element) => {
      STEP.push(element);
    });
    this.addStep.next(STEP);
  }

  public onSelectedStep(index: number) {
    const ADD = this.sessionsData[index].Add;

    if (ADD) {
      this.totalTimeSelected = this.totalTimeSelected + this.sessionsData[index].Duration;
      this._smartProcedures.stepsTotalDuration =
        this._smartProcedures.stepsTotalDuration + this.sessionsData[index].Duration;
    } else {
      this.totalTimeSelected = this.totalTimeSelected - this.sessionsData[index].Duration;
      this._smartProcedures.stepsTotalDuration =
        this._smartProcedures.stepsTotalDuration - this.sessionsData[index].Duration;
    }

    this.validateOVERLOAD();
  }

  public validateOVERLOAD() {
    const AVAILABLE = this.totalTimeReaming - this.totalTimeSelected;
    if (AVAILABLE < 0) {
      this.showTimeWarning = true;
    } else {
      this.showTimeWarning = false;
    }
  }
  //#endregion 'General Methods'
}
