import { APIConfiguration } from '#models/index';
import { WellsApiService } from '#services-api/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-environment-info',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.scss'],
})
export class EnvironmentInfoComponent implements OnInit, OnDestroy {
  //#region 'Variables'
  public jsonConfig: any;

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(private _well: WellsApiService, private _router: Router) {}

  ngOnInit() {
    this.checkEnvironment();
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public checkEnvironment() {
    this.SUBS$.add(
      this._well.getApiConfigurationInfo().subscribe((dt: APIConfiguration) => {
        this.jsonConfig = {
          AngularEnvName: environment.environmentName,
          AngularEnvEndPoint: environment.apiEndpoint,
          APIWellViewServer: dt.ApiWVServer,
          APIWellViewDataBase: dt.ApiWVDatabase,
          APIDCCALCServer: dt.ApiDCServer,
          APIDCCALCDataBase: dt.ApiDCDatabase,
          APIATRServer: dt.ApiATRServer,
          APIATRDataBase: dt.ApiATRDatabase,
        };
      })
    );
  }

  public returnToSettings() {
    this._router.navigate(['settings']);
  }
  //#endregion 'General Methods'
}
