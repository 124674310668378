import { LoadingIndicatorService } from '#services-shared/index';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent implements OnInit {
  //#region 'Angular Life Cycle'
  constructor(public loadingIndicatorSvc: LoadingIndicatorService) {}

  ngOnInit() {}
  //#endregion 'Angular Life Cycle'
}
