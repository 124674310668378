import { SmartProceduresService } from '#services-shared/index';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-smart-procedures',
  templateUrl: './smart-procedures.component.html',
  styleUrls: ['./smart-procedures.component.scss'],
})
export class SmartProceduresComponent implements OnInit {
  //#region 'Input, Output, ViewChild'
  @Output() public addStep: EventEmitter<any[]> = new EventEmitter<any[]>();
  //#endregion 'Input, Output, ViewChild'

  //#region 'Variables'
  public filterText: string;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(public _smartProcedures: SmartProceduresService) {}

  ngOnInit(): void {}
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public showDetail(index: number) {
    this._smartProcedures.showSPDetail(index);
  }

  public sessionDetail(procedureIndex: number, sessionIndex: number) {
    this._smartProcedures.openSession(procedureIndex, sessionIndex);
  }

  public filterByName() {
    this._smartProcedures.filterByName(this.filterText);
  }

  public resetToOriginal() {
    this.filterText = '';
    this.filterByName();
  }
  //#endregion 'General Methods'

  //#region 'Session Component'
  public onAddStep(event: any[]) {
    this.addStep.next(event);
  }
  //#endregion 'Session Component'
}
