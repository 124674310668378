import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  //#region 'Variables'
  public enableNavbar: boolean = true;
  //#endregion 'Variables'

  constructor() {}

  public GetDuration(
    startDate: string,
    endDate: string,
    startTime: string,
    endTime: string,
    language: string,
    culture?: string
  ): string {
    moment.locale(culture && culture.length > 0 ? culture : 'en');
    let timespan: string = null;
    let day: string = null;
    let days: string = null;

    const dateIni = `${startDate}T${startTime}:00Z`;
    const dateFin = `${endDate}T${endTime}:00Z`;

    if (language === 'es') {
      day = 'día';
      days = 'dias';
    } else if (language === 'en') {
      day = 'day';
      days = 'days';
    }

    // Convert dates to Moment
    const DTEINI = moment(dateIni);
    const DTEFIN = moment(dateFin);

    // Get dates difference
    const duration = moment.duration(DTEFIN.diff(DTEINI));
    const _days = DTEFIN.diff(DTEINI, 'days');
    const _hrs = duration.get('hours');
    const _mins = duration.get('minutes');

    if (_days > 0 && _hrs === 0 && _mins === 0) {
      if (_days === 1) {
        timespan = `${_days} ${day}`;
      } else {
        timespan = `${_days} ${days}`;
      }
    } else if (_days > 0 && _hrs > 0 && _mins === 0) {
      if (_days === 1) {
        timespan = `${_days} ${day}, ${_hrs} hrs`;
      } else {
        timespan = `${_days} ${days}, ${_hrs} hrs`;
      }
    } else if (_days > 0 && _hrs > 0 && _mins > 0) {
      if (_days === 1) {
        timespan = `${_days} ${day}, ${_hrs} hrs, ${_mins} mins`;
      } else {
        timespan = `${_days} ${days}, ${_hrs} hrs, ${_mins} mins`;
      }
    } else if (_days === 0 && _hrs > 0 && _mins > 0) {
      timespan = `${_hrs} hrs, ${_mins} mins`;
    } else if (_days === 0 && _hrs > 0 && _mins === 0) {
      if (_hrs === 1) {
        timespan = `${_hrs} hr`;
      } else {
        timespan = `${_hrs} hrs`;
      }
    } else if (_days === 0 && _hrs === 0 && _mins > 0) {
      if (_mins === 1) {
        timespan = `${_mins} min`;
      } else {
        timespan = `${_mins} mins`;
      }
    }

    return timespan;
  }

  public GetDaysOfDates(startDate: string, endDate: string, culture?: string): number {
    moment.locale(culture && culture.length > 0 ? culture : 'en');
    let timespan = 0;

    // Convert dates to Moment
    const DTEINI = moment(startDate);
    const DTEFIN = moment(endDate);

    const x = moment(DTEINI.format('YYYY-MM-DD'));
    const y = moment(DTEFIN.format('YYYY-MM-DD'));

    // Get dates difference
    const duration = y.diff(x, 'days');

    // Add 1 to count the start day
    const _days = duration + 1;
    timespan = _days;

    return timespan;
  }

  public GetDatesDesciption(startDate: string, endDate: string, culture?: string): string {
    moment.locale(culture && culture.length > 0 ? culture : 'en');
    let date = '';

    // Convert dates to Moment
    const DTEINI = moment(startDate);
    const DTEFIN = moment(endDate);

    let startDay = DTEINI.format('D');
    let endDay = DTEFIN.format('D');

    if (startDay === '1') {
      startDay = `${startDay}st`;
    } else if (startDay === '2') {
      startDay = `${startDay}nd`;
    } else if (startDay === '3') {
      startDay = `${startDay}rd`;
    } else {
      startDay = `${startDay}th`;
    }

    if (endDay === '1') {
      endDay = `${endDay}st`;
    } else if (endDay === '2') {
      endDay = `${endDay}nd`;
    } else if (endDay === '3') {
      endDay = `${endDay}rd`;
    } else {
      endDay = `${endDay}th`;
    }

    const startMonth = DTEINI.format('MMMM');
    const endtMonth = DTEFIN.format('MMMM');

    const startYear = DTEINI.format('YYYY');
    const endYear = DTEFIN.format('YYYY');

    if (startMonth === endtMonth && startYear === endYear) {
      date = `${this.FirstLetterCapital(startMonth.toLowerCase())} ${startDay} to ${endDay}, ${startYear}`;
    } else if (startMonth !== endtMonth && startYear === endYear) {
      date = `${this.FirstLetterCapital(startMonth.toLowerCase())} ${startDay} to ${this.FirstLetterCapital(
        endtMonth.toLowerCase()
      )} ${endDay}, ${startYear}`;
    } else if (startYear !== endYear) {
      date = `${this.FirstLetterCapital(
        startMonth.toLowerCase()
      )} ${startDay}, ${startYear} to ${this.FirstLetterCapital(endtMonth.toLowerCase())} ${endDay}, ${endYear}`;
    }

    return date;
  }

  public GetDateFormatted(date: string, culture?: string): string {
    moment.locale(culture && culture.length > 0 ? culture : 'en');
    const nowDay = moment().format('DD');
    const fecha = moment(date);
    const day = fecha.format('DD');
    const time = fecha.format('hh:mm A');

    if (nowDay === day) {
      return `Today - ${time}`;
    } else if (Number(nowDay) - 1 === Number(day)) {
      return `Yesterday - ${time}`;
    } else {
      const formatedDate = moment(date).format('D MMM YYYY');
      return `${formatedDate} - ${time}`;
    }
  }

  public GetDateHourFormatted(date: Date, culture?: string) {
    moment.locale(culture && culture.length > 0 ? culture : 'en');
    let fmtDate: string;
    let x = moment(date).format('D MMM YYYY hh:mm A');
    fmtDate = this.FirstLetterCapital(x);
    fmtDate = this.RemoveLastPoint(fmtDate);
    return fmtDate;
  }

  public currencyFormat(amount: number, currency?: string) {
    const x = amount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    if (currency) {
      return `$${x} ${currency}`;
    } else {
      return `$${x}`;
    }
  }

  public FormatDateShow(fecha: string, culture?: string): string {
    moment.locale(culture && culture.length > 0 ? culture : 'en');
    let date: any;

    if (fecha && fecha.length === 0) {
      date = moment().format('D MMM YYYY');
    } else {
      date = moment(fecha).format('D MMM YYYY');
    }

    date = this.FirstLetterCapital(date);
    date = this.RemoveLastPoint(date);

    return date;
  }

  public getFileExtension(filename: string) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  public isToday(reference: any, momentDate: any) {
    return momentDate.isSame(reference, 'd');
  }

  public isYesterday(reference: any, momentDate: any) {
    return momentDate.isSame(reference, 'd');
  }

  public momentFormat(date: Date): string {
    let fmtDate: string;
    const x = moment(date).format('D MMM YYYY');
    fmtDate = this.FirstLetterCapital(x);
    fmtDate = this.RemoveLastPoint(fmtDate);
    return fmtDate;
  }

  public momentFormatNormal(date: Date): string {
    return moment(date).format('D/MM/YYYY');
  }

  public momentGetCurrentMonth(date: Date) {
    return moment(date, 'YYYY/MM/DD').format('M');
  }

  public momentGetCurrentYear(date: Date) {
    return moment(date, 'YYYY/MM/DD').format('YYYY');
  }

  public currencyFormatUSD(number: number, digits: number) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: digits,
    });

    return formatter.format(number);
  }

  public ReplaceRareWordsHTML(text: string): string {
    let newText = text;

    if (newText) {
      newText = newText.replace(/&#32;/g, ' ');
      newText = newText.replace(/&#33;/g, '!');
      newText = newText.replace(/&#34;/g, '"');
      newText = newText.replace(/&quot;/g, '"');
      newText = newText.replace(/&#35;/g, '#');
      newText = newText.replace(/&#36;/g, '$');
      newText = newText.replace(/&#37;/g, '%');
      newText = newText.replace(/&#38;/g, '&');
      newText = newText.replace(/&amp;/g, '&');
      newText = newText.replace(/&#40;/g, '(');
      newText = newText.replace(/&#41;/g, ')');
      newText = newText.replace(/&#42;/g, '*');
      newText = newText.replace(/&#43;/g, '+');
      newText = newText.replace(/&#44;/g, ',');
      newText = newText.replace(/&#45;/g, '-');
      newText = newText.replace(/&#46;/g, '.');
      newText = newText.replace(/&#47;/g, '/');
      newText = newText.replace(/&#48;/g, '0');
      newText = newText.replace(/&#49;/g, '1');
      newText = newText.replace(/&#50;/g, '2');
      newText = newText.replace(/&#51;/g, '3');
      newText = newText.replace(/&#52;/g, '4');
      newText = newText.replace(/&#53;/g, '5');
      newText = newText.replace(/&#54;/g, '6');
      newText = newText.replace(/&#55;/g, '7');
      newText = newText.replace(/&#56;/g, '8');
      newText = newText.replace(/&#57;/g, '9');
      newText = newText.replace(/&#58;/g, ':');
      newText = newText.replace(/&#59;/g, ';');
      newText = newText.replace(/&#60;/g, '<');
      newText = newText.replace(/&lt;/g, '<');
      newText = newText.replace(/&#61;/g, '=');
      newText = newText.replace(/&#62;/g, '>');
      newText = newText.replace(/&gt;/g, '>');
      newText = newText.replace(/&#63;/g, '?');
      newText = newText.replace(/&#64;/g, '@');
      newText = newText.replace(/&#91;/g, '[');
      newText = newText.replace(/&#93;/g, ']');
      newText = newText.replace(/&#94;/g, '^');
      newText = newText.replace(/&#95;/g, '_');
      newText = newText.replace(/&#123;/g, '{');
      newText = newText.replace(/&#124;/g, '|');
      newText = newText.replace(/&#125;/g, '}');
      newText = newText.replace(/&#126;/g, '~');
      newText = newText.replace(/&#160;/g, ' ');
    }

    return newText;
  }

  public getAllMonths() {
    return moment.months();
  }

  public FirstLetterCapital(texto: string): string {
    if (texto && texto.length > 0) {
      return texto.replace(/^([a-z\u00E0-\u00FC])|\s+([a-z\u00E0-\u00FC])/g, function ($1) {
        return $1.toUpperCase();
      });
    } else {
      return null;
    }
  }

  public RemoveLastPoint(text: string): string {
    return text.replace('.', '');
  }

  public UUID(): string {
    return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  public getFirstLetterWord(word: string) {
    const x = this.FirstLetterCapital(word);
    const y = x.concat(' ').replace(/([a-zA-Z]{0,} )/g, function (match) {
      return match.trim()[0];
    });
    return y.substring(0, 3);
  }

  public zeroPad(num: number, places: number) {
    const zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
  }

  public momentFormatHour(date: Date | string) {
    let fmtDate: string;
    fmtDate = this.FirstLetterCapital(moment(date).format('DD MMM YYYY hh:mm a'));
    fmtDate = this.RemoveLastPoint(fmtDate);
    return fmtDate;
  }

  public isOdd(num: number) {
    return num % 2;
  }

  public FormatDateOpcional(format: string, date?: string, culture?: string) {
    moment.locale(culture && culture.length > 0 ? culture : 'en');
    let dateReturn: any;

    if (date) {
      dateReturn = moment(date).format(format);
    } else {
      dateReturn = moment().format(format);
    }
    dateReturn = this.FirstLetterCapital(dateReturn);
    dateReturn = this.RemoveLastPoint(dateReturn);
    return dateReturn;
  }

  public GetEnumName(_enum: any, find: number) {
    return Object.keys(_enum).find((k) => _enum[k] === find);
  }

  public formatDateWithHourDate(date: Date | string, culture?: string) {
    moment.locale(culture && culture.length > 0 ? culture : 'en');
    let dateReturn: string;

    dateReturn = moment(date).format('DD MMM YYYY h:mm a');
    dateReturn = this.FirstLetterCapital(dateReturn);
    dateReturn = this.RemoveLastPoint(dateReturn);

    return dateReturn;
  }

  public FormatDate(fecha: string, format: string, culture?: string): string {
    moment.locale(culture && culture.length > 0 ? culture : 'en');
    let date: any;

    if (fecha && fecha.length === 0) {
      date = moment().format(format);
    } else {
      date = moment(fecha).format(format);
    }

    date = this.FirstLetterCapital(date);
    date = this.RemoveLastPoint(date);

    return date;
  }

  public DateLetterFormatPrint(fecha: string, culture?: string) {
    moment.locale(culture && culture.length > 0 ? culture : 'en');
    let date: string;
    let P1: any;
    let P2: any;
    let P3: any;

    if (fecha && fecha.length === 0) {
      P1 = moment().format('D');
      P2 = moment().format('MMMM');
      P3 = moment().format('YYYY');
    } else {
      P1 = moment().format('D');
      P2 = moment().format('MMMM');
      P3 = moment().format('YYYY');
    }

    date = `${this.FirstLetterCapital(P2)} ${P1} of ${P3}`;
    date = this.RemoveLastPoint(date);

    return date;
  }

  public ReplaceLineBreak(text: string) {
    let newText = text;
    if (text) {
      newText = text.replace(/\r?\n/g, '<br>');
    }
    return newText;
  }

  public compare(a: string, b: string) {
    const dateA: any = new Date(a).getDay();
    const dateB: any = new Date(b).getDay();
    return dateA - dateB;
  }

  public momentMonth(date: Date): string {
    return this.FirstLetterCapital(moment(date).format('MMMM'));
  }

  public momentCreateDate(date: Date, time: string) {
    let fmtDate: string;
    const x = moment(date).format('D MMM YYYY');
    const times = time.split(':');
    let correctTime: string;

    if (Number(times[0]) > 12) {
      const hour = Number(times[0]) - 12;
      correctTime = hour + ':' + times[1] + ' PM';
    } else {
      correctTime = time + ' AM';
    }

    fmtDate = this.FirstLetterCapital(`${x} ${correctTime}`);
    fmtDate = this.RemoveLastPoint(fmtDate);
    return fmtDate;
  }

  public momentFormatTime(date: Date) {
    return moment(date).format('hh:mm A');
  }

  public getNameMonth(date: string, cultureLanguage: string) {
    const objDate = new Date(date),
      locale = cultureLanguage,
      month = objDate.toLocaleString(locale, { month: 'short' });
    return month.split('.')[0];
  }

  public text_truncate(text: string, length: number, ending: string) {
    if (length == null) {
      length = 45;
    }
    if (ending == null) {
      ending = '...';
    }
    if (text.length > length) {
      return text.substring(0, length - ending.length) + ending;
    } else {
      return text;
    }
  }

  public IsEndDateValid(start: string, end: string, culture?: string): boolean {
    // Expected format   "YYYY-MM-DDTHH:mm:ssZ"
    moment.locale(culture && culture.length > 0 ? culture : 'en');
    return moment(start).isAfter(end);
  }

  public RoundDate(hoursToAdd: number, date: string, daysToAdd?: number, culture?: string) {
    moment.locale(culture && culture.length > 0 ? culture : 'en');
    const D1 = date.split('T');
    const TimeD1 = D1[1];
    const TimesD1 = TimeD1.split(':');

    let newDate = moment(date);
    if (daysToAdd) {
      newDate = newDate.add(daysToAdd, 'd');
    }

    const originalDate = newDate.subtract(Number(TimesD1[1]), 'minutes');
    const start = originalDate.format('YYYY-MM-DDTHH:mm:ssZ');
    const lastDate = originalDate.add(hoursToAdd, 'h');
    const end = lastDate.format('YYYY-MM-DDTHH:mm:ssZ');

    const dates = {
      dateStart: start,
      endDate: end,
    };

    return dates;
  }

  public createCustomDate(date: string, time: string) {
    // date format YYYY-MM-DD
    return `${date}T${time}:00Z`;
  }

  public getDatesduration(fechaIni: string, timeIni: string, fechaFin: string, timefin: string): string {
    // Convert dates to Moment
    const DTEINI = moment(`${fechaIni}T${timeIni}:00Z`);
    const DTEFIN = moment(`${fechaFin}T${timefin}:00Z`);

    // Get difference between dates
    const duration = moment.duration(DTEFIN.diff(DTEINI));
    const _year = duration.get('years');
    let _yearlbl = '';
    const _month = duration.get('months');
    let _monthlbl = '';
    const _days = duration.get('days');
    let _dayslbl = '';
    const _hrs = duration.get('hours');
    let _hrslbl = '';
    const _mins = duration.get('minutes');
    let _minslbl = '';

    if (_year === 1) {
      _yearlbl = '1 year';
    } else {
      _yearlbl = `${_year} years`;
    }

    if (_month === 1) {
      _monthlbl = '1 month';
    } else {
      _monthlbl = `${_month} months`;
    }

    if (_days === 1) {
      _dayslbl = '1 day';
    } else {
      _dayslbl = `${_days} days`;
    }

    if (_hrs === 1) {
      _hrslbl = '1 hr';
    } else {
      _hrslbl = `${_hrs} hrs`;
    }

    if (_mins === 1) {
      _minslbl = '1 min';
    } else {
      _minslbl = `${_mins} mins`;
    }

    if (_year > 0 && _month > 0 && _days > 0 && _hrs > 0 && _mins > 0) {
      return `${_yearlbl}, ${_monthlbl}, ${_dayslbl}, ${_hrslbl}, ${_minslbl}`;
    } else if (_year > 0 && _month > 0 && _days > 0 && _hrs > 0 && _mins === 0) {
      return `${_yearlbl}, ${_monthlbl}, ${_dayslbl}, ${_hrslbl}`;
    } else if (_year > 0 && _month > 0 && _days > 0 && _hrs === 0 && _mins === 0) {
      return `${_yearlbl}, ${_monthlbl}, ${_dayslbl}`;
    } else if (_year > 0 && _month > 0 && _days === 0 && _hrs === 0 && _mins === 0) {
      return `${_yearlbl}, ${_monthlbl}`;
    } else if (_year > 0 && _month === 0 && _days === 0 && _hrs === 0 && _mins === 0) {
      return `${_yearlbl}`;
    } else if (_year === 0 && _month > 0 && _days > 0 && _hrs > 0 && _mins > 0) {
      return `${_monthlbl}, ${_dayslbl}, ${_hrslbl}, ${_minslbl}`;
    } else if (_year === 0 && _month > 0 && _days > 0 && _hrs > 0 && _mins === 0) {
      return `${_monthlbl}, ${_dayslbl}, ${_hrslbl}`;
    } else if (_year === 0 && _month > 0 && _days > 0 && _hrs === 0 && _mins === 0) {
      return `${_monthlbl}, ${_dayslbl}`;
    } else if (_year === 0 && _month > 0 && _days === 0 && _hrs === 0 && _mins === 0) {
      return `${_monthlbl}`;
    } else if (_year === 0 && _month === 0 && _days > 0 && _hrs > 0 && _mins > 0) {
      return `${_dayslbl}, ${_hrslbl}, ${_minslbl}`;
    } else if (_year === 0 && _month === 0 && _days > 0 && _hrs > 0 && _mins === 0) {
      return `${_dayslbl}, ${_hrslbl}`;
    } else if (_year === 0 && _month === 0 && _days > 0 && _hrs === 0 && _mins === 0) {
      return `${_dayslbl}`;
    } else if (_year === 0 && _month === 0 && _days === 0 && _hrs > 0 && _mins > 0) {
      return `${_hrslbl}, ${_minslbl}`;
    } else if (_year === 0 && _month === 0 && _days === 0 && _hrs > 0 && _mins === 0) {
      return `${_hrslbl}`;
    } else if (_year === 0 && _month === 0 && _days === 0 && _hrs === 0 && _mins > 0) {
      return `${_minslbl}`;
    } else {
      return `0`;
    }
  }

  public addItemLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public removeItemLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  public getItemLocalStorage(key: string) {
    return localStorage.getItem(key);
  }

  public deepClone(jsonDATA: any) {
    return JSON.parse(JSON.stringify(jsonDATA));
  }

  public convertToBase64(arrayBuffer: any) {
    let response: any;
    try {
      response = btoa(
        new Uint8Array(arrayBuffer).reduce(function (data, byte) {
          return data + String.fromCharCode(byte);
        }, '')
      );
    } catch (error) {
      response = undefined;
    }

    return response;
  }

  public returnFiles(data: File[]): FormData {
    const formData = new FormData();
    Array.from(data).map((file: any, index) => {
      return formData.append('file' + index, file, file.name);
    });

    return formData;
  }

  public downloadBlob(blobData: Blob, name: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blobData);
    link.download = name;
    link.click();
  }

  public getFileSize(size: number): number {
    return size * 0.00000095367432;
  }
}
