<div class="box-shadow module" style="height: 690px">
  <div class="row">
    <h2>Users</h2>
    <hr />
  </div>
  <!-- <div *ngIf="errorMsg" class="row" class="alert alert-danger" role="alert">
    {{ errorMsg }}
  </div>
  <div *ngIf="successMsg" class="row" class="alert alert-success" role="alert">
    {{ successMsg }}
  </div> -->
  <div class="row">
    <div class="col-xs-2">
      <label>Business Unit :</label>
      <select class="form-control" [(ngModel)]="selectedBU" (change)="loadOrganizations()">
        <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu">
          {{ bu.Name }}
        </option>
      </select>
    </div>
    <div class="col-xs-2">
      <label>Organization :</label>
      <select class="form-control" [(ngModel)]="selectedOrg">
        <option *ngFor="let org of lstOrganization" [ngValue]="org">
          {{ org.Name }}
        </option>
      </select>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-4">
      <label>User :</label>
    </div>
    <div class="col-md-8"></div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <span class="ui-fluid">
        <p-autoComplete
          [(ngModel)]="selectedUser"
          [suggestions]="users"
          (completeMethod)="getUsers($event)"
          name="usr"
          #usr="ngModel"
          field="FullName"
          placeholder="Please type user name"
          [minLength]="1"
        ></p-autoComplete>
      </span>
    </div>
    <div class="col-md-1">
      <span class="ui-fluid">
        <button type="button" [disabled]="!selectedUser && !firstName" class="btn btn-primary" (click)="addUser()">
          Save
        </button>
      </span>
    </div>
    <div class="col-md-7"></div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-12 table-responsive" style="height: 424px; max-height: 424px">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>CAI</th>
            <th>Email</th>
            <th>Is Admin</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let OU of selectedOrg.OrganizationUsers">
            <td>{{ OU.FirstName }}</td>
            <td>{{ OU.LastName }}</td>
            <td>{{ OU.CAI }}</td>
            <td>{{ OU.Email }}</td>
            <td>
              <input type="checkbox" [(ngModel)]="OU.IsAdmin" (click)="updateOganizationUser(OU)" value="OU.IsAdmin" />
            </td>
            <td style="text-align: end">
              <button type="button" class="btn btn-danger" (click)="removeUser(OU)" style="width: 80px">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Action Message -->
  <p-confirmDialog #cd>
    <p-footer>
      <button
        type="button"
        pButton
        icon="fa-check pi pi-check"
        label="Yes"
        (click)="cd.accept()"
        style="width: 80px"
      ></button>
      <button
        type="button"
        pButton
        icon="fa-close pi pi-times"
        label="No"
        (click)="cd.reject()"
        style="width: 80px"
      ></button>
    </p-footer>
  </p-confirmDialog>
</div>
<!-- Action Message -->

<!-- Toast -->
<p-toast [style]="{ marginTop: '40px !important' }" key="UserNotify" [baseZIndex]="6000"></p-toast>
<p-toast [style]="{ marginTop: '40px !important' }" key="UserError" [baseZIndex]="6000" position="top-center"></p-toast>
<!-- Toast -->
