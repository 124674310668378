<small>
  <table class="table table-striped table-hover text-center table-condensed">
    <caption class="boldTextFont">
      Section :
      {{
        _sectionCasing.SectionDescription
      }}
    </caption>
    <thead class="text-primary">
      <tr>
        <td>Hole Size</td>
        <td>Actual Top Depth</td>
        <td>Actual Bottom Depth</td>
        <td>Start Date</td>
        <td>End Date</td>
      </tr>
    </thead>
    <tbody>
      <td>{{ _sectionCasing.HoleSize }}</td>
      <td>{{ _sectionCasing.ActualTopDepth }}</td>
      <td>{{ _sectionCasing.ActualBottomDepth }}</td>
      <td>{{ _sectionCasing.StartDate }}</td>
      <td>{{ _sectionCasing.EndDate }}</td>
    </tbody>
  </table>
</small>
