import { SectionsApiService, TaskPlanApiService, UserApiService, WellsApiService } from '#services-api/index';
import { SharedModule } from '#services-shared/shared.module';
import { NgModule } from '@angular/core';
import { ATRProcedureApiService } from './jobs/atr-procedure-api.service';
import { CasingComponentApiService } from './sections/casing-component-api.service';
import { CasingStringApiService } from './sections/casing-string-api.service';
import { BusinessUnitsApiService } from './shared/business-unit-api.service';
import { FeedbackApiService } from './shared/feedback-api.service';

@NgModule({
  declarations: [],
  imports: [SharedModule],
  providers: [
    BusinessUnitsApiService,
    TaskPlanApiService,
    FeedbackApiService,
    UserApiService,
    WellsApiService,
    SectionsApiService,
    CasingStringApiService,
    CasingComponentApiService,
    ATRProcedureApiService,
  ],
})
export class ApiModule {}
