import { TaskPlan } from '#models/index';
import { TaskPlanApiService } from '#services-api/index';
import { LoadingIndicatorService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-task-plan-create',
  templateUrl: './task-plan-create.component.html',
})
export class TaskPlanCreateComponent implements OnInit, OnDestroy {
  public taskPlan: TaskPlan = new TaskPlan();
  public displayWarningNote: boolean;
  public displayWarningCode: boolean;
  public displayWarningDesc: boolean;

  //? Subscriptions
  private SUBS$ = new Subscription();

  constructor(
    private taskPlanSvc: TaskPlanApiService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.displayWarningNote = false;
    this.displayWarningCode = false;
    this.displayWarningDesc = false;
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }

  public onRedirectButtonClick(): void {
    this._router.navigate(['admin/task-plan']);
  }

  public createTaskPlan(): void {
    const invalidCode =
      this.taskPlan.Code === undefined || this.taskPlan.Code == null || this.taskPlan.Code.length === 0;
    const invalidDesc =
      this.taskPlan.Description === undefined ||
      this.taskPlan.Description == null ||
      this.taskPlan.Description.length === 0;
    this.displayWarningCode = invalidCode;
    this.displayWarningDesc = invalidDesc;
    if (!invalidCode && !invalidDesc) {
      this.loadingIndicatorSvc.show();
      this.SUBS$.add(
        this.taskPlanSvc
          .createTaskPlan(this.taskPlan)
          .finally(() => this.loadingIndicatorSvc.hide())
          .subscribe(() => {
            this.onRedirectButtonClick();
          })
      );
    }
  }
}
