import { OrganizationUser } from '#models/index';

export class Organization {
  Id: number;
  Name: string;
  OrganizationUsers: OrganizationUser[];

  // public Code: string;
  // public  Description: string;
  // public ID: number;
  // public TaskPlanSections: TaskPlanSection[];
}
