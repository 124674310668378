export class Field {
  UOM: string;
  Label: string;
  Help: string;
  Value: string;

  constructor() {
    this.UOM = '';
    this.Label = '';
    this.Help = '';
    this.Value = '';
  }
}
