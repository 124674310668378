<div class="box-shadow module">
  <h2>Task Plan Template</h2>
  <div class="row">
    <div class="col-sm-6">
      <a (click)="onRedirectButtonClick('create', null)" style="cursor: pointer">Create New</a>
    </div>
    <div class="col-sm-6"></div>
  </div>

  <table class="table table-hover">
    <tr>
      <th>Code</th>
      <th>Description</th>
      <th></th>
    </tr>
    <tr *ngFor="let tp of taskPlans">
      <td>{{ tp.Code }}</td>
      <td>{{ tp.Description }}</td>
      <td style="text-align: end">
        <a (click)="onRedirectButtonClick('edit', tp.ID)" style="cursor: pointer">Edit</a> |
        <a (click)="onRedirectButtonClick('manage', tp.ID)" style="cursor: pointer">Manage</a> |
        <a (click)="onRedirectButtonClick('delete', tp.ID)" style="cursor: pointer">Delete</a>
      </td>
    </tr>
  </table>
</div>
