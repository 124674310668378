import { environment } from '#environments/environment';
import { Job } from '#models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JobsApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/job${method}`;
  }
  //#endregion 'General'

  //#region 'Get'
  public getByWellId(wellId: string): Observable<Job[]> {
    return this.http.get<Job[]>(this.getURL(`/getByWellId`), {
      params: { wellId: wellId },
    });
  }

  public getJobByID(jobID: string): Observable<Job[]> {
    return this.http.get<Job[]>(this.getURL(`/getByJobId`), {
      params: { jobId: jobID },
    });
  }
  //#endregion 'Get'
}
