import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CasingDescription, CasingString } from 'models';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root',
})
export class CasingStringApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/CasingString${method}`;
  }
  //#endregion 'General'

  //#region 'Get'
  public getCasingDescription(): Observable<CasingDescription[]> {
    return this.http.get<CasingDescription[]>(this.getURL(`/getCasingDescription`));
  }

  public getWellboreIdCasingDescription(wellboreId: string, casingDescription: string): Observable<CasingString[]> {
    return this.http.get<CasingString[]>(this.getURL(`/getByWellboreIdCasingDescription`), {
      params: { wellboreId: wellboreId, casingDescription: casingDescription },
    });
  }

  public getCasingStringApproved(casingStringId: string): Observable<boolean> {
    return this.http.get<boolean>(this.getURL(`/IsApproved`), {
      params: { casingStringId: casingStringId },
    });
  }
  //#endregion 'Get'

  //#region 'Create'
  public create(casingString: CasingString): Observable<CasingString> {
    return this.http.post<CasingString>(this.getURL(`/create`), casingString);
  }
  //#endregion 'Create'

  //#region 'Update'
  public update(casingString: CasingString): Observable<CasingString> {
    return this.http.put<CasingString>(this.getURL(`/update`), casingString);
  }
  //#endregion 'Update'
}
