import { NotificationToast, OfflineActivity, OfflineActivityDates } from '#models/index';
import { OfflineActivityService } from '#services-shared/jobs/offline-activity.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';

//? OA = Offline Activity
//? OAD = Offline Activity Dates
//? OAT = Offline Activity Timelog

@Component({
  selector: 'app-offline-activity',
  templateUrl: './offline-activity.component.html',
  styleUrls: ['../operations.shared.scss'],
  providers: [MessageService],
})
export class OfflineActivityComponent implements OnInit, OnDestroy {
  //#region 'Variables'
  public setToastType = '';
  private preventSelectOA = false;
  private preventSelectOAD = false;

  //? Offline Activity
  public isOAUpdate = false;
  public showCreateNewOA = false;
  public showErrorCreateNewOA = false;

  //? Offline Activity Dates
  public isOADUpdate = false;
  public areDatesInvalids = false;
  public showCreateNewOAD = false;
  public showEndDateErrorOAD = false;
  public showStartDateErrorOAD = false;
  public showErrorCreateNewOAD = false;

  //? Forms
  public formOA: FormGroup;
  public formOAD: FormGroup;
  private validateDates = false;

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(private _message: MessageService, public _offlineActivity: OfflineActivityService) {}

  ngOnInit(): void {
    this.loadForms();
    this.loadOA();
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  public loadForms() {
    this.formOA = new FormGroup({
      name: new FormControl('', Validators.required),
    });

    this.formOAD = new FormGroup({
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      comments: new FormControl(''),
    });

    this._onStartDateFormChanges();
    this._onEndDateFormChanges();
  }
  //#endregion 'Load'

  //? Offline Activity
  //#region 'Load -- Offline Activity'
  public loadOA() {
    this._offlineActivity.loadOA().then((OADone: boolean) => {
      if (OADone) {
        this._offlineActivity.getOADByOAId();
      }
    });
  }
  //#endregion 'Load -- Offline Activity'

  //#region 'General Methods -- Offline Activity'
  public selectOA(index: number) {
    if (!this.preventSelectOA) {
      this._offlineActivity.selectedOAId = index;
      this._offlineActivity.getOADByOAId();
    }
  }
  //#endregion 'General Methods -- Offline Activity'

  //#region 'Modals -- Offline Activity'
  public openOA(isEdit: boolean, index?: number) {
    this.isOAUpdate = isEdit;
    this.preventSelectOA =
      index !== null && index !== undefined && index >= 0 && index !== this._offlineActivity.selectedOAId
        ? false
        : true;

    if (isEdit && index !== null && index !== undefined && index >= 0) {
      this._offlineActivity.selectedOAId = index;
      this.formOA.controls['name'].setValue(
        this._offlineActivity.OAList[this._offlineActivity.selectedOAId].Name.Value
      );
    }

    this.showCreateNewOA = true;
  }

  public closeOA() {
    this.formOA.reset();
    this.preventSelectOA = false;
    this.showCreateNewOA = false;
    this.showErrorCreateNewOA = false;
  }
  //#endregion 'Modals -- Offline Activity'

  //#region 'Validations -- Offline Activity'
  private isOAValid(): boolean {
    if (!this.formOA.valid) {
      this.showErrorCreateNewOA = true;
      return false;
    } else {
      this.showErrorCreateNewOAD = false;
      return true;
    }
  }
  //#endregion 'Validations -- Offline Activity'

  //#region 'CRUD -- Offline Activity'
  private setOA(isEdit: boolean): OfflineActivity {
    let dto = new OfflineActivity();
    if (!isEdit) {
      dto.WellId.Value = this._offlineActivity.wellId;
      dto.JobId.Value = this._offlineActivity.jobID;
    } else {
      dto = this._offlineActivity.OAList[this._offlineActivity.selectedOAId];
    }

    dto.Name.Value = this.formOA.controls['name'].value;
    return dto;
  }

  public createOA() {
    if (this.isOAValid()) {
      this._offlineActivity.createOA(this.setOA(false)).then(
        (done: any) => {
          this.loadOA();
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
      this.closeOA();
    }
  }

  public updateOA() {
    if (this.isOAValid()) {
      this._offlineActivity.updateOA(this.setOA(true)).then(
        (done: any) => {
          this.loadOA();
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
      this.closeOA();
    }
  }
  //#endregion 'CRUD -- Offline Activity'

  //? Offline Activity Dates
  //#region 'Load -- Offline Activity Dates'
  private _onStartDateFormChanges() {
    this.SUBS$.add(
      this.formOAD.get('startDate').valueChanges.subscribe(() => {
        if (this.validateDates) {
          this.areDatesValid();
        }
      })
    );
  }

  private _onEndDateFormChanges() {
    this.SUBS$.add(
      this.formOAD.get('endDate').valueChanges.subscribe(() => {
        if (this.validateDates) {
          this.areDatesValid();
        }
      })
    );
  }
  //#endregion 'Load -- Offline Activity Dates'

  //#region 'General Methods -- Offline Activity Dates'
  public async editOADDate(index?: number) {
    if (index !== null && index !== undefined && index >= 0 && index !== this._offlineActivity.selectedOADId) {
      this.preventSelectOAD = false;
      await this.selectOAD(index).then(() => {
        this.preventSelectOAD = true;
      });
    } else {
      this.preventSelectOAD = true;
    }
    this.openOAD(true);
  }

  public selectOAD(index: number) {
    return new Promise((resolve, reject) => {
      try {
        if (!this.preventSelectOAD) {
          this._offlineActivity.selectedOADId = index;
          this._offlineActivity.changeOADSelection().then(() => {
            resolve(true);
          });
        } else {
          resolve(true);
        }
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  private loadOADToEdit() {
    this.validateDates = false;
    this.formOAD.controls['startDate'].setValue(this._offlineActivity.OADObject.StartDate.Value);
    this.formOAD.controls['endDate'].setValue(this._offlineActivity.OADObject.EndDate.Value);
    this.formOAD.controls['comments'].setValue(this._offlineActivity.OADObject.Comment.Value);
    this.validateDates = true;
  }

  private resetFormOAD() {
    this.formOAD.reset();
    this.formOAD.controls['startDate'].setValue(moment().format('MM/DD/YYYY 00:00'));
    this.formOAD.controls['endDate'].setValue(moment().add(1, 'days').format('MM/DD/YYYY 00:00'));
  }
  //#endregion 'General Methods -- Offline Activity Dates'

  //#region 'Modals -- Offline Activity Dates'
  public openOAD(isEdit: boolean) {
    this.validateDates = false;
    this.isOADUpdate = isEdit;

    if (isEdit) {
      this.loadOADToEdit();
    } else {
      this.resetFormOAD();
      this._offlineActivity.setDataToValidateDates(false);
      this.areDatesValid();
    }

    this.showCreateNewOAD = true;
    this.validateDates = true;
  }

  public closeOAD(calculate?: boolean) {
    this.validateDates = false;
    this.areDatesInvalids = false;
    this.preventSelectOAD = false;
    this.showCreateNewOAD = false;
    this.showEndDateErrorOAD = false;
    this.showStartDateErrorOAD = false;
    this.showErrorCreateNewOAD = false;

    if (calculate) this._offlineActivity.calculateRigTime(null, null, true);
    this.resetFormOAD();
  }
  //#endregion 'Modals -- Offline Activity Dates'

  //#region 'Validations -- Offline Activity Dates'
  private isOADValid(): boolean {
    if (!this.formOAD.valid) {
      this.showErrorCreateNewOAD = true;
      return false;
    }

    if (this.areDatesInvalids) {
      return false;
    }

    if (this.showStartDateErrorOAD) {
      return false;
    }

    if (this.showEndDateErrorOAD) {
      return false;
    }

    if (this._offlineActivity.showOADTimeError) {
      return false;
    }

    this._offlineActivity.showOADTimeError = false;
    this.areDatesInvalids = false;
    this.showErrorCreateNewOAD = false;
    return true;
  }

  public areDatesValid() {
    const start = moment(this.formOAD.controls['startDate'].value).format('MM/DD/YYYY H:mm');
    const end = moment(this.formOAD.controls['endDate'].value).format('MM/DD/YYYY H:mm');

    if (start) {
      this.showStartDateErrorOAD = false;
      this.showStartDateErrorOAD = this._offlineActivity.checkDatesExist(start, true);
    }

    if (end) {
      this.showEndDateErrorOAD = false;
      this.showEndDateErrorOAD = this._offlineActivity.checkDatesExist(end, false);
    }

    if (start && end) {
      this.areDatesInvalids = moment(start).isAfter(end);
      this._offlineActivity.calculateRigTime(start, end, false);
    } else {
      this.areDatesInvalids = false;
    }
  }

  private isRigTimeOADValid(): boolean {
    const NEWRIGTIME = this._offlineActivity.OADTime;
    const AVAILABLETIME = this._offlineActivity.getOADTime(
      this.formOAD.controls['startDate'].value,
      this.formOAD.controls['endDate'].value
    );

    if (NEWRIGTIME > 24 || NEWRIGTIME > AVAILABLETIME || NEWRIGTIME < this._offlineActivity.TMLTime) {
      this._offlineActivity.showOADTimeError = true;
      return false;
    } else {
      this._offlineActivity.showOADTimeError = false;
      return true;
    }
  }
  //#endregion 'Validations -- Offline Activity Dates'

  //#region 'Toast'
  public showMessage(msg: any) {
    if (msg.clear) {
      this.hideToast();
    }

    if (msg.toastType) {
      this.setToastType = msg.toastType;
    }

    this._message.clear();
    this._message.add({
      key: msg.key,
      sticky: msg.sticky,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
    });
  }

  public onReject() {
    this.hideToast();
  }

  private hideToast() {
    this._message.clear('c');
    this.preventSelectOAD = false;
  }

  public async onConfirm() {
    this.hideToast();
    if (this.setToastType === 'DeleteOAD') {
      this._offlineActivity.deleteOAD().then(
        (done: any) => {
          this.loadOA();
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
    } else if (this.setToastType === 'DeleteTimeLogOA') {
      this._offlineActivity.deleteOAT().then(
        (done: any) => {
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
    }
  }
  //#endregion 'Toast'

  //#region 'CRUD -- Offline Activity Dates'
  private setOAD(isEdit: boolean): OfflineActivityDates {
    let dto = new OfflineActivityDates();
    if (!isEdit) {
      dto.WellId.Value = this._offlineActivity.wellId;
      dto.OAId.Value = this._offlineActivity.OAList[this._offlineActivity.selectedOAId].IdRec.Value;
    } else {
      dto = this._offlineActivity.OADList[this._offlineActivity.selectedOADId];
    }

    dto.StartDate.Value = moment(this.formOAD.controls['startDate'].value).format('MM/DD/YYYY H:mm');
    dto.EndDate.Value = moment(this.formOAD.controls['endDate'].value).format('MM/DD/YYYY H:mm');
    dto.Comment.Value = this.formOAD.controls['comments'].value;
    return dto;
  }

  public createOAD() {
    if (this.isOADValid()) {
      this._offlineActivity.createOAD(this.setOAD(false)).then(
        (done: any) => {
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
      this.closeOAD();
    }
  }

  public updateOAD() {
    if (this.isOADValid() && this.isRigTimeOADValid()) {
      this._offlineActivity.updateOAD(this.setOAD(true)).then(
        (done: any) => {
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
      this.closeOAD();
    }
  }

  public deleteOAD(oad: OfflineActivityDates) {
    this.preventSelectOAD = true;
    this._offlineActivity.OADDeleteObject = oad;
    this.showMessage({
      key: 'c',
      sticky: true,
      severity: 'warn',
      summary: 'Delete Offline Activity Desc',
      detail: 'Are you sure you want to delete the selected Offline Activity Desc?',
      clear: true,
      toastType: 'DeleteOAD',
    });
  }
  //#endregion 'CRUD -- Offline Activity Dates'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._message.add({
      key: msg && msg.key && msg.key.length > 0 ? msg.key : 'OANotify',
      sticky: msg && msg.key && msg.key != 'OANotify' ? true : false,
      closable: msg && msg.key && msg.key != 'OANotify' ? true : false,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life,
    });
  }
  //#endregion 'Notification'
}
