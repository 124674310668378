import { BusinessUnit, NotificationToast, Organization, OrganizationUser, User } from '#models/index';
import { BusinessUnitsApiService, OrganizationApiService, UserApiService } from '#services-api/index';
import { AuthService, LoadingIndicatorService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styles: ['tbody tr:hover { background-color: #c2e2fb; }'],
  providers: [MessageService],
})
export class UserComponent implements OnInit, OnDestroy {
  //#region 'Variables'
  public email: string;
  public errorMsg: string;
  public lastName: string;
  public firstName: string;
  public successMsg: string;
  public isExternal: number;

  public selectedBU: BusinessUnit;
  public selectedOrg: Organization;
  public selectedUser: User;

  public users: User[];
  public lstUsers: User[] = [];
  public lstBusinessUnit: BusinessUnit[] = [];
  public lstOrganization: Organization[] = [];

  //? Subscriptions
  private SUBS$ = new Subscription();

  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private authService: AuthService,
    private userService: UserApiService,
    private organizationSvc: OrganizationApiService,
    private businessUnitsSvc: BusinessUnitsApiService,
    private confirmationService: ConfirmationService,
    private loadingIndicatorSvc: LoadingIndicatorService,
    private _message: MessageService
  ) {}

  ngOnInit() {
    this.isExternal = 0;
    this.selectedOrg = new Organization();
    this.loadBusinessUnits();
    this.loadOrganizations();
    this.loadAllUsers();
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }

  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  private loadBusinessUnits(): void {
    this.loadingIndicatorSvc.show();
    this.SUBS$.add(
      this.businessUnitsSvc
        .getUserAdminBUs()
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data: BusinessUnit[]) => {
          this.lstBusinessUnit = data;
          if (this.lstBusinessUnit.length > 0) {
            this.selectedBU = this.lstBusinessUnit[0];
            this.loadOrganizations();
          }
        })
    );
  }

  public loadOrganizations(): void {
    this.loadingIndicatorSvc.show();
    if (this.selectedBU) {
      this.SUBS$.add(
        this.organizationSvc
          .getUserAdminOrgs(this.selectedBU.Id)
          .finally(() => this.loadingIndicatorSvc.hide())
          .subscribe((data: Organization[]) => {
            const usrs = data[0].OrganizationUsers.filter((usr) => usr.CAI !== this.authService.currentUser.CAI);
            usrs.sort((a, b) => (a.FirstName > b.FirstName ? 1 : b.FirstName > a.FirstName ? -1 : 0));

            data[0].OrganizationUsers = usrs;
            this.lstOrganization = data;
            if (this.lstOrganization.length > 0) {
              this.selectedOrg = this.lstOrganization[0];
            }
          })
      );
    } else {
      this.loadingIndicatorSvc.hide();
      this.lstOrganization = [];
    }
  }

  public loadAllUsers() {
    this.loadingIndicatorSvc.show();
    this.SUBS$.add(
      this.userService
        .getAll()
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data) => {
          this.lstUsers = data;
        })
    );
  }
  //#endregion 'Load'

  //#region 'Validations'
  public isInvalidString(value: string): boolean {
    return value === undefined || value == null || value.length === 0;
  }
  //#endregion 'Validations'

  //#region 'General Methods'
  private initializeOrganizationUser(): OrganizationUser {
    const orgUsr = new OrganizationUser();
    orgUsr.OrganizationId = this.selectedOrg.Id;
    orgUsr.FirstName = this.isExternal === 0 ? this.selectedUser.FirstName : this.firstName;
    orgUsr.LastName = this.isExternal === 0 ? this.selectedUser.LastName : this.lastName;
    orgUsr.Email = this.isExternal === 0 ? this.selectedUser.Email : this.email;
    orgUsr.CAI = this.isExternal === 0 ? this.selectedUser.CAI : '';
    orgUsr.IsAdmin = true;
    orgUsr.IsUser = true;
    orgUsr.IsExternal = this.isExternal > 0;

    return orgUsr;
  }
  //#endregion 'General Methods'

  //#region 'CRUD'
  public getUsers(event: any) {
    const query = event.query;
    this.SUBS$.add(
      this.userService.getParticipants(query).subscribe((data) => {
        this.users = data;
        this.users.forEach(function (obj, index) {
          this[
            index
          ].FullName = `${this[index].FirstName} ${this[index].LastName} / ${this[index].CAI} / ${this[index].Email}`;
        }, this.users);
      })
    );
  }

  public async addUser() {
    this.loadingIndicatorSvc.show();
    const orgUsr = this.initializeOrganizationUser();

    this.SUBS$.add(
      await this.organizationSvc
        .addOrganizationUser(orgUsr)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe(
          (data) => {
            this.selectedOrg.OrganizationUsers.push(data);
            this.firstName = '';
            this.lastName = '';
            this.email = '';
            this.selectedUser = null;

            this.showNotification({
              key: 'UserNotify',
              severity: 'success',
              summary: 'Success',
              detail: 'User Successfully added',
              life: 3000,
            });
          },
          (error) => {
            this.showNotification({
              key: 'UserError',
              severity: 'error',
              summary: 'Error',
              detail: `Something went wrong, please refresh the page.`,
              life: 6000,
            });
          }
        )
    );
  }

  public updateOganizationUser(orgUsrId: OrganizationUser) {
    this.loadingIndicatorSvc.show();
    let currentUser = this.lstUsers.filter((obj) => obj.CAI == orgUsrId.CAI);
    const userOrg = this.selectedOrg.OrganizationUsers.find((x) => x.Id === orgUsrId.Id);

    if (currentUser && currentUser.length > 0) {
      userOrg.IsAdmin = !userOrg.IsAdmin;
      currentUser[0].IsSystemAdmin = userOrg.IsAdmin;
      this.SUBS$.add(
        this.organizationSvc.updateOrganizationUser(userOrg).subscribe(
          () => {
            this.updateUser(currentUser[0]);
          },
          (error) => {
            userOrg.IsAdmin = !userOrg.IsAdmin;
            this.showNotification({
              key: 'UserError',
              severity: 'error',
              summary: 'Error',
              detail: `Something went wrong, please refresh the page.`,
              life: 6000,
            });
          }
        )
      );
    } else {
      this.showNotification({
        key: 'UserError',
        severity: 'error',
        summary: 'Error',
        detail: `Something went wrong, please refresh the page.`,
        life: 6000,
      });
    }
  }

  private updateUser(user: User) {
    this.SUBS$.add(
      this.userService
        .update(user)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe(() => {})
    );
  }

  public removeUser(orgUsr: OrganizationUser) {
    const message = `Record will be permanently removed, continue?<br/>`;
    this.confirmationService.confirm({
      message: message,
      header: 'Confirm delete',
      accept: () => {
        this.deleteOrganization(orgUsr);
      },
      reject: () => {},
    });
  }

  public async deleteOrganization(orgUsr: OrganizationUser) {
    this.loadingIndicatorSvc.show();
    const USER = this.lstUsers.filter((obj) => obj.CAI == orgUsr.CAI);

    if (USER && USER.length > 0) {
      this.SUBS$.add(
        await this.organizationSvc.removeOrganizationUser(orgUsr.Id).subscribe(
          () => {
            const userOrgIndex = this.selectedOrg.OrganizationUsers.findIndex((x) => x.Id === orgUsr.Id);
            this.selectedOrg.OrganizationUsers.splice(userOrgIndex, 1);
            this.deleteUser(USER[0]);
          },
          (error) => {
            this.showNotification({
              key: 'UserError',
              severity: 'error',
              summary: 'Error',
              detail: `Something went wrong, please refresh the page.`,
              life: 6000,
            });
          }
        )
      );
    } else {
      this.showNotification({
        key: 'UserError',
        severity: 'error',
        summary: 'Error',
        detail: `Something went wrong, please refresh the page.`,
        life: 6000,
      });
    }
  }

  private deleteUser(userID: User) {
    this.SUBS$.add(
      this.userService
        .removeUser(userID.Id)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe(
          () => {},
          (error) => {
            this.showNotification({
              key: 'UserError',
              severity: 'error',
              summary: 'Error',
              detail: `Something went wrong, please refresh the page.`,
              life: 6000,
            });
          }
        )
    );
  }
  //#endregion 'CRUD'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._message.add({
      key: msg.key && msg.key.length > 0 ? msg.key : 'UserNotify',
      sticky: false,
      closable: false,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life,
    });
  }
  //#endregion 'Notification'
}
