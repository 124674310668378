import {
  CasingComponent,
  CasingItemDescription,
  MultiEdit,
  MultipleCasingAdd,
  MultipleCasingImport,
  NotificationToast,
} from '#models/index';
import { CasingComponentApiService } from '#services-api/index';
import { LoadingIndicatorService, SectionCasingService, SectionDataService } from '#services-shared/index';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
  providers: [MessageService],
})
export class InventoryComponent implements OnInit, OnDestroy {
  //#region 'Input, Output, ViewChild'
  @Input() public wellId: string;
  @Input() public tmpSelectedIndex: number;
  @Input() public Verify_CasingComponents_NotValidated: boolean;
  @Input() public tmpSelectedCasingComponent: CasingComponent;
  @Input() public deletedCasingComponentArray: CasingComponent[] = [];

  @Output() public message: EventEmitter<any> = new EventEmitter<any>();
  // @Output() public validateCasing: EventEmitter<any> = new EventEmitter<any>();
  @Output() public threadOffValues: EventEmitter<any> = new EventEmitter<any>();
  @Output() public updateMultipleEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() public updtCasingComponent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dt') dt: any;
  //#endregion 'Input, Output, ViewChild'

  //#region 'Variables'
  public showMultiEdit: boolean;
  public displayMultipleEdit = false;
  public displayAddCasingOverlayPanel: boolean;
  public displayCasingComponentConfigurations: boolean;
  public casingsToMultiEdit: MultiEdit[] = [];
  public sidebar_CasingODID: CasingItemDescription[] = [];

  public editAll: boolean;
  public isMultiEdit: boolean = false;
  public resetOldValues: boolean = true;
  public canIsDrillingBeDisabled: boolean = true;
  public tempComponentMultipleEdit: CasingComponent;

  public itemsToSelect: SelectItem[] = [];
  public selectedItemsDesc: string[] = [];

  //? Add new casings
  public multipleAddIndex: number = 0;
  public isMultipleAdd: boolean = false;
  public showMultipleAdd: boolean = false;
  public showMultipleAddError: boolean = false;
  public multipleAddData: MultipleCasingAdd[] = [];

  //? Import Chevron Excel
  public isMultipleImport: boolean = false;
  public multipleImportIndex: number = 0;
  public multipleImportData: MultipleCasingImport[] = [];

  //? Multiple Edit
  public ME_ODNominal: string;
  public ME_ID: string;
  public ME_WeighLength: string;
  public ME_Grade: string;
  public ME_ThreadOn: string;
  public ME_ChkThreadLoss: boolean;
  public ME_ThreadLoss: string;
  public ME_TopThread: string;
  public ME_BottomThread: string;

  //? UnFocus restore old values
  public OLD_ODNominal: string;
  public OLD_ID: string;
  public OLD_WeightLength: string;
  public OLD_Grade: string;
  public OLD_Index: number;

  //? Sidebar
  public F_ODNominal: string;
  public F_ID: string;
  public F_WeightLength: string;
  public F_Grade: string;

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'File Import Variables'
  public importFileType: number;
  public casingCreationType = '';
  public setFileImportNotify: boolean;
  public displayImportExcelDialog: boolean;
  public displayImportExcelDialogButtons = false;
  public tableRows: any[] = [];
  public tableColumns: any[] = [];
  public casingComponentArrayImport: CasingComponent[] = [];
  //#endregion 'File Import Variables'

  //#region 'Report Format'
  public selectedReportFormat: string[] = [];
  //#endregion 'Report Format'

  //#region 'Angular Life Cycle'
  constructor(
    private _loader: LoadingIndicatorService,
    private _casingComponent: CasingComponentApiService,
    private _dataSection: SectionDataService,
    public _sectionCasing: SectionCasingService,
    private _message: MessageService
  ) {}

  ngOnInit() {
    this._sectionCasing.iconDescription.forEach((element) => {
      this.itemsToSelect.push({ label: element, value: element });
    });
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
    this._loader.hide();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Import'
  public importFileSuccess(ev: any) {
    this.importFileType = ev.type;
    this.isMultipleImport = true;
    this.setFileImportNotify = true;
    this.canIsDrillingBeDisabled = true;
    this.displayImportExcelDialogButtons = true;

    if (this.multipleImportData.length === 0) {
      this.multipleImportData.push(new MultipleCasingImport());
    }

    const IMPORTDATA = this._dataSection.getImportFileData();
    if (IMPORTDATA.length > 0) {
      if (ev.type === 1) {
        this.loadNormalExcel(IMPORTDATA);
      } else {
        this.loadPipeTracerExcel(IMPORTDATA);
      }
    }
  }

  private loadNormalExcel(data: any) {
    let colData = 0;
    for (colData = 0; colData < data[0].length; colData++) {
      this.tableColumns.push({ field: colData, header: data[0][colData] });
    }

    let idx = 1;
    for (idx = 1; idx < data.length; idx++) {
      if (data[idx].length !== 0) {
        const ROW = Object.assign({}, data[idx]);
        if (ROW.hasOwnProperty(0)) {
          this.validateImport([], [], ROW);
        } else {
          if (this.setFileImportNotify) {
            this.tableRows.push(ROW);
          }
        }
      }
    }
  }

  private loadPipeTracerExcel(data: any) {
    const excludedColumns = [
      'Run ?',
      'Ref #',
      'Icon',
      'Centralized ?',
      '# Centralizers',
      'Cent Note',
      'Ext Jwrly',
      'Ref ID',
      'Cum. Len',
      'Top [ftO TH]',
      'Btm Depth [MD] [ftO TH]',
      'Vol Disp Cum [bbl]',
      'Cum Wt [1000bl]',
      'URC',
      'Surplus',
    ];
    let colData = 0;
    for (colData = 0; colData < data[8].length; colData++) {
      const header = data[8][colData];
      const exist = excludedColumns.filter((x) => x === header);
      if (exist.length === 0) {
        this.tableColumns.push({ field: colData, header });
      }
    }

    let idx = 9;
    for (idx; idx < data.length; idx++) {
      if (data[idx].length !== 0) {
        const ROW = Object.assign({}, data[idx]);
        if (ROW.hasOwnProperty(1)) {
          this.validateImport([], [], ROW);
        } else {
          if (this.setFileImportNotify) {
            this.tableRows.push(ROW);
          }
        }
      }
    }

    this.tableRows = this.tableRows.sort((a, b) => (a['1'] > b['1'] ? 1 : b['1'] > a['1'] ? -1 : 0));
  }

  private validateImport(casingDuplicated: any[], drillPipeDuplicated: any[], row: any) {
    if (casingDuplicated.length > 0 && drillPipeDuplicated.length > 0) {
      this.setFileImportNotify = false;
      this.tableRows.push(row);
    } else if (casingDuplicated.length > 0) {
      if (drillPipeDuplicated.length > 0) {
        this.setFileImportNotify = false;
        this.tableRows.push(row);
      } else {
        this.canIsDrillingBeDisabled = false;
        this._sectionCasing.isdrilling = true;
        if (this.setFileImportNotify) {
          this.tableRows.push(row);
        }
      }
    } else if (drillPipeDuplicated.length > 0) {
      if (casingDuplicated.length > 0) {
        this.setFileImportNotify = false;
        this.tableRows.push(row);
      } else {
        this.canIsDrillingBeDisabled = false;
        this._sectionCasing.isdrilling = false;
        if (this.setFileImportNotify) {
          this.tableRows.push(row);
        }
      }
    } else {
      if (this.setFileImportNotify) {
        this.tableRows.push(row);
      }
    }
  }

  public clearImportFile() {
    this.tableRows = [];
    this.tableColumns = [];
    this.multipleImportData = [];
    this.isMultipleImport = false;
    this.setFileImportNotify = undefined;
    this.canIsDrillingBeDisabled = true;
    this._sectionCasing.isdrilling = false;
    this.displayImportExcelDialogButtons = false;
    this._dataSection.setImportFileData([]);
    this._sectionCasing.resetNotifications();
  }

  public createCasingsFromImport() {
    if (this.importFileType === 1) {
      this.initCasingImportChevronExcel();
    } else {
      this.initCasingImportPipeTracer();
    }

    this.casingCreationType = 'Import';
    this.displayImportExcelDialog = false;
    this._sectionCasing.isdrilling = false;
    this.createCasingComponentBulk(this.casingComponentArrayImport);
  }

  public initCasingImportChevronExcel() {
    let isFirstTime = true;
    for (let i = 0; i < this.tableRows.length; i++) {
      let CASING_NEW: CasingComponent = undefined;
      const JOINT_NUMBER = this.tableRows[i]['0'];

      let DTO: MultipleCasingImport = undefined;
      DTO = this.multipleImportData.filter(
        (obj) => Number(JOINT_NUMBER) >= obj.JointStart && Number(JOINT_NUMBER) <= obj.JointEnd
      )[0];

      if (DTO) {
        this._sectionCasing.isdrilling = DTO.IsDrillPipe;
        if (isFirstTime) {
          this._sectionCasing.getLastestJointNumber();
        } else {
          this._sectionCasing.getLastestJointNumber(this.casingComponentArrayImport);
        }

        CASING_NEW = this._sectionCasing.createNewCasingComponent();
        CASING_NEW.Length.Value = this.tableRows[i]['1'];

        if (DTO.ItemDesc && DTO.ItemDesc.length > 0) {
          CASING_NEW.Description.Value = DTO.ItemDesc;
        }
        if (DTO.ItemType && DTO.ItemType.length > 0) {
          CASING_NEW.EquipmentType.Value = DTO.ItemType;
        }
        if (DTO.ODNominal && DTO.ODNominal.length > 0) {
          CASING_NEW.OD.Value = DTO.ODNominal;
        }
        if (DTO.ID && DTO.ID.length > 0) {
          CASING_NEW.ID.Value = DTO.ID;
        }
        if (DTO.WeightLength && DTO.WeightLength.length > 0) {
          CASING_NEW.Weight.Value = DTO.WeightLength;
        }
        if (DTO.Grade && DTO.Grade.length > 0) {
          CASING_NEW.Grade.Value = DTO.Grade;
        }
        if (DTO.TopThread && DTO.TopThread.length > 0) {
          CASING_NEW.TopThread.Value = DTO.TopThread;
        }
        if (DTO.BottomThread && DTO.BottomThread.length > 0) {
          CASING_NEW.BottomThread.Value = DTO.BottomThread;
        }
      } else {
        this._sectionCasing.isdrilling = false;
        if (isFirstTime) {
          this._sectionCasing.getLastestJointNumber();
        } else {
          this._sectionCasing.getLastestJointNumber(this.casingComponentArrayImport);
        }

        CASING_NEW = this._sectionCasing.createNewCasingComponent();
        CASING_NEW.Length.Value = this.tableRows[i]['1'];
      }

      isFirstTime = false;
      this.casingComponentArrayImport.push(CASING_NEW);
    }
  }

  public initCasingImportPipeTracer() {
    let isFirstTime = true;
    for (let i = 0; i < this.tableRows.length; i++) {
      let CASING_NEW: CasingComponent = undefined;
      const JOINT_NUMBER = this.tableRows[i]['1'];

      let DTO: MultipleCasingImport = undefined;
      DTO = this.multipleImportData.filter(
        (obj) => Number(JOINT_NUMBER) >= obj.JointStart && Number(JOINT_NUMBER) <= obj.JointEnd
      )[0];

      if (DTO) {
        this._sectionCasing.isdrilling = DTO.IsDrillPipe;
        if (isFirstTime) {
          this._sectionCasing.getLastestJointNumber();
        } else {
          this._sectionCasing.getLastestJointNumber(this.casingComponentArrayImport);
        }
        CASING_NEW = this._sectionCasing.createNewCasingComponent();
      } else {
        this._sectionCasing.isdrilling = false;
        if (isFirstTime) {
          this._sectionCasing.getLastestJointNumber();
        } else {
          this._sectionCasing.getLastestJointNumber(this.casingComponentArrayImport);
        }
        CASING_NEW = this._sectionCasing.createNewCasingComponent();
      }

      CASING_NEW.Length.Value = this.tableRows[i]['3'];

      if (DTO && DTO.ItemDesc && DTO.ItemDesc.length > 0) {
        CASING_NEW.Description.Value = DTO.ItemDesc;
      } else {
        const DESC = this.tableRows[i]['4'];
        CASING_NEW.Description.Value =
          DESC && DESC.length > 0 && this.validateImportDescription(DESC) ? DESC : 'Casing Joint';
      }

      if (DTO && DTO.ItemType && DTO.ItemType.length > 0) {
        CASING_NEW.EquipmentType.Value = DTO.ItemType;
      } else {
        const TYPE = this.tableRows[i]['11'];
        CASING_NEW.EquipmentType.Value = TYPE && TYPE.length > 0 && this.validateImportType(TYPE) ? TYPE : 'Casing';
      }

      if (DTO && DTO.ODNominal && DTO.ODNominal.length > 0) {
        CASING_NEW.OD.Value = DTO.ODNominal;
      } else {
        const OD = this.tableRows[i]['5'];
        CASING_NEW.OD.Value = OD && OD.length > 0 ? OD : '';
      }

      if (DTO && DTO.ID && DTO.ID.length > 0) {
        CASING_NEW.ID.Value = DTO.ID;
      } else {
        const ID = this.tableRows[i]['8'];
        CASING_NEW.ID.Value = ID && ID.length > 0 ? ID : '';
      }

      if (DTO && DTO.WeightLength && DTO.WeightLength.length > 0) {
        CASING_NEW.Weight.Value = DTO.WeightLength;
      } else {
        const WT = this.tableRows[i]['6'];
        CASING_NEW.Weight.Value = WT && WT.length > 0 ? WT : '';
      }

      if (DTO && DTO.Grade && DTO.Grade.length > 0) {
        CASING_NEW.Grade.Value = DTO.Grade;
      } else {
        const GRADE = this.tableRows[i]['7'];
        CASING_NEW.Grade.Value = GRADE && GRADE.length > 0 ? GRADE : '';
      }

      if (DTO && DTO.TopThread && DTO.TopThread.length > 0) {
        CASING_NEW.TopThread.Value = DTO.TopThread;
      } else {
        const TT = this.tableRows[i]['9'];
        CASING_NEW.TopThread.Value = TT && TT.length > 0 && this.validateImportTopThread(TT) ? TT : '8RD';
      }

      if (DTO && DTO.BottomThread && DTO.BottomThread.length > 0) {
        CASING_NEW.BottomThread.Value = DTO.BottomThread;
      }

      isFirstTime = false;
      this.casingComponentArrayImport.push(CASING_NEW);
    }
  }

  public validateAllCasingInspect() {
    this._sectionCasing.resetNotifications();
    this.message.next({
      key: 'c',
      sticky: true,
      severity: 'warn',
      summary: 'Casing Validation',
      detail:
        'Are you sure you want to validate the selected casing string arrangements and proceed with casing running tally?',
      clear: true,
      toastType: 'ValidateAll',
    });
  }

  public setMultipleImportIsDrilling(index: number) {
    if (this.multipleImportData[index].IsDrillPipe) {
      this.multipleImportData[index].ItemDesc = 'Drill Pipe';
      this.multipleImportData[index].ItemType = 'Other';
    }
  }

  public filterODNominalMultipleImport(dto: MultipleCasingImport, index: number) {
    this.multipleImportIndex = index;
    this.displayCasingComponentConfigurations = true;
    this.filterODNominalSideBar(dto.ODNominal);
  }

  public addNewMultipleImportRecord() {
    const DATA = new MultipleCasingImport();
    DATA.JointStart = this.multipleImportData[this.multipleImportData.length - 1].JointEnd + 1;
    if (DATA.JointStart) {
      this.multipleImportData.push(DATA);
    }
  }

  public deleteMultipleImportRecord(index: number) {
    this.multipleImportData.splice(index, 1);
  }

  public setValueJTEnd(index: number) {
    const JTS = this.multipleImportData[index].JointStart;
    const JTE = this.multipleImportData[index].JointEnd || 1;

    if (JTE < JTS) {
      this.multipleImportData[index].JointEnd = JTS + 1;
    }
  }
  //#endregion 'Import'

  //#region 'Validations'
  public isThreadOffValid(to: any) {
    return to && to !== NaN ? true : false;
  }

  private validateImportDescription(text: string): boolean {
    const DESC = this._sectionCasing.lstCasingDescription.filter((obj) => obj.Description === text);
    return DESC && DESC.length > 0 ? true : false;
  }

  private validateImportType(text: string): boolean {
    const TYPE = this._sectionCasing.lstCasingComponentSubType.filter((obj) => obj === text);
    return TYPE && TYPE.length > 0 ? true : false;
  }

  private validateImportTopThread(text: string): boolean {
    const TT = this._sectionCasing.filteredTopThread.filter((obj) => obj.Name === text);
    return TT && TT.length > 0 ? true : false;
  }

  public isDrill(index: number): boolean {
    const DESC = this._sectionCasing.lstCasingComponent[index].Description.Value;
    if (DESC === 'Drill Pipe' || DESC === 'Heavyweight Drill Pipe') {
      return true;
    } else {
      return false;
    }
  }

  public validateImportJTEnd(index: number) {
    const JTStart = this.multipleImportData[index].JointStart;
    const JTEnd = this.multipleImportData[index].JointEnd;

    if (JTEnd <= JTStart) {
      this.multipleImportData[index].JointEnd = JTStart + 1;
    }
  }
  //#endregion 'Validations'

  //#region 'General Methods'
  public computeThreadOffValues(casingComponent: CasingComponent, index: number, info: string, type: number) {
    let original: number = 0;
    let update = false;

    if (type == 1) {
      //? Thread On
      original = Number(this._sectionCasing.lstCasingComponentOriginal[index].Length.Value);
      update = original == Number(casingComponent.Length.Value) ? false : true;
    } else if (type == 2) {
      //? Thread Loss
      original = Number(this._sectionCasing.lstCasingComponentOriginal[index].ThreadLoss);
      update = original == Number(casingComponent.ThreadLoss) ? false : true;
    } else if (type == 3) {
      update = true;
    }

    if (update) {
      this.threadOffValues.next({
        casingComponent: casingComponent,
        index: index,
        info: info,
      });
    }
  }

  public selectDDLchangeHandler(casingComponent: CasingComponent, index: number) {
    casingComponent = this.isMultiEdit ? this.tempComponentMultipleEdit : casingComponent;

    this.displayCasingComponentConfigurations = true;
    this.tmpSelectedIndex = index;
    this.tmpSelectedCasingComponent = casingComponent;

    this.filterODNominalSideBar(this.isMultiEdit ? this.ME_ODNominal : casingComponent.OD.Value);
    this.resetFilters();
  }

  public filterODNominalSideBar(value: string) {
    this.sidebar_CasingODID = this._sectionCasing.lstCasingODID.filter((i) => i.OD === value);
  }

  public resetALLData() {
    this.showMultiEdit = false;
    this.displayMultipleEdit = false;
    this.displayAddCasingOverlayPanel = false;
    this.displayCasingComponentConfigurations = false;
    this.multipleAddData = [];
    this.multipleImportData = [];
    this.casingsToMultiEdit = [];

    this.ME_ODNominal = undefined;
    this.ME_ID = undefined;
    this.ME_WeighLength = undefined;
    this.ME_Grade = undefined;
    this.ME_ThreadOn = undefined;
    this.ME_ChkThreadLoss = false;
    this.ME_ThreadLoss = undefined;
    this.ME_TopThread = undefined;
    this.ME_BottomThread = undefined;
    this.OLD_ODNominal = undefined;
    this.OLD_ID = undefined;
    this.OLD_WeightLength = undefined;
    this.OLD_Grade = undefined;
    this.OLD_Index = undefined;
    this.resetOldValues = true;
    this.isMultiEdit = false;
    this.isMultipleAdd = false;
    this.isMultipleImport = false;
    this.editAll = false;
    this.tempComponentMultipleEdit = undefined;
  }

  public selectNextThreadOn(idx: number) {
    const TXT = document.getElementById(`txt_Length${idx}`);
    if (TXT) {
      TXT.focus();
    }
  }

  public isDrillingDescription(desc: string, index: number, comeFrom: number) {
    //? comeFrom (1 = Import, 2 = Add)
    if (comeFrom === 1) {
      if (desc === 'Drill Pipe' || desc === 'Heavyweight Drill Pipe') {
        this.multipleImportData[index].IsDrillPipe = true;
        this.multipleImportData[index].ItemType = 'Other';
      } else {
        this.multipleImportData[index].IsDrillPipe = false;
        this.multipleImportData[index].ItemType = '';
      }
    }

    if (comeFrom === 2) {
      if (desc === 'Drill Pipe' || desc === 'Heavyweight Drill Pipe') {
        this.multipleAddData[index].IsDrillPipe = true;
        this.multipleAddData[index].ItemType = 'Other';
      } else {
        this.multipleAddData[index].IsDrillPipe = false;
        this.multipleAddData[index].ItemType = '';
      }
    }
  }

  public resetFilters() {
    this.F_ODNominal = '';
    this.F_ID = '';
    this.F_WeightLength = '';
    this.F_Grade = '';
    this.dt.reset();
  }

  public updateDescription(cc: CasingComponent, index: number) {
    if (cc.Description.Value === 'Drill Pipe' || cc.Description.Value === 'Heavyweight Drill Pipe') {
      this._sectionCasing.isdrilling = true;
      this._sectionCasing.getLastestJointNumber();
      cc.IsExclude = true;
      cc.JointNumber = this._sectionCasing.getLastJoint(false);
      cc.RefID.Value = cc.JointNumber;
      cc.EquipmentType.Value = 'Other';
      this._sectionCasing.isdrilling = false;
      this.updateCasingInspect(cc, index, '');
    } else {
      this._sectionCasing.isdrilling = false;
      this.updateCasingInspect(cc, index, '');
    }
  }
  //#endregion 'General Methods'

  //#region 'Report PDF & Excel'
  public generateReport(OPGenerateReports: OverlayPanel) {
    if (this.selectedReportFormat.includes('PDF')) {
      this._sectionCasing.getCasingInventoryPDF().then(
        () => {},
        (error) => {
          this.showNotification(error);
        }
      );
    }
    if (this.selectedReportFormat.includes('Excel')) {
      this._sectionCasing.getCasingInventoryExcel().then(
        () => {},
        (error) => {
          this.showNotification(error);
        }
      );
    }
    this.selectedReportFormat = [];
    OPGenerateReports.hide();
  }
  //#endregion 'Report PDF & Excel'

  //#region 'Multiple Edit'
  private validateSelectedAll() {
    const notValidated = this._sectionCasing.lstCasingComponent.filter((obj) => !obj.IsValidated);

    if (notValidated.length !== this.casingsToMultiEdit.length) {
      this.editAll = false;
    } else {
      this.editAll = true;
    }
  }

  public resetMultipleEdit() {
    this.selectedItemsDesc = [];
    this.casingsToMultiEdit = [];
    this.deletedCasingComponentArray = [];
    this.tempComponentMultipleEdit = null;
    this.isMultiEdit = false;
    this.showMultiEdit = false;
    this.editAll = false;
    this.displayMultipleEdit = false;

    this.ME_ODNominal = undefined;
    this.ME_ID = undefined;
    this.ME_WeighLength = undefined;
    this.ME_Grade = undefined;
    this.ME_ThreadOn = undefined;
    this.ME_ChkThreadLoss = undefined;
    this.ME_ThreadLoss = undefined;
    this.ME_TopThread = undefined;
    this.ME_BottomThread = undefined;
  }

  public selectAll() {
    if (this.editAll) {
      let index = 0;
      this._sectionCasing.lstCasingComponent.map((element) => {
        if (!element.IsValidated) {
          element.isEdit = true;
          this.allowMultiEdit(element, index, true);
        }
        index++;
        return element;
      });
    } else {
      this._sectionCasing.lstCasingComponent.map((element) => {
        element.isEdit = false;
        return element;
      });
      this.resetMultipleEdit();
    }
  }

  public allowMultiEdit(cc: CasingComponent, index: number, doDeleteCalc?: boolean) {
    this.checkCasingstoEdit(cc, index);
    const casingComponent = this._sectionCasing.lstCasingComponent.filter((obj) => obj.isEdit === true);
    this.tempComponentMultipleEdit = casingComponent.length > 0 ? casingComponent[0] : null;
    this.showMultiEdit = casingComponent.length > 0 ? true : false;

    if (doDeleteCalc) {
      this.deleteCasingInspect(cc);
    }
  }

  private checkCasingstoEdit(casingComponent: CasingComponent, index: number) {
    const newEditComponent = { casingComponent, index };
    const filteredEdit = this.casingsToMultiEdit.filter((obj) => obj.index === newEditComponent.index);
    if (this.casingsToMultiEdit.length > 0) {
      if (filteredEdit.length > 0) {
        this.casingsToMultiEdit = this.casingsToMultiEdit.filter((obj) => obj.index !== newEditComponent.index);
      } else {
        this.casingsToMultiEdit.push(newEditComponent);
      }
    } else {
      this.casingsToMultiEdit.push({ casingComponent, index });
    }
    this.validateSelectedAll();
  }

  public showMultiEditCasings() {
    this.displayMultipleEdit = true;
    this.isMultiEdit = true;

    this.ME_ChkThreadLoss = undefined;
    this.ME_BottomThread = undefined;
    this.ME_Grade = undefined;
    this.ME_ID = undefined;
    this.ME_ODNominal = undefined;
    this.ME_ThreadLoss = undefined;
    this.ME_ThreadOn = undefined;
    this.ME_TopThread = undefined;
    this.ME_WeighLength = undefined;
  }

  private applyMultiEdit(arrayCasingComponent: CasingComponent[]) {
    this.updateMultipleEdit.next({ arrayEdited: arrayCasingComponent });
  }

  public selectItems() {
    if (this.selectedItemsDesc.length > 0) {
      this.resetEditOnList();

      if (this.selectedItemsDesc.length === 1) {
        this.loopEdit(this.selectedItemsDesc[0].toUpperCase());
      } else {
        for (let idx = 0; idx < this.selectedItemsDesc.length; idx++) {
          const element = this.selectedItemsDesc[idx].toUpperCase();
          this.loopEdit(element);
        }
      }

      this.deletedCasingComponentArray = [];
      const TO_DELETE = this._sectionCasing.lstCasingComponent.filter((obj) => obj.isEdit === true);
      this.deletedCasingComponentArray = [...TO_DELETE];
    } else {
      this.resetEditOnList();
    }
  }

  private resetEditOnList() {
    this._sectionCasing.lstCasingComponent.map((element) => {
      element.isEdit = false;
      return element;
    });

    this.showMultiEdit = false;
    this.casingsToMultiEdit = [];
    this.validateSelectedAll();
  }

  private loopEdit(item: string) {
    for (let idx = 0; idx < this._sectionCasing.lstCasingComponent.length; idx++) {
      const element: CasingComponent = this._sectionCasing.lstCasingComponent[idx];
      if (element.Description.Value.toUpperCase() === item) {
        element.isEdit = !element.isEdit;
        this.allowMultiEdit(element, idx, false);
      }
    }
  }
  //#endregion 'Multiple Edit'

  //#region 'Multiple Add'
  public openMultipleEdit() {
    if (this.multipleAddData.length === 0) {
      this.multipleAddData.push(new MultipleCasingAdd());
    }

    this.isMultipleAdd = true;
    this.showMultipleAdd = true;
  }

  public hideMultipleAdd() {
    this.isMultipleAdd = false;
    this.showMultipleAdd = false;
    this.showMultipleAddError = false;
  }

  public addNewMultipleAddRecord() {
    this.multipleAddData.push(new MultipleCasingAdd());
  }

  public deleteMultipleAddRecord(index: number) {
    this.multipleAddData.splice(index, 1);
  }

  public addMultiple() {
    if (!this.verifyAddMultiple()) {
      let isFirstTime = true;
      this.hideMultipleAdd();
      let casingComponentarrayBulk: CasingComponent[] = [];

      this.multipleAddData.forEach((dto) => {
        this._sectionCasing.isdrilling = dto.IsDrillPipe;

        if (isFirstTime) {
          this._sectionCasing.getLastestJointNumber();
        } else {
          this._sectionCasing.getLastestJointNumber(casingComponentarrayBulk);
        }

        let i = 0;
        for (i = 0; i < dto.Quantity; i++) {
          const NEWCASING = this._sectionCasing.createNewCasingComponent();

          NEWCASING.Description.Value = dto.ItemDesc;
          NEWCASING.EquipmentType.Value = dto.ItemType;
          NEWCASING.OD.Value = dto.ODNominal && dto.ODNominal.length > 0 ? dto.ODNominal : NEWCASING.OD.Value;
          NEWCASING.ID.Value = dto.ID && dto.ID.length > 0 ? dto.ID : NEWCASING.ID.Value;
          NEWCASING.Weight.Value =
            dto.WeightLength && dto.WeightLength.length > 0 ? dto.WeightLength : NEWCASING.Weight.Value;
          NEWCASING.Grade.Value = dto.Grade && dto.Grade.length > 0 ? dto.Grade : NEWCASING.Grade.Value;
          NEWCASING.TopThread.Value =
            dto.TopThread && dto.TopThread.length > 0 ? dto.TopThread : NEWCASING.TopThread.Value;
          NEWCASING.BottomThread.Value =
            dto.BottomThread && dto.BottomThread.length > 0 ? dto.BottomThread : NEWCASING.BottomThread.Value;

          casingComponentarrayBulk.push(NEWCASING);
        }
        isFirstTime = false;
      });

      this.displayAddCasingOverlayPanel = false;
      this.casingCreationType = 'AddBulkCasings';
      this.createCasingComponentBulk(casingComponentarrayBulk);
      this.editAll = false;
      this._sectionCasing.isdrilling = false;
      this.selectAll();
    }
  }

  public filterODNominalMultipleAdd(dto: MultipleCasingAdd, index: number) {
    this.multipleAddIndex = index;
    this.displayCasingComponentConfigurations = true;
    this.filterODNominalSideBar(dto.ODNominal);
  }

  private verifyAddMultiple(): boolean {
    let error = false;
    this.multipleAddData.forEach((dto) => {
      if (!dto.Quantity || dto.Quantity.toString().length === 0) {
        error = true;
      }

      if (!dto.ItemDesc || (dto.ItemDesc && dto.ItemDesc.length === 0)) {
        error = true;
      }

      if (!dto.ItemType || (dto.ItemType && dto.ItemType.length === 0)) {
        error = true;
      }
    });
    this.showMultipleAddError = error;
    return error;
  }
  //#endregion 'Multiple Add'

  //#region 'Sidebar'
  public casingComponentSelect(event: any) {
    this.resetOldValues = false;
    this._sectionCasing.selectedCasingODID = event.data;

    if (!this.isMultiEdit && !this.isMultipleAdd && !this.isMultipleImport) {
      if (!!this._sectionCasing.selectedCasingODID.OD) {
        this._sectionCasing.lstCasingComponent[this.tmpSelectedIndex].OD.Value = this._sectionCasing.filteredOD.find(
          (x) => x.OD === this._sectionCasing.selectedCasingODID.OD
        ).OD;
      } else {
        this._sectionCasing.lstCasingComponent[this.tmpSelectedIndex].OD.Value = '';
      }

      if (!!this._sectionCasing.selectedCasingODID.ID) {
        this._sectionCasing.lstCasingComponent[this.tmpSelectedIndex].ID.Value = this._sectionCasing.filteredID.find(
          (x) => x.ID === this._sectionCasing.selectedCasingODID.ID
        ).ID;
      } else {
        this._sectionCasing.lstCasingComponent[this.tmpSelectedIndex].ID.Value = '';
      }

      if (!!this._sectionCasing.selectedCasingODID.Grade) {
        this._sectionCasing.lstCasingComponent[
          this.tmpSelectedIndex
        ].Grade.Value = this._sectionCasing.filteredGrade.find(
          (x) => x.Grade === this._sectionCasing.selectedCasingODID.Grade
        ).Grade;
      } else {
        this._sectionCasing.lstCasingComponent[this.tmpSelectedIndex].Grade.Value = '';
      }

      if (!!this._sectionCasing.selectedCasingODID.Weight) {
        this._sectionCasing.lstCasingComponent[
          this.tmpSelectedIndex
        ].Weight.Value = this._sectionCasing.filteredWeight.find(
          (x) => x.Weight === this._sectionCasing.selectedCasingODID.Weight
        ).Weight;
      } else {
        this._sectionCasing.lstCasingComponent[this.tmpSelectedIndex].Weight.Value = '';
      }

      this.updateCasingInspect(this.tmpSelectedCasingComponent, this.tmpSelectedIndex, '');
    } else if (this.isMultiEdit) {
      this.ME_ODNominal = event.data.OD;
      this.ME_ID = event.data.ID;
      this.ME_WeighLength = event.data.Weight;
      this.ME_Grade = event.data.Grade;
    } else if (this.isMultipleAdd) {
      this.multipleAddData[this.multipleAddIndex].ODNominal = event.data.OD;
      this.multipleAddData[this.multipleAddIndex].ID = event.data.ID;
      this.multipleAddData[this.multipleAddIndex].WeightLength = event.data.Weight;
      this.multipleAddData[this.multipleAddIndex].Grade = event.data.Grade;
    } else if (this.isMultipleImport) {
      this.multipleImportData[this.multipleImportIndex].ODNominal = event.data.OD;
      this.multipleImportData[this.multipleImportIndex].ID = event.data.ID;
      this.multipleImportData[this.multipleImportIndex].WeightLength = event.data.Weight;
      this.multipleImportData[this.multipleImportIndex].Grade = event.data.Grade;
    } else {
      this.resetOldValues = true;
    }

    this.displayCasingComponentConfigurations = false;
  }

  public getCurrentValues(casing: any, index: number, type: number) {
    if (type === 1) {
      this.OLD_ODNominal = casing.OD.Value;
      this.OLD_Index = index;
    } else if (type === 2) {
      this.OLD_ODNominal = this.ME_ODNominal;
    } else if (type === 3) {
      this.OLD_ODNominal = casing.ODNominal;
    }
  }

  public resetToOldValues() {
    if (this.resetOldValues && this.OLD_Index >= 0 && !this.isMultiEdit && !this.isMultipleAdd) {
      this._sectionCasing.lstCasingComponent[this.OLD_Index].OD.Value = this.OLD_ODNominal;
    } else if (this.resetOldValues && this.isMultiEdit) {
      this.ME_ODNominal = this.OLD_ODNominal;
    } else if (this.resetOldValues && this.isMultipleAdd) {
      this.multipleAddData[this.multipleAddIndex].ODNominal = this.OLD_ODNominal;
    } else if (this.resetOldValues && this.isMultipleImport) {
      this.multipleImportData[this.multipleImportIndex].ODNominal = this.OLD_ODNominal;
    }

    this.resetOldValues = true;
  }
  //#endregion 'Sidebar'

  //#region 'CRUD'
  private async createCasingComponentBulk(casingComponentArrayBulk: any[]) {
    this._loader.show();
    this.SUBS$.add(
      await this._casingComponent.createBulk(casingComponentArrayBulk).subscribe(
        (data: CasingComponent[]) => {
          this.multipleImportData = [];
          this.isMultipleImport = false;
          this._sectionCasing.lstCasingComponent = this._sectionCasing.lstCasingComponent.concat(data);
          this._sectionCasing.loadOriginalCasingComponent();
          this._loader.hide();

          this.showNotification({
            severity: 'success',
            summary: 'Correct!',
            detail: 'Casings Created Successfully.',
          });

          if (this.casingCreationType === 'Import') {
            this.displayImportExcelDialogButtons = false;
            this.tableColumns = [];
            this.tableRows = [];
            this.casingComponentArrayImport = [];
            this._dataSection.setImportFileData([]);
            this.casingCreationType = '';
          }
        },
        () => {
          this._loader.hide();
          this.showNotification({
            key: 'inventoryError',
            severity: 'error',
            summary: 'We got a problem!',
            detail: 'Please refresh the page and re-upload file or notify the administrator.',
            life: 6000,
          });
          if (this.casingCreationType === 'Import') {
            this.displayImportExcelDialogButtons = false;
            this.tableColumns = [];
            this.tableRows = [];
            this.casingComponentArrayImport = [];
            this._dataSection.setImportFileData([]);
            this.casingCreationType = '';
          }
        }
      )
    );
  }

  public updateCasingInspect(casingComponent: CasingComponent, index: number, infoField: string) {
    this._sectionCasing.checkforDuplicateJointNumbers(casingComponent, index).then(
      (valid: boolean) => {
        if (valid) {
          this._sectionCasing.resetNotifications();
          this.updtCasingComponent.next({
            casingComponent,
            index,
            infoField,
          });
        }
      },
      (error) => {
        this.showNotification(error);
      }
    );
  }

  public updateMultipleValues() {
    const arrEdits: CasingComponent[] = [];

    this.casingsToMultiEdit.map((element) => {
      if (this.ME_ODNominal && this.ME_ODNominal.length > 0) {
        element.casingComponent.OD.Value = this.ME_ODNominal;
      }
      if (this.ME_ID && this.ME_ID.length > 0) {
        element.casingComponent.ID.Value = this.ME_ID;
      }
      if (this.ME_WeighLength && this.ME_WeighLength.length > 0) {
        element.casingComponent.Weight.Value = this.ME_WeighLength;
      }
      if (this.ME_Grade && this.ME_Grade.length > 0) {
        element.casingComponent.Grade.Value = this.ME_Grade;
      }
      if (this.ME_ThreadLoss && this.ME_ThreadLoss.toString().length > 0) {
        element.casingComponent.ThreadLoss = this.ME_ThreadLoss;
      }
      if (this.ME_ThreadOn && this.ME_ThreadOn.toString().length > 0) {
        element.casingComponent.Length.Value = this.ME_ThreadOn;
      }
      if (this.ME_TopThread && this.ME_TopThread.length > 0) {
        element.casingComponent.TopThread.Value = this.ME_TopThread;
      }
      if (this.ME_BottomThread && this.ME_BottomThread.length > 0) {
        element.casingComponent.BottomThread.Value = this.ME_BottomThread;
      }

      element.casingComponent.IsThreadLoss = this.ME_ChkThreadLoss;
      if (this.ME_ODNominal && this.ME_ODNominal.length > 0) {
        this._sectionCasing.lstCasingComponent[element.index].OD.Value = this.ME_ODNominal;
      }
      if (this.ME_ID && this.ME_ID.length > 0) {
        this._sectionCasing.lstCasingComponent[element.index].ID.Value = this.ME_ID;
      }
      if (this.ME_WeighLength && this.ME_WeighLength.length > 0) {
        this._sectionCasing.lstCasingComponent[element.index].Weight.Value = this.ME_WeighLength;
      }
      if (this.ME_Grade && this.ME_Grade.length > 0) {
        this._sectionCasing.lstCasingComponent[element.index].Grade.Value = this.ME_Grade;
      }
      if (this.ME_TopThread && this.ME_TopThread.length > 0) {
        this._sectionCasing.lstCasingComponent[element.index].TopThread.Value = this.ME_TopThread;
      }
      if (this.ME_BottomThread && this.ME_BottomThread.length > 0) {
        this._sectionCasing.lstCasingComponent[element.index].BottomThread.Value = this.ME_BottomThread;
      }

      arrEdits.push(element.casingComponent);
    });

    this.applyMultiEdit(arrEdits);
    this.resetMultipleEdit();
  }

  public removeCasingInspect() {
    if (this.deletedCasingComponentArray.length > 0) {
      this.message.next({
        key: 'c',
        sticky: true,
        severity: 'warn',
        summary: 'Delete Casing',
        detail: 'Are you sure you want to delete selected casing?',
        clear: true,
        toastType: 'Delete',
      });
    } else {
      this.showNotification({
        key: 'inventoryError',
        severity: 'error',
        summary: 'We got a problem!',
        detail: 'Please select casing components to delete and try again.',
        life: 6000,
      });
    }
  }

  public deleteCasingInspect(casingComponent: CasingComponent) {
    if (this.deletedCasingComponentArray.includes(casingComponent)) {
      this.deletedCasingComponentArray = this.deletedCasingComponentArray.filter((obj) => obj !== casingComponent);
    } else {
      this.deletedCasingComponentArray.push(casingComponent);
    }
  }
  //#endregion 'CRUD'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._message.add({
      key: msg && msg.key && msg.key.length > 0 ? msg.key : 'inventoryNotify',
      sticky: msg && msg.key && msg.key != 'inventoryNotify' ? true : false,
      closable: msg && msg.key && msg.key != 'inventoryNotify' ? true : false,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life,
    });
  }
  //#endregion 'Notification'
}
