import { TaskPlanApiService } from '#services-api/index';
import { LoadingIndicatorService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-task-plan-section-delete',
  templateUrl: './task-plan-section-delete.component.html',
})
export class TaskPlanSectionDeleteComponent implements OnInit, OnDestroy {
  public taskPlanId: number;
  public taskPlanDesc: string;
  public taskPlanSectionId: number;
  public taskPlanSectionDesc: string;

  //? Subscriptions
  private SUBS$ = new Subscription();

  constructor(
    private taskPlanSvc: TaskPlanApiService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.SUBS$.add(
      this.aRoute.params.subscribe((el) => {
        this.taskPlanId = el['taskPlanId'];
        this.taskPlanDesc = el['taskPlanDesc'];
        this.taskPlanSectionId = el['id'];
        this.taskPlanSectionDesc = el['desc'];
      })
    );
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }

  public onRedirectButtonClick(): void {
    this._router.navigate(['admin/task-plan-manage', { id: this.taskPlanId, sectionId: this.taskPlanSectionId }]);
  }

  public deleteTaskPlanSection(): void {
    this.loadingIndicatorSvc.show();
    this.SUBS$.add(
      this.taskPlanSvc
        .deleteTaskPlanSection(this.taskPlanSectionId)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe(() => {
          this.onRedirectButtonClick();
        })
    );
  }
}
