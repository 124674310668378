import { TaskPlan } from '#models/index';
import { TaskPlanApiService } from '#services-api/index';
import { LoadingIndicatorService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-task-plan-edit',
  templateUrl: './task-plan-edit.component.html',
})
export class TaskPlanEditComponent implements OnInit, OnDestroy {
  public taskPlan: TaskPlan = new TaskPlan();
  public taskPlanId: number;
  public displayWarningDesc: boolean;

  //? Subscriptions
  private SUBS$ = new Subscription();

  constructor(
    private taskPlanSvc: TaskPlanApiService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.SUBS$.add(this.aRoute.params.subscribe((el) => (this.taskPlanId = el['id'])));
    this.getTaskPlanById(this.taskPlanId);
    this.displayWarningDesc = false;
  }
  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }

  public onRedirectButtonClick(): void {
    this._router.navigate(['admin/task-plan']);
  }

  public getTaskPlanById(id: number): void {
    this.loadingIndicatorSvc.show();
    this.SUBS$.add(
      this.taskPlanSvc
        .getTaskPlanById(id)
        .finally(() => this.loadingIndicatorSvc.hide())
        .subscribe((data: any) => {
          this.taskPlan.Code = data.Code;
          this.taskPlan.Description = data.Description;
          this.taskPlan.ID = data.ID;
        })
    );
  }

  public updateTaskPlan(): void {
    this.displayWarningDesc =
      this.taskPlan.Description === undefined ||
      this.taskPlan.Description == null ||
      this.taskPlan.Description.length === 0;
    if (!this.displayWarningDesc) {
      this.loadingIndicatorSvc.show();
      this.SUBS$.add(
        this.taskPlanSvc
          .updateTaskPlan(this.taskPlan)
          .finally(() => this.loadingIndicatorSvc.hide())
          .subscribe(() => {
            this.onRedirectButtonClick();
          })
      );
    }
  }
}
