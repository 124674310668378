import { DailyOperation, NotificationToast } from '#models/index';
import { DailyOperationService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-daily-operation',
  templateUrl: './daily-operation.component.html',
  styleUrls: ['../operations.shared.scss'],
  providers: [MessageService],
})
export class DailyOperationComponent implements OnInit, OnDestroy {
  //#region 'Variables'
  public setToastType = '';
  public currentRigTime = 0;
  public isDailyUpdate = false;
  public areDatesInvalids = false;
  public showEndDateError = false;
  public showRigTimeError = false;
  public showCreateNewDaily = false;
  public showSmartProcedures = true;
  public showStartDateError = false;
  public showErrorCreateNewDaily = false;

  // Forms
  public formDailyOperation: FormGroup;
  private validateDates = false;

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(public _sectionDailyOperation: DailyOperationService, private _message: MessageService) {}

  ngOnInit(): void {
    this._sectionDailyOperation.loadActivityCodes();
    this._sectionDailyOperation.loadDOPs();
    this.loadFormDaily();
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  public loadFormDaily() {
    this.formDailyOperation = new FormGroup({
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      rigTime: new FormControl('', Validators.required),
      rptTimeStatus: new FormControl('', Validators.required),
      summary: new FormControl('', Validators.required),
      projectedOps: new FormControl('', Validators.required),
    });

    this._onStartDateFormChanges();
    this._onEndDateFormChanges();
  }

  private _onStartDateFormChanges() {
    this.SUBS$.add(
      this.formDailyOperation.get('startDate').valueChanges.subscribe(() => {
        if (this.validateDates) {
          this.areDatesValid();
        }
      })
    );
  }

  private _onEndDateFormChanges() {
    this.SUBS$.add(
      this.formDailyOperation.get('endDate').valueChanges.subscribe(() => {
        if (this.validateDates) {
          this.areDatesValid();
        }
      })
    );
  }
  //#endregion 'Load'

  //#region 'Modals'
  public openDailyModal(isEdit: boolean) {
    if (!isEdit) {
      this.resetFormDailyOperation();
    }
    this.isDailyUpdate = isEdit;
    this.showErrorCreateNewDaily = false;
    this.showCreateNewDaily = true;
  }

  public closeDailyModal() {
    this.showCreateNewDaily = false;
  }
  //#endregion 'Modals'

  //#region 'Validation'
  private isDailyOperationValid(): boolean {
    if (!this.formDailyOperation.valid) {
      this.showErrorCreateNewDaily = true;
      return false;
    }

    if (this.areDatesInvalids) {
      return false;
    }

    if (this.showStartDateError) {
      return false;
    }

    if (this.showEndDateError) {
      return false;
    }

    if (this.showRigTimeError) {
      return false;
    }

    this.showRigTimeError = false;
    this.areDatesInvalids = false;
    this.showErrorCreateNewDaily = false;
    return true;
  }

  private validateRigTime(): boolean {
    const NEWRIGTIME = Number(this.formDailyOperation.controls['rigTime'].value);
    const AVAILABLETIME = this.getAvailableTime(
      this.formDailyOperation.controls['startDate'].value,
      this.formDailyOperation.controls['endDate'].value
    );

    if (NEWRIGTIME > 24 || NEWRIGTIME > AVAILABLETIME) {
      this.showRigTimeError = true;
      return false;
    } else {
      this.showRigTimeError = false;
      return true;
    }
  }
  //#endregion 'Validation'

  //#region 'CRUD'
  public createDailyOperation() {
    if (this.isDailyOperationValid()) {
      this._sectionDailyOperation.formDOP = this.formDailyOperation;
      this._sectionDailyOperation.createNewDOP().then(
        (done: any) => {
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
      this.closeDailyModal();
    }
  }

  public updateDailyOperation() {
    this.validateRigTime();
    if (this.isDailyOperationValid() && this.validateRigTime()) {
      this._sectionDailyOperation.formDOP = this.formDailyOperation;
      this._sectionDailyOperation.updateDOP().then(
        (done: any) => {
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
      this.closeDailyModal();
    }
  }

  public deleteDOP(dto: DailyOperation) {
    this._sectionDailyOperation.DOPDelteObject = dto;
    this.showMessage({
      key: 'c',
      sticky: true,
      severity: 'warn',
      summary: 'Delete Daily Component',
      detail: 'Are you sure you want to delete the selected Daily Component?',
      clear: true,
      toastType: 'DeleteDaily',
    });
  }
  //#endregion 'CRUD'

  //#region 'Toast'
  public showMessage(msg: any) {
    if (msg.clear) {
      this.hideToast();
    }

    if (msg.toastType) {
      this.setToastType = msg.toastType;
    }

    this._message.clear();
    this._message.add({
      key: msg.key,
      sticky: msg.sticky,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
    });
  }

  public onReject() {
    this.hideToast();
  }

  private hideToast() {
    this._message.clear('c');
  }

  public async onConfirm() {
    this.hideToast();
    if (this.setToastType === 'DeleteDaily') {
      this._sectionDailyOperation.deleteDOP().then(
        (done: any) => {
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
    } else if (this.setToastType === 'DeleteTimeLog') {
      this._sectionDailyOperation.deleteTML().then(
        (done: any) => {
          this.showNotification(done);
        },
        (error) => {
          this.showNotification(error);
        }
      );
    }
  }
  //#endregion 'Toast'

  //#region 'General Methods'
  public editDailyOperation(index?: number) {
    if (index) {
      this.selectDOP(index);
    } else {
      this.loadDailyToEdit();
    }

    this.openDailyModal(true);
  }

  private resetFormDailyOperation() {
    this.formDailyOperation.reset();
    this.formDailyOperation.controls['startDate'].setValue(moment().format('MM/DD/YYYY 00:00'));
    this.formDailyOperation.controls['endDate'].setValue(moment().add(1, 'days').format('MM/DD/YYYY 00:00'));
  }

  public selectDOP(index: number) {
    this._sectionDailyOperation.selectDOP(index, true);
    this.loadDailyToEdit();
  }

  private loadDailyToEdit() {
    this.validateDates = false;
    this.formDailyOperation.controls['startDate'].setValue(this._sectionDailyOperation.DOPObject.ReportStartDate.Value);
    this.formDailyOperation.controls['endDate'].setValue(this._sectionDailyOperation.DOPObject.ReportEndDate.Value);
    this.formDailyOperation.controls['summary'].setValue(this._sectionDailyOperation.DOPObject.OperationsSummary.Value);
    this.formDailyOperation.controls['rptTimeStatus'].setValue(
      this._sectionDailyOperation.DOPObject.StatusReportingTime.Value
    );
    this.formDailyOperation.controls['projectedOps'].setValue(
      this._sectionDailyOperation.DOPObject.ProjectedOperations.Value
    );
    this.formDailyOperation.controls['rigTime'].setValue(this._sectionDailyOperation.DOPObject.RigTime.Value);
    this.validateDates = true;
  }

  public areDatesValid() {
    const start = this.formDailyOperation.controls['startDate'].value;
    const end = this.formDailyOperation.controls['endDate'].value;

    if (start) {
      this.showStartDateError = false;
      this.showStartDateError = this._sectionDailyOperation.checkDatesExist(start);
    }

    if (end) {
      this.showEndDateError = false;
      this.showEndDateError = this._sectionDailyOperation.checkDatesExist(end);
    }

    if (start && end) {
      this.areDatesInvalids = moment(start).isAfter(end);
      this.calculateRigTime(start, end);
    } else {
      this.areDatesInvalids = false;
    }
  }

  private calculateRigTime(start: any, end: any) {
    this.showRigTimeError = false;
    const TIME = this.getAvailableTime(start, end);
    this.formDailyOperation.controls['rigTime'].setValue(TIME);

    if (TIME > 24) {
      this.showRigTimeError = true;
    }
  }

  private getAvailableTime(start: any, end: any) {
    const DURATION = moment.duration(moment(end).diff(moment(start)));
    const HOURS = DURATION.asHours();
    return HOURS;
  }
  //#endregion 'General Methods'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._message.add({
      key: msg && msg.key && msg.key.length > 0 ? msg.key : 'DOPNotify',
      sticky: msg && msg.key && msg.key != 'DOPNotify' ? true : false,
      closable: msg && msg.key && msg.key != 'DOPNotify' ? true : false,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life,
    });
  }
  //#endregion 'Notification'
}
