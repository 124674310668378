import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {
  //#region 'Angular Life Cycle'
  constructor() {}

  ngOnInit() {}
  //#endregion 'Angular Life Cycle'
}
