import { AuthService } from '#services-shared/index';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class IsSystemAdmin implements CanActivate {
  constructor(private authSvc: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authSvc.isSystemAdmin()) {
      return true;
    } else {
      this.router.navigate([this.authSvc.isAuthenticated() ? '/error/not-authorized' : '/login']);

      return false;
    }
  }
}

@Injectable()
export class IsApplicationUser implements CanActivate {
  constructor(private authSvc: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authSvc.isAuthorized()) {
      if (this.authSvc.isInUserTable()) {
        if (this.authSvc.isDisclaimerAccepted()) {
          return true;
        } else {
          return false;
        }
      } else {
        this.router.navigate([this.authSvc.isAuthenticated() ? '/error/not-authorized' : '/login']);
        return false;
      }
    } else {
      this.router.navigate([this.authSvc.isAuthenticated() ? '/error/not-authorized' : '/login']);
      return false;
    }
  }
}
