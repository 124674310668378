import { environment } from '#environments/environment';
import { TaskPlan, TaskPlanItem, TaskPlanSection, TaskPlanSubSection } from '#models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root',
})
export class TaskPlanApiService {
  //#region 'Angular Life Cycle'
  constructor(private http: HttpClient) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General'
  private getURL(method?: string): string {
    return `${environment.apiEndpoint}/task-plan${method}`;
  }
  //#endregion 'General'

  //#region 'TASK PLAN'
  //#region 'Get'
  public getTaskPlans(): Observable<TaskPlan[]> {
    return this.http.get<TaskPlan[]>(this.getURL(`/getTaskPlans`));
  }

  public getTaskPlanById(id: number): Observable<TaskPlan[]> {
    return this.http.get<TaskPlan[]>(this.getURL(`/getTaskPlanById/${id}`));
  }
  //#endregion 'Get'

  //#region 'Create'
  public createTaskPlan(taskPlan: TaskPlan): Observable<TaskPlan> {
    return this.http.post<TaskPlan>(this.getURL(`/createTaskPlan`), taskPlan);
  }
  //#endregion 'Create'

  //#region 'Update'
  public updateTaskPlan(taskPlan: TaskPlan): Observable<TaskPlan> {
    return this.http.put<TaskPlan>(this.getURL(`/updateTaskPlan`), taskPlan);
  }
  //#endregion 'Update'

  //#region 'Delete'
  public deleteTaskPlan(id: number): Observable<TaskPlan> {
    return this.http.put<TaskPlan>(this.getURL(`/deleteTaskPlan/${id}`), null);
  }
  //#endregion 'Delete'

  //#region 'Others'
  public resetTaskPlan(id: number): Observable<TaskPlan> {
    return this.http.put<TaskPlan>(this.getURL(`/resetTaskPlan/${id}`), null);
  }
  //#endregion 'Others'
  //#endregion 'TASK PLAN'

  //#region 'TASK PLAN SECTION'
  //#region 'Get'
  public getTaskPlanSectionById(id: number): Observable<TaskPlanSection[]> {
    return this.http.get<TaskPlanSection[]>(this.getURL(`/getTaskPlanSectionById/${id}`));
  }
  //#endregion 'Get'

  //#region 'Create'
  public createTaskPlanSection(taskPlanSection: TaskPlanSection): Observable<TaskPlanSection> {
    return this.http.post<TaskPlanSection>(this.getURL(`/createTaskPlanSection`), taskPlanSection);
  }
  //#endregion 'Create'

  //#region 'Update'
  public updateTaskPlanSection(taskPlanSection: TaskPlanSection): Observable<TaskPlanSection> {
    return this.http.put<TaskPlanSection>(this.getURL(`/updateTaskPlanSection`), taskPlanSection);
  }
  //#endregion 'Update'

  //#region 'Delete'
  public deleteTaskPlanSection(id: number): Observable<TaskPlanSection> {
    return this.http.put<TaskPlanSection>(this.getURL(`/deleteTaskPlanSection/${id}`), null);
  }
  //#endregion 'Delete'

  //#region 'Others'
  public moveUpTaskPlanSection(id: number): Observable<TaskPlanSection> {
    return this.http.put<TaskPlanSection>(this.getURL(`/moveUpTaskPlanSection/${id}`), null);
  }

  public moveDownTaskPlanSection(id: number): Observable<TaskPlanSection> {
    return this.http.put<TaskPlanSection>(this.getURL(`/moveDownTaskPlanSection/${id}`), null);
  }
  //#endregion 'Others'
  //#endregion 'TASK PLAN SECTION'

  //#region 'TASK PLAN SUB SECTION'
  //#region 'Get'
  public getTaskPlanSubSections(id: number): Observable<TaskPlanSubSection[]> {
    return this.http.get<TaskPlanSubSection[]>(this.getURL(`/getTaskPlanSubSections/${id}`));
  }

  public getTaskPlanSubSectionById(id: number): Observable<TaskPlanSubSection[]> {
    return this.http.get<TaskPlanSubSection[]>(this.getURL(`/getTaskPlanSubSectionById/${id}`));
  }
  //#endregion 'Get'

  //#region 'Create'
  public createTaskPlanSubSection(taskPlanSubSection: TaskPlanSubSection): Observable<TaskPlanSubSection> {
    return this.http.post<TaskPlanSubSection>(this.getURL(`/createTaskPlanSubSection`), taskPlanSubSection);
  }
  //#endregion 'Create'

  //#region 'Update'
  public updateTaskPlanSubSection(taskPlanSubSection: TaskPlanSubSection): Observable<TaskPlanSubSection> {
    return this.http.put<TaskPlanSubSection>(this.getURL(`/updateTaskPlanSubSection`), taskPlanSubSection);
  }
  //#endregion 'Update'

  //#region 'Delete'
  public deleteTaskPlanSubSection(id: number): Observable<TaskPlanSubSection> {
    return this.http.put<TaskPlanSubSection>(this.getURL(`/deleteTaskPlanSubSection/${id}`), null);
  }
  //#endregion 'Delete'

  //#region 'Others'
  public moveUpTaskPlanSubSection(id: number): Observable<TaskPlanSubSection> {
    return this.http.put<TaskPlanSubSection>(this.getURL(`/moveUpTaskPlanSubSection/${id}`), null);
  }

  public moveDownTaskPlanSubSection(id: number): Observable<TaskPlanSection> {
    return this.http.put<TaskPlanSection>(this.getURL(`/moveDownTaskPlanSubSection/${id}`), null);
  }
  //#endregion 'Others'

  //#endregion 'TASK PLAN SUB SECTION'

  //#region 'TASK PLAN ITEM'
  //#region 'Get'
  public getTaskPlanItemById(id: number): Observable<TaskPlanItem> {
    return this.http.get<TaskPlanItem>(this.getURL(`/getTaskPlanItem/${id}`));
  }
  //#endregion 'Get'

  //#region 'Create'
  public createTaskPlanItem(taskPlanItem: TaskPlanItem): Observable<TaskPlanItem> {
    return this.http.post<TaskPlanItem>(this.getURL(`/createTaskPlanItem`), taskPlanItem);
  }
  //#endregion 'Create'

  //#region 'Update'
  public updateTaskPlanItem(taskPlanItem: TaskPlanItem): Observable<TaskPlanItem> {
    return this.http.put<TaskPlanItem>(this.getURL(`/updateTaskPlanItem`), taskPlanItem);
  }
  //#endregion 'Update'

  //#region 'Delete'
  public deleteTaskPlanItem(id: number): Observable<TaskPlanItem> {
    return this.http.put<TaskPlanItem>(this.getURL(`/deleteTaskPlanItem/${id}`), null);
  }
  //#endregion 'Delete'

  //#region 'Others'
  public moveUpTaskPlanItem(id: number): Observable<TaskPlanItem> {
    return this.http.put<TaskPlanItem>(this.getURL(`/moveUpTaskPlanItem/${id}`), null);
  }

  public moveDownTaskPlanItem(id: number): Observable<TaskPlanItem> {
    return this.http.put<TaskPlanItem>(this.getURL(`/moveDownTaskPlanItem/${id}`), null);
  }
  //#endregion 'Others'
  //#endregion 'TASK PLAN ITEM'

  //#region 'GENERAL FUNCTIONS'
  public isInvalidString(value: string): boolean {
    return value === undefined || value == null || value.length === 0;
  }

  public isInvalidInt(value: number): boolean {
    return value === undefined || value == null || value === 0;
  }
  //#endregion 'GENERAL FUNCTIONS'
}
