import { WellsApiService } from '#services-api/index';
import { LoadingIndicatorService, SectionCasingService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationToast, Well } from 'models';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-wells',
  templateUrl: './wells.component.html',
  styleUrls: ['./wells.component.scss'],
  providers: [MessageService],
})
export class WellsComponent implements OnInit, OnDestroy {
  //#region 'Variables'
  public currentWellData: Well[];

  //? Subscriptions
  private SUBS$ = new Subscription();

  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private _loader: LoadingIndicatorService,
    private _well: WellsApiService,
    public _sectionCasing: SectionCasingService,
    private router: Router,
    private _message: MessageService
  ) {}

  ngOnInit() {
    this.getWellData();
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
    this._loader.hide();
  }

  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  async getWellData() {
    this._loader.show();
    this.SUBS$.add(
      await this._well
        .getWellData()
        .finally(() => this._loader.hide())
        .subscribe(
          (data: Well[]) => {
            this.currentWellData = data;
            this.currentWellData.sort((val1, val2) => {
              return <any>new Date(val2.OriginalSpudDate) - <any>new Date(val1.OriginalSpudDate);
            });
          },
          () => {
            this.currentWellData = [];
            this.showNotification({
              severity: 'error',
              summary: 'We got a problem!',
              detail: 'There was an error loading wells, please refresh the page or notify the administrator.',
              life: 30000,
            });
          }
        )
    );
  }

  public wellDetailsClickSections(wellData: Well): void {
    this.router.navigate(['sections', { wellID: wellData.WellId }]);
  }

  public wellDetailsClickJobs(wellData: Well): void {
    this.router.navigate(['jobs', { wellID: wellData.WellId }]);
  }
  //#endregion 'General Methods'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._message.add({
      key: 'wellsError',
      sticky: true,
      closable: true,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life,
    });
  }
  //#endregion 'Notification'
}
