<!-- Content -->
<div id="casingrunning" class="tab-pane fade in module fixModule">
  <!-- Validated -->
  <div class="row">
    <!-- Toolbar Top -->
    <div
      class="row"
      *ngIf="
        _sectionCasing.lstCasingComponents_Validated.length > 0 ||
        _sectionCasing.lstCasingComponents_RunChecked.length > 0
      "
    >
      <div class="pull-right">
        <button
          id="btn_generate_report"
          type="button"
          class="btn btn-primary"
          (click)="OPGenerateReports.toggle($event)"
        >
          <span class="glyphicon glyphicon-download-alt" aria-hidden="true"></span>
          <span class="ml8">Generate Report(s)</span>
        </button>
      </div>
    </div>
    <!-- Toolbar Top -->

    <!-- Notification Message -->
    <!-- <div class="row">
      <p-messages id="msg_run" [(value)]="_sectionCasing.notificationsRunValidated" [closable]="false"></p-messages>
    </div> -->
    <!-- Notification Message -->

    <!-- Casings Validates INFO -->
    <div class="row">
      <label class="text-primary boldTextFont"
        >Casings Validated ({{ _sectionCasing.lstCasingComponents_Validated.length }})</label
      >
    </div>
    <!-- Casings Validates INFO -->

    <!-- List Validated -->
    <div class="row">
      <div class="col-md-12 text-left table-responsive tableHeight">
        <table class="table table-striped noborder">
          <!-- Validated List Headers -->
          <thead>
            <tr class="text-primary helpCursor">
              <th class="w4">Joint#</th>
              <th class="w2">Run?</th>

              <!-- Item Description -->
              <th
                class="w10"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.Description.Help"
              >
                {{ _sectionCasing.lstCasingComponentColumns.Description.Label }}
                <span *ngIf="_sectionCasing.lstCasingComponentColumns.Description.UOM !== ''"
                  >({{ _sectionCasing.lstCasingComponentColumns.Description.UOM }})</span
                >
              </th>
              <!-- Item Description -->

              <!-- Equipment Type -->
              <th
                class="w10"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.EquipmentType.Help"
              >
                {{ _sectionCasing.lstCasingComponentColumns.EquipmentType.Label }}
                <span *ngIf="_sectionCasing.lstCasingComponentColumns.EquipmentType.UOM !== ''"
                  >({{ _sectionCasing.lstCasingComponentColumns.EquipmentType.UOM }})</span
                >
              </th>
              <!-- Equipment Type -->

              <!-- Top Connection Thread -->
              <th
                class="w10"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.TopThread.Help"
              >
                {{ _sectionCasing.lstCasingComponentColumns.TopThread.Label }}
                <span *ngIf="_sectionCasing.lstCasingComponentColumns.TopThread.UOM !== ''"
                  >({{ _sectionCasing.lstCasingComponentColumns.TopThread.UOM }})</span
                >
              </th>
              <!-- Top Connection Thread -->

              <!-- Bottom Connection Thread -->
              <th
                class="w10"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.BottomThread.Help"
              >
                {{ _sectionCasing.lstCasingComponentColumns.BottomThread.Label }}
                <span *ngIf="_sectionCasing.lstCasingComponentColumns.BottomThread.UOM !== ''"
                  >({{ _sectionCasing.lstCasingComponentColumns.BottomThread.UOM }})</span
                >
              </th>
              <!-- Bottom Connection Thread -->

              <!-- Weight/Length (lb/ft) -->
              <th
                class="w10"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.Weight.Help"
              >
                {{ _sectionCasing.lstCasingComponentColumns.Weight.Label }}
                <span *ngIf="_sectionCasing.lstCasingComponentColumns.Weight.UOM !== ''"
                  >({{ _sectionCasing.lstCasingComponentColumns.Weight.UOM }})</span
                >
              </th>
              <!-- Weight/Length (lb/ft) -->

              <!-- Len Thread On (ft) -->
              <th
                class="w8"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.Length.Help"
              >
                Len Thread On
                <span *ngIf="_sectionCasing.lstCasingComponentColumns.Length.UOM !== ''"
                  >({{ _sectionCasing.lstCasingComponentColumns.Length.UOM }})</span
                >
              </th>
              <!-- Len Thread On (ft) -->

              <th class="w8">Len Thread Off (ft)</th>
              <th class="w10">Len Thread Loss (in)</th>

              <!-- OD -->
              <th
                class="w10"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.OD.Help"
              >
                {{ _sectionCasing.lstCasingComponentColumns.OD.Label }}
                <span *ngIf="_sectionCasing.lstCasingComponentColumns.OD.UOM !== ''"
                  >({{ _sectionCasing.lstCasingComponentColumns.OD.UOM }})</span
                >
              </th>
              <!-- OD -->

              <!-- Actions -->
              <th class="w4" *ngIf="!_sectionCasing.casingStringIdApproved">Delete</th>
              <th class="w4" *ngIf="!_sectionCasing.casingStringIdApproved">Edit</th>
              <!-- Actions -->
            </tr>
          </thead>
          <!-- Validated List Headers -->

          <!-- Validates List Content -->
          <tbody>
            <tr *ngFor="let rowData of _sectionCasing.lstCasingComponents_Validated; let rowIndex = index">
              <!-- Joint # -->
              <td class="w4">{{ rowData.JointNumber }}</td>
              <!-- Joint # -->

              <!-- Run? -->
              <td class="w2">
                <i
                  *ngIf="rowData.IsApproved; else elseRunBlock"
                  [ngClass]="rowData.Run ? 'pi pi-check text-primary' : 'pi pi-ban text-warning'"
                ></i>
                <ng-template #elseRunBlock>
                  <p-checkbox
                    id="ccb_validated_run_0{{ rowIndex }}"
                    name="chkRun"
                    (onChange)="onClickRun(rowData, 'RUN')"
                    [(ngModel)]="rowData.Run"
                    binary="true"
                  ></p-checkbox>
                </ng-template>
              </td>
              <!-- Run? -->

              <!-- Item Description -->
              <td class="w10">{{ rowData.Description.Value }}</td>
              <!-- Item Description -->

              <!-- Equipment Type -->
              <td class="w10">{{ rowData.EquipmentType.Value }}</td>
              <!-- Equipment Type -->

              <!-- Top Connection Thread -->
              <td class="w10">{{ rowData.TopThread.Value }}</td>
              <!-- Top Connection Thread -->

              <!-- Bottom Connection Thread -->
              <td class="w10">{{ rowData.BottomThread.Value }}</td>
              <!-- Bottom Connection Thread -->

              <!-- Weight/Length (lb/ft) -->
              <td class="w10">{{ rowData.Weight.Value }}</td>
              <!-- Weight/Length (lb/ft) -->

              <!-- Thread On (ft) -->
              <td class="w8">{{ rowData.Length.Value }}</td>
              <!-- Thread On (ft) -->

              <!-- Thread Off -->
              <td class="w8">{{ rowData.ThreadOff | number: '1.2' }}</td>
              <!-- Thread Off -->

              <!-- Thread Loss -->
              <td class="w10">
                <div *ngIf="_sectionCasing.casingStringIdApproved; else elseThreadLossBlock">
                  {{ rowData.ThreadLoss | number: '1.2' }}
                  <i [ngClass]="rowData.IsThreadLoss ? 'pi pi-check text-primary' : 'pi pi-ban text-warning'"></i>
                </div>
                <ng-template #elseThreadLossBlock>
                  <div>
                    <div class="col-sm-8 threadLoss">
                      <input
                        id="txt_thread_loss_0{{ rowIndex }}"
                        size="5"
                        type="number"
                        onkeyup="if(this.value < 0){this.value = this.value * -1}"
                        min="0"
                        class="form-control text-right"
                        [(ngModel)]="rowData.ThreadLoss"
                        (blur)="computeThreadOffValues(rowData, rowIndex, '')"
                        onfocus="this.select();"
                      />
                    </div>
                    <div
                      class="col-sm-4 threadLossCheck"
                      data-toggle="tooltip"
                      data-placement="top"
                      data-container="body"
                      title="Toggle Thread Loss"
                    >
                      <p-checkbox
                        class="ml6"
                        id="ccb_is_thread_loss_0{{ rowIndex }}"
                        name="chkValidIsThreadLoss"
                        (onChange)="computeThreadOffValues(rowData, rowIndex, '')"
                        [(ngModel)]="rowData.IsThreadLoss"
                        binary="true"
                      >
                      </p-checkbox>
                    </div>
                  </div>
                </ng-template>
              </td>
              <!-- Thread Loss -->

              <!-- OD -->
              <td class="w10">
                {{ rowData.OD.Value }}
              </td>
              <!-- OD -->

              <!-- Actions -->
              <th class="w4" *ngIf="!_sectionCasing.casingStringIdApproved">
                <button
                  id="btn_remove_0{{ rowIndex }}"
                  type="button"
                  class="btn btn-danger btn-sm centerContent"
                  (click)="removeCasingInspect(rowData)"
                >
                  <i class="pi pi-trash"></i>
                </button>
              </th>
              <td class="w4" *ngIf="!_sectionCasing.casingStringIdApproved">
                <button
                  id="btn_unvalidate_0{{ rowIndex }}"
                  type="button"
                  class="btn btn-primary btn-sm centerContent"
                  (click)="unvalidateCasing(rowData, rowIndex, false)"
                >
                  <span class="divCenter"><i class="pi pi-pencil"></i></span>
                </button>
              </td>
              <!-- Actions -->
            </tr>
          </tbody>
          <!-- Validates List Content -->
        </table>
        <div *ngIf="_sectionCasing.lstCasingComponents_Validated.length === 0" class="centerNoData">
          <span>Sorry, the selected casing has no data to display in Casing Validated at this time.</span>
        </div>
      </div>
    </div>
    <!-- List Validated -->
  </div>
  <!-- Validated -->
  <br />
  <!-- Notification On Run -->
  <div class="row">
    <p-messages id="msg_on_run" [(value)]="_sectionCasing.notificationsOnRun" [closable]="false"></p-messages>
  </div>
  <!-- Notification On Run -->
  <br />
  <!-- List Runchecked -->
  <div class="row">
    <!-- Casings Run INFO -->
    <div class="row">
      <td class="col-sm-5">
        <label class="text-primary">Casings Run ({{ _sectionCasing.lstCasingComponents_RunChecked.length }})</label>
      </td>
      <td class="col-sm-2">
        <label>RKB to Hanger Set Depth</label><br />
        <input
          id="txt_rkb"
          type="number"
          onkeyup="if(this.value < 0){this.value = this.value * -1}"
          min="0"
          class="form-control"
          *ngIf="_sectionCasing.casingString"
          [(ngModel)]="_sectionCasing.casingString.RKB"
          (blur)="updateCasingString(1, 'RKB')"
          [readonly]="_sectionCasing.casingStringIdApproved"
          onfocus="select();"
        />
      </td>
      <td class="col-sm-2">
        <label>Total Open Hole Depth</label><br />
        <input
          id="txt_shoe_depth"
          type="number"
          onkeyup="if(this.value < 0){this.value = this.value * -1}"
          min="0"
          class="form-control"
          *ngIf="_sectionCasing.casingString"
          [(ngModel)]="_sectionCasing.casingString.SetDepth"
          (blur)="updateCasingString(1, 'Set_Depth')"
          [readonly]="_sectionCasing.casingStringIdApproved"
          onfocus="select();"
        />
      </td>
      <td class="col-sm-2">
        <label>Casing Shoe Set Depth</label><br />
        <div class="mt5">
          <span>{{ _sectionCasing.shoeDepth | number: '1.0-2' }}</span>
        </div>
      </td>
      <td class="col-sm-1">
        <label>Rat Hole</label><br />
        <div class="mt5">
          <span>{{ _sectionCasing.ratHole | number: '1.0-2' }}</span>
        </div>
      </td>
    </div>
    <!-- Casings Run INFO -->

    <!-- Cut Paste Icons -->
    <div class="alert alert-warning mb0" role="alert" *ngIf="isToggleEnabled">
      <button id="btn_cancel" type="button" class="btn btn-danger" (click)="cancelCutPaste()">
        <span class="divCenter"><i class="pi pi-ban mr6"></i>Cancel</span>
      </button>
      &nbsp;&nbsp;
      <button id="btn_cut" type="button" *ngIf="showCut" class="btn btn-primary" (click)="cutRunComponents()">
        <span class="divCenter"><i class="pi pi-copy mr6"></i>Cut</span>
      </button>
      &nbsp;&nbsp;
      <button id="btn_paste" type="button" *ngIf="showPaste" class="btn btn-primary" (click)="pasteRunComponents()">
        <span class="divCenter"><i class="pi pi-save mr6"></i>Paste</span>
      </button>
      &nbsp;&nbsp;
      <span class="sr-only">Note:</span>
      <label>{{ toggleMessages }}</label>
    </div>
    <!-- Cut Paste Icons -->

    <!-- List Run -->
    <div class="row">
      <div class="col-md-12 text-left table-responsive tableHeight">
        <table class="table table-striped noborder">
          <!-- Run List Headers -->
          <thead>
            <tr class="text-primary helpCursor">
              <th class="w2" *ngIf="!_sectionCasing.casingStringIdApproved">Order</th>

              <!-- Sequence -->
              <th
                class="w2"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.SysSeq.Help"
              >
                <span>Seq#</span>
              </th>
              <!-- Sequence -->

              <th class="w2">Joint#</th>
              <th class="w2">Run?</th>
              <th class="w2">Bad?</th>

              <!-- Item Description -->
              <th
                class="w8"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.Description.Help"
              >
                {{ _sectionCasing.lstCasingComponentColumns.Description.Label }}
              </th>
              <!-- Item Description -->

              <!-- Equipment Type -->
              <th
                class="w6"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.EquipmentType.Help"
              >
                {{ _sectionCasing.lstCasingComponentColumns.EquipmentType.Label }}
              </th>
              <!-- Equipment Type -->

              <!-- Top Connection Thread -->
              <th
                class="w6"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.TopThread.Help"
              >
                {{ _sectionCasing.lstCasingComponentColumns.TopThread.Label }}
              </th>
              <!-- Top Connection Thread -->

              <!-- Bottom Connection Thread -->
              <th
                class="w6"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.BottomThread.Help"
              >
                {{ _sectionCasing.lstCasingComponentColumns.BottomThread.Label }}
              </th>
              <!-- Bottom Connection Thread -->

              <!-- Weight/Length (lb/ft) -->
              <th
                class="w5"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.Weight.Help"
              >
                Weight / Len
                <span *ngIf="_sectionCasing.lstCasingComponentColumns.Weight.UOM !== ''"
                  >({{ _sectionCasing.lstCasingComponentColumns.Weight.UOM }})</span
                >
              </th>
              <!-- Weight/Length (lb/ft) -->

              <!-- Len Thread On (ft) -->
              <th
                class="w5"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.Length.Help"
              >
                Len Thread On
                <span *ngIf="_sectionCasing.lstCasingComponentColumns.Length.UOM !== ''"
                  >({{ _sectionCasing.lstCasingComponentColumns.Length.UOM }})</span
                >
              </th>
              <!-- Len Thread On (ft) -->

              <th class="w5">Len Thread Off (ft)</th>
              <th class="w10">Len Thread Loss (in)</th>

              <!-- Cum Len (ft) -->
              <th
                class="w6"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.CumLength.Help"
              >
                Cum Len
                <span *ngIf="_sectionCasing.lstCasingComponentColumns.CumLength.UOM !== ''"
                  >({{ _sectionCasing.lstCasingComponentColumns.CumLength.UOM }})</span
                >
              </th>
              <!-- Cum Len (ft) -->

              <!-- OD -->
              <th
                class="w6"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                [title]="_sectionCasing.lstCasingComponentColumns.OD.Help"
              >
                {{ _sectionCasing.lstCasingComponentColumns.OD.Label }}
              </th>
              <!-- OD -->

              <th class="w2">Centralized</th>
              <th class="w6">Jt Bottom</th>
              <th class="w6">Jt Top</th>
              <th class="w5">Hanger Depth</th>
              <th
                class="w5"
                data-toggle="tooltip"
                onmouseenter="$(this).tooltip('show')"
                data-container="body"
                data-placement="top"
                title="Only check for landing joint"
              >
                Exclude from Wellview
              </th>
              <th class="w2" *ngIf="!_sectionCasing.casingStringIdApproved">Edit</th>
            </tr>
          </thead>
          <!-- Run List Headers -->

          <!-- Run List Content -->
          <tbody>
            <tr *ngIf="isCutEnabled" class="cursor">
              <td
                id="td_run_paste_area_first"
                style="padding: 0"
                colspan="20"
                (click)="setPasteIndex(-1)"
                [ngClass]="pasteIndex === -1 ? 'highlightSelectedPaste text-center' : 'highlightPasteArea text-center'"
              >
                <span style="float: left; margin-left: 12px">
                  <b> Click here to paste the selected casings </b>
                </span>
              </td>
            </tr>
            <ng-container *ngFor="let rowData of _sectionCasing.lstCasingComponents_RunChecked; let rowIndex = index">
              <tr [ngClass]="{ 'badJoint ybg': rowData.IsBadJoint }">
                <!-- Toggle Sequence -->
                <td
                  class="w2"
                  style="cursor: pointer"
                  *ngIf="!_sectionCasing.casingStringIdApproved && !readyToPaste"
                  (click)="selectToggleIndex(rowData)"
                >
                  <i
                    id="icn_toggle_check_0{{ rowIndex }}"
                    *ngIf="!rowData.isReorder; else selected"
                    class="pi pi-ellipsis-v"
                  ></i>
                  <ng-template #selected>
                    <i id="icn_toggle_uncheck_0{{ rowIndex }}" class="pi pi-check text-primary"></i>
                  </ng-template>
                </td>
                <!-- Toggle Sequence -->

                <!-- Sequence -->
                <td class="w2">{{ rowData.SysSeq.Value }}</td>
                <!-- Sequence -->

                <!-- Joint # -->
                <td class="w2">{{ rowData.JointNumber }}</td>
                <!-- Joint # -->

                <!-- Run? -->
                <td class="w2">
                  <i
                    *ngIf="rowData.IsApproved || rowData.IsBadJoint; else elseRunBlock"
                    [ngClass]="rowData.Run ? 'pi pi-check text-primary' : 'pi pi-ban text-warning'"
                  ></i>
                  <ng-template #elseRunBlock>
                    <p-checkbox
                      id="ccb_run_0{{ rowIndex }}"
                      name="chkRun"
                      (onChange)="onClickRun(rowData, 'UNRUN')"
                      [(ngModel)]="rowData.Run"
                      binary="true"
                    ></p-checkbox>
                  </ng-template>
                </td>
                <!-- Run? -->

                <!-- Bad? -->
                <td class="w2">
                  <i
                    *ngIf="rowData.IsApproved; else elseBadBlock"
                    [ngClass]="rowData.IsBadJoint ? 'pi pi-check text-primary' : 'pi pi-ban text-warning'"
                  ></i>
                  <ng-template #elseBadBlock>
                    <p-checkbox
                      id="ccb_bad_0{{ rowIndex }}"
                      name="chkBad"
                      (onChange)="onClickBad(rowData, rowIndex)"
                      [(ngModel)]="rowData.IsBadJoint"
                      binary="true"
                    ></p-checkbox>
                  </ng-template>
                </td>
                <!-- Bad? -->

                <!-- Item Description -->
                <td class="w8">{{ rowData.Description.Value }}</td>
                <!-- Item Description -->

                <!-- Equipment Type -->
                <td class="w6">{{ rowData.EquipmentType.Value }}</td>
                <!-- Equipment Type -->

                <!-- Top Connection Thread -->
                <td class="w6">{{ rowData.TopThread.Value }}</td>
                <!-- Top Connection Thread -->

                <!-- Bottom Connection thread -->
                <td class="w6">{{ rowData.BottomThread.Value }}</td>
                <!-- Bottom Connection thread -->

                <!-- Weight/Length (lb/ft) -->
                <td class="w5">{{ rowData.Weight.Value | number: '1.2' }}</td>
                <!-- Weight/Length (lb/ft) -->

                <!-- Thread On (ft) -->
                <td class="w5">{{ rowData.Length.Value }}</td>
                <!-- Thread On (ft) -->

                <!-- Thread Off -->
                <td class="w5">{{ rowData.ThreadOff | number: '1.2' }}</td>
                <!-- Thread Off -->

                <!-- Thread Loss -->
                <td class="w10">
                  <div
                    *ngIf="_sectionCasing.casingStringIdApproved || rowData.IsBadJoint; else elseThreadLossBlock"
                    class="centerContent"
                  >
                    {{ rowData.ThreadLoss | number: '1.2' }}
                    <i
                      [ngClass]="rowData.IsThreadLoss ? 'pi pi-check text-primary ml6' : 'pi pi-ban text-warning ml6'"
                    ></i>
                  </div>
                  <ng-template #elseThreadLossBlock>
                    <div>
                      <div class="col-sm-8 threadLoss">
                        <input
                          id="txt_thread_loss_0{{ rowIndex }}"
                          type="number"
                          onkeyup="if(this.value < 0){this.value = this.value * -1}"
                          min="0"
                          size="5"
                          class="form-control text-right"
                          [(ngModel)]="rowData.ThreadLoss"
                          (blur)="computeThreadOffValues(rowData, rowIndex, '')"
                          onfocus="this.select();"
                        />
                      </div>
                      <div
                        class="col-sm-4 threadLossCheck"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-container="body"
                        title="Toggle Thread Loss"
                      >
                        <p-checkbox
                          id="ccb_is_thread_loss_0{{ rowIndex }}"
                          class="ml6"
                          name="chkRunIsThreadLoss"
                          (onChange)="computeThreadOffValues(rowData, rowIndex, '')"
                          [(ngModel)]="rowData.IsThreadLoss"
                          binary="true"
                        >
                        </p-checkbox>
                      </div>
                    </div>
                  </ng-template>
                </td>
                <!-- Thread Loss -->

                <!-- Cum Length (ft) -->
                <td class="w6">
                  <span *ngIf="!rowData.IsBadJoint; else badJTB">{{ rowData.CumLength.Value | number: '1.2' }}</span>
                  <ng-template #badJTB><span>------</span></ng-template>
                </td>
                <!-- Cum Length (ft) -->

                <!-- OD -->
                <td class="w6">
                  {{ rowData.OD.Value }}
                </td>
                <!-- OD -->

                <!-- Centralized -->
                <td class="w2 centerContent">
                  <i
                    class="cursor"
                    *ngIf="isCentralizeHtmlValid(rowIndex); else elseCentralizerBlock"
                    [ngClass]="rowData.IsCentralized ? 'pi pi-check text-primary' : 'pi pi-ban text-warning'"
                  ></i>
                  <ng-template #elseCentralizerBlock>
                    <p-checkbox
                      id="ccb_centralized_0{{ rowIndex }}"
                      name="chkCentralized"
                      (onChange)="updateCasingInspect(rowData, rowIndex, 'casingSpacingUpdate')"
                      [(ngModel)]="rowData.IsCentralized"
                      binary="true"
                    ></p-checkbox>
                  </ng-template>
                  <!-- Edit Icon -->
                  <i
                    id="ccb_run_edit_0{{ rowIndex }}"
                    *ngIf="!isCentralizeHtmlValid(rowIndex)"
                    class="cursor"
                    [ngClass]="
                      rowData.IsApproved
                        ? 'pi pi-info-rowIndexcircle text-primary ml6'
                        : 'pi pi-pencil text-primary ml6'
                    "
                    (click)="openCentralizedNotes(rowData, rowIndex)"
                    data-toggle="tooltip"
                    onmouseenter="$(this).tooltip('show')"
                    data-container="body"
                    data-placement="top"
                    [title]="
                      rowData.IsApproved ? 'Click to view centralized notes' : 'Click to enter centralized notes'
                    "
                  >
                  </i>
                  <!-- Edit Icon -->
                </td>
                <!-- Centralized -->

                <!-- Jt Bottom -->
                <td class="w6">
                  <span *ngIf="!rowData.IsBadJoint; else badJTB">{{ rowData.JointBottom | number: '1.2' }}</span>
                  <ng-template #badJTB><span>------</span></ng-template>
                </td>
                <!-- Jt Bottom -->

                <!-- Jt Top -->
                <td class="w6">
                  <span *ngIf="!rowData.IsBadJoint; else badJTT">{{ rowData.JointTop | number: '1.2' }}</span>
                  <ng-template #badJTT><span>------</span></ng-template>
                </td>
                <!-- Jt Top -->

                <!-- Hanger Depth -->
                <td class="w5">
                  <i
                    *ngIf="isCentralizeHtmlValid(rowIndex); else elseHangerDepth"
                    [ngClass]="rowData.IsReferenceDepth ? 'pi pi-check text-primary' : 'pi pi-ban text-warning'"
                  ></i>
                  <ng-template #elseHangerDepth>
                    <p-checkbox
                      id="ccb_hanger_depth_0{{ rowIndex }}"
                      name="chkHangerDepth"
                      [(ngModel)]="rowData.IsReferenceDepth"
                      (ngModelChange)="updateDepthRef(rowData)"
                      [disabled]="rowData.IsReferenceDepth"
                      binary="true"
                    >
                    </p-checkbox>
                  </ng-template>
                </td>
                <!-- Hanger Depth -->

                <!-- Exclude -->
                <td class="w5">
                  <i
                    *ngIf="
                      rowData.Description.Value === 'Drill Pipe' ||
                        rowData.Description.Value === 'Heavyweight Drill Pipe' ||
                        rowData.IsApproved ||
                        rowData.IsBadJoint;
                      else elseExcludeBlock
                    "
                    [ngClass]="rowData.IsExclude ? 'pi pi-check text-primary' : 'pi pi-ban text-warning'"
                  ></i>
                  <ng-template #elseExcludeBlock>
                    <p-checkbox
                      id="ccb_exclude_0{{ rowIndex }}"
                      name="chkExclude"
                      (onChange)="updateExclude(rowData)"
                      [(ngModel)]="rowData.IsExclude"
                      binary="true"
                    ></p-checkbox>
                  </ng-template>
                </td>
                <!-- Exclude -->

                <!-- Edit -->
                <td class="w2" *ngIf="!_sectionCasing.casingStringIdApproved">
                  <button
                    id="btn_run_edit_0{{ rowIndex }}"
                    *ngIf="!rowData.IsBadJoint"
                    type="button"
                    class="btn btn-primary"
                    (click)="unvalidateCasing(rowData, rowIndex, true)"
                  >
                    <span class="divCenter"><i class="pi pi-pencil"></i></span>
                  </button>
                </td>
                <!-- Edit -->
              </tr>
              <tr *ngIf="isCutEnabled" class="cursor">
                <td
                  id="td_run_paste_area_0{{ rowIndex }}"
                  style="padding: 0"
                  colspan="20"
                  (click)="setPasteIndex(rowIndex)"
                  [ngClass]="
                    pasteIndex === rowIndex ? 'highlightSelectedPaste text-center' : 'highlightPasteArea text-center'
                  "
                >
                  <span style="float: left; margin-left: 12px">
                    <b> Click here to paste the selected casings </b>
                  </span>
                </td>
              </tr>
            </ng-container>
          </tbody>
          <!-- Run List Content -->
        </table>
        <div *ngIf="_sectionCasing.lstCasingComponents_RunChecked.length === 0" class="centerNoData">
          <span>Sorry, the selected casing has no data to display in Casing Running at this moment.</span>
        </div>
      </div>
    </div>
    <!-- List Run -->
  </div>
  <!-- List Runchecked -->
  <br />
  <!-- Toolbar Bottom -->
  <div class="row">
    <div class="col-1 fixTotal text-primary">
      <b>Total Items: {{ _sectionCasing.onTotal }}</b>
    </div>
    <div class="col-1 fixTotal onRun">
      <b>On Run: {{ _sectionCasing.onRun }}</b>
    </div>
    <div class="col-1 fixTotal onBad">
      <b>Bad Joints: {{ _sectionCasing.onBadJoint }}</b>
    </div>
    <div class="col-1 fixTotal onShelf">
      <b>Left on Shelf: {{ _sectionCasing.onShelf }}</b>
    </div>
    <div class="col-1 fixTotal text-primary ml12">
      <b>Centralized: {{ _sectionCasing.onCentralized }}</b>
    </div>
    <div *ngIf="!_sectionCasing.casingStringIdApproved" class="col-3 pull-right ml12">
      <button
        id="btn_tlb_finalize"
        *ngIf="
          _sectionCasing.lstCasingComponents_Validated.length > 0 ||
          _sectionCasing.lstCasingComponents_RunChecked.length > 0
        "
        type="button"
        class="btn btn-danger"
        (click)="runCasing()"
      >
        <span class="divCenter"><i class="pi pi-lock mr6"></i>Finalize & Write to WellView</span>
      </button>
    </div>
    <div *ngIf="!_sectionCasing.casingStringIdApproved" class="col-3 pull-right ml12">
      <button
        id="btn_tlb_centralize"
        *ngIf="_sectionCasing.lstCasingComponents_RunChecked.length > 0"
        type="button"
        class="btn btn-primary"
        (click)="showCentralize()"
      >
        <span class="divCenter"><i class="pi pi-th-large mr6"></i>Centralize Casings</span>
      </button>
    </div>
    <div *ngIf="!_sectionCasing.casingStringIdApproved" class="col-1 pull-right ml12">
      <button
        id="btn_tlb_clear_all"
        *ngIf="_sectionCasing.lstCasingComponents_RunChecked.length > 0"
        type="button"
        class="btn btn-danger"
        (click)="runClearAll()"
      >
        <span class="divCenter"><i class="pi pi-trash mr6"></i>Clear All</span>
      </button>
    </div>
    <div *ngIf="showRunUnRunButton()" class="col-2 pull-right ml12">
      <button
        id="btn_tlb_multiple_run"
        type="button"
        class="btn btn-primary"
        [disabled]="disableRunUnRunButton"
        (click)="showMultipleRunUnRun()"
      >
        <span class="divCenter"><i class="pi pi-check mr6"></i>Run/Un-Run</span>
      </button>
    </div>
  </div>
  <!-- Toolbar Bottom -->
</div>
<!-- Content -->

<!-- Generate Reports Option -->
<p-overlayPanel #OPGenerateReports appendTo="body">
  <div class="tac">
    <label class="text-primary boldTextFont">Select Casing Running Tally Report</label>
  </div>
  <div>
    <div>
      <label class="text-primary boldTextFont">All casings</label>
      <br />
      <p-checkbox
        id="ccb_pdf_all"
        class="boldTextFont"
        name="group1"
        value="PDF_AllCasings"
        label="PDF"
        [(ngModel)]="selectedReportFormat"
        inputId="pdf_all"
      >
      </p-checkbox>
      <p-checkbox
        id="ccb_excel_all"
        class="ml12 boldTextFont"
        name="group1"
        value="Excel_AllCasings"
        label="Excel"
        [(ngModel)]="selectedReportFormat"
        inputId="xcl_all"
      >
      </p-checkbox>
    </div>
    <div>
      <label class="text-primary boldTextFont">Run Only</label>
      <br />
      <p-checkbox
        id="ccb_pdf_run"
        class="boldTextFont"
        name="group1"
        value="PDF_RunOnly"
        label="PDF"
        [(ngModel)]="selectedReportFormat"
        inputId="pdf_run"
      >
      </p-checkbox>
      <p-checkbox
        id="ccb_excel_run"
        class="ml12 boldTextFont"
        name="group1"
        value="Excel_RunOnly"
        label="Excel"
        [(ngModel)]="selectedReportFormat"
        inputId="xcl_run"
      >
      </p-checkbox>
    </div>
  </div>
  <div class="mt12">
    <button
      id="btn_report_download"
      type="button"
      class="btn btn-primary tac"
      (click)="generateReport(OPGenerateReports)"
    >
      <span class="glyphicon glyphicon-download-alt" aria-hidden="true"></span>
      <span class="ml8">Download</span>
    </button>
  </div>
</p-overlayPanel>
<!-- Generate Reports Option -->

<!-- Notes -->
<p-dialog
  header="Centralized Notes"
  [(visible)]="displayCentralizedNotes"
  [baseZIndex]="5000"
  [modal]="true"
  [closable]="false"
>
  <h4 *ngIf="disableCentralizedNotes">{{ centralizedNotes }}</h4>
  <textarea
    id="txt_notes_centralize"
    *ngIf="!disableCentralizedNotes"
    rows="20"
    cols="80"
    placeholder="Start Typing Here..."
    [(ngModel)]="centralizedNotes"
    maxlength="500"
  ></textarea>
  <br />
  <div *ngIf="!disableCentralizedNotes">Character Count (500 max.) : {{ centralizedNotes?.length }}</div>
  <p-footer>
    <button id="btn_notes_cancel" type="button" class="btn btn-danger" (click)="displayCentralizedNotes = false">
      <span class="divCenter"><i class="pi pi-ban mr6"></i>Cancel</span>
    </button>
    <button id="btn_notes_accept" type="button" class="btn btn-success" (click)="saveNotes()">
      <span class="divCenter"><i class="pi pi-save mr6"></i>Accept</span>
    </button>
  </p-footer>
</p-dialog>
<!-- Notes -->

<!-- Centralize -->
<p-dialog
  header="Centralizer Program"
  [(visible)]="displayCentralize"
  [baseZIndex]="5000"
  [modal]="true"
  [closable]="false"
>
  <div class="row">
    <table class="table table-striped noborder">
      <thead>
        <tr>
          <td style="width: 16%">End Depth</td>
          <td style="width: 16%">Start Depth</td>
          <td style="width: 20%">Centralizer Joint Placement</td>
          <td style="width: 20%">Centralizer Type</td>
          <td style="width: 28%"></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ct of centralizeData; let idx = index">
          <td style="width: 16%">
            <input
              id="txt_end_0{{ idx }}"
              type="number"
              onkeyup="if(this.value < 0){this.value = this.value * -1}"
              min="0"
              size="5"
              class="form-control text-right"
              [(ngModel)]="ct.EndDepth"
              (blur)="validateNumber(ct, 2)"
              onfocus="this.select();"
            />
          </td>
          <td style="width: 16%">
            <input
              id="txt_start_0{{ idx }}"
              type="number"
              onkeyup="if(this.value < 0){this.value = this.value * -1}"
              min="0"
              size="5"
              class="form-control text-right"
              [(ngModel)]="ct.StartDepth"
              (blur)="validateNumber(ct, 1)"
              onfocus="this.select();"
            />
          </td>
          <td style="width: 20%">
            <select id="ddl_cjp_0{{ idx }}" class="form-control" [(ngModel)]="ct.CentralizerPlacement">
              <option>All Joint</option>
              <option>Every Other Joint</option>
              <option>Every 3rd Joint</option>
              <option>Every 4th Joint</option>
              <option>No Centralizer</option>
            </select>
          </td>
          <td style="width: 20%">
            <select id="ddl_type_0{{ idx }}" class="form-control" [(ngModel)]="ct.CentralizerType">
              <option>Semi-Rigid</option>
              <option>Rigid</option>
              <option>Bow Spring</option>
            </select>
          </td>
          <td style="width: 28%">
            <div class="fl">
              <button id="btn_add_cent_0{{ idx }}" type="button" class="btn btn-primary" (click)="addNewCentrilize()">
                <span class="divCenter"><i class="pi pi-plus-circle mr6"></i>Add</span>
              </button>
            </div>
            <div *ngIf="centralizeData.length > 1">
              <button
                id="btn_add_delete_0{{ idx }}"
                type="button"
                class="btn btn-danger ml4"
                (click)="deleteCentrilize(idx)"
              >
                <span class="divCenter"><i class="pi pi-trash mr6"></i>Delete</span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row">
    <div class="col-md-12" *ngIf="showCtlNumberExist">
      <span class="errorSpan">Start or End already exist, please verify.</span>
    </div>
    <div class="col-md-12" *ngIf="showCtlIncorrectNumber">
      <span class="errorSpan">Start is greater than End or End is smaller than start, please verify.</span>
    </div>
  </div>
  <p-footer>
    <button id="btn_cent_cancel" type="button" class="btn btn-danger" (click)="hideCentralize()">
      <span class="divCenter"><i class="pi pi-ban mr6"></i>Cancel</span>
    </button>
    <button id="btn_cent_apply" type="button" class="btn btn-success" (click)="centralizeCasings()">
      <span class="divCenter"><i class="pi pi-save mr6"></i>Apply</span>
    </button>
  </p-footer>
</p-dialog>
<!-- Centralize -->

<!-- Run/Un-Run -->
<p-dialog
  header="Multiple Run/Un-Run"
  [(visible)]="displayMultipleRunning"
  [baseZIndex]="5000"
  [modal]="true"
  [closable]="false"
>
  <div class="row">
    <span class="boldTextFont">Would you like to proceed with the multiple Run/Un-Run of the selected components?</span>
    <!-- <table class="table table-striped noborder">
      <thead>
        <tr>
          <td style="width: 50%;">Initial Sequence #</td>
          <td style="width: 50%;">Final Sequence #</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="width: 50%;">
            <input
              id="txt_multiple_start"
              type="number" onkeyup="if(this.value < 0){this.value = this.value * -1}" min="0"
              size="5"
              class="form-control text-right"
              [(ngModel)]="startSeq"
            />
          </td>
          <td style="width: 50%;">
            <input
              id="txt_multiple_end"
              type="number" onkeyup="if(this.value < 0){this.value = this.value * -1}" min="0"
              size="5"
              class="form-control text-right"
              [(ngModel)]="finalSeq"
            />
          </td>
        </tr>
      </tbody>
    </table> -->
  </div>
  <!-- <div class="row">
    <div class="col-md-12" *ngIf="showMultipleRunningError">
      <span class="errorSpan">Please verify the info you want to submmit.</span>
    </div>
  </div> -->
  <p-footer>
    <button id="btn_multi_run_cancel" type="button" class="btn btn-danger" (click)="hideMultipleRunUnRun()">
      <span class="divCenter"><i class="pi pi-ban mr6"></i>Cancel</span>
    </button>
    <button id="btn_multi_run_apply" type="button" class="btn btn-success" (click)="applyRunUnRun()">
      <span class="divCenter"><i class="pi pi-save mr6"></i>Accept</span>
    </button>
  </p-footer>
</p-dialog>
<!-- Run/Un-Run -->

<!-- Toast -->
<p-toast [style]="{ marginTop: '40px !important' }" key="runningNotify" [baseZIndex]="6000"></p-toast>
<p-toast
  [style]="{ marginTop: '40px !important' }"
  key="runningError"
  [baseZIndex]="6000"
  position="top-center"
></p-toast>
<!-- Toast -->
