<!-- Offline Activity -->
<div>
  <!-- Messages -->
  <!-- <div class="row">
    <p-messages id="dly_msg" [(value)]="_offlineActivity.notifyOA" [closable]="false"></p-messages>
  </div> -->
  <!-- Messages -->

  <!-- Content -->
  <div class="row">
    <!-- Left Panel -->
    <div class="leftTablesOA">
      <!-- Offline Activity List -->
      <div class="mb4">
        <span class="tabTitle titleActivity">Offline Activity</span>
        <button id="btn_new_activity" type="button" class="btn btn-success createActivity" (click)="openOA(false)">
          <i class="pi pi-plus"></i>
          <span class="ml6">Create Activity</span>
        </button>
      </div>
      <div class="tableLeftMainOA">
        <table class="w100">
          <tbody>
            <tr
              id="tr_select_oa_0{{ idx }}"
              *ngFor="let oa of _offlineActivity.OAList; let idx = index"
              [ngClass]="{ selected: oa.selected }"
              class="selectedBorder"
              (click)="selectOA(idx)"
            >
              <td class="p6">
                <table width="100%">
                  <tr>
                    <td>
                      <div class="row">
                        <div class="col-md-11 noPadding">
                          <span class="tabTitle">
                            <span class="rigTime">#{{ idx + 1 }} :</span>
                            {{ oa.Name.Value }}</span
                          >
                        </div>
                        <div class="col-md-1 noPadding">
                          <div id="div_edit_oa_0{{ idx }}" class="fl" (click)="openOA(true, idx)">
                            <i class="pi pi-pencil edtIcon"></i>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="_offlineActivity.OAHaveData()" class="centerNoData">
          <span>There are no Offline Activities to display at this moment.</span>
        </div>
      </div>
      <!-- Offline Activity List -->
      <br />
      <!-- Offline Activity Dates List -->
      <div class="mb4">
        <span class="tabTitle titleActivity">Offline Activity Dates</span>
        <button
          id="btn_new_oad"
          type="button"
          class="btn btn-success createActivity"
          [disabled]="_offlineActivity.OAHaveData()"
          (click)="openOAD(false)"
        >
          <i class="pi pi-plus"></i>
          <span class="ml6">Create Activity Date</span>
        </button>
      </div>
      <div class="tableLeftOA">
        <table class="w100">
          <tbody>
            <tr
              id="tr_select_oad"
              *ngFor="let oad of _offlineActivity.OADList; let idx = index"
              [ngClass]="{ selected: oad.selected }"
              class="selectedBorder"
              (click)="selectOAD(idx)"
            >
              <td class="p6">
                <table width="100%">
                  <tr>
                    <td>
                      <div class="row">
                        <div class="col-md-10 noPadding">
                          <span class="tabTitle">
                            <span class="rigTime">Comments:</span>
                            {{ oad.Comment.Value }}</span
                          >
                        </div>
                        <div class="col-md-2 noPadding mt10">
                          <div id="div_edit_oad_0{{ idx }}" class="fl" (click)="editOADDate(idx)">
                            <i class="pi pi-pencil edtIcon"></i>
                          </div>
                          <div id="div_delete_oad_0{{ idx }}" class="fl" (click)="deleteOAD(oad)">
                            <i class="pi pi-trash dltIcon"></i>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <br />
                </table>
                <table width="100%">
                  <tr>
                    <td class="w50" valign="bottom">
                      <span class="tabDesc">Start</span>
                    </td>
                    <td class="w50" valign="bottom">
                      <span class="tabDesc">End</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="w50" valign="bottom">
                      <span class="rigTime">{{ oad.StartDate.Value }}</span>
                    </td>
                    <td class="w50" valign="bottom">
                      <span class="rigTime">{{ oad.EndDate.Value }}</span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="!_offlineActivity.OADHaveData()" class="centerNoData">
          <span>Sorry, the selected Offline Activity has no Dates to display at this moment.</span>
        </div>
      </div>
      <!-- Offline Activity Dates List -->
    </div>
    <!-- Left Panel -->
    <!-- Offline Activity Content -->
    <div class="offlineContent">
      <div class="row">
        <!-- Offline Activity Desc -->
        <div id="oa" class="col-md-7">
          <div class="mt24">
            <span class="subTitle"> Comments </span>
          </div>
          <p *ngIf="_offlineActivity.OADObjectExist()">
            <span class="text-data">
              {{ _offlineActivity.OADObject.Comment.Value }}
            </span>
          </p>
          <div *ngIf="!_offlineActivity.OADObjectExist()" class="centerNoData">
            <span>There is no info to display.</span>
          </div>
        </div>
        <!-- Offline Activity Desc -->

        <!-- Offline Activity Content -->
        <div class="col-md-5">
          <div class="row mt24">
            <div class="col-md-4">
              <div>
                <span class="subTitle">Start</span>
              </div>
              <div *ngIf="_offlineActivity.OADObjectExist()">
                <span class="rigTime">{{ _offlineActivity.OADObject.StartDate.Value }}</span>
              </div>
            </div>
            <div class="col-md-4">
              <div>
                <span class="subTitle">End</span>
              </div>
              <div *ngIf="_offlineActivity.OADObjectExist()">
                <span class="rigTime">{{ _offlineActivity.OADObject.EndDate.Value }}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div>
                <span class="subTitle">Total Hours</span>
              </div>
              <div *ngIf="_offlineActivity.OADObjectExist()">
                <span class="rigTime">{{ _offlineActivity.OADTime | number: '1.2' }} hrs.</span>
              </div>
            </div>
            <div class="col-md-1">
              <div *ngIf="_offlineActivity.OADObjectExist()" id="div_edit_oad" class="fl" (click)="editOADDate()">
                <i class="pi pi-pencil edtIcon"></i>
              </div>
            </div>
            <div *ngIf="!_offlineActivity.OADObjectExist()" class="col-md-12 centerNoData">
              <span>There is no info to display.</span>
            </div>
          </div>
        </div>
        <!-- Offline Activity Content -->
      </div>
      <hr />
      <!-- OAD Timelog -->
      <app-timelog-oa (message)="showMessage($event)"></app-timelog-oa>
      <!-- OAD Timelog -->
    </div>
    <!-- Offline Activity Content -->
  </div>
  <!-- Content -->
</div>
<!-- Offline Activity -->

<!-- Create New / Edit Offline Activity -->
<p-dialog
  [(visible)]="showCreateNewOA"
  [style]="{ width: '400px', height: 'auto' }"
  [contentStyle]="{ overflow: 'auto' }"
  [baseZIndex]="5000"
  [modal]="true"
  [showHeader]="false"
  [closeOnEscape]="false"
>
  <form [formGroup]="formOA">
    <br />
    <div class="row">
      <div class="col-md-12">
        <span *ngIf="!isOAUpdate" class="titleCreateNew">Create Offline Activity</span>
        <span *ngIf="isOAUpdate" class="titleCreateNew">Edit Offline Activity</span>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="row"><span class="rigTime">Name</span></div>
        <div class="row">
          <div class="col-md-12 noPaddingLeft noPaddingRight">
            <input id="txt_oa_name" size="6" type="text" class="form-control" formControlName="name" />
          </div>
        </div>
      </div>
    </div>
    <br />
    <div *ngIf="showErrorCreateNewOA" class="row">
      <div class="col-md-12">
        <span class="errorSpan">- Please type a name.</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="fr">
          <button id="btn_create_oa" *ngIf="!isOAUpdate" type="button" class="btn btn-primary" (click)="createOA()">
            Create
          </button>
          <button id="btn_update_oa" *ngIf="isOAUpdate" type="button" class="btn btn-primary" (click)="updateOA()">
            Update
          </button>
          <button id="btn_cancel_oa" type="button" class="btn btn-danger ml8" (click)="closeOA()">Cancel</button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
<!-- Create New / Edit Offline Activity -->

<!-- Create New / Edit Offline Activity Date -->
<p-dialog
  [(visible)]="showCreateNewOAD"
  [style]="{ width: '650px', height: 'auto' }"
  [contentStyle]="{ overflow: 'auto' }"
  [baseZIndex]="5000"
  [modal]="true"
  [showHeader]="false"
  [closeOnEscape]="false"
>
  <form [formGroup]="formOAD">
    <br />
    <div class="row">
      <div class="col-md-12">
        <span *ngIf="!isOADUpdate" class="titleCreateNew">Create Offline Activity Date</span>
        <span *ngIf="isOADUpdate" class="titleCreateNew">Edit Offline Activity Date</span>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-5">
        <div class="row">
          <div>
            <span class="rigTime"><span class="mark-asterisk">*</span> Start Date</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 noPaddingLeft">
            <p-calendar
              id="dtp_start_date_oad"
              [showIcon]="true"
              [showTime]="true"
              formControlName="startDate"
            ></p-calendar>
          </div>
          <div *ngIf="showStartDateErrorOAD" class="col-md-12 noPaddingLeft">
            <span class="errorSpan">Date already exist, please verify.</span>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="row">
          <div>
            <span class="rigTime"><span class="mark-asterisk">*</span> End Date</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 noPaddingLeft">
            <p-calendar
              id="dtp_end_date_oad"
              [showIcon]="true"
              [showTime]="true"
              formControlName="endDate"
            ></p-calendar>
          </div>
          <div *ngIf="showEndDateErrorOAD" class="col-md-12 noPaddingLeft">
            <span class="errorSpan">Date already exist, please verify.</span>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="row">
          <div><span class="rigTime">Total Hours</span></div>
        </div>
        <div class="row">
          <div class="col-md-12 noPaddingLeft">
            <span class="rigTime">{{ _offlineActivity.OADTime | number: '1.2' }} hrs.</span>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="row"><span class="rigTime">Comments</span></div>
        <div class="row">
          <div class="col-md-12 noPaddingLeft noPaddingRight">
            <textarea style="height: 280px" id="txt_summary_oad" formControlName="comments"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <span class="errorSpan">NOTE: All the fields marked with (*) are required.</span>
      </div>
    </div>
    <br />
    <div *ngIf="showErrorCreateNewOAD" class="row">
      <div class="col-md-12">
        <span class="errorSpan">- Please fill all the fields marked with (*).</span>
      </div>
    </div>
    <div *ngIf="areDatesInvalids || this._offlineActivity.showOADTimeError" class="row">
      <div class="col-md-12">
        <span class="errorSpan"
          >- Please validate the Start and End date, consider time can not be more than 24 hrs.</span
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="fr">
          <button id="btn_create_oad" *ngIf="!isOADUpdate" type="button" class="btn btn-primary" (click)="createOAD()">
            Create
          </button>
          <button id="btn_update_oad" *ngIf="isOADUpdate" type="button" class="btn btn-primary" (click)="updateOAD()">
            Update
          </button>
          <button id="btn_cancel_oad" type="button" class="btn btn-danger ml8" (click)="closeOAD(true)">Cancel</button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
<!-- Create New / Edit Offline Activity Date -->

<!-- Toast -->
<p-toast position="center" key="c" (onClose)="onReject()" [modal]="true" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="tac">
      <i class="pi pi-exclamation-triangle fontSize3m"></i>
      <h3>{{ message.summary }}</h3>
      <p>{{ message.detail }}</p>
    </div>
    <div class="ui-g ui-fluid">
      <div class="ui-g-6">
        <button
          id="btn_toast_yes"
          type="button"
          pButton
          (click)="onConfirm()"
          label="Yes"
          class="ui-button-info"
        ></button>
      </div>
      <div class="ui-g-6">
        <button
          id="btn_toast_no"
          type="button"
          pButton
          (click)="onReject()"
          label="No"
          class="ui-button-secondary"
        ></button>
      </div>
    </div>
  </ng-template>
</p-toast>

<p-toast [style]="{ marginTop: '40px !important' }" key="OANotify" [baseZIndex]="6000"></p-toast>
<p-toast [style]="{ marginTop: '40px !important' }" key="OAError" [baseZIndex]="6000" position="top-center"></p-toast>
<!-- Toast -->
