<nav class="navbar navbar-default navbar-default-subsite centered" role="navigation">
  <!-- Brand and toggle get grouped for better mobile display -->
  <div class="visible-xs">
    <div class="navbar-header centered visible-xs">
      <div class="hamburger-mobile pull-left hidden-sm hidden-md hidden-lg dropdown dropdown-mobile">
        <a
          href="#"
          class="dropdown-toggle pull-left"
          data-toggle="dropdown"
          data-target="#navbar-brand-centered-subsite"
          aria-expanded="false"
        >
          <span class="sr-only"> Toggle navigation </span>
          <span class="glyphicon glyphicon-menu-hamburger black" aria-hidden="true"></span>

          <!-- Was Commented -->
          <!-- <span class="icon-text">menu</span> -->
        </a>
      </div>
    </div>
    <div class="navbar-brand navbar-brand-centered visible-xs">
      <div class="hallmark-container">
        <a class="hallmark" href="/" title="Chevron"
          ><img alt="Chevron" src="/assets/images/hallmark.png" width="36" height="40"
        /></a>
      </div>
      <div class="sitename-container vertical-align">
        <a class="sitename light-blue font-gotham" href="/">inside chevron</a>
      </div>
    </div>
    <div class="search search-mobile pull-right hidden-sm hidden-md hidden-lg dropdown dropdown-mobile">
      <a href="https://onesearch.sp.chevron.net/" class="dropdown-toggle pull-right">
        <span class="glyphicon glyphicon-search black" aria-hidden="true"></span>
        <span class="sr-only">toggle search</span>
      </a>
    </div>
  </div>
  <!-- desktop -->
  <br />
  <div
    class="collapse navbar-collapse js-navbar-collapse centered row hidden-xs font-gotham"
    id="navbar-brand-centered"
  >
    <ul class="nav navbar-nav navbar-left">
      <li class="dropdown dropdown-large">
        <a>
          <span class="black" aria-hidden="true">
            <app-welcome></app-welcome>
          </span>
        </a>
      </li>
    </ul>

    <!-- Was Commented -->
    <!-- <ul class="nav navbar-nav navbar-left" *ngIf="showAdminMenu">
      <li class="dropdown dropdown-large hamburger pull-left">
        <button type="button" class="navbar-toggle dropdown-toggle" data-toggle="dropdown"
          data-target="#navbar-hamburger-menu" aria-expanded="false">
          <span class="sr-only">Toggle navigation</span>
          <span class="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span>
          <span class="icon-text menu">Admin menu</span>
        </button>
      </li>
    </ul> -->
    <!-- Was Commented -->

    <div class="navbar-brand navbar-brand-centered">
      <div class="hallmark-container">
        <a class="hallmark" href="/" title="Chevron"
          ><img alt="Chevron" src="/assets/images/hallmark.png" width="42" height="46"
        /></a>
      </div>
      <div class="sitename-container vertical-align">
        <div>
          <span class="appName">DSR Workspace</span>
        </div>
        <div>
          <span>&nbsp;/&nbsp;</span>
        </div>
        <div>
          <span class="rigName">{{ environmentName }}</span>
        </div>
      </div>
    </div>

    <ul class="nav navbar-nav navbar-right black">
      <li class="dropdown dropdown-large search pull-right">
        <a href="https://inside.chevron.com/" class="onesearch-button" tabindex="0" target="_blank">
          <span class="glyphicon glyphicon-search black hidden-xs" aria-hidden="true"></span>
          <span class="icon-text hidden-sm">One Search</span>
        </a>
      </li>
      <li class="dropdown dropdown-large search pull-right">
        <app-feedback></app-feedback>
      </li>
    </ul>
  </div>
</nav>

<nav class="navbar navbar-subsite centered" role="navigation">
  <!--navbar-fixed-top-->
  <div class="container-fluid">
    <div
      class="collapse navbar-collapse js-navbar-collapse centered row font-gotham navbar-mobile dropdown-menu *dropdown-menu-mobile"
      id="navbar-brand-centered-subsite"
    >
      <div *ngIf="_tooltip.enableNavbar" class="mobile-nav-container vertical-align flex-center centered">
        <div
          *ngIf="showWellsPage && showGoBack"
          class="dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue w100i"
          style="color: #ffffff"
        >
          <span class="boldTextFont" (click)="goBack()">Go Back</span>
        </div>
        <!--width-1280-->
        <ul [ngClass]="{ 'ml-78i': showGoBack }" class="nav navbar-nav primary">
          <li
            *ngIf="showWellsPage"
            class="dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue"
            (click)="redirect('/wells')"
          >
            <a>Wells</a>
            <!-- <a  [routerLink]="['/wells']">Wells</a> -->
          </li>
          <li
            *ngIf="showWellsPage"
            class="dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue"
            (click)="redirect('/settings')"
          >
            <a>Settings</a>
            <!-- <a [routerLink]="['/settings']">Settings</a> -->
          </li>

          <!-- Was Commented -->
          <!-- <li class="dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
            <a [routerLink]="['/sync']">Sync</a>
          </li>
          <li class="col-sm-8"></li>
          <li *ngIf="!showAdminMenu"
            class="col-sm-4 dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
          </li> -->

          <li
            *ngIf="showAdminMenu"
            class="dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue"
            (click)="redirect('/admin/users')"
          >
            <a> Administration </a>
            <!-- <a [routerLink]="['/admin/users']"> Administration </a> -->
            <!-- <div class="dropdown-menu row row-offcanvas row-offcanvas-right">
              <div class="sidebar-offcanvas">
                <div class="sub-menu clear-both">
                  <div class="nav-header nav-header-2 visible-xs">
                    <a href='#' class="offcanvas-link vertical-align ie10-flex-start">
                      <span class="link-text">nav-header-2</span>
                    </a>
                  </div>
                  <ul class="list-unstyled icon-list">
                    <li class="level-2 menu-hover" (click)="redirect('/admin/users')">
                      <a>Users</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->
          </li>
          <li
            class="dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue"
            (click)="redirect('/help')"
          >
            <a>Help</a>
            <!-- <a [routerLink]="['/help']">Help</a> -->
          </li>
          <!-- Was Commented -->
        </ul>
        <div
          role="dialog"
          tabindex="-1"
          aria-label="Utility Menu"
          id="navbar-hamburger-menu"
          class="utility-nav dropdown"
        >
          <div class="dropdown-menu show-xs">
            <div class="small-nav-container">
              <div class="small-nav small-nav-corp-links">
                <!-- visible-xs -->
                <hr class="hr-mobile-nav visible-xs" />

                <ul class="list-unstyled icon-list">
                  <li class="menu-hover">
                    <a [routerLink]="['/admin/business-units']">Business Units</a>
                  </li>
                </ul>
              </div>
              <div class="small-nav small-nav-corp-links">
                <!-- visible-xs -->
                <hr class="hr-mobile-nav visible-xs" />

                <ul class="list-unstyled icon-list">
                  <li class="menu-hover">
                    <a [routerLink]="['/admin/organizations']">Organizations</a>
                  </li>
                </ul>
              </div>
              <div class="small-nav small-nav-corp-links">
                <!-- visible-xs -->
                <hr class="hr-mobile-nav visible-xs" />

                <ul class="list-unstyled icon-list">
                  <li class="menu-hover">
                    <a [routerLink]="['/admin/task-plan']">Task Plan Template</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="mobile-nav-backdrop visible-xs"></div> -->
    </div>
  </div>
</nav>
