import { AuthService } from '#services-shared/index';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  //#region 'Angular Life Cycle'
  constructor(private authSvc: AuthService, private router: Router) {}

  ngOnInit() {
    if (this.authSvc.isAuthorized() && this.authSvc.isInUserTable()) {
      this.router.navigate(['']);
    } else {
      this.authSvc.login();
    }
  }
  //#endregion 'Angular Life Cycle'
}
