import { NotificationToast } from '#models/index';
import { UserApiService } from '#services-api/index';
import { AuthService, LoadingIndicatorService } from '#services-shared/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [MessageService],
})
export class SettingsComponent implements OnInit, OnDestroy {
  //#region 'Variables'
  public profiles: Array<String> = [];
  public units: Array<String> = [];

  // displayed settings on webpage
  public userName: string;
  public userUnit: string;
  public userProfile: string;

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle''
  constructor(
    private _user: UserApiService,
    private _auth: AuthService,
    private _router: Router,
    private _loader: LoadingIndicatorService,
    private _message: MessageService
  ) {}

  ngOnInit() {
    // populate dropdown menus
    this.retrieveProfile();
    this.retrieveUnits();

    // populate initial variables of user settings from server
    this.userName = `${this._auth.getCurrentUser().FirstName} ${this._auth.getCurrentUser().LastName}`;
    this.userUnit = this._auth.getCurrentUser().UnitSet;
    this.userProfile = this._auth.getCurrentUser().Profile;
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
    this._loader.hide();
  }
  //#endregion 'Angular Life Cycle''

  //#region 'General Methods'
  async retrieveProfile() {
    this._loader.show();
    this.SUBS$.add(
      await this._user
        .getProfiles()
        .finally(() => this._loader.hide())
        .subscribe(
          (data: Array<String>) => {
            this.profiles = data;
          },
          () => {
            this.showNotification({
              key: 'StError',
              severity: 'error',
              summary: 'We got a problem!',
              detail: 'There was an error loading settings, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
    );
  }

  async retrieveUnits() {
    this._loader.show();
    this.SUBS$.add(
      await this._user
        .getUnits()
        .finally(() => this._loader.hide())
        .subscribe(
          (data: Array<String>) => {
            this.units = data;
          },
          () => {
            this.showNotification({
              key: 'StError',
              severity: 'error',
              summary: 'We got a problem!',
              detail: 'There was an error loading settings, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
    );
  }

  public enableEnvironment() {
    this._router.navigate(['settings/env']);
  }
  //#endregion 'General Methods'

  //#region 'CRUD'
  async updateSettings() {
    // push local variables to logged user in authSVC
    this._auth.getCurrentUser().UnitSet = this.userUnit;
    this._auth.getCurrentUser().Profile = this.userProfile;

    // update
    this._loader.show();
    this.SUBS$.add(
      await this._user
        .update(this._auth.getCurrentUser())
        .finally(() => this._loader.hide())
        .subscribe(
          () => {
            this.showNotification({
              severity: 'success',
              summary: 'Correct!',
              detail: `User settings have been updated, measurements now displayed in ${this.userUnit} units`,
            });
          },
          () => {
            this.showNotification({
              key: 'StError',
              severity: 'error',
              summary: 'We got a problem!',
              detail: 'There was an error saving settings, please refresh the page or notify the administrator.',
              life: 6000,
            });
          }
        )
    );
  }
  //#endregion 'CRUD'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._message.add({
      key: msg && msg.key && msg.key.length > 0 ? msg.key : 'StNotify',
      sticky: msg && msg.key && msg.key != 'StNotify' ? true : false,
      closable: msg && msg.key && msg.key != 'StNotify' ? true : false,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life,
    });
  }
  //#endregion 'Notification'
}
